import { isInvisibleUser } from 'helpers/userHelper';

export const selectUserInfo = state => {
  return {
    ...state?.userInfo,
    isInvisibleUser: isInvisibleUser(state?.userInfo?.userInfo),
  };
};

export const selectShouldInitiateUserInfoLoad = (
  state,
  overrideLoggedIn = false,
) => {
  return (
    (state?.app?.loggedIn || overrideLoggedIn) &&
    !state?.userInfo?.userInfo &&
    !state?.userInfo?.userInfoError &&
    !state.userInfo?.userInfoLoading
  );
};

export const selectShouldInitiateCreditInfoLoad = state => {
  return (
    state?.app?.loggedIn &&
    !state?.creditInfo?.creditInfo &&
    !state?.creditInfo?.creditInfoError &&
    !state.creditInfo?.creditInfoLoading
  );
};

export const selectCreditInfo = state => {
  const { creditInfo } = state;

  return {
    creditInfo: creditInfo?.creditInfo,
    creditInfoLoading: creditInfo?.creditInfoLoading,
    creditInfoError: creditInfo?.creditInfoError,
  };
};

export const selectFeaturedAds = state => {
  const { featuredAd } = state;

  return {
    featuredAds: featuredAd?.featuredAds,
    featuredAdsLoading: featuredAd?.featuredAdsLoading,
    featuredAdsError: featuredAd?.featuredAdsError,
  };
};

export const selectConfig = state => {
  const configState = state.config;
  const { config, configLoading: loading, configError: error } = configState;

  return {
    config: config,
    configLoading: loading,
    configError: error,
  };
};

export const selectSesameCash = state => {
  return {
    cashAccount: state.sesameCash.cashAccount,
    cashAccountLoading: state.sesameCash.cashAccountLoading,
    cashAccountError: state.sesameCash.cashAccountError,
    allCardDetails: state.sesameCash.allCardDetails,
    allCardDetailsLoading: state.sesameCash.allCardDetailsLoading,
    allCardDetailsError: state.sesameCash.allCardDetailsError,
    cashPinToken: state.sesameCash.cashPinToken,
    cashPinTokenLoading: state.sesameCash.cashPinTokenLoading,
    cashPinTokenSuccess: state.sesameCash.cashPinTokenSuccess,
    cashPinTokenError: state.sesameCash.cashPinTokenError,
    cashPinTokenExpireTime: state.sesameCash.cashPinTokenExpireTime,
  };
};

export const selectShouldInitiateConfigLoad = state => {
  return state?.app?.loggedIn && !state?.config?.config;
};

export const selectShouldSesameCashLoad = state => {
  const isPinTokenLoaded =
    state.sesameCash.cashPinToken || state.sesameCash.cashPinTokenError;

  const isCashAccountLoaded =
    state.sesameCash.cashAccount || state.sesameCash.cashAccountError;

  const isAllCardDetailsLoaded =
    state.sesameCash.allCardDetails || state.sesameCash.allCardDetailsError;

  return (
    state?.app?.loggedIn &&
    !(isPinTokenLoaded || isCashAccountLoaded || isAllCardDetailsLoaded)
  );
};

export const selectShouldFeaturedAdLoad = state => {
  return (
    state?.app?.loggedIn &&
    !state?.featuredAds?.featuredAds &&
    !state?.featuredAds?.featuredAdsError &&
    !state.featuredAds?.featuredAdsLoading
  );
};

export const selectOcfInfo = state => {
  return {
    loggedIn: state.app.loggedIn,
    loggingIn: state.app.loggingIn,
    ocfLoggedIn: state.app.ocfLoggedIn,
    ocfInfo: state.ocfInfo.ocfInfo,
  };
};
