export const CLICK = 'CLICK';
export const CLICK_APPLY = 'CLICK_APPLY';
export const INTERNAL_EMAIL_REDIRECT = 'INTERNAL_EMAIL_REDIRECT';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SEEN_OFFER = 'SEEN_OFFER';
export const VIEW_ALERT = 'VIEW_ALERT';
export const VIEW_MODAL = 'VIEW_MODAL';
export const VIEW_MODULE = 'VIEW_MODULE';
export const SEEN_MODULE = 'SEEN_MODULE';
export const VIEW_OFFER = 'VIEW_OFFER';
export const VIEW_PAGE = 'VIEW_PAGE';
export const VIEW_POPUP = 'VIEW_POPUP';
export const DISMISS_POPUP = 'DISMISS_POPUP';
//export const PIXEL_FIRED = 'PIXEL_FIRED';
