/*


const dispatch = useDispatch();
const a_fetchMarketplaceCreditCards = wrapService(fetchMarketplaceCreditCards, {
  name: "CREDIT_CARD_MARKETPLACE"
});

 */

export const wrapService = (s, config) => {
  if (!s) throw new Error('Service cannot be null');
  if (!config) throw new Error('Config cannot be null');
  if (typeof s !== 'function') throw new Error('Service must be a function');

  return (...args) =>
    (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        s(...args)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    };
};

/*
    View - HTML Templates/ Components
    Controller/Partial - connect to redux and pass data to the view
    action/hook
              ----------------------
    reducer----SLAPI------BANKING------external services


 */
