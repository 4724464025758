import {
  SELECT_CREDIT_REPORT_TAB_DATA,
  RESET_CREDIT_REPORT_TAB_CONTAINER_DATA,
} from 'types/creditReportTabContainer.constans';
import { LOGOUT } from 'types/app.constants';

const initialState = {
  creditReportTabContainer: null,
  containerLoading: false,
  containerError: null,
};

export default function creditReportTabContainerReducer(
  state = initialState,
  action,
) {
  switch (action?.type) {
    case SELECT_CREDIT_REPORT_TAB_DATA: {
      return {
        ...state,
        creditReportTabContainer: action?.creditReportTabContainer,
        containerLoading: action?.containerLoading,
        containerError: action?.containerError,
      };
    }
    case LOGOUT:
    case RESET_CREDIT_REPORT_TAB_CONTAINER_DATA:
      return initialState;
    default:
      return state;
  }
}
