import { formatDate } from 'helpers/dateHelper';
import { formatUSD } from 'helpers/money.helper';
import {
  CREDIT_MANAGER_TAGS,
  TIME_LINES,
  GRADE_CONFIG,
  CREDIT_BUILDER_CONFIG,
  CREDIT_REPORT_CONFIG,
  INQUIRIES_CONFIG,
  RENT_REPORTING_CONFIG,
  CREDIT_USAGE_CONFIG,
  SCROLL_FOCUS_MODULES,
  PAST_TIME_LINES,
  FUTURE_TIME_LINES,
  TIME_LINE_TODAY,
  LOAN_TYPE_ACCOUNT_TYPES,
} from './CreditManager.constants';
import { cloneDataObject, emptyObj } from 'helpers/objectHelpers';
import { isEmpty } from 'helpers/string.helper';
import { uuidv4 } from 'helpers/number';

const formatField = (field, value) => {
  const { pattern, operation, quantityDecimals, ifNullGenerateId, prefixId } =
    field;
  switch (field?.type) {
    case 'date':
      return pattern ? formatDate(value, pattern) : value;
    case 'money':
      return formatUSD(value);
    case 'number':
      if (operation === 'absolute') {
        return Math.abs(value);
      } else {
        return value;
      }
    case 'percentage':
      return value?.toFixed?.(quantityDecimals) ?? value;
    default: {
      if (ifNullGenerateId) {
        const prefix = prefixId ?? 'card-hash-';
        if (!value) {
          return `${prefix}${uuidv4()}`;
        } else {
          return `${prefix}${value?.toString()}`;
        }
      } else {
        return value;
      }
    }
  }
};

const formatDataStructure = props => {
  const { data, map } = props || {};
  if (!map) return null;
  let newData = {};
  map?.forEach(field => {
    const { name, parent } = field || {};
    let value;
    parent?.forEach(parentName => {
      if (!value) {
        value = data[parentName];
      } else {
        value = value[parentName];
      }
    });
    if (!value) {
      value = data;
    }
    newData[name] = formatField(field, value[name]);
  });
  return newData;
};

const getTimeLineId = creditManagerTags => {
  let timeLineId = null;
  creditManagerTags?.forEach(tag => {
    TIME_LINES?.forEach(tl => {
      if (tag === tl.timeLineId && !timeLineId) {
        timeLineId = tag;
      }
    });
    if (timeLineId) return null;
  });
  return timeLineId;
};

export const formatTradelines = response => {
  const timeLines = TIME_LINES.map(tl => cloneDataObject(tl));
  response?.modules
    ?.filter(module => module?.additionalData)
    ?.forEach(module => {
      module?.additionalData?.tradelines?.forEach(tradeline => {
        const creditManagerTags = tradeline?.creditManagerTags;
        const timeLineId = getTimeLineId(creditManagerTags);
        creditManagerTags
          ?.filter(creditManagerTag => creditManagerTag !== timeLineId)
          ?.forEach(creditManagerTag => {
            if (timeLineId) {
              const index = timeLines?.findIndex(
                tl => tl?.timeLineId === timeLineId,
              );
              const newTradeline = formatDataStructure({
                data: tradeline,
                map: CREDIT_MANAGER_TAGS[creditManagerTag],
              });
              if (newTradeline) {
                if (index === -1) {
                  timeLines.push({
                    timeLineId,
                    [creditManagerTag]: [newTradeline],
                  });
                } else {
                  timeLines[index].timeLineId = timeLineId;
                  if (!timeLines[index]?.modules?.[creditManagerTag]) {
                    timeLines[index].modules = {
                      ...timeLines[index]?.modules,
                      [creditManagerTag]: [newTradeline],
                    };
                  } else {
                    timeLines[index]?.modules?.[creditManagerTag]?.push(
                      newTradeline,
                    );
                  }
                }
              }
            }
          });
      });
    });
  return timeLines;
};

export const fillTimeLines = props => {
  const { timeLineId, timeLines, creditManagerTag, data, map } = props;
  if (timeLineId) {
    const index = timeLines?.findIndex(tl => tl?.timeLineId === timeLineId);
    const newData = formatDataStructure({ data, map });
    if (newData) {
      if (index === -1) {
        timeLines.push({
          timeLineId,
          [creditManagerTag]: [newData],
        });
      } else {
        timeLines[index].timeLineId = timeLineId;
        if (!timeLines[index]?.modules?.[creditManagerTag]) {
          timeLines[index].modules = {
            ...timeLines[index]?.modules,
            [creditManagerTag]: [newData],
          };
        } else {
          timeLines[index]?.modules?.[creditManagerTag]?.push(newData);
        }
      }
    }
  }
};

export const formatTagsAccounts = (response, config) => {
  const timeLines = TIME_LINES.map(tl => cloneDataObject(tl));
  response?.modules
    ?.filter(module => module?.additionalData)
    ?.forEach(module => {
      const { identifier, map, creditManagerTag } = config;
      const dataRow = module?.additionalData?.[identifier];
      if (dataRow) {
        if (Array.isArray(dataRow)) {
          dataRow?.forEach(data => {
            const creditManagerTags = data?.creditManagerTags;
            const timeLineId = getTimeLineId(creditManagerTags);
            fillTimeLines({
              timeLineId,
              timeLines,
              creditManagerTag,
              data,
              map,
            });
          });
        } else {
          const creditManagerTags = dataRow?.creditManagerTags;
          const timeLineId =
            getTimeLineId(creditManagerTags) ?? config?.timeLineId;
          fillTimeLines({
            timeLineId,
            timeLines,
            creditManagerTag,
            data: dataRow,
            map,
          });
        }
      }
    });
  return timeLines;
};

export const formatTodayAccounts = (response, config) => {
  const { identifier, map, creditManagerTag, timeLineId } = config;
  const timeLines = TIME_LINES.map(tl => cloneDataObject(tl));
  response?.modules
    ?.filter(module => module?.additionalData)
    ?.forEach(module => {
      const dataRow = module?.additionalData?.[identifier];
      if (dataRow) {
        if (Array.isArray(dataRow)) {
          dataRow?.forEach(data => {
            fillTimeLines({
              timeLineId,
              timeLines,
              creditManagerTag,
              data,
              map,
            });
          });
        } else {
          fillTimeLines({
            timeLineId,
            timeLines,
            creditManagerTag,
            data: dataRow,
            map,
          });
        }
      }
    });
  return timeLines;
};

export const formatCreditManagerResponse = (response, showRentReporting) => {
  const tradelines = formatTradelines(response);
  const creditReports = formatTagsAccounts(response, CREDIT_REPORT_CONFIG);
  const gradeChanges = formatTodayAccounts(response, GRADE_CONFIG);
  const creditBuilders = formatTodayAccounts(response, CREDIT_BUILDER_CONFIG);
  const inquiries = formatTagsAccounts(response, INQUIRIES_CONFIG);
  const rentReportings = formatTagsAccounts(response, RENT_REPORTING_CONFIG);
  const creditUsages = formatTodayAccounts(response, CREDIT_USAGE_CONFIG);
  const modules = tradelines?.map((tradeline, index) => {
    const creditReport = creditReports[index];
    const gradeChange = gradeChanges[index];
    const creditBuilder = creditBuilders[index];
    const inquiry = inquiries[index];
    const rentReporting = showRentReporting ? rentReportings[index] : {};
    const creditUsage = creditUsages[index];
    let modules = {
      ...tradeline?.modules,
      ...creditReport?.modules,
      ...gradeChange?.modules,
      ...creditBuilder?.modules,
      ...inquiry?.modules,
      ...rentReporting?.modules,
      ...creditUsage?.modules,
    };
    if (isEmpty(modules)) modules = undefined;
    return {
      ...tradeline,
      modules,
    };
  });
  let tradelineMostRecent = null;
  let mostRecentPastEvent = null;
  let mostRecentUpcommingEvent = null;
  modules?.forEach(tradeline => {
    const { timeLineId, modules } = tradeline || {};
    if (
      !mostRecentPastEvent &&
      PAST_TIME_LINES.includes(timeLineId) &&
      !emptyObj(modules)
    ) {
      mostRecentPastEvent = tradeline;
    }
    if (
      !mostRecentUpcommingEvent &&
      [TIME_LINE_TODAY, ...FUTURE_TIME_LINES].includes(timeLineId) &&
      !emptyObj(modules)
    ) {
      mostRecentUpcommingEvent = tradeline;
    }

    SCROLL_FOCUS_MODULES?.forEach(name => {
      const delinquentModule = tradeline?.modules?.[name];
      if (delinquentModule) {
        delinquentModule?.forEach(item => {
          const daysAgo = item?.creditManagerDateReportedInDays;
          const daysMR = tradelineMostRecent?.creditManagerDateReportedInDays;
          if (!tradelineMostRecent || daysAgo < daysMR) {
            tradelineMostRecent = {
              ...item,
              timeLineId: tradeline?.timeLineId,
            };
          }
        });
      }
    });
  });
  const emptyTodayModules = !modules?.find(({ timeLineId, modules }) => {
    return timeLineId === TIME_LINE_TODAY && !emptyObj(modules);
  });

  return {
    moduleCards: modules,
    tradelineMostRecent,
    mostRecentPastEvent,
    mostRecentUpcommingEvent,
    emptyTodayModules,
  };
};

export const getAccountDetail = ({ card, modulePosition, prefix }) => {
  const {
    idHash,
    creditor,
    balance,
    apr,
    totalMonthlyPayments,
    creditLimit,
    accountCondition,
    dateOpened,
    creditTypeSymbol,
    creditManagerDateReported,
    loanType2,
  } = card;

  const aprValid = apr ?? 0;

  let newIdHash = idHash;
  if (prefix) {
    newIdHash = idHash?.split(prefix)?.[1];
  }

  const data = {
    idHash: newIdHash,
    apr: aprValid,
    calculatedApr: aprValid,
    aprUserUpdated: false,
    balance,
    monthlyPayment: totalMonthlyPayments,
    creditLimit,
    name: creditor,
    status: accountCondition,
    openedDate: dateOpened,
    loanType2,
    creditTypeSymbol,
  };

  const trackingDetails = {
    debtType: LOAN_TYPE_ACCOUNT_TYPES[loanType2],
    pagePosition: modulePosition,
    accountName: creditor,
  };

  return {
    visible: true,
    type: LOAN_TYPE_ACCOUNT_TYPES[loanType2],
    data,
    reportPulledDate: creditManagerDateReported,
    factor: 'Account Mix',
    trackingDetails,
    isOpen: accountCondition === 'Open',
    datatestid: 'account-detail',
  };
};
