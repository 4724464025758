import { createLogger } from 'helpers/logger';

const logger = createLogger({
  name: 'data_dome_listeners',
  categories: ['data_dome_listeners'],
});

const listeners = [
  {
    event: 'dd_ready',
  },
  {
    event: 'dd_post',
  },
  {
    event: 'dd_post_done',
  },
  {
    event: 'dd_blocked',
  },
  {
    event: 'dd_captcha_displayed',
  },
  {
    event: 'dd_captcha_error',
  },
  {
    event: 'dd_captcha_passed',
  },
];

listeners.forEach(x => {
  window.addEventListener(x.event, () => {
    const event = x.event;
    logger.info(`DataDome: ${event}`);
  });
});
