import { DOMAIN } from 'helpers/env';

export const getCookieValue = name => {
  var value = '; ' + document.cookie;

  var parts = value.split('; ' + name + '=');
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  } else {
    return null;
  }
};
export const setCookieValue = (name, value) => {
  document.cookie = name + '=' + value + ';';
};
// set crossDomain to true to set cookie at top level ".creditsesame.com" domain
export const setCookieWithExpiration = (
  name,
  value,
  days,
  hours,
  mins,
  crossDomain,
) => {
  let expires = '',
    domain = '',
    setExpiration = false,
    expiryTime = new Date();

  if (typeof days === 'number' && days > 0) {
    expiryTime.setMilliseconds(expiryTime.getMilliseconds() + days * 864e5);
    setExpiration = true;
  }

  if (typeof hours === 'number' && hours > 0) {
    expiryTime.setMilliseconds(
      expiryTime.getMilliseconds() + hours * 60 * 60 * 1000,
    );
    setExpiration = true;
  }

  if (typeof mins === 'number' && mins > 0) {
    expiryTime.setMilliseconds(expiryTime.getMilliseconds() + mins * 60 * 1000);
    setExpiration = true;
  }

  if (setExpiration) {
    expires = '; expires=' + expiryTime.toUTCString();
  }

  if (crossDomain) {
    domain = `; domain=.${DOMAIN}`;
  }

  document.cookie = [
    encodeURIComponent(name),
    '=',
    encodeURIComponent(value),
    '; path=/',
    domain,
    '; secure',
    expires,
  ].join('');
};
export const deleteCookieValue = name => {
  deleteCookieWithOpts(name);
};
export const deleteCookieValueForCSDomain = name => {
  deleteCookieWithOpts(name, true, `.${DOMAIN}`, '/');
};
export const deleteCookieWithOpts = (name, secure, domain, path) => {
  let newVal = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  if (path) newVal += ` path=${path};`;
  if (secure === true) newVal += ` secure=True;`;
  if (secure === false) newVal += ` secure=False;`;
  if (domain) newVal += ` domain=${domain};`;

  document.cookie = newVal;
};
