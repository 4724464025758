import {
  AFF_SIGNUP_DOB_SSN_FORM,
  AFF_SIGNUP_INFO_FORM,
} from 'types/mrph.pages.constants';

export const affiliateSignupConstants = {
  LOADING_START: 'AFFILIATE_SIGNUP_LOADING_START',
  LOADING_FINISH: 'AFFILIATE_SIGNUP_LOADING_FINISH',
  DECRYPTION_LOADING_START: 'AFFILIATE_SIGNUP_DECRYPTION_LOADING_START',
  DECRYPTION_LOADING_FINISH: 'AFFILIATE_SIGNUP_DECRYPTION_LOADING_FINISH',
  DECRYPT_DATA_SUCCESS: 'AFFILIATE_SIGNUP_DECRYPT_DATA_SUCCESS',
  CLEAR_TU_ERROR: 'AFFILIATE_SIGNUP_CLEAR_TU_ERROR',
  REPORT_TU_ERROR: 'AFFILIATE_SIGNUP_REPORT_TU_ERROR',
  UPDATE_ADDRESS_FAIL: 'AFFILIATE_SIGNUP_UPDATE_ADDRESS_FAIL',
  CREATE_USER_SUCCESS: 'AFFILIATE_SIGNUP_CREATE_USER_SUCCESS',
  UPDATE_PASSWORD_SUCCESS: 'AFFILIATE_SIGNUP_UPDATE_PASSWORD_SUCCESS',
  SET_SIGNUP_STEP: 'AFFILIATE_SIGNUP_SET_SIGNUP_STEP',
  CLEAR_AFFILIATE_SIGNUP_STATE: 'CLEAR_AFFILIATE_SIGNUP_STATE',
  ENABLE_ERROR_PATH: 'AFFILIATE_SIGNUP_ENABLE_ERROR_PATH',
};

export const optEventsAffiliateSignup = {
  [AFF_SIGNUP_DOB_SSN_FORM]: 'viewed_affiliate_signup_dob_ssn_form',
  [AFF_SIGNUP_INFO_FORM]: 'viewed_affiliate_signup_info_form',
};

export const optEventsSignupSubmit = {
  [AFF_SIGNUP_DOB_SSN_FORM]: 'viewed_affiliate_signup_dob_ssn_form',
  [AFF_SIGNUP_INFO_FORM]: 'affiliate_signup_info_form_submit',
};
