import moment from 'moment';
import {
  getApiKeyLogoutTime,
  getOcfLogoutTime,
  getUserLogoutTime,
} from 'helpers/localstorage';

export const genericGetSessionTimeout = sessionTime => {
  const time = moment(sessionTime);

  if (!time.isValid()) {
    return null;
  } else {
    return time;
  }
};

export const getSessionTimeout = () => {
  const sessionTime = getUserLogoutTime();
  return genericGetSessionTimeout(sessionTime);
};

export const getOcfSessionTimeout = () => {
  const sessionTime = getOcfLogoutTime();
  return genericGetSessionTimeout(sessionTime);
};

export const getApiKeySessionTimeout = () => {
  const sessionTime = getApiKeyLogoutTime();
  return genericGetSessionTimeout(sessionTime);
};

export const isSessionValid = () => {
  const sessionTimeout = getSessionTimeout();
  if (sessionTimeout === null) {
    return false;
  } else {
    const now = moment();

    return sessionTimeout.isAfter(now);
  }
};

export const isOcfSessionValid = () => {
  const sessionTimeout = getOcfSessionTimeout();
  if (sessionTimeout === null) {
    return false;
  } else {
    const now = moment();
    return sessionTimeout.isAfter(now);
  }
};
