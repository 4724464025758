export const IMPROVE_YOUR_SCORE = 'IMPROVE_YOUR_SCORE';
export const SAVE_FOR_A_RAINY_DAY = 'SAVE_FOR_A_RAINY_DAY';
export const FIND_MONTHLY_SAVINGS = 'FIND_MONTHLY_SAVINGS';
export const FIND_PREQUALIFIED_OFFERS = 'FIND_PREQUALIFIED_OFFERS';
export const IMPROVE_YOUR_CREDIT_GRADE_TO_BUILD_CREDIT =
  'IMPROVE_YOUR_CREDIT_GRADE_TO_BUILD_CREDIT';
export const INSURANCE_FOR_PEACE_OF_MIND = 'INSURANCE_FOR_PEACE_OF_MIND';
export const FIND_REWARDS = 'FIND_REWARDS';
export const DEBT_RELIEF = 'DEBT_RELIEF';

export const NEW_CC_SCORE_OVER_TIME = 'NEW_CC_SCORE_OVER_TIME';
export const NEW_CARD_SCORE_INCREASE = 'NEW_CARD_SCORE_INCREASE';
export const CREDIT_REPAIR_EXPERT = 'CREDIT_REPAIR_EXPERT';
export const NEGATIVE_MARKS = 'NEGATIVE_MARKS';
export const SELF_LENDER_SCORE_OVER_TIME = 'SELF_LENDER_SCORE_OVER_TIME';
export const HIGHER_CREDIT_LIMIT = 'HIGHER_CREDIT_LIMIT';
export const CREDIT_FOUNDATION = 'CREDIT_FOUNDATION';
export const CREDIT_USAGE = 'CREDIT_USAGE';
export const AUTO_REFI = 'AUTO_REFI';
export const REWARDS = 'REWARDS';
export const PL_PQ_OFFERS = 'PL_PQ_OFFERS';
export const AUTO_INSURANCE_FOR_CREDIT_SCORE_INCREASE =
  'AUTO_INSURANCE_FOR_CREDIT_SCORE_INCREASE';
export const HOME_INSURANCE_FOR_NEW_HOME = 'HOME_INSURANCE_FOR_NEW_HOME';
export const AUTO_INSURANCE_FOR_NEW_AUTO_LOAN =
  'AUTO_INSURANCE_FOR_NEW_AUTO_LOAN';
export const CREDIT_SCORE_650 = 650;
