import { CREDIT_SCORE_650 } from 'pages/Goals/goals.constants';
import { isFreeTrial, isPremium } from './userHelper';
import {
  isCb20Enrolled,
  isEnrolledInCash,
  isNotEnrolled,
} from './sesameCash.helpers';
import {
  CB2_RR_UPSELL_GOALS,
  CB2_RR_UPSELL_GOALS_CB2,
  CB2_RR_UPSELL_GOALS_RR,
} from 'types/goals.constants';
import { PREMIUM_FEATURE_RENT_REPORTING } from 'types/premium.constants';

export const searchGoalModule = (goals, searchModule) => {
  let moduleFound = {};
  if (goals) {
    goals.forEach(goal =>
      goal.actions.forEach(action => {
        if (action.name === searchModule) {
          moduleFound = action;
        }
      }),
    );
  }
  return moduleFound;
};

export const qualifyToRentReporting = props => {
  const { userInfo, creditInfo } = props;
  return (
    userInfo?.rentReporting == null &&
    (creditInfo?.creditScore ?? Number.MAX_VALUE) <= CREDIT_SCORE_650
  );
};

export const qualifyToCreditBuilder = props => {
  const { sesameCashAccount, creditInfo } = props;
  return (
    !sesameCashAccount?.securedCard?.cbVersion &&
    (creditInfo?.creditScore ?? Number.MAX_VALUE) <= CREDIT_SCORE_650
  );
};

export const getPathForRentReporting = props => {
  const { userInfo } = props;
  let linkPathRR = '/plus?feature=' + PREMIUM_FEATURE_RENT_REPORTING;
  if (isFreeTrial(userInfo) || isPremium(userInfo)) {
    linkPathRR = '/rentPaymentReportingHome';
  }
  return linkPathRR;
};

export const getPathForCreditBuilder2 = props => {
  const { userInfo, sesameCashAccount } = props;
  let linkPathCB2 = '/dashboard';
  if (isNotEnrolled(userInfo)) {
    linkPathCB2 = '/cash';
  } else if (
    isEnrolledInCash(userInfo) &&
    !isCb20Enrolled(sesameCashAccount, userInfo)
  ) {
    linkPathCB2 = '/cb20Enrollment';
  }
  return linkPathCB2;
};

export const getRuleForExperiment = props => {
  const { userInfo, creditInfo } = props;
  if (!userInfo) return null;
  if (!creditInfo) return null;
  if (qualifyToCreditBuilder(props) && qualifyToRentReporting(props)) {
    return CB2_RR_UPSELL_GOALS;
  } else if (qualifyToCreditBuilder(props)) {
    return CB2_RR_UPSELL_GOALS_CB2;
  } else if (qualifyToRentReporting(props)) {
    return CB2_RR_UPSELL_GOALS_RR;
  } else {
    return null;
  }
};

export const updateFiltersGoalsGet = props => {
  const { getState, filters } = props;
  const {
    userInfo: { userInfo },
    creditInfo: { creditInfo },
    sesameCash: { cashAccount: sesameCashAccount },
  } = getState();
  const includeRentReporting = qualifyToRentReporting({ userInfo, creditInfo });
  const includeCreditBuilder = qualifyToCreditBuilder({
    sesameCashAccount,
    creditInfo,
  });
  if (includeRentReporting) {
    filters['includeRentReporting'] = includeRentReporting;
  }
  if (includeCreditBuilder) {
    filters['includeCreditBuilder'] = includeCreditBuilder;
  }
};
