import * as morpheusPageNamesTypes from 'types/mrph.pageName.constants';

let partnerPageNameCodes = {};
partnerPageNameCodes[morpheusPageNamesTypes.OVERVIEW] = 'PGNM43';
partnerPageNameCodes[morpheusPageNamesTypes.ACCOUNT_MIX] = 'PGNM2';
partnerPageNameCodes[morpheusPageNamesTypes.CC_MARKETPLACE] = 'PGNM15';
partnerPageNameCodes[morpheusPageNamesTypes.CC_CATEGORIES] = 'PGNM16';
partnerPageNameCodes[morpheusPageNamesTypes.CC_DETAILS] = 'PGNM14';
partnerPageNameCodes[morpheusPageNamesTypes.CREDIT_USAGE] = 'PGNM19';
partnerPageNameCodes[morpheusPageNamesTypes.CREDIT_AGE] = 'PGNM17';
partnerPageNameCodes[morpheusPageNamesTypes.ACTIONS] = 'PGNM26';
partnerPageNameCodes[morpheusPageNamesTypes.PAYMENT_HISTORY] = 'PGNM44';
partnerPageNameCodes[morpheusPageNamesTypes.LOG_IN] = 'PGNM24';
partnerPageNameCodes[morpheusPageNamesTypes.SIMULATION_NEW_CARD_OR_DECLINE] =
  'PGNM39';
partnerPageNameCodes[morpheusPageNamesTypes.WHATS_CHANGED] = 'PGNM55';
partnerPageNameCodes[morpheusPageNamesTypes.CC_BORROWING_POWER] = 'PGNM32';
partnerPageNameCodes[morpheusPageNamesTypes.INCREASE_CREDIT_LIMIT] = 'PGNM38';
partnerPageNameCodes[morpheusPageNamesTypes.CARD_LIMITS] = 'PGNM31';
partnerPageNameCodes[morpheusPageNamesTypes.USERS_LIKE_YOU] = 'PGNM40';
partnerPageNameCodes[morpheusPageNamesTypes.CC_REWARDS_CATEGORIES] = 'PGNM33';
partnerPageNameCodes[morpheusPageNamesTypes.REBALANCE_CREDIT_UTILIZATION] =
  'PGNM58';
partnerPageNameCodes[
  morpheusPageNamesTypes.INCREASE_CREDIT_LIMIT_BY_TRADELINES
] = 'PGNM37';
partnerPageNameCodes[morpheusPageNamesTypes.ALL_PRODUCT] = 'PGNM61';
partnerPageNameCodes[morpheusPageNamesTypes.CREDIT_FOUNDATION] = 'PGNM56';
partnerPageNameCodes[morpheusPageNamesTypes.HOLIDAY_SPENDING] = 'PGNM36';
partnerPageNameCodes[morpheusPageNamesTypes.CARD_COMPARISON] = 'PGNM30';
partnerPageNameCodes[
  morpheusPageNamesTypes.BECOME_ELIGIBLE_FOR_AWESOME_THINGS
] = 'PGNM29';
partnerPageNameCodes[morpheusPageNamesTypes.CC_APPROVAL_ODDS] = 'PGNM35';
partnerPageNameCodes[morpheusPageNamesTypes.OTHER_MEMBERS_CARD_INTEREST] =
  'PGNM57';
partnerPageNameCodes[morpheusPageNamesTypes.CC_UTILIZATION] = 'PGNM34';

export default partnerPageNameCodes;
