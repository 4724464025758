import {
  apiUrls,
  buildAllNotificationsEndpoint,
  buildCreditMonitoringEndpoint,
  buildCSIDEndpoint,
} from 'api-urls';
import {
  buildFetchOptionsWithHeaders,
  convertObjectToQueryString,
  standardizedFetchHandler,
} from 'externals/_services/service_helpers';

export async function getCreditMonitoringAlerts(alertOptions) {
  const options = buildFetchOptionsWithHeaders();
  let url = buildCreditMonitoringEndpoint();

  if (alertOptions) {
    const params = convertObjectToQueryString(alertOptions);
    url = `${url}/${params}`;
  }

  return standardizedFetchHandler(url, options);
}

export async function updateAlertSettings(body) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'PUT',
    body,
  });

  return standardizedFetchHandler(
    apiUrls.updateAlertSettings(),
    requestOptions,
  );
}

export async function getAlertSettings() {
  const requestOptions = buildFetchOptionsWithHeaders();

  return standardizedFetchHandler(
    apiUrls.updateAlertSettings(),
    requestOptions,
  );
}

export async function getCMAlertByAlertId(alertId) {
  const requestOptions = buildFetchOptionsWithHeaders();

  return standardizedFetchHandler(
    `${buildCreditMonitoringEndpoint()}/${alertId}`,
    requestOptions,
  );
}

export async function saveAlertView(alertId, viewedStatus, notificationType) {
  const options = buildFetchOptionsWithHeaders({
    method: 'PUT',
    body: { viewed: viewedStatus },
  });

  const url = getUrlByNotificationType(alertId, notificationType);

  return standardizedFetchHandler(url, options);
}

const getUrlByNotificationType = (alertId, notificationType) => {
  switch (notificationType) {
    case 'CREDIT_MONITORING':
      return `${buildCreditMonitoringEndpoint()}/${alertId}/viewed`;
    case 'ID_PROTECTION':
      return `${buildCSIDEndpoint()}/${alertId}/viewed`;
    default:
      return `${buildAllNotificationsEndpoint()}/${alertId}`;
  }
};

export async function getCSIDAlerts(premiumOptions) {
  const options = buildFetchOptionsWithHeaders();
  let url = buildCSIDEndpoint();

  if (premiumOptions) {
    const params = convertObjectToQueryString(premiumOptions);
    url = `${url}/${params}`;
  }

  return standardizedFetchHandler(url, options);
}

export async function getCSIDAlertByAlertId(alertId) {
  const requestOptions = buildFetchOptionsWithHeaders();

  return standardizedFetchHandler(
    `${buildCSIDEndpoint()}/${alertId}`,
    requestOptions,
  );
}

export async function patchAlertSettings(
  notificationsConfig,
  userId,
  mailingId,
  variationId,
) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'PATCH',
    body: notificationsConfig,
  });

  let apiUrl = apiUrls.patchAlertSettings(userId);
  if (mailingId) {
    apiUrl += `&mailingId=${mailingId}`;
  }
  if (variationId) {
    apiUrl += `&variationId=${variationId}`;
  }

  return standardizedFetchHandler(apiUrl, requestOptions);
}
