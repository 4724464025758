/* global CSMxpTracker */
import { buildStandardException } from 'helpers/error.helper';

export const mixpanelTrack = (name, metadata) => {
  try {
    window.mixpanel.unified.track(name, metadata);

    return {
      success: true,
      name: name,
      attributes: metadata,
    };
  } catch (e) {
    return {
      success: false,
      err: buildStandardException({
        code: 'MIXPANEL_08',
        message: 'Error happened while sending event:' + e,
      }),
    };
  }
};

export const mixpanelVisitorTrack = function (event, finalAttributes) {
  if (typeof CSMxpTracker !== 'undefined') {
    CSMxpTracker.trackEvent(event, finalAttributes);
    return {
      success: true,
      name: event,
      attributes: finalAttributes,
    };
  } else {
    return {
      success: false,
      err: { code: 'MIXPANEL_05', message: 'Mixpanel not found on window' },
    };
  }
};
