import {
  buildFetchOptionsWithHeaders,
  convertObjectToQueryString,
  standardizedFetchHandler,
} from 'externals/_services/service_helpers';
import { buildPersonalizationEndpoint } from 'api-urls';

export async function fetchOffers(containerName, getParams) {
  const requestOptions = buildFetchOptionsWithHeaders();
  const queryStringParams = Object.assign({}, { containerName }, getParams);
  const queryString = convertObjectToQueryString(queryStringParams);

  return standardizedFetchHandler(
    buildPersonalizationEndpoint() + queryString,
    requestOptions,
  );
}
