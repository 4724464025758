import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useEventHandler, useTooltipHandler } from 'hook/event.hook';
import { ReactComponent as Close } from 'style/icons/close.svg';
import classnames from 'classnames';
import { FlexContainer } from 'components/Containers/FlexContainer/FlexContainer';
import OText from 'components/CText/OText';
import './CSToolTip.scss';
import { isHtml } from 'helpers/string.helper';
import { isMobileByOS } from 'externals/_tracking/dom/navigator';

const getIconStyle = isInline => {
  if (isInline) {
    return { verticalAlign: 'middle', display: 'inline-block' };
  } else {
    return {};
  }
};

const TooltipInnerContent = ({
  datatestid,
  title,
  tooltipContent,
  closeable,
  closeHandler,
  tooltipFooter,
  wrapperRef,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  classNameContent,
  onClick,
}) => {
  return (
    <FlexContainer
      wrapperRef={wrapperRef}
      id="tooltipInnerContent"
      className="flex-column"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <div
        className="tooltipContent"
        data-testid={`${
          datatestid && !!datatestid ? datatestid : ''
        }-tooltipContent`}
      >
        <div className={classnames(classNameContent)}>
          {isHtml(title) ? <OText text={title} /> : <span>{title}</span>}
          {tooltipContent}
        </div>
        {closeable && (
          <div className="closeTooltipIcon">
            <Close onClick={closeHandler} />
          </div>
        )}
      </div>
      {tooltipFooter ? tooltipFooter : null}
    </FlexContainer>
  );
};

function CSTooltip({
  title,
  tooltipContent,
  placement,
  isInline,
  datatestid,
  className,
  children,
  tooltipName,
  pagePosition,
  closeable,
  pageName,
  overlayClassName,
  removeMargins,
  removeMarginsMobile,
  onVisibleChangeHandler,
  tooltipFooter,
  tooltipId,
  isDisabledClickEvent,
  vertical,
  extraAttributes,
  tooltipContentClassName,
  ...props
}) {
  const classes = classnames('CSToolTip', {
    'inblock valign-middle': isInline,
    [className]: className,
    marg0: removeMargins,
    removeMarginsMobile: removeMarginsMobile,
  });
  const isMobile = isMobileByOS();
  const trackingEventHandler = useEventHandler();
  const tooltipEventCallback = useTooltipHandler(trackingEventHandler);

  const tooltipClickEventHandler = (
    tooltipName,
    pagePosition,
    vertical,
    extraAttributes,
  ) => {
    let verticalAttr = {};
    if (vertical) {
      verticalAttr = {
        Vertical: vertical,
      };
    }
    tooltipEventCallback(
      {
        tooltip: tooltipName,
        pagePosition: pagePosition,
      },
      {
        'Page Name': pageName,
        ...verticalAttr,
        ...extraAttributes,
      },
    );
  };

  const innerOnVisibleChangeHandler = visible => {
    if (typeof onVisibleChangeHandler === 'function')
      onVisibleChangeHandler(visible);
  };

  const tooltipTrigger = ['hover', 'click'];
  if (!isMobile) {
    tooltipTrigger.push('focus');
  }

  if (isDisabledClickEvent) {
    tooltipTrigger.pop('click');
  }

  const getAriaLabel = (title, tooltipContent) => {
    if (typeof title === 'string' && title !== '') {
      return title;
    }
    if (typeof tooltipContent === 'string' && tooltipContent !== '') {
      return tooltipContent;
    }
    return '';
  };
  const ariaLabel = getAriaLabel(title, tooltipContent);

  return (
    <div
      data-testid={`${
        datatestid && !!datatestid ? datatestid : ''
      }-tooltipContainer`}
      className={classes}
    >
      <Tooltip
        placement={placement ? placement : 'top'}
        onClick={e => {
          e.stopPropagation();
          !isDisabledClickEvent &&
            tooltipClickEventHandler(
              tooltipName,
              pagePosition,
              vertical,
              extraAttributes,
            );
        }}
        overlayClassName={`tooltip-popup ${overlayClassName}`}
        trigger={tooltipTrigger}
        onVisibleChange={innerOnVisibleChangeHandler}
        title={
          <TooltipInnerContent
            datatestid={datatestid}
            title={title}
            classNameContent={tooltipContentClassName}
            tooltipContent={tooltipContent}
            closeable={closeable}
            tooltipFooter={tooltipFooter}
          />
        }
        {...props}
      >
        {children ? (
          <span role="main" aria-label={`Tooltip ${ariaLabel}`} tabIndex={0}>
            {children}
          </span>
        ) : (
          <QuestionCircleOutlined
            style={getIconStyle(isInline)}
            aria-label={`Tooltip ${ariaLabel}`}
            tabIndex={0}
          />
        )}
      </Tooltip>
    </div>
  );
}

CSTooltip.propTypes = {
  isInline: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

CSTooltip.defaultProps = {
  isInline: false,
  tooltipName: 'tooltip',
  pagePosition: '',
};

export { CSTooltip };
