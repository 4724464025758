import { LOGOUT } from 'types/app.constants';
import { dmCampaignSignupConstants as signupConstants } from './DMCampaignSignup.constants';

const initialState = {
  loading: false,
  signup1Step: null,
  signup2Step: null,
  email: null,
  phoneNumber: null,
  last4Ssn: null,
  signup2Data: null,
  dupeSsnFirstName: null,
  addressValidated: false,
  isSsnValidation: false,

  errorTU: null,
  errorSectionTU: null,
};

const dmCampaignReducer = (state = Object.assign({}, initialState), action) => {
  switch (action.type) {
    case signupConstants.LOADING_START:
      return Object.assign({}, state, {
        loading: true,
      });
    case signupConstants.LOADING_FINISH:
      return Object.assign({}, state, {
        loading: false,
      });
    case signupConstants.INIT_SIGNUP_2:
      return Object.assign({}, state, {
        email: action.email,
        phoneNumber: action.phoneNumber,
        last4Ssn: action.last4Ssn,
        tempPassword: action.tempPassword,
        signup2Data: action.signup2Data,
        signup1Step: null,
      });
    case signupConstants.CREATE_USER_SUCCESS:
    case signupConstants.UPDATE_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        tempPassword: action.tempPassword,
      });
    case signupConstants.UPDATE_ADDRESS_FAIL:
      return Object.assign({}, state, {
        loading: false,
        addressValidated: action.addressValidated,
      });
    case signupConstants.REPORT_TU_ERROR:
      return Object.assign({}, state, {
        errorTU: action.errorTU,
        errorSectionTU: action.errorSectionTU,
      });
    case signupConstants.CLEAR_TU_ERROR:
      return Object.assign({}, state, {
        errorTU: null,
        errorSectionTU: null,
      });
    case signupConstants.INIT_DUPE_SSN_LOADING:
      return Object.assign({}, state, {
        dupeSsnFirstName: action.dupeSsnFirstName,
      });
    case signupConstants.SET_SIGNUP_2_STEP:
      return Object.assign({}, state, {
        signup2Step: action.step,
      });
    case LOGOUT:
      return Object.assign({}, initialState);
    default:
      return state;
  }
};

export default dmCampaignReducer;
