export const FEATURE_NAME_MY_ACTIONS = 'MyActions';
export const FEATURE_NAME_GOALS = 'MyGoals';
export const FEATURE_NAME_CREDIT_MANAGER = 'CreditManager';
export const FEATURE_NAME_MARKETPLACE = 'Marketplace';
export const FEATURE_NAME_CREDIT_CARDS = 'CreditCards';
export const FEATURE_NAME_PERSONAL_LOANS = 'PersonalLoan';
export const FEATURE_NAME_AUTO_LOANS = 'AutoLoans';
export const FEATURE_NAME_HOME_LOANS = 'HomeLoans';
export const FEATURE_NAME_INSURANCE = 'Insurance';
export const FEATURE_NAME_HOME_INSURANCE = 'HomeInsurance';
export const FEATURE_NAME_AUTO_INSURANCE = 'AutoInsurance';
export const FEATURE_NAME_LIFE_INSURANCE = 'LifeInsurance';
export const FEATURE_NAME_SESAMECASH = 'SesameCash';
export const FEATURE_NAME_ALERT_MONITORING = 'AlertMonitoring';
export const FEATURE_NAME_CREDIT_REPORT = 'CreditReport';
export const FEATURE_NAME_CREDIT_LOCK = 'CreditLock';
export const FEATURE_NAME_PROFILE = 'Profile';
export const FEATURE_NAME_REFER_A_FRIEND = 'ReferAFriend';
export const FEATURE_NAME_ID_PROTECTION = 'IdProtection';
export const FEATURE_NAME_PREMIUM_PLAN = 'PremiumPlans';
export const FEATURE_NAME_NOTIFICATION_SETTING = 'NotificationSettings';
export const FEATURE_NAME_BUSINESS_LOANS = 'BusinessLoans';
export const FEATURE_NAME_ACTION_PLAN = 'ActionPlan';
