import {
  OCF_INFO_GET_FAILURE,
  OCF_INFO_GET_REQUEST,
  OCF_INFO_GET_SUCCESS,
} from 'types/ocfInfo.constants';
import { OCF_LOGOUT, LOGOUT } from 'types/app.constants';
const initialState = {
  loading: false,
  error: null,
  ocfInfo: null,
};

export default function ocfInfoReducer(state = initialState, action) {
  switch (action.type) {
    case OCF_INFO_GET_REQUEST:
      return Object.assign({}, initialState, {
        loading: true,
      });
    case OCF_INFO_GET_SUCCESS:
      return Object.assign({}, initialState, {
        ocfInfo: action.userData,
      });
    case OCF_INFO_GET_FAILURE:
      return Object.assign({}, initialState, {
        error: action.error,
      });
    case OCF_LOGOUT:
    case LOGOUT:
      return Object.assign({}, initialState);
    default:
      return state;
  }
}
