import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  setLoginFromLogoutData,
  getLoginFromLogoutData,
} from 'helpers/localstorage';
import { getNegativeRemarksCount } from 'helpers/credit_info';
import { parseFeatureAdOffer } from 'helpers/featuredAd.helpers';

/* credit saint will go live for a small test, migrate this logic to svc if it gets into morpheus featuredAd.productKey === 'lexington_law'*/
const validateCreditSaintAd = (creditInfo, featuredAd) => {
  let validatedFeatureAd = featuredAd;
  if (
    isNotCreditSaintValid(featuredAd?.productKey, creditInfo) ||
    isLexLawValid(featuredAd?.productKey, creditInfo)
  ) {
    validatedFeatureAd = null;
  }
  return validatedFeatureAd;
};

const isNotCreditSaintValid = (productKey, creditInfo) => {
  return (
    productKey === 'credit_saint' &&
    (!(creditInfo?.creditScore >= 300 && creditInfo?.creditScore <= 450) ||
      creditInfo?.creditScoreAnalysis?.paymentHistory?.collections < 2)
  );
};

const isLexLawValid = (productKey, creditInfo) => {
  return (
    productKey === 'lexington_law' && getNegativeRemarksCount(creditInfo) < 1
  );
};

export const getFeaturedAdOffer = (location, position, featuredAds) => {
  let featuredAdOffer = null;
  if (featuredAds?.featureAds) {
    const featuredAdArray = featuredAds.featureAds.filter(
      m => m.position === position && m.location === location,
    );
    let featuredAd = featuredAdArray.length > 0 ? featuredAdArray[0] : null;
    featuredAdOffer = parseFeatureAdOffer(featuredAd);
  }
  return featuredAdOffer;
};

export const getNativeAdOffers = (location, position, featuredAds) => {
  let featuredAdArray = null;
  if (featuredAds?.featureAds) {
    featuredAdArray = featuredAds.featureAds.filter(
      m => m.position === position && m.location === location,
    );
  }
  return featuredAdArray;
};

//loads the logout featured ad
export const useLogoutFeaturedAd = (position, enabled) => {
  const { featuredAdOffer } = useFeaturedAd('LOGOUT', position, false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading && enabled) {
      let rawLogData = getLoginFromLogoutData();
      let logData = rawLogData ? JSON.parse(rawLogData) : null;

      //set featured ad
      if (!logData) {
        logData = {
          featuredAdOffer,
        };
      } else {
        logData.featuredAdOffer = featuredAdOffer;
      }
      setLoginFromLogoutData(JSON.stringify(logData));
      setLoading(false);
    }
    //eslint-disable-next-line
  }, [loading, enabled]);
};

export const useFeaturedAd = (location, position, hideAd) => {
  const { featuredAds, creditInfo, featuredAdsLoading, featuredAdsError } =
    useSelector(state => ({
      featuredAds: state.featuredAd.featuredAds,
      featuredAdsLoading: state.featuredAd.featuredAdsLoading,
      featuredAdsError: state.featuredAd.featuredAdsError,
      creditInfo: state.creditInfo.creditInfo,
    }));

  let featuredAdOffer = null;

  if (!hideAd) {
    featuredAdOffer = getFeaturedAdOffer(location, position, featuredAds);
    featuredAdOffer = validateCreditSaintAd(creditInfo, featuredAdOffer);
  }

  return {
    featuredAdOffer,
    featuredAdsReady: featuredAds || featuredAdsError,
    featuredAdsLoading,
    featuredAdsError,
  };
};
