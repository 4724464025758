import {
  SELF_SIM_PAYLOAD,
  AUTO_REFINANCE_PAYLOAD,
  CREDIT_REPAIR_EXPERT_SIM_PAYLOAD,
  AUTO_INSURANCE_ACTIONS_PAYLOAD,
  HOME_INSURANCE_ACTIONS_PAYLOAD,
  PAYLOAD,
} from 'types/payload.constants';
import { LOGIN_SUCCESS, LOGOUT } from 'types/app.constants';
import { mergePayloadChanges } from 'helpers/reducer.helper';

const initialState = {
  actionsLoading: false,
  actionsLoaded: false,

  selfSim: null,
  selfSimLoading: false,
  selfSimError: null,

  creditRepairExpertSim: null,
  creditRepairExpertSimLoading: false,
  creditRepairExpertSimError: null,

  newCardScoreSim: null,
  newCardScoreSimLoading: false,
  newCardScoreSimError: null,

  newCardScoreIncreaseSim: null,
  newCardScoreIncreaseSimLoading: false,
  newCardScoreIncreaseSimError: null,

  autoRefinance: null,
  autoRefinanceLoading: false,
  autoRefinanceError: null,

  creditApplicationDeniedSim: null,

  autoInsuranceOffers: null,
  autoInsuranceOffersLoading: false,
  autoInsuranceOffersError: null,

  homeInsuranceOffers: null,
  homeInsuranceOffersLoading: false,
  homeInsuranceOffersError: null,

  numberOfActions: null,
};

export default function actionsReducer(state = initialState, action) {
  switch (action.type) {
    case AUTO_INSURANCE_ACTIONS_PAYLOAD:
    case HOME_INSURANCE_ACTIONS_PAYLOAD:
    case SELF_SIM_PAYLOAD:
    case CREDIT_REPAIR_EXPERT_SIM_PAYLOAD:
    case AUTO_REFINANCE_PAYLOAD:
    case LOGIN_SUCCESS:
    case PAYLOAD:
      return mergePayloadChanges(state, action);
    case LOGOUT:
      return Object.assign({}, initialState);
    default:
      return state;
  }
}
