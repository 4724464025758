import {
  NOTIFICATIONS_ALERTS_PAYLOAD,
  PAYLOAD,
  NOTIFICATIONS_ALERTS_DETAILS_PAYLOAD,
} from 'types/payload.constants';
import { SELECT_NOTIFICATION_ALERT } from 'types/notificationsAlerts.constants';
import { LOGIN_SUCCESS, LOGOUT } from 'types/app.constants';
import { mergePayloadChanges } from 'helpers/reducer.helper';
const initialState = {
  notificationsAlerts: null,
  notificationsAlertsLoading: false,
  notificationsAlertsError: null,
  selectedAlert: null,
  selectedAlertsDetails: {
    selectedAlertDetails: null,
    selectedAlertDetailsLoading: false,
    selectedAlertDetailsError: null,
  },
};

export default function notificationsAlertsReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case PAYLOAD:
    case NOTIFICATIONS_ALERTS_PAYLOAD:
    case LOGIN_SUCCESS:
      return mergePayloadChanges(state, action);
    case NOTIFICATIONS_ALERTS_DETAILS_PAYLOAD:
      return Object.assign({}, state, {
        selectedAlertsDetails: action.payload,
      });
    case SELECT_NOTIFICATION_ALERT:
      return {
        ...state,
        selectedAlert: action.selectedAlert,
      };
    case LOGOUT:
      return Object.assign({}, initialState);
    default:
      return state;
  }
}
