import {
  getCreditMonitoringAlerts,
  saveAlertView,
} from 'externals/_services/alert.service';
import { wrapService } from 'actions/service_wrapper.actions';
import { CMALERTS_PAYLOAD } from 'types/payload.constants';
import { successAlerts as successCmAlerts } from 'actions/cmAlerts.actions';

const a_getCreditMonitoringAlerts = wrapService(getCreditMonitoringAlerts, {
  name: 'getCreditMonitoringAlerts',
});
const a_saveAlertView = wrapService(saveAlertView, {
  name: 'saveAlertView',
});

function payloadAlerts(cmAlerts, cmAlertsLoading, cmAlertsError) {
  return {
    type: CMALERTS_PAYLOAD,
    cmAlerts,
    cmAlertsLoading,
    cmAlertsError,
  };
}

export function requestAlerts() {
  return payloadAlerts(null, true, null);
}

export function successAlerts(alertsData) {
  return payloadAlerts(alertsData, false, null);
}

export function failureAlerts(error) {
  return payloadAlerts(null, false, error);
}

export function getCMAlerts(count = 100000, forceReload = false) {
  return (dispatch, getState) => {
    const { cmAlertsLoading } = getState().cmAlerts;
    if (cmAlertsLoading && !forceReload)
      return Promise.resolve('Request already in process');

    dispatch(requestAlerts());
    return new Promise((resolve, reject) => {
      dispatch(
        a_getCreditMonitoringAlerts({
          itemsPerPage: count,
        }),
      )
        .then(alertsData => {
          dispatch(successAlerts(alertsData));
          resolve(alertsData);
        })
        .catch(err => {
          dispatch(failureAlerts(err));
          reject(err);
        });
    });
  };
}

export function setViewAlert(alertId, viewStatus, notificationType) {
  return (dispatch, getState) => {
    const state = getState();
    const { userInfo } = state.userInfo;
    const { cmAlerts } = state.cmAlerts;

    if (!userInfo) return Promise.reject('Userinfo missing');

    return new Promise((resolve, reject) => {
      dispatch(a_saveAlertView(alertId, viewStatus, notificationType))
        .then(() => {
          const alerts = cmAlerts.items.map(alert => {
            if (alert.id === alertId) {
              alert.viewed = viewStatus;
            }
            return alert;
          });
          dispatch(successCmAlerts({ items: alerts }));
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  };
}
