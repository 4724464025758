import { wrapService } from 'actions/service_wrapper.actions';
import { fetchInsurances } from 'externals/_services/product.service';

const a_fetchInsuranceOffers = wrapService(fetchInsurances, {
  name: 'FETCH_INSURANCE_OFFERS',
});

export const loadInsuranceOffers = queryObject => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch(a_fetchInsuranceOffers(queryObject))
      .then(res => {
        resolve({ offers: res?.items });
      })
      .catch(error => reject(error));
  });
};
