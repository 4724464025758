import {
  clearCStrack,
  setUserLogoutTime,
  testLocalStorage,
} from 'helpers/localstorage';
import { poll } from 'helpers/polling.helper';
import { getSessionRole, isAnySessionActive, USER_ROLE } from 'helpers/session';
import {
  FATAL_ERROR,
  END_SESSION,
  TOGGLE_OFFLINE_MODE,
  FORCE_HIDE_MENU_BAR,
} from 'types/app.constants';
import { getUserInfo } from 'actions/userInfo.actions';
import { standardizeException } from 'helpers/error.helper';
import { createLogger, PERF } from 'helpers/logger';
import { getDifferenceInMillis } from 'helpers/dateHelper';
import { getBrowser } from 'externals/_tracking/dom/navigator';
import { getGetParam, getQueryStringAsMap } from 'helpers/domHelper';
import { setRefCode } from 'actions/tracking.actions';
import { getUserProfile } from 'externals/_services/user.service';
import { isSessionValid } from 'helpers/sessionLocalStorageReader.helper';
import {
  getOptimizelyUniqueIdentifier,
  initializeOptimizelysUniqueIdentifier,
  optimizelyClient,
  getDecisionForFlag,
} from 'externals/_tracking/optimizely/optFlags';
import { getCookieValue } from 'helpers/cookie.helper';
import { DOMAIN } from 'helpers/env';

const logger = createLogger({
  name: 'app.actions',
});

export const endSession = () => {
  window.location = window.location.origin + `/logout?reason=SESSION_TIMEOUT`;
  return {
    type: END_SESSION,
  };
};

export const checkAndCreateDeviceId = () => {
  const deviceId = getCookieValue('cs_device_id');
  if (!deviceId) {
    const browser = getBrowser().name + Math.floor(Math.random() * 1000);
    document.cookie =
      'cs_device_id=' +
      browser +
      `;domain=.${DOMAIN};path=/;expires=Fri, 31 Dec 9999 23:59:59 GMT`;
  }
};

const storeRef = dispatch => {
  const ref = getGetParam('ref');
  if (ref) {
    dispatch(setRefCode(ref));
  }
};

const checkLocalStorage = () => {
  try {
    const val = testLocalStorage();
    if (val) {
      logger.error('Local storage is failing the test:' + val);
    }
  } catch (e) {
    logger.error(e.message);
  }
};

const OPTIMIZELY_URL_PREFIX = 'opt_var_';
const forceVariationsAsNeeded = () => {
  const queryStringMap = getQueryStringAsMap();
  const userId = getOptimizelyUniqueIdentifier();
  poll(() => optimizelyClient.isReady()).then(() => {
    Object.keys(queryStringMap ?? {}).forEach(key => {
      if (key.indexOf(OPTIMIZELY_URL_PREFIX) === 0) {
        const cleanedKey = key.replace(OPTIMIZELY_URL_PREFIX, '');

        optimizelyClient.setForcedVariation(
          cleanedKey,
          userId,
          queryStringMap[key],
        );
      }
    });
  });
};

const activateNonitorForCaptchScreen = () => {
  let reportedCaptcha = false;
  setInterval(() => {
    const el = document.getElementById('captcha-container');

    if (el && !reportedCaptcha) {
      reportedCaptcha = true;
      logger.error('Detected captcha on screen');
    }
    if (!el && reportedCaptcha) {
      reportedCaptcha = false;
      logger.error('Detected captcha solved');
    }
  }, 1000);
};

export const initApp = () => dispatch => {
  if (!isSessionValid()) {
    setUserLogoutTime(null);
  } else {
    const isUserSessionActive = getSessionRole() === USER_ROLE;
    if (isUserSessionActive) {
      poll(() => optimizelyClient.isReady()).then(() => {
        const vri2 = getDecisionForFlag('vri2');
        dispatch(getUserInfo(false, vri2?.config?.vriVersion)).catch(() => {});
      });
    }
  }

  activateNonitorForCaptchScreen();
  checkAndCreateDeviceId();
  checkLocalStorage();
  storeRef(dispatch);
  initializeOptimizelysUniqueIdentifier();
  forceVariationsAsNeeded();

  //check session identifier
  if (!isAnySessionActive()) {
    clearCStrack();
  }

  const now = new Date();
  const validatorTime = window.getValidatorInitDate
    ? window.getValidatorInitDate()
    : new Date();
  const elapsedTime = getDifferenceInMillis(now, validatorTime);
  logger.debug('App::initApp', {
    categories: [PERF],
    elapsedTime: elapsedTime,
  });
};

const createFatalError = fatalError => ({ type: FATAL_ERROR, fatalError });

export const handleTopLevelError = err => dispatch => {
  const error = standardizeException(err);
  logger.logFatalClientError(err);
  dispatch(createFatalError(error.message));
};

export const monitorOfflineStatus = () => (dispatch, getState) => {
  const checker = () => {
    return new Promise((resolve, reject) => {
      getUserProfile().then(resolve).catch(reject);
    });
  };

  const checkOnce = () => {
    setTimeout(() => {
      checker()
        .then(() => {
          const state = getState();
          if (state.app.isOffline) {
            dispatch(toggleOffline());
          }
        })
        .reject(() => {
          const state = getState();
          if (state.app.loggedIn) {
            checkOnce();
          } else if (!state.app.loggedIn && state.app.isOffline) {
            dispatch(toggleOffline());
          }
        });
    }, 10);
  };

  checkOnce();
};
export const toggleOffline = () => ({ type: TOGGLE_OFFLINE_MODE });

export const switchOnOffMenuBar = (
  newForceHideMenuBar,
  newMenuType,
  newForceMobileHideMenuBar,
  opts,
) => ({
  type: FORCE_HIDE_MENU_BAR,
  forceHideMenuBar: newForceHideMenuBar,
  menuType: newMenuType,
  forceMobileHideMenuBar: newForceMobileHideMenuBar,
  headerMenuOpts: opts,
});

export const deactivatePublicHeader = pageMenuType => (dispatch, getState) => {
  const state = getState();
  const { forceHideMenuBar, menuType } = state?.app;

  if (menuType === pageMenuType && forceHideMenuBar) {
    dispatch(switchOnOffMenuBar(false, null));
  }
};
