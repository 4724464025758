/**
 * Only use this file if you have extreme circumstances. These calls are thin
 * wrappers around the braze API. braze.track.js has more logic to verify that
 * the event has all of the expected attributes.
 *
 * Most likely, you should use eventHandler.actions.js interface to send data
 * to braze.
 *
 */

/* global appboy */

export function setBrazeUser(usrTkn) {
  try {
    appboy.changeUser(usrTkn);
    return { success: true };
  } catch (e) {
    return { success: false, err: e };
  }
}

export function brazeEventTrack(event, additionalData) {
  try {
    appboy.logCustomEvent(event, additionalData);
    return { success: true };
  } catch (e) {
    return { success: false, err: e };
  }
}

export function initBraze(user) {
  try {
    setBrazeUser(user);
    return { success: true };
  } catch (e) {
    return { success: false, err: e };
  }
}

export function startBrazeSession(userToken) {
  try {
    appboy.openSession();
    setBrazeUser(userToken);
    return { success: true };
  } catch (e) {
    return { success: false, err: e };
  }
}

export function getBrazeContentCards() {
  // try {
  return {
    success: true,
    contentCardsData: {}, //appboy.getCachedContentCards(),
  };
  // } catch (e) {
  //   return {
  //     success: false,
  //     err: e,
  //   };
  // } Commented to clear warnings
}
