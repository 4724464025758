import { createLogger } from 'helpers/logger';

const SESSION_ERROR = 'The session id was not found in the request.';
const SESSION_ERROR_2 = 'You have provided incorrect credentials.';
const SESSION_ERROR_3 =
  'Full authentication is required to access this resource';
const sessionErrorList = [SESSION_ERROR, SESSION_ERROR_2, SESSION_ERROR_3];

const doesErrorMessageIndicateSessionError = msg =>
  sessionErrorList.indexOf(msg) >= 0;

const logger = createLogger({
  name: 'logger.actions',
});

export const checkForGlobalAPIError =
  (err, ref, history) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      if (!err) {
        logger.error(
          `Undefined API Error passed into api error handler:${ref}--${err}`,
        );
        resolve();
      } else {
        const { message } = err;
        logger.reportAPIError(err, ref);

        if (doesErrorMessageIndicateSessionError(message)) {
          history.push('/logout?reason=SESSION_TIMEOUT');
        } else {
          if (!err.isApiError) {
            logger.errorException(
              'Non API Error passed into api error handler',
              err,
            );
          }
          resolve();
        }
      }
    });
  };
