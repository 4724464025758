import { useFlag } from 'hook/flag.hook';

export const useIdProtectionExp = () => {
  const idProtectionWebExp = useFlag('id_protection_web_phx', {
    defaultConfig: {
      enabled: false,
    },
    forceDefaultConfigForDisabledOptimizely: true,
  });
  return idProtectionWebExp;
};
