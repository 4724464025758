/* eslint-disable */
/*
This file is a copy and paste of the snippet from the vendor. It has been
modified as little as possible for simplicity's sake
 */
import { getOptimizelySource } from 'helpers/env';

(function () {
  var pd = document.createElement('script');
  var src = getOptimizelySource();

  pd.src = '//cdn.optimizely.com/js/' + src;
  if (src) {
    document.head.appendChild(pd);
  }
})();
