import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT } from 'types/app.constants';
import { prefilledSignupConstants as signupConstants } from './PrefilledSignup.constants';

const initialState = {
  loading: false,
  decryptedData: null,
  signup1Step: 'ACCT_CREATION_EMAIL_FORM',
  isAccountCreated: false,
  storedFormData: null,
  needLogin: false,
  addressValidated: false,
};

const prefilledSignupReducer = (
  state = Object.assign({}, initialState),
  action,
) => {
  switch (action.type) {
    case signupConstants.START_INFO_FORM_SUBMIT:
    case signupConstants.START_SSN_FORM_SUBMIT:
    case signupConstants.DECRYPT_DATA_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case signupConstants.DECRYPT_DATA_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        decryptedData: action.decryptedData,
      });
    case signupConstants.CREATE_USER_SUCCESS:
      return Object.assign({}, state, {
        storedFormData: action.storedFormData,
        isAccountCreated: true,
        needLogin: true,
      });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        needLogin: false,
      });
    case LOGIN_FAILURE:
    case signupConstants.DECRYPT_DATA_FAIL:
    case signupConstants.CREATE_USER_FAIL:
    case signupConstants.UPDATE_PHONE_FAIL:
    case signupConstants.UPDATE_DOB_FAIL:
    case signupConstants.UPDATE_SSN_FAIL:
    case signupConstants.INFO_FORM_SUCCESS:
    case signupConstants.SSN_FORM_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
      });
    case signupConstants.UPDATE_ADDRESS_FAIL:
      return Object.assign({}, state, {
        loading: false,
        addressValidated: action.addressValidated,
      });
    case signupConstants.SET_SIGNUP_1_STEP:
      return Object.assign({}, state, {
        signup1Step: action.step,
      });
    case signupConstants.CLEAR_PREFILLED_SIGNUP_STATE:
    case LOGOUT:
      return Object.assign({}, initialState);
    default:
      return state;
  }
};

export default prefilledSignupReducer;
