import { mergePayloadChanges } from 'helpers/reducer.helper';
import { CSID3BDIAL_PAYLOAD, PAYLOAD } from 'types/payload.constants';
import {
  IS_FROM_LOGIN,
  RESET_IS_FROM_LOGIN,
} from '../types/premiumPaymentPending.constants';

const initialState = {
  isFromLogin: false,
  csid3BDial: null,
  csid3BDialError: null,
  csid3BDialLoading: null,
};

const premiumReducer = function (state = initialState, action) {
  switch (action.type) {
    case CSID3BDIAL_PAYLOAD:
    case PAYLOAD:
      return mergePayloadChanges(state, action);
    case IS_FROM_LOGIN:
      return Object.assign({}, state, {
        isFromLogin: true,
      });
    case RESET_IS_FROM_LOGIN:
      return Object.assign({}, state, {
        isFromLogin: false,
      });
    default:
      return state;
  }
};

export default premiumReducer;
