import React from 'react';
import './Image.scss';

export const Image = ({ imgClass, datatestid, ...props }) => {
  return (
    <div
      style={props.style}
      data-testid={datatestid}
      className={`imgContainCenter ${imgClass} ${props.className}`}
    />
  );
};
