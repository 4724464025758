import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLogger } from 'hook/error.hook';
import { getNotifications } from 'actions/notificationsAlerts.actions';

//needs to accept selectedAlert if id in param
export const useCMAlerts = () => {
  const {
    userInfo,
    notificationsAlertsLoading,
    notificationsAlertsError,
    notificationsAlerts,
    cmAlerts,
    cmAlertsLoading,
  } = useSelector(state => ({
    userInfo: state.userInfo.userInfo,
    notificationsAlerts: state.notificationsAlerts?.notificationsAlerts,
    notificationsAlertsLoading:
      state.notificationsAlerts?.notificationsAlertsLoading,
    notificationsAlertsError:
      state.notificationsAlerts?.notificationsAlertsError,
    cmAlerts: state.cmAlerts?.cmAlerts,
    cmAlertsLoading: state.cmAlerts?.cmAlertsLoading,
  }));

  const unreadAlertCount = getUnrealAlertCount();
  const noAlerts = cmAlerts?.length > 0;
  const dispatch = useDispatch();

  const { reportAPIError } = useLogger();

  function getUnrealAlertCount() {
    return cmAlerts && cmAlerts.items && cmAlerts.items.length
      ? cmAlerts.items.filter(alert => !alert.viewed).length
      : 0;
  }

  useEffect(() => {
    if (
      userInfo &&
      !notificationsAlertsLoading &&
      !notificationsAlertsError &&
      !notificationsAlerts
    ) {
      dispatch(getNotifications()).catch(err => {
        reportAPIError(err);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userInfo,
    notificationsAlertsLoading,
    notificationsAlertsError,
    notificationsAlerts,
  ]);

  return {
    cmLoading: cmAlertsLoading,
    cmAlerts,
    unreadAlertCount,
    error: notificationsAlertsError,
    noAlerts,
  };
};
