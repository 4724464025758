import { optimizelyClient } from 'externals/_tracking/optimizely/optFlags';
import { getEnvironment, LOCAL, PERF, PREPROD_LOCAL, TEST } from 'helpers/env';
import { get } from 'helpers/objectHelpers';
import { setLoggingFilters } from 'helpers/localstorage';
import { CONFIG_PAYLOAD } from 'types/payload.constants';

function payload(config, configLoading, configError) {
  return { config, configLoading, configError, type: CONFIG_PAYLOAD };
}

function request() {
  return payload(null, true, null);
}
export function __success(config) {
  return payload(config, false, null);
}
function failure(error) {
  return payload(null, false, error);
}

export const retrieveConfigForLogin = () => dispatch => {
  return new Promise((resolve, reject) => {
    const environment = getEnvironment();
    const useLocal =
      environment === LOCAL ||
      environment === TEST ||
      environment === PREPROD_LOCAL ||
      environment === PERF;

    const clientConfiguration = optimizelyClient.decide('client_configuration');
    const isClientConfigurationisEnabled = clientConfiguration.enabled;

    if (isClientConfigurationisEnabled && !useLocal) {
      dispatch(__success(clientConfiguration?.variables?.client_config));
      resolve(clientConfiguration?.variables?.client_config);
    } else {
      import('test/staticData/config')
        .then(configData => {
          const config = configData.default;
          dispatch(__success(config));
          const loggingFilters = get(config, ['loggingFilters'], []);
          setLoggingFilters(JSON.stringify(loggingFilters));
          resolve(config);
        })
        .catch(err => {
          dispatch(failure(err.error));
          reject(err);
        });
    }
  });
};

export function retrieveConfig(doNotClear = false) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const state = getState();
      if (state.config.configLoading || state.config.config) {
        return resolve(state.config.config);
      } else {
        if (!doNotClear) {
          dispatch(request());
        }
        import('test/staticData/config')
          .then(configData => {
            const config = configData.default;
            dispatch(__success(config));
            const loggingFilters = get(config, ['loggingFilters'], []);
            setLoggingFilters(JSON.stringify(loggingFilters));
            resolve(config);
          })
          .catch(err => {
            dispatch(failure(err.error));
            reject(err);
          });
      }
    });
  };
}

export const loadConfig = () => dispatch => {
  const environment = getEnvironment();
  const useLocal =
    environment === LOCAL ||
    environment === TEST ||
    environment === PREPROD_LOCAL ||
    environment === PERF;

  const clientConfiguration = optimizelyClient.decide('client_configuration');
  const isClientConfigurationisEnabled = clientConfiguration.enabled;

  if (isClientConfigurationisEnabled && !useLocal) {
    dispatch(__success(clientConfiguration?.variables?.client_config));
  } else {
    dispatch(retrieveConfig(true)).catch(() => {});
  }
};
