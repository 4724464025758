import React from 'react';
import { closeModal } from 'helpers/modal.helper';
import PreQualTermsModal from 'components/PreQualTermsModal';

const PreQualTermsController = props => {
  return (
    <PreQualTermsModal
      visible={true}
      onCloseClick={() => {
        closeModal(props.history);
      }}
    />
  );
};

export default PreQualTermsController;
