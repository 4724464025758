import { mergePayloadChanges } from 'helpers/reducer.helper';
import { PAYLOAD, TIPOFTHEDAY_PAYLOAD } from 'types/payload.constants';

const initialState = {
  loading: false,
  tipInfo: [],
};

const tipOfTheDayReducer = function (state = initialState, action) {
  switch (action.type) {
    case PAYLOAD:
    case TIPOFTHEDAY_PAYLOAD:
      return mergePayloadChanges(state, action);
    default:
      return state;
  }
};

export default tipOfTheDayReducer;
