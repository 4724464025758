export const signupConstants = {
  CREATE_USER_REQUEST: 'SIGNUP_CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS: 'SIGNUP_CREATE_USER_SUCCESS',
  CREATE_USER_FAIL: 'SIGNUP_CREATE_USER_FAIL',
  SET_SIGNUP_STEP: 'SET_SIGNUP',
  UPDATE_SIGNUP_STEP: 'SIGNUP_CHANGE_STEP',

  UPDATE_USER_REQUEST: 'SIGNUP_UPDATE_USER_INFO_REQUEST',
  UPDATE_USER_SUCCESS: 'SIGNUP_UPDATE_USER_INFO_SUCCESS',
  UPDATE_USER_FAIL: 'SIGNUP_UPDATE_USER_INFO_FAIL',

  UPDATE_USER_ADDRESS_REQUEST: 'UPDATE_USER_ADDRESS_REQUEST',
  UPDATE_USER_ADDRESS_SUCCESS: 'UPDATE_USER_ADDRESS_SUCCESS',
  UPDATE_USER_ADDRESS_FAIL: 'UPDATE_USER_ADDRESS_FAIL',
  ADDRESS_FUZZY_MATCH: 'ADDRESS_FUZZY_MATCH',

  UPDATE_USER_SSN_REQUEST: 'UPDATE_USER_SSN_REQUST',
  UPDATE_USER_SSN_SUCCESS: 'UPDATE_USER_SSN_SUCCESS',
  UPDATE_USER_SSN_FAIL: 'UPDATE_USER_SSN_FAIL',
  SSN_EXIST_USER: 'SSN_EXIST_USER',
  VERIFY_USER_SSN_REQUEST: 'VERIFY_USER_SSN_REQUST',
  VERIFY_USER_SSN_SUCCESS: 'VERIFY_USER_SSN_SUCCESS',
  VERIFY_USER_SSN_FAIL: 'VERIFY_USER_SSN_FAIL',

  GET_QUESTIONS_REQUEST: 'GET_QUESTIONS_REQUEST',
  GET_QUESTIONS_SUCCESS: 'GET_QUESTIONS_SUCCESS',
  GET_QUESTIONS_FAIL: 'GET_QUESTIONS_FAIL',

  SSN_EXIST_OTPCODE_SUCCESS: 'SSN_EXIST_OTPCODE_SUCCESS',

  SUBMIT_QUESTIONS_REQUEST: 'SUBMIT_QUESTIONS_REQUEST',
  SUBMIT_QUESTIONS_SUCCESS: 'SUBMIT_QUESTIONS_SUCCESS',
  SUBMIT_QUESTIONS_FAIL: 'SUBMIT_QUESTIONS_FAIL',

  FINALIZE_SIGNUP: 'FINALIZE_SIGNUP',

  SIGNUP_ERROR_LOGOUT: 'SIGNUP_ERROR_LOGOUT',
  SIGNUP_CREATE_ALERT: 'SIGNUP_ALERT_CREATE',
  SIGNUP_CLEAR_ALERT: 'SIGNUP_ALERT_CLEAR',
  SIGNUP_LOGIN_FAILURE: 'SIGNUP_LOGIN_FAILURE',
  SIGNUP_TRY_LOGIN_ON_WELCOME_BACK: 'SIGNUP_TRY_LOGIN_ON_WELCOME_BACK',

  GET_USERAGENT_STRING: 'GET_USERAGENT_STRING',

  SUMMARY_UPDATE_REQUEST: 'SUMMARY_UPDATE_REQUEST',
  ACTIVATE_SESAMECASH_SIGNUP: 'ACTIVATE_SESAMECASH_SIGNUP',
  INVISIBLE_USER: 'INVISIBLE_USER',
  DUP_KBA_PHONE_VERIFICATION: 'DUP_KBA_PHONE_VERIFICATION',
  CLEAR_ERROR_ALERTS: 'SIGNUP_CLEAR_ERROR_ALERTS',

  STORE_TU_SESSION_ID: 'STORE_TU_SESSION_ID',
  SHOW_OTP: 'SHOW_OTP',
  SHOW_KBA: 'SHOW_KBA',

  UPDATE_SELF_LOAN_ERRORS: 'UPDATE_SELF_LOAN_ERRORS',
  SET_VARIATIONS: 'SET_ONE_CLICK_VARIATIONS',
};

export const ACCT_CREATION = 'accountCreation';
export const PROFILE = 'profile';
export const ADDRESS = 'address';
export const PHONE_NUMBER = 'phoneNumber';
export const SSN = 'ssn';
export const SSN_9_DIGIT = 'ssn_9_digit';
export const PASSWORD = 'password';
export const VALIDATION = 'validation';
export const QUESTIONS = 'questions';
export const SUMMARY = 'summary';
export const BOOSTER_ENROLL = 'boosterEnroll';
export const SELF_LOAN_TU_ENROLL = 'tuEnroll';
export const BOOSTER_SUCCESS_LANDING = 'BOOSTER_SUCCESS_LANDING';
export const BOOSTER_CONGRATS = 'boosterCongrats';
export const DM_ACCT_CREATION = 'dmAccountCreation';
export const SIGNUP_CASH = 'signupCash';
export const QUESTIONS_SSN_MATCH = 'ssn_match_questions';
export const DUPE_QUESTIONS = 'dupe_questions';
export const DUP_KBA_PHONE_VERIFICATION = 'dupKBAPhoneVerification';
export const REVALIDATE_EMAIL = 'REVALIDATE_EMAIL';
export const TWO_FA_OTP_REQUEST = 'TWO_FA_OTP_REQUEST';
export const TWO_FA_OTP_VERIFY = 'TWO_FA_OTP_VERIFY';
export const REVIEW_INFO = 'REVIEW_INFO';
export const UPDATE_INFO = 'UPDATE_INFO';
export const CHECK_OPTIONS = 'CHECK_OPTIONS';
export const REVIEWED_USER_INFO = 'REVIEWED_USER_INFO';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const CONFIRM_UPDATED_INFO = 'CONFIRM_UPDATED_INFO';
export const ONE_CLICK_SIGNUP_KBA_ENTER_NAME_AND_DOB =
  'ONE_CLICK_SIGNUP_KBA_ENTER_NAME_AND_DOB';
export const ONE_CLICK_SIGNUP_KBA_ENTER_HOME_ADDRESS =
  'ONE_CLICK_SIGNUP_KBA_ENTER_HOME_ADDRESS';
export const ONE_CLICK_SIGNUP_KBA_REVIEW_INFORMATION =
  'ONE_CLICK_SIGNUP_KBA_REVIEW_INFORMATION';
export const GO_TO_NEXT_PAGE = 'GO_TO_NEXT_PAGE';
export const GO_TO_PREVIOUS_PAGE = 'GO_TO_PREVIOUS_PAGE';

export const stepOrder = [
  PROFILE,
  ADDRESS,
  PHONE_NUMBER,
  SSN,
  REVALIDATE_EMAIL,
  SUMMARY,
  QUESTIONS,
  PASSWORD,
  BOOSTER_ENROLL,
  BOOSTER_SUCCESS_LANDING,
  BOOSTER_CONGRATS,
  DUP_KBA_PHONE_VERIFICATION,
  SELF_LOAN_TU_ENROLL,
];
export const stepOrderCash = [
  PROFILE,
  ADDRESS,
  PHONE_NUMBER,
  SSN,
  SUMMARY,
  QUESTIONS,
];

const maxStepPercentages = 4;
const maxPercentage = 33;

export const STEP_ORDER_PERCENTAGES = {
  [PROFILE]: {
    pos: 1,
    max: maxStepPercentages,
    maxPercentage: maxPercentage,
  },
  [ADDRESS]: {
    pos: 2,
    max: maxStepPercentages,
    maxPercentage: maxPercentage,
  },
  [PHONE_NUMBER]: {
    pos: 3,
    max: maxStepPercentages,
    maxPercentage: maxPercentage,
  },
  [SSN]: {
    pos: 4,
    max: maxStepPercentages,
    maxPercentage: maxPercentage,
  },
};

export const SIGNUP_OPT_TIMEOUT = 1200;

//Address Validation Constants
export const DEFAULT_VALIDATION = 'DEFAULT';
export const SMARTY_STREETS_VALIDATION = 'SMARTY_STREETS';
export const USER_VALIDATION = 'USER';

//Local Storage Constants
export const ssnExistsData = 'dupErr';
export const otpData = 'otpData';
export const otpValidation = 'otpValidation';

// signup 1 view types
export const INVISIBLE_VIEW = 'INVISIBLE_VIEW';
export const PREMIUM_VIEW = 'PREMIUM_VIEW';
export const VALUE_PROPS_VIEW = 'VALUE_PROPS_VIEW';
export const STANDARD_VIEW = 'STANDARD_VIEW';
export const SOCIAL_TICKER_VIEW = 'SOCIAL_TICKER_VIEW';
