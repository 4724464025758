import React from 'react';
import classnames from 'classnames';

const BodyFooterDisclaimer = props => {
  return (
    <div className={classnames('text-12  padTB4', props.color, props.className)}>
      {props.children}
    </div>
  );
};

BodyFooterDisclaimer.defaultProps = {
  color: 'fontGray400',
};

export default BodyFooterDisclaimer;
