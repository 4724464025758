export const SELF_SIM_PAYLOAD = 'SELF_SIM_PAYLOAD';
export const CREDIT_REPAIR_EXPERT_SIM_PAYLOAD =
  'CREDIT_REPAIR_EXPERT_SIM_PAYLOAD';
export const AUTO_REFINANCE_PAYLOAD = 'AUTO_REFINANCE_PAYLOAD';

export const CREDIT_INFO_PAYLOAD = 'CREDIT_INFO_PAYLOAD';

export const CONFIG_PAYLOAD = 'CONFIG_PAYLOAD';

export const CASH_PIN_TOKEN_PAYLOAD = 'CASH_PIN_TOKEN_PAYLOAD';
export const ALL_CARD_DETAILS_PAYLOAD = 'ALL_CARD_DETAILS_PAYLOAD';
export const SECURED_CARD_STATEMENTS_PAYLOAD =
  'SECURED_CARD_STATEMENTS_PAYLOAD';
export const SECURED_CARD_SETTINGS_PAYLOAD = 'SECURED_CARD_SETTINGS_PAYLOAD';
export const RECENT_BUILDER_TRANSACTIONS_PAYLOAD =
  'RECENT_BUILDER_TRANSACTIONS_PAYLOAD';
export const BANKING_CONFIG_PAYLOAD = 'BANKING_CONFIG_PAYLOAD';

export const DEBT_RELIEF_PAYLOAD = 'DEBT_RELIEF_PAYLOAD';

export const CMALERTS_PAYLOAD = 'CMALERTS_PAYLOAD';
export const CSIDALERTS_PAYLOAD = 'CSIDALERTS_PAYLOAD';
export const HIGHLIGHT_FEATURES_PAYLOAD = 'HIGHLIGHT_FEATURES_PAYLOAD';
export const FEATURED_AD_PAYLOAD = 'FEATURED_AD_PAYLOAD';

export const CREDIT_CARDS_CONTAINER_PAYLOAD = 'CREDIT_CARDS_CONTAINER_PAYLOAD';
export const HOME_PAGE_CONTAINER_PAYLOAD = 'HOME_PAGE_CONTAINER_PAYLOAD';

export const CSID3BDIAL_PAYLOAD = 'CSID3BDIAL_PAYLOAD';
export const TIPOFTHEDAY_PAYLOAD = 'TIPOFTHEDAY_PAYLOAD';

export const SUBSCRIPTION_PAYLOAD = 'SUBSCRIPTION_PAYLOAD';
export const BILLING_PAYLOAD = 'BILLING_PAYLOAD';

export const PAYLOAD = 'PAYLOAD';

export const AUTO_INSURANCE_ACTIONS_PAYLOAD = 'AUTO_INSURANCE_ACTIONS_PAYLOAD';
export const HOME_INSURANCE_ACTIONS_PAYLOAD = 'HOME_INSURANCE_ACTIONS_PAYLOAD';

export const NOTIFICATIONS_ALERTS_PAYLOAD = 'NOTIFICATIONS_ALERTS_PAYLOAD';
export const NOTIFICATIONS_ALERTS_DETAILS_PAYLOAD =
  'NOTIFICATIONS_ALERTS_DETAILS_PAYLOAD';

export const buildPayloadDispatcher =
  (keyName, actionKey = PAYLOAD) =>
  (payload = null, loading = false, error = null) => {
    return {
      type: actionKey,
      [keyName]: payload,
      [keyName + 'Loading']: loading,
      [keyName + 'Error']: error,
    };
  };
