import {
  CANCEL_REMOVING_ACCOUNT,
  CLEAR_TRANSFER_FORM,
  CONFIRM_TRANSFER,
  GO_TO_CONFIRM_TRANSFER,
  GO_TO_FORM,
  GO_TO_SUCCESS,
  GO_TO_VALIDATING,
  SELECT_ACCOUNT_TO_REMOVE,
  SELECT_EXTERNAL_ACCOUNT,
  SHOW_ACCOUNT_SELECTION,
  SWITCH_TRANSFER_DIRECTION,
  TOGGLE_INSUFFICIENT_BALANCE_MODAL,
  TOGGLE_REMOVE_EXTERNAL_ACCOUNTS,
  TOGGLE_ZERO_BALANCE_MODAL,
  TRANSFER_FORM,
  TRANSFER_SUCCESS,
  VALIDATING,
} from 'types/bankTransfer.constants';
import { LOGOUT } from 'externals/_tracking/mixpanel/types/Mixpanel.event.types';

const initialState = {
  transferToCash: true,
  phase: null,
  isRemovingAccount: false,
  accountToRemove: null,
  showInsufficientBalanceModal: false,
  showZeroBalanceModal: false,
};

const bankTransferReducer = function (state = initialState, action) {
  switch (action.type) {
    case GO_TO_FORM:
      return {
        ...state,
        phase: TRANSFER_FORM,
      };
    case SWITCH_TRANSFER_DIRECTION:
      return {
        ...state,
        transferToCash: !state.transferToCash,
      };
    case SELECT_EXTERNAL_ACCOUNT:
      return {
        ...state,
        phase: SHOW_ACCOUNT_SELECTION,
        accountToRemove: null,
        isRemovingAccount: false,
      };
    case TOGGLE_REMOVE_EXTERNAL_ACCOUNTS:
      return {
        ...state,
        isRemovingAccount: !state.isRemovingAccount,
      };
    case GO_TO_CONFIRM_TRANSFER:
      return {
        ...state,
        phase: CONFIRM_TRANSFER,
      };
    case SELECT_ACCOUNT_TO_REMOVE:
      return {
        ...state,
        accountToRemove: action.accountToRemove,
      };
    case CANCEL_REMOVING_ACCOUNT:
      return {
        ...state,
        accountToRemove: null,
      };
    case GO_TO_VALIDATING:
      return {
        ...state,
        phase: VALIDATING,
      };
    case GO_TO_SUCCESS:
      return {
        ...state,
        phase: TRANSFER_SUCCESS,
      };
    case LOGOUT:
    case CLEAR_TRANSFER_FORM:
      return {
        ...initialState,
      };
    case TOGGLE_ZERO_BALANCE_MODAL:
      return {
        ...state,
        showZeroBalanceModal: !state.showZeroBalanceModal,
      };
    case TOGGLE_INSUFFICIENT_BALANCE_MODAL:
      return {
        ...state,
        showInsufficientBalanceModal: !state.showInsufficientBalanceModal,
      };
    default:
      return state;
  }
};

export default bankTransferReducer;
