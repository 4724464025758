import {
  buildFetchOptionsWithHeaders,
  standardizedFetchHandler,
} from './service_helpers';

import {
  buildUpdateUserQuestionaireEndpoint,
  buildUserQuestionaireEndpoint,
} from 'api-urls';

export async function getUserQuestionaire() {
  const fetchOpts = buildFetchOptionsWithHeaders();

  return standardizedFetchHandler(buildUserQuestionaireEndpoint(), fetchOpts);
}

export async function updateQuestionaireChoices(answerObj, questionnaireId) {
  const body = JSON.stringify(answerObj);
  const requestOptions = buildFetchOptionsWithHeaders({ method: 'PUT', body });

  return standardizedFetchHandler(
      buildUpdateUserQuestionaireEndpoint(questionnaireId),
    requestOptions,
  );
}
