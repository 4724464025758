import {
  FINISH_CATEGORIES_LOAD,
  START_CATEGORIES_LOAD,
} from 'types/cardCategories.constants';
import { LOGOUT } from 'types/app.constants';

const initialState = {
  cardCategoriesLoading: false,
  cardCategoriesLoaded: false,
};

export default function cardCategoriesReducer(state = initialState, action) {
  switch (action.type) {
    case START_CATEGORIES_LOAD:
      return Object.assign({}, initialState, {
        cardCategoriesLoading: true,
      });
    case FINISH_CATEGORIES_LOAD:
      return Object.assign({}, initialState, {
        cardCategoriesLoading: false,
        cardCategoriesLoaded: true,
      });
    case LOGOUT:
      return Object.assign({}, initialState);
    default:
      return state;
  }
}
