import {
  getOptimizelyUniqueIdentifier,
  optimizelyClient,
} from 'externals/_tracking/optimizely/optFlags';
import { buildOptimizelyDatapoints } from 'externals/_tracking';
import { OptimizelyProvider } from '@optimizely/react-sdk';
import { buildUserMetaAsCamelCase } from 'helpers/tracking.helpers';
import { useSelector } from 'react-redux';
import { createContext, useEffect, useMemo, useState } from 'react';

export const OptimizelyContext = createContext('optimizely');

const optimizelyStateSelector = state => {
  return {
    ocfInfo: state?.ocfInfo?.ocfInfo,
    cmAlerts: state?.cmAlerts?.cmAlerts,
    creditInfo: state?.creditInfo?.creditInfo,
    csidAlerts: state?.csidAlerts?.csidAlerts,
    newSecuredCardSim: state?.actions?.newSecuredCardSim,
    personalLoan: state?.datacache?.PL_PREQUAL_RESULT_KEY,
    sesameCashAccount: state?.sesameCash?.cashAccount,
    userInfo: state?.userInfo?.userInfo,
    page: state?.page,
    alertSettings: state?.alertSettings?.alertSettings,
    subscriptionInfo: state?.subscriptionInfo?.subscription,
    creditCardCategoriesContainer:
      state?.product?.creditCardCategoriesContainer,
    app: state?.app,
    todayContainer: state?.todayContainer?.todayContainer,
    oneClickSignup: state?.oneClickSignup,
  };
};

export const OptimizelyHoc = props => {
  const state = useSelector(optimizelyStateSelector);
  const [internalReference, setInternalReference] = useState(null);

  let userPayload = {
    id: getOptimizelyUniqueIdentifier(),
    attributes: {},
  };

  const userAttributes = useMemo(() => {
    return buildOptimizelyDatapoints(state);
    //eslint-disable-next-line
  }, [
    state.cmAlerts,
    state.creditInfo,
    state.ocfInfo,
    state.csidAlerts,
    state.newSecuredCardSim,
    state.personalLoan,
    state.sesameCashAccount,
    state.userInfo,
    state.alertSettings,
    state.subscriptionInfo,
    state.creditCardCategoriesContainer,
    state.app,
    state.todayContainer,
    state.oneClickSignup,
  ]);
  const userReplacementKeysAttributes = useMemo(() => {
    if (!state.userInfo || !state.creditInfo) return {};
    return buildUserMetaAsCamelCase(state?.userInfo, state?.creditInfo);
    //eslint-disable-next-line
  }, [state.creditInfo, state.userInfo]);

  userPayload.attributes = userAttributes;

  const consolidatePolling = true;
  const checkAndOverrideArray = () => {
    const initializedInternalReference = [];
    const overrideMrphOpt = {
      internalReference: initializedInternalReference,
      override: true,
      forEach: x => initializedInternalReference.forEach(x),
      push: x => initializedInternalReference.push(x),
      find: x => initializedInternalReference.find(x),
      *[Symbol.iterator]() {
        for (const x of initializedInternalReference) {
          yield x;
        }
      },
    };
    if (window.mrphOpt) {
      overrideMrphOpt?.internalReference?.push(...window.mrphOpt);
    }

    overrideMrphOpt.push = x => {
      setInternalReference(overrideMrphOpt);
      overrideMrphOpt?.internalReference?.push(x);
      overrideMrphOpt.length = overrideMrphOpt?.internalReference?.length;
    };
    if (!global.isUnitTestSuite) {
      setInternalReference(overrideMrphOpt);
    }
    window.mrphOpt = overrideMrphOpt;
    return overrideMrphOpt;
  };

  useEffect(() => {
    if (consolidatePolling && internalReference === null) {
      checkAndOverrideArray();
    }
    //eslint-disable-next-line
  }, []);

  const optimizelyProviderState = {
    consolidatePolling,
    getVariationKey: experimentKey => {
      let finalReference = internalReference;
      if (finalReference === null) {
        finalReference = checkAndOverrideArray();
      }
      const foundExp = finalReference.find(e => e?.exp === experimentKey);

      if (foundExp) return foundExp.var;
      else return null;
    },
    userReplacementKeysAttributes,
  };

  return (
    <>
      {' '}
      <OptimizelyProvider optimizely={optimizelyClient} user={userPayload}>
        <OptimizelyContext.Provider value={optimizelyProviderState}>
          {props.children}
        </OptimizelyContext.Provider>
      </OptimizelyProvider>
    </>
  );
};
