import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './root-reducer';
import { setCSContext } from 'externals/_tracking';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function buildActionCapture(arr) {
  return function ActionCapture(store) {
    return function (next) {
      return function (action) {
        // do something
        // then (usually) - continue to the next action
        if (arr) arr.push(action);
        return next(action);
      };
    };
  };
}

export const buildStore = preOpts => {
  let actionCapture = undefined;
  const opts = preOpts ? preOpts : {};
  const middleware = [thunkMiddleware.withExtraArgument(setCSContext)];
  if (opts.actionLog) {
    actionCapture = buildActionCapture(opts.actionLog);
    middleware.push(actionCapture);
  }

  const freshStore = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware)),
  );

  return freshStore;
};

const storeMeta = buildStore();
export const store = storeMeta;
