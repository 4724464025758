import { paths } from 'components/TextFromConfig/config.paths';

export const CREDIT_LIMIT = 'CREDIT_LIMIT';
export const SELF = 'SELF';
export const CREDIT_FOUNDATION = 'CREDIT_FOUNDATION';
export const NEW_CARD_SCORE_INCREASE = 'NEW_CARD_SCORE_INCREASE';
export const NEW_CARD_SCORE_INCREASE_OVER_TIME =
  'NEW_CARD_SCORE_INCREASE_OVER_TIME';
export const REDUCE_CREDIT_USAGE_WITH_LIMIT_INCREASE =
  'REDUCE_CREDIT_USAGE_WITH_LIMIT_INCREASE';
export const CC_REWARDS = 'CC_REWARDS';
export const NEGATIVE_MARKS = 'NEGATIVE_MARKS';
export const CREDIT_REPAIR_EXPERT = 'CREDIT_REPAIR_EXPERT';
export const CREDIT_REPAIR_PL_PREQUAL = 'CREDIT_REPAIR_PL_PREQUAL';

//OPT Experiment actions
export const DEBT_RELIEF = 'DEBT_RELIEF';

export const getActionsList = () => {
  let actionsList = [
    CREDIT_LIMIT,
    SELF,
    CREDIT_FOUNDATION,
    NEW_CARD_SCORE_INCREASE,
    NEW_CARD_SCORE_INCREASE_OVER_TIME,
    REDUCE_CREDIT_USAGE_WITH_LIMIT_INCREASE,
    CC_REWARDS,
    NEGATIVE_MARKS,
    CREDIT_REPAIR_EXPERT,
  ];
  return actionsList;
};

// actionConfig.name -> been used on ActionSummaryView for check clicking the action tile
const actionConfig = {
  AUTO_INSURANCE_FOR_CREDIT_SCORE_INCREASE: {
    name: 'Auto Insurance For Improved Credit Score',
    configPath: paths.AUTO_INSURANCE_FOR_CREDIT_SCORE_INCREASE,
    class: 'autoInsuranceForImprovedCreditScore',
  },
  AUTO_INSURANCE_FOR_NEW_AUTO_LOAN: {
    name: 'Auto Insurance For New Auto Loan',
    configPath: paths.AUTO_INSURANCE_FOR_NEW_AUTO_LOAN,
    class: 'autoInsuranceForNewAutoLoan',
  },

  CREDIT_LIMIT: {
    name: 'Higher Credit Limit',
    configPath: paths.CREDIT_LIMIT.BASE,
    class: 'creditLimit',
  },
  SELF: {
    name: 'Self Lender - Score Over Time',
    configPath: paths.SELF_LENDER.BASE,
    class: 'selfLender',
  },
  CREDIT_FOUNDATION: {
    name: 'Credit Foundation',
    configPath: paths.CREDIT_FOUNDATION.BASE,
    class: 'creditFoundation',
  },
  HOME_INSURANCE: {
    name: 'Home Insurance',
    configPath: paths.HOME_INSURANCE,
    class: 'homeInsurance',
  },
  NEW_CARD_SCORE_INCREASE: {
    name: 'New Card Score Increase',
    configPath: paths.NEW_CARD_SCORE_INCREASE.BASE,
    class: 'newCardScoreIncrease',
  },
  NEW_CARD_SCORE_INCREASE_OVER_TIME: {
    name: 'New CC - Score Over Time',
    configPath: paths.NEW_CARD_SCORE_INCREASE_OVER_TIME.BASE,
    class: 'newCardScoreOverTime',
  },
  REDUCE_CREDIT_USAGE_WITH_LIMIT_INCREASE: {
    name: 'Credit Usage',
    configPath: paths.REDUCE_CREDIT_USAGE_WITH_LIMIT_INCREASE.BASE,
    class: 'reduceUsage',
  },
  AUTO_REFINANCE: {
    name: 'Auto Refinance',
    configPath: paths.AUTO_REFINANCE.BASE,
    class: 'autoRefinance',
  },
  CC_REWARDS: {
    name: 'Rewards',
    configPath: paths.CC_REWARDS.BASE,
    class: 'ccRewards',
  },
  NEGATIVE_MARKS: {
    name: 'Bad Payment History',
    configPath: paths.NEGATIVE_MARKS.BASE,
    class: 'negativeMarks',
  },
  CREDIT_REPAIR_EXPERT: {
    name: 'Bad Payment History Score Increase',
    configPath: paths.CREDIT_REPAIR_EXPERT.BASE,
    class: 'creditRepairExpert',
  },
  DEBT_RELIEF: {
    name: 'Debt Relief',
    configPath: paths.DEBT_RELIEF,
    class: 'debtReliefServices',
  },
  PL_PREQUAL_MODULE: {
    name: 'Personal Loans Prequalification',
    class: 'plPrequalModule',
  },
  PL_PREQUAL_OFFER_MODULE: {
    name: 'Personal Loans Prequalification Offers',
    class: 'plPrequalModule',
  },
};

export const getActionIcon = module => {
  const action = actionConfig[module];
  if (action) {
    return action['class'];
  } else {
    return null;
  }
};

export const getActionName = module => {
  const action = actionConfig[module];
  if (action) {
    return action['name'];
  } else {
    return null;
  }
};
