/*
 WIP, need to expand for all the click types for mixpanel, 
 currently these are the ones that are needed for braze and also apply to mixpanel
*/
//Braze && Mixpanel click types
export const MAIN_NAV = 'Main Nav';
export const CREDIT_GRADE = 'Credit Grade';
export const FILTERS = 'Filters';
export const REVIEWS = 'Reviews';
export const SLIDER = 'Slider';
export const PREQUAL = 'Prequal';
export const ALERT = 'Alert';

//Additional Mixpanel click types
export const NAVIGATION = 'Navigation';
export const APPLY = 'Apply';
export const TOOLTIP = 'Tooltip';
export const SCORE_CHANGE_TAB = 'Score change tab';
