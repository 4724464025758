import {
  SET_OFFER_STATE,
  INCREASE_OFFER_COUNT,
  REDUCE_OFFER_COUNT,
} from 'types/offerState.constants';
import { LOGOUT } from 'types/app.constants';

const handleSetOfferState = (state, action) => {
  const fingerprint = action.fingerprint;
  const oldState = state[fingerprint];
  const finalOldState = oldState ? oldState : {};
  const newState = action.state;
  const mergedState = Object.assign({}, finalOldState, newState);

  return Object.assign({}, state, { [fingerprint]: mergedState });
};

const increaseOfferState = (state, action) => {
  const fingerprint = action.fingerprint;
  const fingerprintCountKey = fingerprint + '_COUNT';
  const currentState = state[fingerprint];
  const currentCount = state[fingerprintCountKey];
  if (currentState && currentCount) {
    return Object.assign({}, state, {
      [fingerprintCountKey]: currentCount + 1,
    });
  } else {
    return Object.assign({}, state, {
      [fingerprintCountKey]: 1,
      [fingerprint]: currentState ? currentState : {},
    });
  }
};

const reduceOfferState = (state, action) => {
  const fingerprint = action.fingerprint;
  const fingerprintCountKey = fingerprint + '_COUNT';
  const currentCount = state[fingerprintCountKey];
  const newCount = currentCount - 1;
  if (newCount > 0) {
    return Object.assign({}, state, {
      [fingerprintCountKey]: newCount,
    });
  } else {
    return Object.assign({}, state, {
      [fingerprintCountKey]: undefined,
      [fingerprint]: undefined,
    });
  }
};

export default function offerStateReducer(state = {}, action) {
  switch (action.type) {
    case SET_OFFER_STATE:
      return handleSetOfferState(state, action);
    case INCREASE_OFFER_COUNT:
      return increaseOfferState(state, action);
    case REDUCE_OFFER_COUNT:
      return reduceOfferState(state, action);
    case LOGOUT:
      return {};
    default:
      return state;
  }
}
