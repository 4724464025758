export const mktTrackingCookieName = 'inbound_trk_params';

export const mktCookieConfig = {
  channel: 'Channel',
  publisher: 'ReferringURLPublisher',
  campaignId: 'CampaignId',
  subCampaignId: 'SubCampaignId',
  subId: 'SubId',
  subId2: 'SubId2',
  network: 'Network',
  creative: 'ReferringURLCreativeId',
  marketingUrl: 'marketingUrl',
  marketingMedium: 'medium',
  marketingType: 'type',
  adGroupId: 'ReferringURLAdGroupId',
  adKeyword: 'MarketingKeyword',
};

export const adwordsCookieConfig = {
  campaignId: 'ReferringURLCampaignId',
  subCampaignId: 'ReferringURLAdGroupId',
  subId: 'MarketingKeyword',
  subId2: 'MarketingMatchType',
};

export const REGISTRATION_MKT_SRC = 'marketingSource';
export const SESION_MKT_SRC = 'sessionMarketingSource';
export const IR_CLICK_ID = 'irclickid';
export const E2E_SIGNUP_REDIRECT = 'e2e_signup_redirect';
