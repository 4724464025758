import { getContainerData } from 'actions/offers.actions';
import { RESET_CREDIT_REPORT_TAB_CONTAINER_DATA } from 'types/creditReportTabContainer.constans';

export const getCreditReportTab = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { creditReportTabContainer } = getState();
    const { containerLoading } = creditReportTabContainer || {};
    if (!containerLoading) {
      dispatch(getContainerData('CREDIT_REPORT_TAB'))
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    } else {
      resolve({ message: 'CREDIT_REPORT_TAB container is still working' });
    }
  });
};

export const clearCreditReportTab = () => ({
  type: RESET_CREDIT_REPORT_TAB_CONTAINER_DATA,
});
