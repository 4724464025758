import { getItem } from 'helpers/localstorage';

// To create an audience
// window.checkValueInLocalStorage('BOOSTER_RELAUNCH_SIGNUP', 'true')
// Usually the value needs to be a string since localstorage always stores as string
function checkValueInLocalStorage(keyName, tester = true) {
  const val = getItem(keyName);

  if (typeof tester === 'function') {
    return tester(val);
  } else {
    return val === tester;
  }
}

// To create an audience
// window.checkOptimizelyContext('isCashEnrolled', true)
function checkOptimizelyContext(keyName, tester = true) {
  const val = getItem('CSContext');
  const parsed = JSON.parse(val);

  if (typeof parsed !== 'object') return false;

  if (typeof tester === 'function') {
    return tester(parsed[keyName], parsed);
  } else {
    return parsed[keyName] === tester;
  }
}

window.checkValueInLocalStorage = checkValueInLocalStorage;
window.checkOptimizelyContext = checkOptimizelyContext;
