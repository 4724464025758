import { buildStorage, buildStorageByAttributeName } from 'api-urls';
import {
  buildFetchOptionsWithHeaders,
  standardizedFetchHandler,
} from './service_helpers';

export async function getStorageByAttributeName(attributeName) {
  const requestOptions = buildFetchOptionsWithHeaders();

  return standardizedFetchHandler(
    buildStorageByAttributeName(attributeName),
    requestOptions,
  );
}

export async function addStorageByAttributeName(body) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body: body,
  });
  return standardizedFetchHandler(buildStorage(), requestOptions);
}

export async function updateStorageByAttributeName(body) {
  const requestOptions = buildFetchOptionsWithHeaders({ method: 'PUT', body });

  return standardizedFetchHandler(buildStorage(), requestOptions);
}
