import React from 'react';
import './BodyContainer.scss';

function BodyContainer({
  children,
  className,
  type,
  datatestid,
  style,
  ...props
}) {
  const testid = datatestid ? `${datatestid}-body` : null;
  let tempClass = `BodyContainer`;
  tempClass = className ? `${tempClass} ${className}` : tempClass;
  tempClass = type ? `${tempClass} ${type}` : tempClass;

  return (
    <div className={tempClass} data-testid={testid} id={props.id} style={style}>
      <div id="main"></div>
      {children}
    </div>
  );
}

export { BodyContainer };
