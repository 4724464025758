export const GENERAL = 'GENERAL';
export const GENERAL_TU = 'GENERAL_TU';
export const DEVICE_BLOCKED = 'DEVICE_BLOCKED';
export const DUP_DROP = 'DUP_DROP';
export const DUP_ERROR = 'DUP_ERROR';
export const ACCOUNT_LOCKOUT = 'ACCOUNT_LOCKOUT';
export const CREDIT_PROFILE_ERR = 'CREDIT_PROFILE_ERR';
export const QUESTIONS_NOT_RECEIVED = 'QUESTIONS_NOT_RECEIVED';

//Mapping to keep consistent with existing reporting - tech debt to verify error page naming conventions with product
export const errorPageNameMap = {
  GENERAL: 'default',
  GENERAL_TU: 'generalTU',
  DEVICE_BLOCKED: 'iovationFailed',
  DUP_DROP: 'ssnExistDrop',
  DUP_ERROR: 'ssnExistError',
  ACCOUNT_LOCKOUT: 'accountLockedError',
  CREDIT_PROFILE_ERR: 'creditProfileError',
  QUESTIONS_NOT_RECEIVED: 'questionsNotReceived',
};
