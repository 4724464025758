import { LOGOUT } from 'types/app.constants';
import {
  SELECT_1B_3B_FACTOR,
  SELECT_1B_3B_BUREAU,
  RESET_1B_3B_FACTOR_BUREAU,
} from 'types/score1b3bFactorDial.constants';

const initialState = {
  factor: {
    selectedFactor: null,
    selectedFactorGrade: null,
  },
  bureau: {
    selectedBureau: null,
  },
};

export default function score1b3bFactorDial(state = initialState, action) {
  switch (action?.type) {
    case SELECT_1B_3B_FACTOR: {
      return {
        ...state,
        factor: {
          selectedFactor: action?.selectedFactor,
          selectedFactorGrade: action?.selectedFactorGrade,
        },
      };
    }
    case SELECT_1B_3B_BUREAU: {
      return {
        ...state,
        bureau: {
          selectedBureau: action?.selectedBureau,
        },
      };
    }
    case LOGOUT:
    case RESET_1B_3B_FACTOR_BUREAU:
      return initialState;
    default:
      return state;
  }
}
