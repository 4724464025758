import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  clearFrozenEvents,
  setFreezeEvents,
  standardEventHandler,
} from 'actions/eventHandler.actions';
import { CLICK } from 'externals/_tracking/types/eventTypes.constants';
import {
  MAIN_NAV,
  NAVIGATION,
  TOOLTIP,
  SCORE_CHANGE_TAB,
} from 'externals/_tracking/types/clickEventTypes.constants';
import { isTest } from 'helpers/env';
import { featureVisited } from 'helpers/highlightFeatures.helper';

export const useEventHandler = () => {
  const dispatch = useDispatch();

  const memoizedHandle = useMemo(() => {
    function eventHandler(name, additionalData, eventOpts) {
      if (!isTest()) {
        dispatch(standardEventHandler(name, additionalData, eventOpts));
      }
    }
    return eventHandler;
    //eslint-disable-next-line
  }, []);

  return memoizedHandle;
};

export const useLinkHandler = eventHandler => {
  function onLinkClick(metadata = {}) {
    const eventAttributes = {};
    if (metadata.factor) {
      eventAttributes['Click Type'] = metadata.factor;
      if (metadata.modulePosition) {
        eventAttributes['Module Position'] = metadata.modulePosition;
      }

      eventHandler(CLICK, eventAttributes);
    } else if (metadata.navItem) {
      eventAttributes['Click Type'] = MAIN_NAV;
      eventAttributes['Nav Item'] = metadata.navItem;

      eventHandler(CLICK, eventAttributes);
    } else if (metadata.isMenu) {
      const attrs = {
        'Click Type': MAIN_NAV,
        'Nav Item': 'Toggle',
        'Is Open': metadata.isOpen,
      };

      eventHandler(CLICK, attrs);
    } else if (metadata.tabname) {
      const attrs = {
        'Click Type': NAVIGATION,
        Item: metadata.tabname,
      };

      eventHandler(CLICK, attrs);
    } else if (metadata.link) {
      let attrs = {
        'Click Type': NAVIGATION,
        Link: metadata.link,
      };

      if (typeof metadata.module !== 'undefined') {
        attrs['Module'] = metadata.module;
      }
      if (typeof metadata.navLocation !== 'undefined') {
        attrs['Nav Location'] = metadata.navLocation;
      }

      if (typeof metadata.modulePosition !== 'undefined') {
        attrs['Module Position'] = metadata.modulePosition;
      }

      if (typeof metadata.Vertical !== 'undefined') {
        attrs.Vertical = metadata?.Vertical;
      }

      eventHandler(CLICK, attrs);
    } else if (metadata.back) {
      const attrs = {
        'Click Type': NAVIGATION,
        Link: 'Back',
      };

      eventHandler(CLICK, attrs);
    } else if (metadata.location && metadata.extraEventAttributes) {
      const attrs = {
        'Click Type': NAVIGATION,
        'Nav Location': metadata.location,
      };
      eventHandler(
        CLICK,
        Object.assign({}, attrs, metadata.extraEventAttributes),
      );
    } else if (metadata.location) {
      const attrs = {
        'Click Type': NAVIGATION,
        'Nav Location': metadata.location,
      };
      eventHandler(CLICK, attrs);
    }

    if (metadata.featureToHighlight) {
      featureVisited(metadata.featureToHighlight);
    }
  }

  return onLinkClick;
};

export const useScoreChangeHandler = eventHandler => {
  function onScoreChangeTabClick(metadata) {
    const attrs = {
      'Click Type': SCORE_CHANGE_TAB,
      'Module Name': metadata['Module Name']
        ? metadata['Module Name']
        : metadata['Page Position'],
      Tab: metadata.tab,
      __braze: true,
    };
    if (metadata.Vertical) {
      attrs.Vertical = metadata.Vertical;
    }
    eventHandler(CLICK, attrs);
  }

  return onScoreChangeTabClick;
};

export const useTooltipHandler = eventHandler => {
  function onTooltipClick(metadata, extraAttributes) {
    const attrs = {
      'Click Type': TOOLTIP,
      'Page Position': metadata.pagePosition,
      Tooltip: metadata.tooltip,
    };

    eventHandler(CLICK, Object.assign({}, attrs, extraAttributes));
  }

  return onTooltipClick;
};

export const useEventBatching = (freeze, delay = 1000) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (freeze) {
      dispatch(setFreezeEvents(freeze));
    }
    if (!freeze) {
      setTimeout(() => {
        dispatch(setFreezeEvents(freeze));
        dispatch(clearFrozenEvents());
      }, delay);
    }
    //eslint-disable-next-line
  }, [freeze]);
};
