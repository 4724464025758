export const CHANGE_OF_ADDRESS_ALERT = 'CHANGE_OF_ADDRESS_ALERT';
export const CHANGE_OF_ADDRESS_REPORT = 'CHANGE_OF_ADDRESS_REPORT';

export const COURT_RECORDS_ALERT = 'COURT_RECORDS_ALERT';
export const COURT_RECORDS_REPORT = 'COURT_RECORDS_REPORT';
export const CYBER_AGENT_ALERT = 'CYBER_AGENT_ALERT';
export const CYBER_AGENT_REPORT = 'CYBER_AGENT_REPORT';

export const IDENTITY_TRACE_ALERT = 'IDENTITY_TRACE_ALERT';
export const IDENTITY_TRACE_REPORT = 'IDENTITY_TRACE_REPORT';
export const NON_CREDIT_ALERT = 'NON_CREDIT_ALERT';
export const NON_CREDIT_REPORT = 'NON_CREDIT_REPORT';

export const SEX_OFFENDER_ALERT = 'SEX_OFFENDER_ALERT';
export const SEX_OFFENDER_REPORT = 'SEX_OFFENDER_REPORT';
export const SSN_TRACE_ALERT = 'SSN_TRACE_ALERT';
export const SSN_TRACE_REPORT = 'SSN_TRACE_REPORT';
export const DARK_WEB_MONITORING = 'DARK_WEB_MONITORING';
export const NAME_CHANGE = 'NAME_CHANGE';
export const ADDRESS_CHANGE = 'ADDRESS_CHANGE';
export const CRIMINAL_ACTIVITY = 'CRIMINAL_ACTIVITY';

export const csidAlertsNamingMap = {
  CHANGE_OF_ADDRESS_ALERT: 'Change of Address',
  CHANGE_OF_ADDRESS_REPORT: 'Change of Address',
  COURT_RECORDS_ALERT: 'Court Records',
  COURT_RECORDS_REPORT: 'Court Records',
  CYBER_AGENT_ALERT: 'Black Market Website',
  CYBER_AGENT_REPORT: 'Black Market Website',
  IDENTITY_TRACE_ALERT: 'Identity Trace',
  IDENTITY_TRACE_REPORT: 'Identity Trace',
  NON_CREDIT_ALERT: 'Payday Loans',
  NON_CREDIT_REPORT: 'Payday Loans Report',
  SEX_OFFENDER_ALERT: 'Sex Offender',
  SEX_OFFENDER_REPORT: 'Sex Offender',
  SSN_TRACE_ALERT: 'SSN Trace',
  SSN_TRACE_REPORT: 'SSN Trace',
  DARK_WEB_MONITORING: 'Dark Web Alert',
  ADDRESS_CHANGE: 'Address Change Alert',
  NAME_CHANGE: 'Name Change Alert',
  CRIMINAL_ACTIVITY: 'Court Record Alert',
};
