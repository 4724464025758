import { mergePayloadChanges } from 'helpers/reducer.helper';
import { PAYLOAD } from 'types/payload.constants';
import {
  RENT_REPORTING_AUTH_ERROR,
  RENT_REPORTING_AUTH_REQUEST,
  RENT_REPORTING_AUTH_SUCCESS,
  RENT_REPORTING_PAYMENTS_ERROR,
  RENT_REPORTING_PAYMENTS_REQUEST,
  RENT_REPORTING_PAYMENTS_SUCCESS,
} from 'types/rentReporting.constants';

const initialState = {
  rentReportingPaymentsLoading: null,
  rentReportingPaymentsError: null,
  rentReportingPayments: null,
  rentReportingAuthLoading: null,
  rentReportingAuthError: null,
  rentReportingAuth: null,
};

const rentReportingReducer = function (state = initialState, action) {
  switch (action.type) {
    case PAYLOAD:
    case RENT_REPORTING_PAYMENTS_SUCCESS:
    case RENT_REPORTING_PAYMENTS_ERROR:
    case RENT_REPORTING_PAYMENTS_REQUEST:
    case RENT_REPORTING_AUTH_REQUEST:
    case RENT_REPORTING_AUTH_ERROR:
    case RENT_REPORTING_AUTH_SUCCESS:
      return mergePayloadChanges(state, action);
    default:
      return state;
  }
};

export default rentReportingReducer;
