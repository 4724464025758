export const formatNumberNoDecimals = n => {
  return Math.round(n);
};

export const formatApr = n => {
  return Math.round(n * 100) / 100;
};

export const formatRatingPercentage = n => {
  return (Math.round(10 * n) / 10).toFixed(1);
};
export const formatNumber2Decimal = n => {
  return (Math.round(100 * n) / 100).toFixed(2);
};

export const padSingleDigitWithLeadingZero = number => {
  return number >= 10 ? number : '0' + number;
};

export const formatUSDWithCents = (number, prefix = '$') => {
  return new Intl.NumberFormat(
    'en-US',
    { style: 'currency', currency: 'USD' },
    { maximumFractionDigits: 0 },
  ).format(number);
};

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    //eslint-disable-next-line
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const countDecimalPlaces = num => {

  const numStr = String(num);

  if (numStr.includes('.')) {
    return numStr.split('.')[1].length;
  } else {
    return 0;
  }
}
export const commafy = num => {
  if (typeof num === "undefined") {
    return "";
  }
  var str = num.toString().split(".");
  if (str[0].length >= 4) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
  }
  if (str[1] && str[1].length >= 5) {
    str[1] = str[1].replace(/(\d{3})/g, "$1 ");
  }
  return str.join(".");
};
export const decommafy = str => {
  const regex = /[,$]/g;
  if (regex.test(str)) {
    str = str.replace(regex, "");
  }
  return str;
};
export const isNumber = n => {
  if (typeof n === "number") return true;
  else if (typeof n === "string") {
    return !isNaN(parseFloat(n)) && isFinite(n.toString().replace(/^-/, ""));
  }
};
export const countDecimals = function (n) {
  let targetVal = n;
  if (targetVal === undefined || targetVal === null) return 0;
  if (!isNumber(targetVal)) return 0;
  if (typeof targetVal === "number") targetVal = targetVal.toString();
  const splitArr = targetVal.split(".");
  if (splitArr.length <= 1) return 0;

  return splitArr[1].length || 0;
}