import { LOGIN_SUCCESS, LOGOUT } from 'types/app.constants';
import { CSIDALERTS_PAYLOAD, PAYLOAD } from 'types/payload.constants';
import { mergePayloadChanges } from 'helpers/reducer.helper';

const initialState = {
  csidAlerts: null,
  csidAlertsError: null,
  csidAlertsLoading: null,
};

export default function csidAlertsReducer(state = initialState, action) {
  switch (action.type) {
    case CSIDALERTS_PAYLOAD:
    case LOGIN_SUCCESS:
    case PAYLOAD:
      return mergePayloadChanges(state, action);
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
