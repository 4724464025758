import React from 'react';
import './Label.scss';
import classnames from 'classnames';

function Label({
  type,
  bold,
  color,
  inline,
  children,
  className,
  datatestid,
  prefix,
  typeHeader = 'div',
  ...props
}) {
  const labelClass = classnames({
    [type]: type,
    [color]: color,
    bold: bold,
    inlineBlock: inline,
    notBold: bold !== undefined && !bold,
    [className]: className,
  });

  const Element = typeHeader;

  return (
    <Element
      className={labelClass}
      data-testid={datatestid ? `${datatestid}` : null}
      tabIndex={'0'}
      {...props}
    >
      {children}
    </Element>
  );
}

export default Label;
