import { useViewModalWatcher } from 'hook/helper.hook';
import React from 'react';
import { SESSION_TIMEOUT_MODAL } from 'types/morph.pagePosition.constants';
import { SessionTimeoutModal } from './SessionTimeoutModal/SessionTimeoutModal';
import { useSessionManager } from './sessionManager.hook';

const SessionManagerController = props => {
  const sessionTimeout = useSessionManager();
  const SECONDS_TO_TIMEOUT = 60;

  const modalAttrs = {
    'Modal Name': SESSION_TIMEOUT_MODAL,
    'Page Position': SESSION_TIMEOUT_MODAL,
  };

  useViewModalWatcher(modalAttrs, sessionTimeout.sessionTimeoutModalOpen);

  return (
    <SessionTimeoutModal
      datatestid={'sessionTimeoutModal'}
      seconds={SECONDS_TO_TIMEOUT}
      {...sessionTimeout}
    />
  );
};

export default SessionManagerController;
