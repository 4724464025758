import {
  ADD_ITEM_TO_CHECKOUT,
  CLEAR_CHECKOUT_CART,
  ADD_ITEM_TO_PRECHECKOUT,
} from 'types/checkout.constants';
import { LOGOUT, INVALID_ROUTE_REDIRECT } from 'types/app.constants';

const initialState = {
  checkoutItemId: null,
  precheckoutItemId: null,
  checkoutConfiguration: null,
};

export default function checkoutReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ITEM_TO_CHECKOUT:
      return Object.assign({}, initialState, {
        checkoutItemId: action.checkoutItemId,
        precheckoutItemId: action.checkoutItemId,
        checkoutConfiguration: action.checkoutConfiguration,
      });
    case CLEAR_CHECKOUT_CART:
      return Object.assign({}, initialState, {
        checkoutItemId: null,
        precheckoutItemId: null,
      });
    case ADD_ITEM_TO_PRECHECKOUT:
      return Object.assign({}, initialState, {
        precheckoutItemId: action.precheckoutItemId,
      });
    case LOGOUT:
    case INVALID_ROUTE_REDIRECT:
      return Object.assign({}, initialState);
    default:
      return state;
  }
}
