import { wwwUrls } from 'api-urls';

const dateToStr = function (myDate) {
  return (
    myDate.getFullYear() +
    '-' +
    (myDate.getMonth() + 1) +
    '-' +
    myDate.getDate() +
    ' ' +
    myDate.getHours() +
    ':' +
    myDate.getMinutes() +
    ':' +
    myDate.getSeconds()
  );
};

export const fetchTrkCookies = () => {
  const fetchOpts = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'X-requested-with': 'XMLHttpRequest',
      Origin: window.location.origin,
    },
  };
  const date = dateToStr(new Date());
  const query = `?NO_REDIRECT=true&referer=${document.referrer}&localTimeCreateDate=${date}`;

  return new Promise(resolve => {
    try {
      fetch(wwwUrls.buildTrkEndpoint() + query, fetchOpts)
        .then(resolve)
        .catch(resolve);
    } catch (err) {
      resolve();
    }
  });
};
