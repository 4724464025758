export const isVariationActive = (expFlag, variation = 'var1') => {
  return (
    expFlag?.loading === false &&
    expFlag?.isEnabled &&
    expFlag?.variationKey === variation
  );
};

export const isVariationLoadedAndEnabled = expFlag => {
  return (
    expFlag.loading === false &&
    expFlag.isEnabled &&
    expFlag.variationKey !== 'off'
  );
};

export const isNonControlVariationLoadedAndEnabled = expFlag => {
  return (
    expFlag?.loading === false &&
    expFlag?.isEnabled &&
    expFlag?.variationKey !== 'off' &&
    expFlag?.variationKey !== 'on' &&
    expFlag?.variationKey !== 'control'
  );
};

export const isDecisionNonControlVariationAndEnabled = expFlag => {
  return (
    expFlag?.isEnabled &&
    expFlag?.variationKey !== 'off' &&
    expFlag?.variationKey !== 'on' &&
    expFlag?.variationKey !== 'control'
  );
};
