import { mergePayloadChanges } from 'helpers/reducer.helper';
import {
  SET_DATA_FOR_CASH_ACCOUNT_MODAL,
  SET_DATA_FOR_CASH_PANELS,
} from 'types/app.constants';

const initialState = {
  urlOnCloseCashAccountModal: null,
  panelCardExpanded: false,
  panelCardExpandedWithButtons: false,
  panelOptionsExpanded: false,
};

export default function configReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_FOR_CASH_ACCOUNT_MODAL:
    case SET_DATA_FOR_CASH_PANELS:
      return mergePayloadChanges(state, action);
    default:
      return state;
  }
}
