/* Braze event types */
export const LOGIN = `LOGIN`;
export const REFRESH_CREDIT_PROFILE = `REFRESH_CREDIT_PROFILE`;
export const REGISTER = `REGISTER`;
export const VIEW_PAGE = `VIEW_PAGE`;
export const VIEW_OFFER = `VIEW_OFFER`;
export const VIEW_MODAL = `VIEW_MODAL`;
export const DISMISS_MODAL = 'DISMISS_MODAL';
export const CLICK = `CLICK`;
export const CLICK_APPLY = `CLICK_APPLY`;
export const CLICK_MAIN_NAV = `CLICK_MAIN_NAV`;
export const CLICK_FILTERS = `CLICK_FILTERS`;
export const CLICK_REVIEWS = `CLICK_REVIEWS`;
export const CLICK_SLIDER = `CLICK_SLIDER`;
export const CLICK_PREQUAL = `CLICK_PREQUAL`;
export const CLICK_ALERT = `CLICK_ALERT`;
export const SEEN_OFFER = `SEEN_OFFER`;
export const CLICK_SEE_MY_CU = 'CLICK_SEE_MY_CU';
export const CLICK_TRANSFER_MONEY = 'CLICK_TRANSFER_MONEY';
export const CHECKOUT_BUY_NOW_PREMIUM = 'CHECKOUT_BUY_NOW_PREMIUM';
export const VIEW_POPUP = 'VIEW_POPUP';
export const DISMISS_POPUP = 'DISMISS_POPUP';
export const BUY_NOW_PLUS = 'Buy Now Plus';
export const BUY_NOW_PREMIUM = 'Buy Now Premium ';
