import {
  SETTINGS_ALERTS_REQUEST,
  SETTINGS_ALERTS_SUCCESS,
  SETTINGS_ALERTS_FAILURE,
} from 'types/alertSettings.constants';

import {
  getAlertSettings,
  updateAlertSettings,
} from 'externals/_services/alert.service';
import { wrapService } from 'actions/service_wrapper.actions';

const a_getAlertSettings = wrapService(getAlertSettings, {
  name: 'getAlertSettings',
});
const a_updateAlertSettings = wrapService(updateAlertSettings, {
  name: 'updateAlertSettings',
});

function settingsRequest() {
  return { type: SETTINGS_ALERTS_REQUEST };
}
function settingsSuccess(settings) {
  return { type: SETTINGS_ALERTS_SUCCESS, settings };
}
function settingsError(error) {
  return { type: SETTINGS_ALERTS_FAILURE, error };
}

export const getNotificationsSettings = doNotClear => dispatch => {
  if (!doNotClear) {
    dispatch(settingsRequest());
  }
  return new Promise((resolve, reject) => {
    dispatch(a_getAlertSettings())
      .then(settings => {
        dispatch(settingsSuccess(settings));
        resolve(settings);
      })
      .catch(err => {
        dispatch(settingsError(err));
        reject(err);
      });
  });
};

export const updateNotificationSettings =
  (settings, doNotClear) => dispatch => {
    if (!doNotClear) {
      dispatch(settingsRequest());
    }
    return new Promise((resolve, reject) => {
      dispatch(a_updateAlertSettings(settings))
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
