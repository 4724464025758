export const CASH_PIN_TOKEN_SUCCESS = 'CASH_PIN_TOKEN_SUCCESS';
export const CASH_PIN_TOKEN_ERROR = 'CASH_PIN_TOKEN_ERROR';
export const CASH_PIN_TOKEN_REQUEST = 'CASH_PIN_TOKEN_REQUEST';

export const CASH_ACCOUNT_PAYLOAD = 'CASH_ACCOUNT_PAYLOAD';

export const CASH_CARD_DETAILS_REQUEST = 'CASH_CARD_DETAILS_REQUEST';
export const CASH_CARD_DETAILS_SUCCESS = 'CASH_CARD_DETAILS_SUCCESS';
export const CASH_CARD_DETAILS_ERROR = 'CASH_CARD_DETAILS_ERROR';

export const CASH_CARD_PIN_REQUEST = 'CASH_CARD_PIN_REQUEST';
export const CASH_CARD_PIN_SUCCESS = 'CASH_CARD_PIN_SUCCESS';
export const CASH_CARD_PIN_ERROR = 'CASH_CARD_PIN_ERROR';

export const CASH_CARD_SET_PIN_SUCCESS = 'CASH_CARD_SET_PIN_SUCCESS';
export const CASH_CARD_SET_PIN_ERROR = 'CASH_CARD_SET_PIN_ERROR';
export const CASH_CARD_SET_PIN_FEEDBACK = 'CASH_CARD_SET_PIN_FEEDBACK';

export const CASH_LOAD_RECENT_TRANSACTIONS = 'CASH_LOAD_RECENT_TRANSACTIONS';
export const CASH_SUCCESS_RECENT_TRANSACTIONS =
  'CASH_SUCCESS_RECENT_TRANSACTIONS';
export const CASH_ERROR_RECENT_TRANSACTIONS = 'CASH_ERROR_RECENT_TRANSACTIONS';

export const CASH_LOAD_TRANSACTION_PAGE = 'CASH_LOAD_TRANSACTION_PAGE';
export const CASH_SUCCESS_TRANSACTION_PAGE = 'CASH_SUCCESS_TRANSACTION_PAGE';
export const CASH_ERROR_TRANSACTION_PAGE = 'CASH_ERROR_TRANSACTION_PAGE';

export const CASH_LOAD_BANK_TRANSFER = 'CASH_LOAD_BANK_TRANSFER';
export const CASH_SUCCESS_BANK_TRANSFER = 'CASH_SUCCESS_BANK_TRANSFER';
export const CASH_ERROR_BANK_TRANSFER = 'CASH_ERROR_BANK_TRANSFER';

export const SESAME_CASH_BOOSTER_ENHANCED_1 = 'SESAME_CASH_BOOSTER_ENHANCED_1';

export const SET_UTILIZATION_VALUE = 'SET_UTILIZATION_VALUE';
export const SET_JUMIO_VERIFIED = 'SET_JUMIO_VERIFIED';
export const CLEAR_SESAME_CASH_ERRORS = 'CLEAR_SESAME_CASH_ERRORS';

export const ATOMIC_SDK_OUTCOME_SUCCESS = 'success';
export const ATOMIC_SDK_OUTCOME_FAILURE = 'failure';
export const ATOMIC_SDK_OUTCOME_PENDING = 'pending';

export const SET_CASH_LOADED = 'SET_CASH_LOADED';

export const CHANGE_PAYMENT_METHOD_DATA_TEST_ID =
  'primary-builder-payment-management';

export const PAYMENT_METHOD_AUTOMATIC = 'AUTOMATIC';
export const PAYMENT_METHOD_MANUAL = 'MANUAL';
export const PAYMENT_STATUS_MISSED = 'MISSED';
export const PAYMENT_STATUS_FAILED = 'FAILED';
export const PAYMENT_STATUS_COMPLETED = 'COMPLETED';
export const PAYMENT_STATUS_NONE = 'NONE';
export const PAYMENT_STATUS_PENDING = 'PENDING';
export const MANUAL_PAYMENT_DATA_TEST_ID = 'manual-payment';

export const CREDIT_BUILDER_2 = 'CREDIT_BUILDER_2';
export const UNFUNDED = 'UNFUNDED';
export const FUNDED_NOT_DELINQUENT_NO_TRANSACTIONS =
  'FUNDED_NOT_DELINQUENT_NO_TRANSACTIONS';
export const FUNDED_NOT_DELINQUENT_HAS_TRANSACTIONS =
  'FUNDED_NOT_DELINQUENT_HAS_TRANSACTIONS';
export const AUTO_PAY_SUCCESS = 'AUTO_PAY_SUCCESS';
export const MANUAL_PAYMENT_DUE = 'MANUAL_PAYMENT_DUE';
export const MANUAL_PAYMENT_SUCCESS = 'MANUAL_PAYMENT_SUCCESS';
export const MANUAL_PAYMENT_FAILED_DELINQUENT =
  'MANUAL_PAYMENT_FAILED_DELINQUENT';
export const MANUAL_PAYMENT_STATEMENT_NOT_GENERATED =
  'MANUAL_PAYMENT_STATEMENT_NOT_GENERATED';
export const SHOW_MENUBAR_ON_ENROLLMENT = 'SHOW_MENUBAR_ON_ENROLLMENT';

export const ENROLLSTATUS_DELETED = 'DELETED';
export const ENROLLSTATUS_OPTIN = 'OPT_IN';
export const ENROLLSTATUS_ENROLLLATER = 'ENROLL_LATER';
export const ENROLLSTATUS_NONE = 'NONE';
export const ENROLLSTATUS_FAILED = 'FAILED';

export const NOT_ENROLLED_STATUSES = [
  ENROLLSTATUS_DELETED,
  ENROLLSTATUS_OPTIN,
  ENROLLSTATUS_ENROLLLATER,
  ENROLLSTATUS_NONE,
  ENROLLSTATUS_FAILED,
];
