import { getPayrollSwitchAtomicPublicToken } from 'externals/_services/banking.service';
import {
  isEnrolledInCash,
  isUserEnrolledCash,
  isValidCashPinToken,
} from 'helpers/sesameCash.helpers';
import { BANKING_VERTICAL } from 'types/mrph.vertical.constants';
import {
  CASH_ACCOUNT_PAYLOAD,
  CASH_CARD_DETAILS_ERROR,
  CASH_CARD_DETAILS_REQUEST,
  CASH_CARD_DETAILS_SUCCESS,
  CASH_CARD_PIN_ERROR,
  CASH_CARD_PIN_REQUEST,
  CASH_CARD_PIN_SUCCESS,
  CASH_CARD_SET_PIN_ERROR,
  CASH_CARD_SET_PIN_FEEDBACK,
  CASH_CARD_SET_PIN_SUCCESS,
  CASH_ERROR_BANK_TRANSFER,
  CASH_ERROR_RECENT_TRANSACTIONS,
  CASH_ERROR_TRANSACTION_PAGE,
  CASH_LOAD_BANK_TRANSFER,
  CASH_LOAD_RECENT_TRANSACTIONS,
  CASH_LOAD_TRANSACTION_PAGE,
  CASH_SUCCESS_BANK_TRANSFER,
  CASH_SUCCESS_RECENT_TRANSACTIONS,
  CASH_SUCCESS_TRANSACTION_PAGE,
  CLEAR_SESAME_CASH_ERRORS,
  SET_CASH_LOADED,
  SET_JUMIO_VERIFIED,
} from 'types/sesameCash.constants';

import {
  fetchAllCardDetails,
  fetchPinService,
  fetchSesameCashPinToken,
  fetchThirdPartyBankingApi,
  fetchThirdPartyCardDetails,
  getAddressVerification,
  getPendingBankTransfers,
  getStackStoryByDate,
  getStackTransactionsRecent,
  sesameCashEnrollUser,
  setCardPinService,
} from 'externals/_services/sesameCash.service';

import { standardEventHandler } from 'actions/eventHandler.actions';
import { success } from 'actions/notification.actions';
import { wrapService } from 'actions/service_wrapper.actions';
import { getUserInfo } from 'actions/userInfo.actions.js';
import {
  updateUserIdVerificationSubmitted,
  verifyUserId,
} from 'externals/_services/user.service';
import { pushOptAttribute } from 'externals/_tracking/optimizely/optimizely.util';
import { SESAME_CASH_ENROLL } from 'externals/_tracking/types/eventTypes.constants';
import { addMonth, getFirstOfTheMonth, getMoment } from 'helpers/dateHelper';
import { getPublicUrl } from 'helpers/env';
import { getItem, setItem } from 'helpers/localstorage';
import { createLogger } from 'helpers/logger';
import { poll } from 'helpers/polling.helper';
import { forceSuccess } from 'helpers/promiseHelper';
import moment from 'moment';
import {
  SESAME_CASH_FAILURE,
  SET_DATA_FOR_CASH_ACCOUNT_MODAL,
} from 'types/app.constants';
import {
  ALL_CARD_DETAILS_PAYLOAD,
  CASH_PIN_TOKEN_PAYLOAD,
  buildPayloadDispatcher,
} from 'types/payload.constants';
import {
  ID_VERIFICATION_FAILED,
  ID_VERIFICATION_SUBMITTED,
  ID_VERIFICATION_VERIFIED,
} from 'types/signupStatus.constants';

const logger = createLogger({
  name: 'sesameCash.actions',
});

const a_fetchSesameCashPinToken = wrapService(fetchSesameCashPinToken, {
  name: 'fetchSesameCashPinToken',
});
const a_sesameCashEnrollUser = wrapService(sesameCashEnrollUser, {
  name: 'sesameCashEnrollUser',
});

const a_getUsersAddressVerification = wrapService(getAddressVerification, {
  name: 'getAddressVerification',
});
const a_fetchThirdPartyCardDetails = wrapService(fetchThirdPartyCardDetails, {
  name: 'fetchThirdPartyCardDetails',
});
const a_fetchAllCardDetails = wrapService(fetchAllCardDetails, {
  name: 'fetchThirdPartyCardDetails',
});
const a_fetchPinService = wrapService(fetchPinService, {
  name: 'fetchPinService',
});
const a_setCardPinService = wrapService(setCardPinService, {
  name: 'setCardPinService',
});
const a_getStackTransactionsRecent = wrapService(getStackTransactionsRecent, {
  name: 'getStackTransactionsRecent',
});
const a_getStackStoryByDate = wrapService(getStackStoryByDate, {
  name: 'getStackStoryByDate',
});

const a_getPendingBankTransfers = wrapService(getPendingBankTransfers, {
  name: 'getPendingBankTransfers',
});
const a_fetchThirdPartyBankingApi = wrapService(fetchThirdPartyBankingApi, {
  name: 'fetchThirdPartyBankingApi',
});

function cashPinTokenPayload(
  cashPinTokenSuccess,
  cashPinTokenLoading,
  cashPinToken,
  cashPinTokenExpireTime,
  cashPinTokenError,
) {
  return {
    type: CASH_PIN_TOKEN_PAYLOAD,
    cashPinTokenSuccess,
    cashPinTokenLoading,
    cashPinToken,
    cashPinTokenExpireTime,
    cashPinTokenError,
  };
}

export function cashPinTokenRequest() {
  return cashPinTokenPayload(null, true, null, null, null);
}

export function cashPinTokenSuccess(cashPinToken) {
  return cashPinTokenPayload(
    true,
    false,
    cashPinToken.token,
    moment().add(14, 'm'),
    null,
  );
}

function cashPinTokenError(err) {
  return cashPinTokenPayload(null, false, null, null, err);
}

export function cashSetPinTokenFeedbackMessage(feedback) {
  return { type: CASH_CARD_SET_PIN_FEEDBACK, feedback };
}

function cashSetPinTokenSuccess() {
  return { type: CASH_CARD_SET_PIN_SUCCESS };
}

function cashSetPinTokenError(err) {
  return { type: CASH_CARD_SET_PIN_ERROR, err };
}

export const getEnrolledStatus = userInfo => {
  const userBankingInfo = userInfo && userInfo.banking;
  return userBankingInfo && userBankingInfo.enrollStatus
    ? userBankingInfo.enrollStatus
    : null;
};

export const enrollSesameCash = body => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(a_sesameCashEnrollUser(body))
      .then(res => {
        pushOptAttribute({ userBankingEnrollStatus: res.enrollStatus });
        pushOptAttribute({ sesameCashPinEnrolled: true });
        dispatch(cashPinTokenSuccess(res));
        resolve(res);
      })
      .catch(err => {
        pushOptAttribute({ userBankingEnrollStatus: null });
        pushOptAttribute({ sesameCashPinEnrolled: false });
        dispatch(cashPinTokenError({ cashPinToken: SESAME_CASH_FAILURE }));
        reject(err);
      });
  });
};

export const getUsersAddressVerification = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(a_getUsersAddressVerification())
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getSesameCashPinToken =
  (forceRegisteredStatus = false, noRegisteredStatus) =>
  (dispatch, getState) => {
    let initiateFetch = false;
    const { userInfo, sesameCash } = getState();
    const enrollStatus = getEnrolledStatus(userInfo.userInfo);
    if (
      (!sesameCash.cashPinTokenLoading &&
        !sesameCash.cashPinTokenError &&
        enrollStatus === 'REGISTERED') ||
      forceRegisteredStatus
    ) {
      if (!noRegisteredStatus) dispatch(cashPinTokenRequest());
      initiateFetch = true;
    }
    if (initiateFetch) {
      return new Promise((resolve, reject) => {
        dispatch(a_fetchSesameCashPinToken())
          .then(res => {
            dispatch(cashPinTokenSuccess(res));
            resolve(res);
          })
          .catch(err => {
            dispatch(cashPinTokenError(err));
            reject(err);
          });
      });
    } else {
      return new Promise(r => r({}));
    }
  };

export const getSesameCashLogin = loginDataCollector => {
  const enrollStatus = getEnrolledStatus(loginDataCollector.userInfo);
  pushOptAttribute({ userBankingEnrollStatus: enrollStatus });
  if (enrollStatus === 'REGISTERED') {
    loginDataCollector
      .dispatch(getSesameCashPinToken())
      .then(res => {
        pushOptAttribute({ sesameCashPinEnrolled: true });
        loginDataCollector.dispatch(getCashProfile());
      })
      .catch(() => {
        pushOptAttribute({ sesameCashPinEnrolled: false });
      });
  }
};

export const retrieveSesameCashPinForLogin = props => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { userInfo } = props || {};
    if (isUserEnrolledCash(userInfo)) {
      dispatch(a_fetchSesameCashPinToken())
        .then(res => {
          dispatch(cashPinTokenSuccess(res));
          resolve(res);
        })
        .catch(err => {
          dispatch(cashPinTokenError(err));
          resolve(err);
        });
    } else {
      const errorMsg = 'Current account is not enrolled to Banking';
      const err = {
        error: errorMsg,
        message: errorMsg,
        code: 'BANKING_GET_PIN_ERROR',
      };
      dispatch(cashPinTokenError(err));
      reject?.(err);
    }
  });
};

const cashProfilePayload = buildPayloadDispatcher(
  'cashAccount',
  CASH_ACCOUNT_PAYLOAD,
);

export const getCashProfile =
  (doNotClear, tokenArg, ignoreLoading = false) =>
  (dispatch, getState) => {
    if (!getState().sesameCash.cashAccountLoading || ignoreLoading) {
      if (!doNotClear) {
        dispatch(cashProfilePayload(null, true, null));
      }

      return new Promise((resolve, reject) => {
        const token = getState().sesameCash.cashPinToken;
        dispatch(a_fetchThirdPartyBankingApi(tokenArg ?? token))
          .then(res => {
            dispatch(cashProfilePayload(res, false, null));
            resolve(res);
          })
          .catch(err => {
            dispatch(cashProfilePayload(null, false, err));
            reject(err);
          });
      });
    } else {
      return new Promise(r => r({}));
    }
  };

function cashCardDetailRequest() {
  return { type: CASH_CARD_DETAILS_REQUEST };
}

export function cashCardDetailSuccess(cardDetails) {
  return { type: CASH_CARD_DETAILS_SUCCESS, cardDetails };
}

function cashCardDetailFailure(err) {
  return { type: CASH_CARD_DETAILS_ERROR, err };
}

export const getCashCardDetails = token => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch(cashCardDetailRequest());

    dispatch(a_fetchThirdPartyCardDetails(token))
      .then(response => {
        dispatch(cashCardDetailSuccess(response));
        resolve(response);
      })
      .catch(err => {
        dispatch(cashCardDetailFailure(err));
        reject(err);
      });
  });
};

const payloadForAllCardDetails = (
  allCardDetails,
  allCardDetailsLoading,
  allCardDetailsError,
) => ({
  type: ALL_CARD_DETAILS_PAYLOAD,
  allCardDetails,
  allCardDetailsLoading,
  allCardDetailsError,
});

export const getAllCardDetails = token => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch(payloadForAllCardDetails(null, true, null));

    dispatch(a_fetchAllCardDetails(token))
      .then(response => {
        dispatch(payloadForAllCardDetails(response, false, null));
        resolve(response);
      })
      .catch(err => {
        dispatch(payloadForAllCardDetails(null, false, err));
        reject(err);
      });
  });
};

function cashCardPinRequest() {
  return { type: CASH_CARD_PIN_REQUEST };
}
export function cashCardPinSuccess(pinResult) {
  return { type: CASH_CARD_PIN_SUCCESS, pinResult };
}
function cashCardPinFailure(err) {
  return { type: CASH_CARD_PIN_ERROR, err };
}

export const getCashCardPin = (token, doNotClear) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try {
      if (!doNotClear) {
        dispatch(cashCardPinRequest());
      }
      dispatch(a_fetchPinService(token))
        .then(result => {
          dispatch(cashCardPinSuccess(result.cardPin));
          resolve(result);
        })
        .catch(err => {
          dispatch(cashCardPinFailure(err));
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export const setCashCardPin = (token, cardPin) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try {
      dispatch(a_setCardPinService(token, cardPin))
        .then(result => {
          dispatch(cashSetPinTokenSuccess());
          resolve(result);
        })
        .catch(err => {
          dispatch(cashSetPinTokenError(err));
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};

const startLoadingRecentCashTransaction = _ => ({
  type: CASH_LOAD_RECENT_TRANSACTIONS,
});
const successLoadingRecentCashTransaction = payload => ({
  type: CASH_SUCCESS_RECENT_TRANSACTIONS,
  payload,
});
const errorLoadingRecentCashTransaction = err => ({
  type: CASH_ERROR_RECENT_TRANSACTIONS,
  err,
});

export const getRecentCashTransactions = token => (dispatch, getState) => {
  const state = getState();
  dispatch(startLoadingRecentCashTransaction());

  return new Promise((resolve, reject) => {
    dispatch(
      a_getStackTransactionsRecent(token ?? state.sesameCash.cashPinToken),
    )
      .then(payload => {
        dispatch(successLoadingRecentCashTransaction(payload));
        resolve(payload);
      })
      .catch(err => {
        dispatch(errorLoadingRecentCashTransaction(err));
        reject(err);
      });
  });
};

const startLoadingTransactionPage = pageStartDate => ({
  type: CASH_LOAD_TRANSACTION_PAGE,
  pageStartDate,
});
const successLoadingTransactionPage = payload => ({
  type: CASH_SUCCESS_TRANSACTION_PAGE,
  payload,
});
const errorLoadingTransactionPage = err => ({
  type: CASH_ERROR_TRANSACTION_PAGE,
  err,
});

export const getCashTransactionByPage =
  (pageStartDate, token) => (dispatch, getState) => {
    const state = getState();
    const currentDate = state.sesameCash.pageStartDate;
    // currently, the UI will only allow the user to scroll further backwards. Fix for a race condition
    const finalDate = getMoment(currentDate).isAfter(pageStartDate)
      ? pageStartDate
      : currentDate;
    dispatch(startLoadingTransactionPage(finalDate));

    return new Promise((resolve, reject) => {
      dispatch(
        a_getStackStoryByDate(
          token ?? state.sesameCash.cashPinToken,
          pageStartDate,
        ),
      )
        .then(payload => {
          const freshState = getState();
          const list = freshState.sesameCash.pagedTransactions;
          if (list) {
            const newlist = [...list, ...payload];
            dispatch(successLoadingTransactionPage(newlist));
            resolve(newlist);
          } else {
            dispatch(successLoadingTransactionPage(payload));
            resolve(payload);
          }
        })
        .catch(err => {
          dispatch(errorLoadingTransactionPage(err));
          reject(err);
        });
    });
  };

const startLoadingBankTransfer = _ => ({ type: CASH_LOAD_BANK_TRANSFER });
const successLoadingBankTransfer = payload => ({
  type: CASH_SUCCESS_BANK_TRANSFER,
  payload,
});
const errorLoadingBankTransfer = err => ({
  type: CASH_ERROR_BANK_TRANSFER,
  err,
});

export const loadBankTransfer = token => (dispatch, getState) => {
  const state = getState();
  dispatch(startLoadingBankTransfer());

  return new Promise((resolve, reject) => {
    dispatch(
      a_getPendingBankTransfers(token ?? state?.sesameCash?.cashPinToken),
    )
      .then(payload => {
        dispatch(successLoadingBankTransfer(payload));
        resolve(payload);
      })
      .catch(err => {
        dispatch(errorLoadingBankTransfer(err));
        reject(err);
      });
  });
};

export function setJumioVerified(value) {
  return { type: SET_JUMIO_VERIFIED, payload: value };
}

export const startJumioPolling = () => (dispatch, getState) => {
  dispatch(setJumioVerified(true));
  const checkerFunction = () => {
    dispatch(getUserInfo(true));
    const state = getState();
    const userInfo = state?.userInfo?.userInfo;

    if (!userInfo) {
      return null;
    }
    if (userInfo?.signUpStatus === ID_VERIFICATION_VERIFIED) {
      dispatch(success('Success - we were able to verify your account!'));
      dispatch(
        standardEventHandler(SESAME_CASH_ENROLL, {
          Vertical: BANKING_VERTICAL,
        }),
      );
      return {
        activeVariation: true,
      };
    }
    if (userInfo?.signUpStatus === ID_VERIFICATION_FAILED) {
      return {
        activeVariation: true,
      };
    }
    if (userInfo?.signUpStatus === ID_VERIFICATION_SUBMITTED) {
      // Keep polling if submitted.
      return null;
    }
    return {};
  };

  poll(checkerFunction, 120000, 1000);
};

export const verifyJumioUserId = () => dispatch => {
  return new Promise((resolve, reject) => {
    const errorUrl = `${getPublicUrl()}/jumio-submitted`;
    const payload = {
      successUrl: `${getPublicUrl()}/jumio-submitted/success`,
      errorUrl,
      customerIntent: 'BANKING',
      refreshRedirectUrl: false,
    };
    verifyUserId(payload)
      .then(data => {
        setItem('jumioTransactionReference', data.transactionReference);
        window.location = data.redirectUrl;
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const updateJumioUserIdVerificationSubmitted =
  customerInternalReference => dispatch => {
    const transactionReference = getItem('jumioTransactionReference');
    return new Promise((resolve, reject) => {
      const payload = {
        transactionStatus: 'SUCCESS',
        transactionReference,
        customerInternalReference,
      };
      forceSuccess(updateUserIdVerificationSubmitted(payload))
        .then(() => {
          return dispatch(getUserInfo(true));
        })
        .then(data => {
          dispatch(startJumioPolling());
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };

export const refreshCashApiOutage = _ => {
  return { type: CLEAR_SESAME_CASH_ERRORS };
};

export const setCashLoaded = (cashLoaded, cashLoading) => {
  return { type: SET_CASH_LOADED, cashLoaded, cashLoading };
};

export const retrieveTransactionPages = token => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let pageStartMonth1 = getFirstOfTheMonth();
    let pageStartMonth2 = addMonth(getFirstOfTheMonth(), -1);
    let pageStartMonth3 = addMonth(getFirstOfTheMonth(), -2);

    dispatch(getCashTransactionByPage(pageStartMonth1, token));
    dispatch(getCashTransactionByPage(pageStartMonth2, token));
    dispatch(getCashTransactionByPage(pageStartMonth3, token));
  });
};

export const retrieveBaseSesameCash =
  (deps, skipChecks = false) =>
  async (dispatch, getState) => {
    const addLoadersBasedOnChecks = (
      arr,
      pinResponse,
      accountFunded,
      hasBoosterCard,
    ) => {
      if (accountFunded) {
        if (!state?.sesameCash?.allCardDetails || hasBoosterCard) {
          arr.push(dispatch(getAllCardDetails(pinResponse?.token)));
        }
      }
    };

    const state = getState();

    if (
      !skipChecks &&
      (state?.sesameCash?.cashLoading || state?.sesameCash?.cashLoaded)
    ) {
      return;
    }
    dispatch(setCashLoaded(false, true));

    if (isEnrolledInCash(deps?.userInfo)) {
      let pinResponse = null;
      if (!state?.sesameCash?.cashPinToken) {
        pinResponse = await dispatch(getSesameCashPinToken(true));
      } else {
        pinResponse = state?.sesameCash?.cashPinToken;
      }

      const phase1Loaders = [];
      if (!state?.sesameCash?.cashAccount) {
        phase1Loaders.push(
          dispatch(getCashProfile(false, pinResponse?.token, skipChecks)),
        );
      }
      const phase1AccountFunded = deps?.userInfo?.banking?.accountFunded;
      const phase1HasBuilder = deps?.userInfo?.banking?.hasBoosterCard;
      addLoadersBasedOnChecks(
        phase1Loaders,
        pinResponse,
        phase1AccountFunded,
        phase1HasBuilder,
      );

      const phase1 = await Promise.all(phase1Loaders);
      const profile = state?.sesameCash?.cashAccount ?? phase1[0];
      const phase2AccountFunded =
        !phase1AccountFunded && profile?.receivedFirstFund;
      const phase2HasBuilder =
        !phase1HasBuilder && profile?.securedCard?.hasCard;
      const phase2Loaders = [];
      addLoadersBasedOnChecks(
        phase2Loaders,
        pinResponse,
        phase2AccountFunded,
        phase2HasBuilder,
      );

      await Promise.all(phase2Loaders);

      dispatch(setCashLoaded(true, false));
    } else {
      dispatch(setCashLoaded(true, false));
      return '';
    }
  };

const a_getPayrollSwitchAtomicPublicToken = wrapService(
  getPayrollSwitchAtomicPublicToken,
  {
    name: 'getPayrollSwitchAtomicPublicToken',
  },
);
export const validateAndUpdateCashPinToken =
  props => async (dispatch, getState) => {
    const {
      state: { pinTokenRequestAttempts },
      setState,
    } = props;
    const globalState = getState();
    const sesameCashState = globalState?.sesameCash;
    if (isValidCashPinToken(sesameCashState)) {
      setState({ cashPinTokenValid: true });
    } else {
      try {
        await dispatch(getSesameCashPinToken(false, true));
        setState({
          pinTokenRequestAttempts: pinTokenRequestAttempts + 1,
        });
      } catch (err) {
        logger.showError(err);
        logger.reportAPIError(err);
        setState({ loading: false });
      }
    }
  };

export const retrieveAtomicPublicToken = () => async (dispatch, getState) => {
  const state = getState();
  const cashToken = state?.sesameCash?.cashPinToken;
  const res = await dispatch(a_getPayrollSwitchAtomicPublicToken(cashToken));

  return res;
};

export const setUrlOnCloseCashAccountModal = url => async dispatch => {
  dispatch({
    type: SET_DATA_FOR_CASH_ACCOUNT_MODAL,
    urlOnCloseCashAccountModal: url ?? null,
  });
};
