import React, { useEffect, useState, useRef } from 'react';
import { useEventHandler } from 'hook/event.hook';
import {
  VIEW_PAGE,
  VIEW_MODAL,
  VIEW_OFFER,
  VIEW_MODULE,
  DISMISS_MODAL,
  VIEW_POPUP,
  SEEN_MODULE,
  SEEN_OFFER,
  SEEN_NOTIFICATION,
} from 'externals/_tracking/types/eventTypes.constants.js';
import { openNewTab } from 'helpers/domHelper';
import { useDispatch, useSelector } from 'react-redux';
import {
  incrementOfferCount,
  reduceOfferCount,
  setOfferState,
  setTriggeredForSeenOffer,
  setTriggeredForViewAction,
  setTriggeredForViewOffer,
} from 'actions/offerState.actions';
import { CLICK } from 'externals/_tracking/types/eventTypes.constants';
import { useHistory } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import { guaranteeTrk } from 'externals/_services/user.service';
import { wrapService } from 'actions/service_wrapper.actions';
import { getDiffMsBetweenDates, getMoment } from 'helpers/dateHelper';
import { resetSession } from 'partial/SessionManager/sessionManager.actions';
import { useConfig } from 'hook/config.hook';
import { containsString } from 'helpers/string.helper';

const a_guaranteeTrk = wrapService(guaranteeTrk, {
  name: 'guaranteeTrk',
});

export const useScrollToTop = (...dependencies) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, [...dependencies]);
};

export const useScrollToTopOfModal = (classname, isOpen, pageChange) => {
  useEffect(() => {
    const selectedElement = document.querySelector(classname);
    if (selectedElement) {
      selectedElement.scrollTop = 0;
    }
    //eslint-disable-next-line
  }, [isOpen, pageChange]);
};

export const useScrollIntoView = (id, ...dependencies) => {
  useEffect(() => {
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView();
    }
    //eslint-disable-next-line
  }, [...dependencies]);
};

export const useScrollTo = (id, yOffset, ...dependencies) => {
  useEffect(() => {
    const el = document.getElementById(id);
    if (el) {
      const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
    //eslint-disable-next-line
  }, [...dependencies]);
};

export const scrollTo = (id, yOffset = 0, delay) => {
  if (!delay) {
    scrollToInner(id, yOffset);
  } else {
    setTimeout(() => {
      scrollToInner(id, yOffset);
    }, delay);
  }
};

export const scrollToInner = (id, yOffset = 0) => {
  const el = document.getElementById(id);
  if (el) {
    const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
};

export const scrollToClassElement = props => {
  const { className, yOffset, delay, isModal, modalClass } = props ?? {};
  if (isModal) {
    setTimeout(() => {
      scrollToClassInnerWithModal(className, yOffset, modalClass, delay);
    }, delay);
  } else {
    setTimeout(() => {
      scrollToClassInner(className, yOffset);
    }, delay);
  }
};

export const scrollToClassInner = (className, yOffset = 0) => {
  const finalClassName = '.' + className;
  const selectedElement = document.querySelector(finalClassName);
  if (selectedElement) {
    const y =
      selectedElement.getBoundingClientRect().top +
      window.pageYOffset +
      yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
};

export const scrollToClassInnerWithModal = (
  className,
  yOffset = 0,
  modalClass,
  delay,
) => {
  const finalModalClassName = '.' + modalClass;
  const selectedModalElement = document.querySelector(finalModalClassName);
  const selectedElement = document.getElementsByClassName(className);
  if (selectedModalElement) {
    selectedModalElement.style.scrollBehavior = 'smooth';
    selectedModalElement.scrollTop = selectedElement[0]?.offsetTop;
  }
};

export const highLight = (colorClass, ...ids) => {
  setTimeout(() => {
    const hightLightClass = colorClass ? `highlight${colorClass}` : 'highlight';
    ids.forEach(id => {
      const el = document.getElementById(id);
      if (el) {
        el.classList.toggle(hightLightClass);
        setTimeout(() => {
          el.classList.remove(hightLightClass);
        }, 4000);
      }
    });
  }, 500);
};
export const highLightRow = (...ids) => {
  const hightLightClass = 'highLightRow';
  setTimeout(() => {
    const highLightedRows = document.querySelectorAll(`.${hightLightClass}`);
    highLightedRows?.forEach(row => {
      row?.classList?.remove(hightLightClass);
      const id = row?.id;
      const elIcon = document.getElementById(`${id}-icon`);
      if (elIcon) {
        elIcon.classList.toggle('hide');
      }
    });
    ids?.forEach(id => {
      const el = document.getElementById(id);
      if (el) {
        el.classList?.toggle(hightLightClass);
      }
      const elIcon = document.getElementById(`${id}-icon`);
      if (elIcon) {
        elIcon.classList?.remove('hide');
      }
    });
  }, 500);
};

export const usePageView = (loading, attrs) => {
  const eventHandler = useEventHandler();
  const [triggered, setTriggered] = useState(false);
  const [startTime, setStartTime] = useState(getMoment());
  useEffect(() => {
    if (loading === false && !triggered) {
      const baseAttributes = {
        'Total Time': getDiffMsBetweenDates(getMoment(), startTime),
        'Total Performance Time':
          performance.getEntriesByType('navigation')[0].duration,
      };
      if (attrs) {
        eventHandler(VIEW_PAGE, {
          ...baseAttributes,
          ...attrs,
        });
      } else {
        eventHandler(VIEW_PAGE, baseAttributes);
      }
      setTriggered(true);
    }
    //eslint-disable-next-line
  }, [loading, eventHandler, triggered, attrs]);

  return {
    viewPageTriggered: triggered,
    clear: () => {
      setTriggered(false);
      setStartTime(getMoment());
    },
  };
};

export const useAlertDetailPageView = (loading, shouldTrackView, attrs) => {
  const eventHandler = useEventHandler();
  const [triggered, setTriggered] = useState(false);
  useEffect(() => {
    if (loading === false && !triggered && shouldTrackView) {
      if (attrs) {
        eventHandler(VIEW_PAGE, attrs);
      } else {
        eventHandler(VIEW_PAGE);
      }
      setTriggered(true);
    }
  }, [loading, eventHandler, triggered, shouldTrackView, attrs]);
};

const validateCondition = condition => {
  if (condition !== null && typeof condition !== 'undefined') {
    if (condition === true) {
      return true;
    } else {
      return false;
    }
  }
  return true;
};

export const useViewPopup = (attrs, condition) => {
  const eventHandler = useEventHandler();
  const [triggered, setTriggered] = useState(false);
  useEffect(() => {
    if (!triggered && validateCondition(condition)) {
      eventHandler(VIEW_POPUP, Object.assign({}, attrs));
      setTriggered(true);
    }
    //eslint-disable-next-line
  }, [triggered]);
};

export const useViewPopupWatcher = (attrs, isOpen, dependencies, condition) => {
  const eventHandler = useEventHandler();
  const validCondition = condition || condition === undefined;
  useEffect(() => {
    if (isOpen && validCondition) {
      eventHandler(VIEW_POPUP, Object.assign({}, attrs));
    }
    //eslint-disable-next-line
  }, [isOpen, ...dependencies]);
};

export const useViewModule = (attrs, condition) => {
  // FYI: POD3-3709 , removing firing event , reference of useViewModule will be remove in tech debt tickets.

  // const eventHandler = useEventHandler();
  // const [triggered, setTriggered] = useState(false);
  // useEffect(() => {
  //   if (!triggered && validateCondition(condition)) {
  //     eventHandler(VIEW_MODULE, Object.assign({}, attrs));
  //     setTriggered(true);
  //   }
  //   //eslint-disable-next-line
  // }, [triggered, condition]);

  return {
    triggered: false,
  };
};

export const useViewActionModule = (attrs, condition) => {
  const eventHandler = useEventHandler();
  const [triggered, setTriggered] = useState(false);
  const actionName = useRef(null);
  const newActionName = attrs['Page Position'];
  useEffect(() => {
    if (
      (!triggered || newActionName !== actionName.current) &&
      validateCondition(condition)
    ) {
      eventHandler(VIEW_MODULE, Object.assign({}, attrs));
      setTriggered(true);
      actionName.current = newActionName;
    }
    //eslint-disable-next-line
  }, [triggered, condition, attrs]);
};

export const useViewModal = (attrs, loading) => {
  const eventHandler = useEventHandler();
  const [triggered, setTriggered] = useState(false);
  const internalAttr = useRef(null);
  useEffect(() => {
    if (!loading && !triggered) {
      eventHandler(VIEW_POPUP, attrs);
      setTriggered(true);
      internalAttr.current = attrs;
    }
    //eslint-disable-next-line
  }, [triggered, loading]);

  useEffect(() => {
    return () => {
      eventHandler(DISMISS_MODAL, internalAttr.current);
    };
    //eslint-disable-next-line
  }, []);
};

export const useViewModalWatcher = (attrs, isOpen, didUpdate) => {
  const eventHandler = useEventHandler();
  const [hasOpened, setHasOpened] = useState(isOpen);
  useEffect(() => {
    if (isOpen) {
      setHasOpened(true);
      eventHandler(VIEW_MODAL, Object.assign({}, attrs));
    } else {
      if (hasOpened) {
        eventHandler(DISMISS_MODAL, Object.assign({}, attrs));
      }
    }
    //eslint-disable-next-line
  }, [isOpen, didUpdate]);
};

export const useViewOffer = (attrs, isModal, condition) => {
  const eventHandler = useEventHandler();
  const [triggered, setTriggered] = useState(false);

  useEffect(() => {
    if (!triggered && !isModal && validateCondition(condition)) {
      eventHandler(VIEW_OFFER, attrs);
      setTriggered(true);
    }
    //eslint-disable-next-line
  }, []);
};

export const useViewOfferWithFingerprint = (
  attrs,
  isModal,
  triggered,
  setTriggered,
  fingerprint,
) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!triggered && !isModal) {
      dispatch(setTriggeredForViewOffer(fingerprint, attrs));
    }
    //eslint-disable-next-line
  }, []);
};

export const useViewAction = (fingerprint, attrs, triggered) => {
  const dispatch = useDispatch();
  const currentModule = useRef(null);
  const module = attrs['Action Name'];
  useEffect(() => {
    if (!triggered || currentModule.current !== module) {
      dispatch(setTriggeredForViewAction(fingerprint, attrs));
      currentModule.current = module;
    }
    //eslint-disable-next-line
  }, [triggered, attrs]);
};

export const useViewActionWithFingerprint = (attrs, triggered, fingerprint) => {
  const dispatch = useDispatch();
  const currentModule = useRef(null);
  const module = attrs['Action Name'];
  useEffect(() => {
    if (!triggered || currentModule.current !== module) {
      dispatch(setTriggeredForViewOffer(fingerprint, attrs, true));
      currentModule.current = module;
    }
    //eslint-disable-next-line
  }, [triggered, attrs]);
};

export const useOnContactSupportClick = attrs => {
  const eventHandler = useEventHandler();
  const { config } = useConfig();
  const onContactSupportClick = () => {
    eventHandler(
      'Click',
      Object.assign(
        {
          'Click Type': 'Contact Support',
        },
        attrs,
      ),
    );
    openNewTab(config?.supportLinks?.submitNewRequestSupportLink);
  };

  return onContactSupportClick;
};

export const useSeenModulePixel = (
  attrs,
  condition,
  override = true,
  handlerVisibility,
) => {
  const eventHandler = useEventHandler();
  const [isSeenTriggered, setIsSeenTriggered] = useState(false);
  const onVisibilityChange = isVisible => {
    handlerVisibility?.(isVisible);
    if (
      isVisible === true &&
      !isSeenTriggered &&
      validateCondition(condition)
    ) {
      eventHandler(SEEN_MODULE, attrs);
      override && setIsSeenTriggered(true);
    }
  };

  return (
    <VisibilitySensor onChange={onVisibilityChange}>
      <div className="invisible-pixel" />
    </VisibilitySensor>
  );
};

export const useSeenOfferPixel = attrs => {
  const eventHandler = useEventHandler();
  const [isSeenTriggered, setIsSeenTriggered] = useState(false);
  const onVisibilityChange = isVisible => {
    if (isVisible === true && !isSeenTriggered) {
      eventHandler(SEEN_OFFER, attrs);
      setIsSeenTriggered(true);
    }
  };

  return (
    <VisibilitySensor onChange={onVisibilityChange}>
      <div className="invisible-pixel" />
    </VisibilitySensor>
  );
};

export const useSeenNotificationPixel = attrs => {
  const eventHandler = useEventHandler();
  const [isSeenTriggered, setIsSeenTriggered] = useState(false);
  const onVisibilityChange = isVisible => {
    if (isVisible && !isSeenTriggered) {
      eventHandler(SEEN_NOTIFICATION, attrs);
      setIsSeenTriggered(true);
    }
  };

  return (
    <VisibilitySensor onChange={onVisibilityChange}>
      <div className="invisible-pixel" />
    </VisibilitySensor>
  );
};

export const useOnOpenLink = (url, clickType, attrs) => {
  const eventHandler = useEventHandler();

  const onOpenLinkClick = () => {
    eventHandler(
      CLICK,
      Object.assign(
        {
          'Click Type': clickType,
        },
        attrs,
      ),
    );
    openNewTab(url);
  };

  return onOpenLinkClick;
};

export const useOpenInternalLink = (url, trackingData) => {
  const eventHandler = useEventHandler();
  const history = useHistory();
  const onOpenInternalLink = () => {
    eventHandler(CLICK, trackingData);
    history.push(url);
  };
  return onOpenInternalLink;
};

export const useComplexState = initialState => {
  const finalInitialState =
    typeof initialState === 'object'
      ? JSON.parse(JSON.stringify(initialState))
      : {};
  const [state, setStateInternal] = useState(finalInitialState);
  const setState = newKeys => {
    setStateInternal(state => {
      return Object.assign({}, state, newKeys);
    });
  };
  return [state, setState];
};

export const useFingerprintedOfferState = fingerprint => {
  const dispatch = useDispatch();
  const state = useSelector(state => {
    return state.offerState[fingerprint];
  });

  useEffect(() => {
    if (state === undefined) {
      dispatch(incrementOfferCount(fingerprint));
    }
    return () => {
      dispatch(reduceOfferCount(fingerprint));
    };
    //eslint-disable-next-line
  }, []);

  const setState = newState => {
    dispatch(setOfferState(fingerprint, newState));
  };

  const finalState = state ? state : {};
  return [finalState, setState];
};

export const useOptimizedFingerprintedOfferState = (
  fingerprint,
  { defaultEventHandler, fullAttr, isModal },
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(incrementOfferCount(fingerprint));
    return () => {
      dispatch(reduceOfferCount(fingerprint));
    };
    //eslint-disable-next-line
  }, []);

  const onVisibilityChange = isVisible => {
    if (isVisible === true) {
      dispatch(setTriggeredForSeenOffer(fingerprint, fullAttr));
    }
  };
  return {
    onVisibilityChange,
  };
};

export const useSessionReset = validation => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (validation) {
      dispatch(resetSession());
    }
    //eslint-disable-next-line
  }, []);
};

export const useMarketingTrkCookies = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    //Set trk, src cookies
    dispatch(a_guaranteeTrk());
  }, [dispatch]);
};

export const useUpdateCallback = (callback, dependencies) => {
  const ref = useRef(true);
  useEffect(() => {
    if (ref.current) {
      ref.current = false;
      return;
    }
    return callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependencies]);
};

export const usePreviousPersistent = value => {
  // initialise the ref with previous and current values
  const ref = useRef({
    value: value,
    prev: null,
  });

  const current = ref.current.value;

  // if the value passed into hook doesn't match what we store as "current"
  // move the "current" to the "previous"
  // and store the passed value as "current"
  if (value !== current) {
    ref.current = {
      value: value,
      prev: current,
    };
  }

  // return the previous value only
  return ref.current.prev;
};

export const useKeyPressWithRef = (ref, callback) => {
  useEffect(() => {
    const handleKeyPress = event => {
      callback(event.key);
    };

    const element = ref?.current;
    if (element) {
      element.addEventListener('keydown', function (event) {
        // Check if the Enter key was pressed
        if (event.key === 'Enter' || event.keyCode === 13) {
          handleKeyPress(event);
        }
      });
    }

    return () => {
      if (element) {
        element.removeEventListener('keydown', handleKeyPress);
      }
    };
  }, [ref, callback]);
};

export const useAddAccesibilityAttributes = (className, attributes) => {
  useEffect(() => {
    const element = document.querySelector(className);
    if (element && attributes) {
      attributes.map(attr => {
        element.setAttribute(attr?.attribute, attr?.value);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useCarouselScrollIntoViewOnFocus = props => {
  const { carouselRef, carouselRoot } = props ?? {};
  const handleTabClick = index => {
    carouselRef?.current?.goTo(index);
  };

  const handleKeyPress = event => {
    if (event.key === 'Tab') {
      let activeElement = '';
      setTimeout(() => {
        activeElement = document.activeElement;
        const dataIndex = activeElement.getAttribute('data-index');
        const classNames = activeElement.getAttribute('class');
        const hasClassSlickActive = containsString(classNames, 'slick-active');
        if (dataIndex && !hasClassSlickActive) {
          handleTabClick(dataIndex);
        }
      }, 100);
    }
  };

  useEffect(() => {
    let slickSlides = '';
    if (carouselRoot) {
      //Adding querySelectorAll to handle tabindex antd doesn't allow to use tab on keyboard
      slickSlides = carouselRoot?.current?.querySelectorAll('.slick-slide');
    }
    if (slickSlides) {
      slickSlides.forEach(slide => {
        slide.setAttribute('tabIndex', '0');
      });
    }
  }, [carouselRoot]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);
};
