import { CREDIT_INFO_RESET } from 'types/creditInfo.constants';
import { getWhatsChanged } from 'helpers/credit_info';

import {
  LOGOUT,
  INVALID_ROUTE_REDIRECT,
  LOGIN_SUCCESS,
} from 'types/app.constants';
import { mergePayloadChanges } from 'helpers/reducer.helper';
import { CREDIT_INFO_PAYLOAD, PAYLOAD } from 'types/payload.constants';

const initialState = {
  creditInfoLoading: false,
  creditInfoError: null,
  creditInfo: null,
  anythingChanged: null,
};

const handlePayloadMerge = (state, action) => {
  const newState = mergePayloadChanges(state, action);
  if (newState.creditInfo && state.creditInfo !== newState.creditInfo) {
    newState.anythingChanged = getWhatsChanged(
      action.creditInfo,
    ).anythingChanged;
  }
  return newState;
};

export default function creditInfoReducer(state = initialState, action) {
  switch (action.type) {
    case CREDIT_INFO_PAYLOAD:
    case LOGIN_SUCCESS:
    case PAYLOAD:
      return handlePayloadMerge(state, action);
    case CREDIT_INFO_RESET:
    case LOGOUT:
    case INVALID_ROUTE_REDIRECT:
      return Object.assign({}, initialState);
    default:
      return state;
  }
}
