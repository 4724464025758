import {
  convertToCardExpiryDate,
  getDiffDaysBetweenDates,
  getMoment,
  parseStandardTimestamp,
} from 'helpers/dateHelper';
import {
  CASH_ADDRESS_UPDATE_METADATA,
  getItem,
  setItem,
} from 'helpers/localstorage';
import {
  CREDIT_BUILDER_2,
  NOT_ENROLLED_STATUSES,
} from 'types/sesameCash.constants';

export const FAILED_KYC_HARD = 'FAILED_KYC_HARD';
export const FAILED_KYC = 'FAILED_KYC';

export const formatUserAddressForCash = userAddress => {
  let formattedAddress = Object.assign({}, userAddress);
  if (
    formattedAddress.zip.split('-').length > 1 ||
    formattedAddress.zip.length > 5
  ) {
    formattedAddress.zip = formattedAddress.zip.substring(0, 5);
  }
  return formattedAddress;
};

export const isEnrolledAndFunded = (userInfo, sesameCashAccount) => {
  if (!sesameCashAccount) return false;
  const banking = userInfo && userInfo.banking ? userInfo.banking : {};
  const isFunded = sesameCashAccount.receivedFirstFund;
  const isEnrolled = banking.enrollStatus === 'REGISTERED';

  return isEnrolled && isFunded;
};

export const isEnrolledNotFunded = (userInfo, sesameCashAccount) => {
  if (!sesameCashAccount) return false;
  const banking = userInfo && userInfo.banking ? userInfo.banking : {};
  const isFunded = sesameCashAccount.receivedFirstFund;
  const isEnrolled = banking.enrollStatus === 'REGISTERED';

  return isEnrolled && !isFunded;
};

export const isNotEnrolled = userInfo => {
  if (!userInfo) return false;

  return (
    userInfo?.banking?.enrollStatus === 'ENROLL_LATER' ||
    userInfo?.banking?.enrollStatus === null ||
    userInfo?.banking?.enrollStatus === 'DELETED' ||
    userInfo?.banking === null
  );
};

export const isNotEnrolledAndEligibleForCreditBuilder2 = userInfo => {
  return (
    isNotEnrolled(userInfo) &&
    userInfo?.whitelistedProjects?.includes(CREDIT_BUILDER_2)
  );
};

export const isNotEnrolledScoreBelow650 = (userInfo, creditInfo) => {
  return isNotEnrolled(userInfo) && isCreditScoreBelow650(creditInfo);
};

export const isNotEnrolledScoreAbove650 = (userInfo, creditInfo) => {
  return isNotEnrolled(userInfo) && isCreditScoreAbove650(creditInfo);
};

export const isEligibleForCreditBuilder2 = userInfo => {
  return userInfo?.whitelistedProjects?.includes(CREDIT_BUILDER_2);
};

export const isFraudBlocked = userInfo => {
  if (!userInfo) return false;

  return userInfo?.banking?.enrollStatus === 'FRAUD_BLOCK';
};

export const determineIfShipmentNoticeShouldShow = (
  cashAccount,
  allCardDetails,
) => {
  if (!cashAccount || !allCardDetails) return false;

  const changedFirstPin = cashAccount.changedFirstPin;
  const physicalCard = allCardDetails.cards.find(c => c.type === 'PHYSICAL');
  if (!physicalCard) return false;
  const isShipped = physicalCard.shipped;

  return !changedFirstPin && isShipped;
};

export const canUserEnrollInCash = userInfo => {
  if (!userInfo) return false;
  const enrollStatus = userInfo?.banking?.enrollStatus;
  return (
    enrollStatus === 'ENROLL_LATER' ||
    enrollStatus === 'DELETED' ||
    enrollStatus === null ||
    enrollStatus === undefined
  );
};

export const isEnrolledInCash = userInfo => {
  return userInfo?.banking?.enrollStatus === 'REGISTERED';
};

export const isBoosterMarkettingElligible = creditInfo =>
  creditInfo?.creditScore < 700;

export const isBoosterEnrolled = sesameCashAccount =>
  sesameCashAccount?.securedCard?.hasCard === true;

export const isKYCHardFail = userInfo => {
  if (!userInfo) return false;

  return userInfo?.banking?.enrollStatus === FAILED_KYC_HARD;
};

export const isKYCSoftFail = userInfo => {
  if (!userInfo) return false;

  return userInfo?.banking?.enrollStatus === FAILED_KYC;
};

export const isAddressAllowedToBeUpdated = userInfo => {
  if (!isEnrolledInCash(userInfo)) return true;

  const data = getItem(CASH_ADDRESS_UPDATE_METADATA);
  if (data) {
    try {
      const parsed = JSON.parse(data);
      const dayDiff = getDiffDaysBetweenDates(
        parseStandardTimestamp(parsed?.dateSubmitted),
        null,
      );

      return dayDiff < 90;
    } catch (e) {
      setItem(CASH_ADDRESS_UPDATE_METADATA, null);
      return true;
    }
  } else {
    return true;
  }
};

export const isCb20Enrolled = (sesameCashAccount, userInfo) => {
  if (isNotEnrolled(userInfo)) return false;
  if (!sesameCashAccount) return false;
  return sesameCashAccount?.securedCard?.cbVersion === '2';
};

export const isCreditBuilder10Enrolled = ({ sesameCashAccount, userInfo }) => {
  if (isNotEnrolled(userInfo)) return false;
  if (!sesameCashAccount) return false;
  if (!sesameCashAccount?.securedCard?.optedIn) return false;
  return sesameCashAccount?.securedCard?.cbVersion === '1';
};

export const isCreditBuilder20Enrolled = sesameCashAccount => {
  if (!sesameCashAccount) return false;
  if (!sesameCashAccount?.securedCard?.optedIn) return false;
  return sesameCashAccount?.securedCard?.cbVersion === '2';
};
export const showCb20Module = sesameCashAccount => {
  if (sesameCashAccount?.securedCard?.cbVersion === '2') {
    if (
      !sesameCashAccount?.securedCard?.hasCard &&
      !sesameCashAccount?.securedCard?.optedIn
    ) {
      return false;
    }
    return true;
  } else {
    return false;
  }
};

export const isValidCashPinToken = sesameCashState => {
  const {
    cashPinToken,
    cashPinTokenSuccess,
    cashPinTokenError,
    cashPinTokenLoading,
  } = sesameCashState;
  const tokenNotAvail =
    !cashPinToken && cashPinTokenSuccess !== false && !cashPinTokenError;
  return !cashPinTokenLoading && !tokenNotAvail && cashPinToken;
};

export const decodeTokenToJson = token => {
  if (typeof token === 'string') {
    const element = token?.split('.')?.[1];
    if (element) {
      try {
        const decoded = JSON.parse(Buffer.from(element, 'base64').toString());
        return { decoded };
      } catch (error) {
        return { error };
      }
    }
  }
  return null;
};

export const checkIfTokenIsExpired = token => {
  let tokenObj = token;
  if (typeof token === 'string') {
    tokenObj = decodeTokenToJson(token);
  }
  if (tokenObj?.decoded) {
    const expiration = tokenObj?.decoded?.exp;
    if (expiration) {
      const expDate = new Date(expiration * 1000);
      return getMoment().isAfter(expDate);
    }
  }
  return true;
};

export const isCreditScoreBelow650 = creditInfo =>
  creditInfo?.creditScore <= 650;

export const isCreditScoreAbove650 = creditInfo =>
  creditInfo?.creditScore > 650;

export const getOverviewRedesignFlag = (isCash2Enrollment = false) => ({
  enabled: !isCash2Enrollment,
}); // It should be replaced by optimizely flag on EPOD-2048

export const isUserEnrolledCash = userInfo => {
  if (!userInfo || !userInfo?.banking || !userInfo?.banking?.enrollStatus)
    return false;
  return !NOT_ENROLLED_STATUSES.includes(userInfo?.banking?.enrollStatus);
};

export const isBalancePositive = cashAccount => {
  if (!cashAccount) return false;
  return cashAccount?.balance > 0;
};

export const isCardExpiryLessThan90Days = cardInfo => {
  const expDate = convertToCardExpiryDate(cardInfo?.expiry);
  const diffDays = getDiffDaysBetweenDates(expDate, new Date());
  if (!cardInfo) return false;
  return diffDays < 90;
};

export const isCardExpiryMoreThan30Days = cardInfo => {
  let expDate = convertToCardExpiryDate(cardInfo?.expiry);
  const diffDays = getDiffDaysBetweenDates(expDate, new Date());
  if (!cardInfo) return false;
  return diffDays > 30;
};

export const isCardExpired = cardInfo => {
  let expDate = convertToCardExpiryDate(cardInfo?.expiry);
  const diffDays = getDiffDaysBetweenDates(expDate, new Date());
  if (!cardInfo) return false;
  return diffDays < 0;
};
