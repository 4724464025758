import {
  ADD_THIN_FILE_STORAGE,
  REMOVE_THIN_FILE_STORAGE,
} from '../types/thinFile.constants';
import { mergePayloadChanges } from 'helpers/reducer.helper';

const initialState = {
  isThreeBPropertyExist: false,
  threeBEnrollmentFail: false,
  threeBEnrollmentFailType: null,
  threeBEnrollmentFailMessage: null
};

const thinFileReducer = function (state = initialState, action) {
  switch (action.type) {
    // Payments
    case ADD_THIN_FILE_STORAGE:
      return mergePayloadChanges(state, action);
    case REMOVE_THIN_FILE_STORAGE:
      return initialState;
    default:
      return state;
  }
};

export default thinFileReducer;
