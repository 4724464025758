import React from 'react';
import './PageLoadingMask.scss';
import Label from 'components/form/Label/Label.js';
import loadingGif from 'style/icons/seed_loader1.gif';
import classnames from 'classnames';
import { isMobileByOS } from 'externals/_tracking/dom/navigator';

const DefaultLoadingMask = ({
  description,
  datatestid,
  className,
  fontClass,
  isSmall,
  replaceSizeDefaultClass,
  spinnerWidth,
}) => {
  const finalClassName = classnames(className, {
    'page-loading-mask': !replaceSizeDefaultClass,
    replaceSizeDefaultClass: replaceSizeDefaultClass,
  });
  const finalFontClass = fontClass ? fontClass : 'H3';
  const spinnerSize = !isSmall ? 200 : isMobileByOS() ? 78 : 120;
  const spinnerNumberSize = spinnerWidth ? spinnerWidth : spinnerSize;
  return (
    <div
      data-testid={datatestid ? datatestid : 'page-loading-mask'}
      className={finalClassName}
    >
      <div className="inner-box">
        <div className="animation-wrapper" style={{ width: spinnerNumberSize }}>
          <img
            style={{ maxWidth: spinnerNumberSize }}
            alt="loading"
            src={loadingGif}
          />
        </div>

        <div className="loading-description text-center">
          <Label type={finalFontClass} className="fontGray8">
            {description}
          </Label>
        </div>
      </div>
    </div>
  );
};

DefaultLoadingMask.defaultProps = {
  description: 'Loading...',
};

export default DefaultLoadingMask;
