import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLogger } from 'hook/error.hook';
import { isPlatinumPlan } from 'helpers/userHelper';
import { getNotifications } from 'actions/notificationsAlerts.actions';

export const usePremiumAlerts = () => {
  const {
    userInfo,
    premiumAlerts,
    notificationsAlertsLoading,
    notificationsAlertsError,
    notificationsAlerts,
  } = useSelector(state => ({
    userInfo: state.userInfo.userInfo,
    premiumAlerts: state.csidAlerts,
    loggedIn: state.app.loggedIn,
    notificationsAlerts: state.notificationsAlerts?.notificationsAlerts,
    notificationsAlertsLoading:
      state.notificationsAlerts?.notificationsAlertsLoading,
    notificationsAlertsError:
      state.notificationsAlerts?.notificationsAlertsError,
  }));
  const { csidAlerts, csidAlertsError: premiumError } = premiumAlerts;

  const isPremiumUser = userInfo && userInfo.premiumAccount;
  const hasPremiumAlerts = isPlatinumPlan(userInfo);

  const noPremiumAlerts = csidAlerts?.length > 0;
  const dispatch = useDispatch();

  const logger = useLogger({ name: 'csidAerts.hook' });

  function getUnreadPremiumAlertCount() {
    return (
      notificationsAlerts?.items?.filter(alert => !alert.viewed)?.length ?? 0
    );
  }

  useEffect(() => {
    if (
      userInfo &&
      !notificationsAlertsLoading &&
      !notificationsAlertsError &&
      !notificationsAlerts
    ) {
      dispatch(getNotifications()).catch(err => {
        logger.reportAPIError(err);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userInfo,
    notificationsAlertsLoading,
    notificationsAlerts,
    notificationsAlertsError,
  ]);

  return {
    premiumLoading: notificationsAlertsLoading,
    csidAlerts,
    isPremiumUser,
    hasPremiumAlerts,
    getUnreadPremiumAlertCount,
    premiumError,
    noPremiumAlerts,
  };
};
