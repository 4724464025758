import { getVariationMap } from 'externals/_tracking/optimizely/optFlags';

const isOptimizelyProperlyInitialized = () => {
  return window.optimizely && typeof window.optimizely.get === 'function';
};

const getActiveExperiments = () => {
  if (isOptimizelyProperlyInitialized()) {
    return window.optimizely.get('state').getExperimentStates({
      isActive: true,
    });
  } else {
    return null;
  }
};

export const getTrackingInfo = () => {
  let optTrkAttrs = [];
  let individualOptProperties = {};

  const activeExperiments = getActiveExperiments();

  if (activeExperiments) {
    for (let [key, value] of Object.entries(activeExperiments)) {
      if (!value.isInExperimentHoldback) {
        optTrkAttrs.push({
          'Experiment Name': value.experimentName,
          'Experiment Id': key,
          'Variation Name': value.variation.name,
          'Variation Id': value.variation.id,
        });

        individualOptProperties[
          `OPT-${value.experimentName} (${key})`
        ] = `${value.variation.name} (${value.variation.id})`;
      }
    }
  }

  return {
    'Optimizely Experiments': optTrkAttrs,
    ...getVariationMap(),
    ...individualOptProperties,
  };
};
