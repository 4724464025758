import {
  buildCreditProfileEndpoint,
  buildCreditProfileEndpointWithDetail,
  buildConvertFreeTrialEndpoint,
  buildConvertFreeTrialUpgradeEndpoint,
} from 'api-urls';
import {
  buildFetchOptionsWithHeaders,
  standardizedFetchHandler,
  convertObjectToQueryString,
} from './service_helpers';

export function getCreditProfile() {
  const requestOptions = buildFetchOptionsWithHeaders();
  return standardizedFetchHandler(
    buildCreditProfileEndpointWithDetail(),
    requestOptions,
  );
}

export function getCreditProfileWithQuery(args) {
  const requestOptions = buildFetchOptionsWithHeaders();
  const finalArgs = Object.assign(
    {
      details: 'true',
    },
    args,
  );
  const query = convertObjectToQueryString(finalArgs);

  return standardizedFetchHandler(
    buildCreditProfileEndpoint() + query,
    requestOptions,
  );
}

export function putConvertFreeTrial(props) {
  let body = {};
  if (props?.consentedTo) {
    body = { consentedTo: props.consentedTo };
  }
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'PUT',
    body,
  });
  if (props?.isUpgradeNow) {
    return standardizedFetchHandler(
      buildConvertFreeTrialUpgradeEndpoint(),
      requestOptions,
    );
  } else {
    return standardizedFetchHandler(
      buildConvertFreeTrialEndpoint(),
      requestOptions,
    );
  }
}
