import { useDispatch, useSelector } from 'react-redux';
import {
  addNotification,
  deleteNotification,
} from 'actions/notification.actions';

const useNotification = () => {
  const dispatch = useDispatch();
  const notificationState = useSelector(state => state.notification);

  return {
    notifications: notificationState.notifications,
    addNotification: item => dispatch(addNotification(item)),
    deleteNotification: id => dispatch(deleteNotification(id)),
  };
};

export default useNotification;
