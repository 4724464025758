import React from 'react';
import { ReactComponent as ArrowBack } from 'components/premium/PremiumLandingCard/assets/arrow_back.svg';
import { CLICK } from 'externals/_tracking/mixpanel/types/Mixpanel.event.types';
import { useEventHandler } from 'hook/event.hook';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { OVERVIEW_URL } from 'types/mrph.routes.constants';

const StayLimited = ({ titleText, clickType, customOptions, isPlusUser }) => {
  const eventHandler = useEventHandler();
  const history = useHistory();
  const redirectOnClick = () => {
    let attr = {
      'Click Type': clickType,
    };
    if (customOptions) {
      attr = customOptions?.clickTypeAttr;
    }
    eventHandler(CLICK, attr);

    if (customOptions?.goBackUrl) {
      history.push(customOptions?.goBackUrl);
    } else {
      history.push(OVERVIEW_URL);
    }
  };
  const navbarClass = classnames('pointer', {
    stayLimitedPlus: isPlusUser,
    stayLimited: !isPlusUser,
  });

  return (
    <div onClick={redirectOnClick} className={navbarClass}>
      <nav className="padL10">
        <ArrowBack
          aria-label="Go back to the homepage. stay Limited"
          role="img"
        />
        <span className="padL10 subTitle1 ">{titleText}</span>
      </nav>
    </div>
  );
};

export default StayLimited;
