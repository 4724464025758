import {
  identifyUnifiedUser,
  setCSContentByDataPoints,
} from 'externals/_tracking';
import {
  brazeEventTrack,
  startBrazeSession,
} from 'externals/_tracking/braze/Braze.api';
import { getGetParam } from 'helpers/domHelper';
import moment from 'moment';
import {
  getItem,
  getLastUserTokenToLogIn,
  PREMIUM_SCORE_AUTO_UPDATE_MODAL,
  setItem,
  setLastUserTokenToLogIn,
  setOcfLogoutTime,
  setResurrectedUser,
  setUserLogoutTime,
  getHideAutomaticModalExperience,
  setUsrTkn,
  setOptimizelyUUID,
  getSessionCount,
  setSessionCount,
  setCStrack,
  setFirstNameUser,
  getDailyScoreRefreshCount,
  setDailyScoreRefreshCount,
  LOGIN_COUNT_COLLECTION_GUIDE,
  LOGIN_COUNT_SUGGESTED_CARD_BANNER,
} from 'helpers/localstorage';
import { triggerResurrectedUser } from 'helpers/pixelHelper';
import { tagInspectletSession } from 'externals/_tracking/inspectlet';
import {
  initOptSessionAttributes,
  manuallyActivatePage,
  pushOptAttribute,
} from 'externals/_tracking/optimizely';
import {
  formatAsYearMonthDay,
  isDateSameDateAsToday,
} from 'helpers/dateHelper';
import { getEnrolledStatus } from 'actions/sesameCash.actions';
import { pushUserProfileOptAttributes } from 'actions/login.actions';
import { getAttributesForRefreshProfile } from 'helpers/tracking.helpers';
import { brazeEventNames } from 'externals/_tracking/braze/Braze.events.config';
import { ACTIVATE_AUTOMATIC_UPDATE_MODAL } from 'types/app.constants';
import { get } from 'helpers/objectHelpers';
import { setSurveyAttributes } from 'externals/_tracking/webengage';
import { createLogger } from 'helpers/logger';
import {
  deleteCookieValueForCSDomain,
  setCookieWithExpiration,
} from 'helpers/cookie.helper';
import {
  setUserLoginCountCollectionGuideBanner,
  setUserLoginCountSuggestedCardFilterBanner,
} from '../../helpers/session';
import { identifyUser } from 'externals/_tracking/impact/impact.track';

const logger = createLogger({
  name: 'platform_dependencies.actions',
});

const setLocalSession = loginDataCollector => {
  const t = moment();
  t.add(loginDataCollector.loginResponse.expiresSeconds, 's');
  const logoutTime = t.format();
  loginDataCollector.logoutTime = logoutTime;
  if (!loginDataCollector.arguments.isOcfLogin) {
    setUserLogoutTime(logoutTime);
  } else {
    setOcfLogoutTime(logoutTime);
  }
};

function activateAutomaticUpdateModal() {
  return { type: ACTIVATE_AUTOMATIC_UPDATE_MODAL };
}

export const runSessionResponseDependencies = (loginDataCollector, isOcf) => {
  return new Promise((resolve, reject) => {
    try {
      const { loginResponse } = loginDataCollector;
      loginDataCollector.finalAction.loginResponse = loginResponse;
      if (!isOcf) {
        identifyUnifiedUser(loginResponse.userId);
      } else {
        identifyUnifiedUser(getGetParam('fobs'));
      }

      // identify user for impact
      identifyUser(loginResponse.userId);

      // set "usrTkn" cookie for fully registered morpheus user
      setCookieWithExpiration(
        'usrTkn',
        loginResponse.userId,
        100,
        null,
        null,
        true,
      );

      startBrazeSession(loginResponse.userId);
      setLocalSession(loginDataCollector);

      tagInspectletSession({
        usrTkn: loginResponse.userId,
        sessionIdentifier: loginResponse.sessionIdentifier,
      });
      initOptSessionAttributes(
        loginResponse.userId,
        loginResponse.sessionIdentifier,
      );
      const lastUserTokenToLogIn = getLastUserTokenToLogIn();
      setLastUserTokenToLogIn(loginResponse.userId);
      setUsrTkn(loginResponse?.userId);
      setOptimizelyUUID(loginResponse?.userId);
      if (lastUserTokenToLogIn !== loginResponse.userId) {
        //logger.info('First time user logged into device');
      }

      const sessionIdentifier =
        loginDataCollector.loginResponse.sessionIdentifier;
      if (loginDataCollector.csApiSessionId) {
        deleteCookieValueForCSDomain('cs-api-sessid');
        if (loginDataCollector.csApiSessionId !== sessionIdentifier) {
          logger.error('csAPISessionId is not equal to session response');
        }
      }

      loginDataCollector.finalAction.sessionIdentifier = sessionIdentifier;
      setCStrack(loginDataCollector.finalAction.sessionIdentifier);
      loginDataCollector.finalAction.user = loginResponse
        ? loginResponse.userId
        : null;

      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

export const runUserInfoDependencies = loginDataCollector => {
  return new Promise((resolve, reject) => {
    try {
      const { loginResponse } = loginDataCollector;
      const { userInfo } = loginDataCollector.finalAction;
      //Trigger resurrected user pixel
      if (loginResponse && loginResponse.isUserResurrected) {
        triggerResurrectedUser(userInfo);
      }
      setFirstNameUser(userInfo?.firstName);
      setResurrectedUser(loginResponse.isUserResurrected);
      pushOptAttribute({
        isPremiumEnrolled: userInfo && userInfo.premiumAccount,
      });
      loginDataCollector.userInfo = userInfo;

      const enrollStatus = getEnrolledStatus(userInfo);
      const sesameCashPinEnrolled =
        get(userInfo, ['banking', 'enrollStatus'], null) === 'REGISTERED';
      pushOptAttribute({ sesameCashPinEnrolled });
      pushOptAttribute({ userBankingEnrollStatus: enrollStatus });
      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

export const runCreditInfoDependencies = loginDataCollector => {
  return new Promise((resolve, reject) => {
    try {
      manuallyActivatePage('mrph_postLoginCreditProfileSuccess');
      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

export const runUserAndCreditInfoOptmizelyDependecies = loginDataCollector => {
  return new Promise((resolve, reject) => {
    try {
      const { userInfo, creditInfo, cmAlerts, csidAlerts } =
        loginDataCollector.finalAction;
      setCSContentByDataPoints({ userInfo, creditInfo, cmAlerts, csidAlerts });
      manuallyActivatePage('mrph_postOptimizelyContextSet');
      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

export const runUserAndCreditInfoDependencies = loginDataCollector => {
  return new Promise((resolve, reject) => {
    try {
      const { userInfo, creditInfo, cmAlerts, csidAlerts } =
        loginDataCollector.finalAction;
      setCSContentByDataPoints({ userInfo, creditInfo, cmAlerts, csidAlerts });

      const { dispatch } = loginDataCollector;

      const { isOcfLogin } = loginDataCollector.arguments;

      setSurveyAttributes({ userInfo, creditInfo });

      const currentLoggedInCount = getSessionCount() ?? 0;
      setSessionCount(Number(currentLoggedInCount) + 1);

      const dailyScoreRefreshCount = Number(getDailyScoreRefreshCount() ?? 0);
      setDailyScoreRefreshCount(dailyScoreRefreshCount + 1);

      // this is a temporary solution to show collection guide banner
      // in overview page only once after release feature
      const userLoginCountAfterCollectionGuideRelease = Number(
        getItem(LOGIN_COUNT_COLLECTION_GUIDE) ?? 0,
      );
      if (userLoginCountAfterCollectionGuideRelease) {
        setUserLoginCountCollectionGuideBanner(
          userLoginCountAfterCollectionGuideRelease + 1,
        );
      }

      // this is a temporary solution to show suggested card banner
      // in Overview only once after hide collection guide banner
      const userLoginCountSuggestedBestCardBannerRelease = Number(
        getItem(LOGIN_COUNT_SUGGESTED_CARD_BANNER) ?? 0,
      );
      if (userLoginCountSuggestedBestCardBannerRelease) {
        setUserLoginCountSuggestedCardFilterBanner(
          userLoginCountSuggestedBestCardBannerRelease + 1,
        );
      }

      if (
        !isOcfLogin &&
        !(userInfo.status === 'pending' || userInfo.status === 'blocked') &&
        creditInfo.code !== 'CP_1019'
      ) {
        pushUserProfileOptAttributes(creditInfo);
        // Automatic Update Modal
        if (userInfo.premiumAccount && creditInfo) {
          const isLastRefreshedToday = isDateSameDateAsToday(
            creditInfo.lastRefreshDate,
          );

          const autoUpdateModalDate = getItem(PREMIUM_SCORE_AUTO_UPDATE_MODAL);

          // If lastRefreshDate is not today
          if (!isLastRefreshedToday) {
            // loginDataCollector.dispatch(a_getCreditScoreRefresh()).then(_ =>
            //   loginDataCollector.dispatch(retrieveCreditProfile()),
            // );
          }
          // If "lastRefreshDate" is not today OR the refresh modal is not displayed for today
          if (
            !getHideAutomaticModalExperience() &&
            (!isLastRefreshedToday ||
              !autoUpdateModalDate ||
              (isLastRefreshedToday &&
                !isDateSameDateAsToday(autoUpdateModalDate)))
          ) {
            setItem(PREMIUM_SCORE_AUTO_UPDATE_MODAL, formatAsYearMonthDay());
            dispatch(activateAutomaticUpdateModal());
          }
        }

        const refreshDate = moment(creditInfo.reportPulledDate);
        const now = moment();
        now.add(-1, 'minute');

        if (refreshDate.isAfter(now) && creditInfo) {
          const attrs = getAttributesForRefreshProfile(creditInfo);
          console.log(
            'Braze Event',
            brazeEventNames.REFRESH_CREDIT_PROFILE,
            attrs,
          );
          brazeEventTrack(brazeEventNames.REFRESH_CREDIT_PROFILE, attrs);
        }

        manuallyActivatePage('mrph_postLogin');
        resolve();
      } else {
        resolve();
      }
    } catch (e) {
      reject(e);
    }
  });
};

export const runAfterFullyLoadedState = loginDataCollector => {
  return new Promise((resolve, reject) => {});
};
