import React from 'react';
import OpenInAppBtnView from 'partial_Header_components_OpenInAppBtn/OpenInAppBtnView';
import { useEventHandler } from 'hook/event.hook';
import { CLICK } from 'externals/_tracking/types/eventTypes.constants';
import { openNewTab } from 'helpers/domHelper';
import { useSelector } from 'react-redux';
import { isPlus } from 'helpers/userHelper';
import './OpenInAppBtn.scss';

const OpenInAppBtnController = () => {
  const eventHandler = useEventHandler();
  const { userInfo, config } = useSelector(state => ({
    userInfo: state?.userInfo?.userInfo,
    config: state?.config?.config,
  }));
  const isPlusUser = isPlus(userInfo);
  const onClickOpenApp = () => {
    eventHandler(CLICK, {
      'Click Type': 'Global Header App Download',
    });
    const appURL = 'https://smart.link/9aknquacvpn2w';
    openNewTab(appURL);
  };

  return config?.login?.smsCodeLogin ? (
    <OpenInAppBtnView
      onClick={onClickOpenApp}
      isPlusUser={isPlusUser}
      label="Continue in App"
    />
  ) : (
    <></>
  );
};

export default OpenInAppBtnController;
