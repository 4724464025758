import moment from 'moment';
import { emptyObj } from 'helpers/objectHelpers';
import { stateList } from 'helpers/address.helper';
import { getItem } from './localstorage';
import { createLogger } from './logger';

const logger = createLogger({
  name: 'signup.helpers',
  categories: ['signup'],
});

export const MIN_PASSWORD_LEN = 12;

//functions to validate user input before dispatching actions

export const validateFirstName = firstName => {
  if (!firstName) {
    return 'Please enter your first name.';
  }

  if (firstName.length > 40) {
    return 'First name is longer than the maximum of 40 characters.';
  }

  if (/[^a-zA-Z '-]/gi.test(firstName)) {
    return 'Please only enter letters, spaces, single quote or hyphen for your First Name.';
  }

  return false;
};

export const validateLastName = lastName => {
  if (!lastName) {
    return 'Please enter your last name.';
  }

  if (lastName.length > 40) {
    return 'Last Name is longer than the maximum of 40 characters.';
  }

  if (/[^a-zA-Z '-]/gi.test(lastName)) {
    return 'Please only enter letters, spaces, single quote or hyphen for your Last Name.';
  }

  return false;
};

export const validateDob = dob => {
  const date = dob.split('-');
  if (date.length !== 3) {
    return 'Please enter a valid date.';
  }
  const YY = date[0];
  const MM = date[1];
  const DD = date[2];

  return validateMM(MM) || validateDD(DD, MM) || validateYY(DD, MM, YY);
};

export const validateMM = MM => {
  const dobMM = parseInt(MM);

  if (!dobMM) {
    return 'Please enter a valid date.';
  }
  if (dobMM <= 0 || dobMM > 12) {
    return 'Please enter a valid date.';
  }

  return false;
};

export const validateDD = (DD, MM) => {
  const dobMM = parseInt(MM);
  const dobDD = parseInt(DD);

  if (!dobDD) {
    return 'Please enter a valid date.';
  }
  if (!validDate(dobDD, dobMM)) {
    return 'Please enter a valid date.';
  }
  return false;
};

export const validateYY = (DD, MM, YY) => {
  const dobMM = parseInt(MM);
  const dobDD = parseInt(DD);
  const dobYY = parseInt(YY);

  if (!YY) {
    return 'Please enter a valid date.';
  }
  if (YY.length < 4) {
    return 'Please enter a valid date.';
  }
  if (dobYY < 1800) {
    return 'Please enter a valid date.';
  }
  if (moment(`${MM}/${DD}/${YY}`).isAfter(new Date())) {
    return 'Please enter a valid date.';
  }
  if (
    moment(`${MM}/${DD}/${YY}`, 'MM/DD/YYYY', false).format() === 'Invalid date'
  ) {
    return 'Please enter a valid date.';
  }

  if (!minAge(dobDD, dobMM, dobYY)) {
    return 'You must be 18 years old to become a Credit Sesame member.';
  }
  if (!maxAge(dobDD, dobMM, dobYY)) {
    return 'Please enter a valid date.';
  }
  return false;
};

export const validateOnlyYY = YY => {
  const dobYY = parseInt(YY);
  if (
    !YY ||
    YY.length < 4 ||
    dobYY < 1800 ||
    !minAge(1, 1, dobYY) ||
    !maxAge(31, 12, dobYY)
  ) {
    return 'Please enter a valid year of birth.';
  }
  return false;
};

const validDate = (dd, mm) => {
  if (dd === 0) {
    return false;
  }
  if ((mm < 8 && mm % 2 === 0) || (mm >= 8 && mm % 2 !== 0)) {
    if (mm === 2) {
      return dd <= 29;
    }
    return dd <= 30;
  } else return dd <= 31;
};

const minAge = (dd, mm, yy) => {
  if (!dd || !mm || !yy) {
    return true;
  }

  let validAge = false,
    today = new Date(),
    todayYYYY = today.getFullYear(),
    yearDiff = todayYYYY - yy;
  if (yearDiff === 18) {
    var todayMM = today.getMonth(),
      todayDD = today.getDate();

    if ((todayMM === mm - 1 && todayDD >= dd) || todayMM > mm - 1) {
      validAge = true;
    }
  } else if (yearDiff > 18) {
    validAge = true;
  }

  return validAge;
};

export const validateEmail = email => {
  if (!email) {
    return 'Please enter a valid email address.';
  }

  let emailId = email.split('@')[0];

  if (emailId.length > 80) {
    return 'Email exceeds the maximum number of characters.';
  }

  if (
    // eslint-disable-next-line no-useless-escape
    !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
      email,
    )
  ) {
    return 'Please enter a valid email.';
  }

  return false;
};

export const validatePassword = password => {
  if (!password) {
    return 'Password is required.';
  }
  if (password.length < MIN_PASSWORD_LEN) {
    return `Password conditions are not met: Password needs to be at least ${MIN_PASSWORD_LEN} characters.`;
  }
  if (password.length > 128) {
    return 'Password conditions are not met: Password cannot be longer than 128 characters.';
  }
  if (!/[A-Z]/.test(password)) {
    return 'Password conditions are not met: Password requires one upper case letter';
  }
  if (!/[a-z]/.test(password)) {
    return 'Password conditions are not met: Password requires one lower case letter.';
  }
  if (!/\d/.test(password)) {
    return 'Password conditions are not met: Password requires at least 1 digit';
  }
  return false;
};

export const validatePasswordCase1 = password => {
  if (!password) {
    return false;
  }
  if (password.length >= MIN_PASSWORD_LEN) {
    return true;
  } else {
    return false;
  }
};

export const validatePasswordCase2 = password => {
  if (!password) {
    return false;
  }
  if (/[A-Z]/.test(password)) {
    return true;
  } else {
    return false;
  }
};

export const validatePasswordCase3 = password => {
  if (!password) {
    return false;
  }
  if (/[a-z]/.test(password)) {
    return true;
  } else {
    return false;
  }
};

export const validatePasswordCase4 = password => {
  if (!password) {
    return false;
  }
  if (/\d/.test(password)) {
    return true;
  } else {
    return false;
  }
};

export const validateAddress = (address, usePOBoxValidation = false) => {
  let addressErrors = {};
  for (let item in address) {
    let error = getValidationError(item, address[item], usePOBoxValidation);
    if (error) {
      addressErrors[item] = error;
    }
  }
  return emptyObj(addressErrors) ? false : addressErrors;
};

export const validateStreet = street => {
  if (!street) {
    return 'Please enter your street address.';
  }
  if (/@/.test(street)) {
    return 'Address should not contain the @ character.';
  }
  return false;
};

export const validateStreetwPOB = street => {
  if (!street) {
    return 'Please enter your street address.';
  }
  if (/@/.test(street)) {
    return 'Address should not contain the @ character.';
  }
  if (
    /P\.O\. Box|P\.0\. Box|P\. O\. Box|P\. 0\. Box|P\.  O\.  Box|P\.  0\.   Box|PO Box|POBox|POB|P0B|P\.O\.B|P\.0\.B|P\. O\. B|P\. 0\. B|P\.O\.|P\.0\.|P\. O\.|P\. 0\.|PO|P0|Post Office Box|PostOfficeBox|P Office Box|P\. Office Box|Post O Box|PMB Box/.test(
      street,
    )
  ) {
    return 'PO Box addresses are not accepted.';
  }
  return false;
};

export const validateZip = zip => {
  if (!zip) {
    return 'Please enter a valid zip code.';
  }
  if (!/^\d{5}(-\d{4})?$/.test(zip)) {
    return 'Please enter a valid zip code.';
  }
  if (/^[0]+$/.test(zip)) {
    return 'Please enter a valid zip code.';
  }
  return false;
};

export const validateCity = city => {
  if (!city) {
    return 'Please enter your city.';
  }
  return false;
};

export const validateState = state => {
  if (!state) {
    return 'Please enter your state.';
  }
  return false;
};

export const validatePhoneUS = number => {
  if (!number) {
    return 'Please enter a valid phone number.';
  }
  let phoneNum = number.replace(/\s+/g, '');
  let result = phoneNum.length > 9 && phoneNum.match(/^(\+?1-?)?[()\d-]*$/);
  if (result) {
    phoneNum = phoneNum.replace(/([()-]|(^\+?1-?))/g, '');
  }

  if (!number) {
    return 'Please enter a valid phone number.';
  }
  if (
    !(
      result &&
      phoneNum.match(/^[2-9]([02-9]\d|1[02-9])[2-9]([02-9]\d|1[02-9])\d{4}$/)
    )
  ) {
    return 'Please enter a valid phone number.';
  }
  return false;
};

export const validateSignupSSN = (ssn, full9SSN) => {
  const ssnDigits = full9SSN ? '9' : '4';

  if (!ssn) {
    return `Please verify the last ${ssnDigits} digits of your Social Security number.`;
  }

  if (!full9SSN && ssn.length !== 4) {
    return 'Please verify the last 4 digits of your Social Security number.';
  }

  if (full9SSN && ssn.length !== 9) {
    return 'Please verify the last 9 digits of your Social Security number.';
  }

  return false;
};

export const validate = items => {
  let errors = {};
  for (let item in items) {
    if (item === 'address') {
      Object.assign(errors, validate(items[item]));
    } else {
      const error = getValidationError(item, items[item]);

      if (error) {
        Object.assign(errors, { [item]: error });
      }
    }
  }
  return emptyObj(errors) ? false : errors;
};

const getValidationError = (item, value, usePOBoxValidation = false) => {
  switch (item) {
    case 'firstName':
      return validateFirstName(value);
    case 'lastName':
      return validateLastName(value);
    case 'primaryAddress':
      return validateAddress(value);
    case 'secondaryAddress':
      return validateAddress(value);
    case 'street':
      return usePOBoxValidation
        ? validateStreetwPOB(value)
        : validateStreet(value);
    case 'zip':
      return validateZip(value);
    case 'city':
      return validateCity(value);
    case 'state':
      return validateState(value);
    case 'dob':
      return validateDob(value);
    case 'MM':
      return validateMM(value);
    case 'DD':
      return validateDD(value.dobDD, value.dobMM);
    case 'YY':
      return validateYY(value.dobDD, value.dobMM, value.dobYY);
    case 'contactPhoneNumber':
      return validatePhoneUS(value);
    case 'ssn':
      return validateSignupSSN(value.ssn, value.full9SSN);
    default:
      return false;
  }
};

export const fillUserDetails = (userInfo, stateObj) => {
  const userDetails = {};
  const address = {};
  for (let property in stateObj) {
    if (userInfo[property] && userInfo[property] !== null) {
      if (property === 'primaryAddress' || property === 'secondaryAddress') {
        for (let addressProp in stateObj[property]) {
          if (userInfo[property][addressProp]) {
            let value = userInfo[property][addressProp];
            if (addressProp === 'state') {
              value = getStateFormatted(userInfo[property][addressProp]);
            }
            Object.assign(address, {
              [property]: {
                ...address[property],
                [addressProp]: value,
              },
            });
          }
        }
      } else if (property === 'birthDate') {
        userDetails.dob = moment(userInfo[property]).format('YYYY-MM-DD');
      } else if (property === 'contactPhoneNumber') {
        userDetails[property] = formatPhoneNumber(userInfo[property]);
      } else {
        userDetails[property] = userInfo[property];
      }
    }
  }

  return Object.assign({}, { user: userDetails }, { address: address });
};

export const formatPhoneNumber = phoneNumberString => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

export const scrollToInvalid = errors => {
  let firstErrorForm;
  if (errors) {
    Object.keys(errors).forEach(key => {
      if (key !== undefined) {
        firstErrorForm = document.getElementsByClassName('is-invalid')[0];
        if (firstErrorForm) {
          window.scrollTo(
            0,
            window.pageYOffset +
              firstErrorForm.getBoundingClientRect().top -
              100,
          );
          firstErrorForm.focus();
        }
      }
    });
  }
};

export const validateSignupIncome = income => {
  if (/[a-zA-Z]/.test(income)) {
    return 'Please enter only digits.';
  }
  if (!/^[0-9]*$/.test(income)) {
    return 'Please enter only digits.';
  }
  return false;
};

export const ssnMasker9 = val => {
  let output = '';

  for (let i = 0; i < val.length; i++) {
    if (i === 3) output += '-';
    if (i === 5) output += '-';

    output += '\u25CF';
  }

  return output;
};

export const ssnMasker4 = val => {
  let output = '';

  for (let i = 0; i < val.length; i++) {
    output += '\u25CF';
  }

  return output;
};

export const getSsnMasker = isFull => {
  if (isFull) {
    return ssnMasker9;
  } else {
    return ssnMasker4;
  }
};

export const getStateAbrev = name => {
  if (!name) {
    return null;
  }
  for (const property in stateList) {
    if (name === stateList[property].name) {
      return property;
    }
  }
  return '';
};

export const getStateFormatted = abbrev => {
  if (!abbrev || !stateList[abbrev]) {
    return null;
  }
  return stateList[abbrev].name;
};

export const getDateFormatted = (m, d, y) => {
  let mm = m ? (m < 10 ? `0${parseInt(m)}` : m) : m;
  let dd = d ? (d < 10 ? `0${parseInt(d)}` : d) : d;
  return `${y}-${mm}-${dd}`;
};

export const emailMasker = fullEmail => {
  if (!fullEmail) {
    return null;
  }

  const [email, domain] = fullEmail.split('@');
  let mask = '';
  for (let i = 0; i < email.length; i++) {
    if (i === 0 || i === email.length - 1) {
      mask += email.charAt(i);
    } else mask += '*';
  }
  return `${mask}@${domain}`;
};

export const formatKBAphoneNumber = phoneNumber => {
  if (phoneNumber) {
    return phoneNumber.replace(/X/g, '*');
  } else {
    return '';
  }
};

export const getLoginLinkWithParams = data => {
  const params = new URLSearchParams(data);
  return params ? `/login?${params.toString()}` : '/login';
};

const maxAge = (dd, mm, yy) => {
  if (!dd || !mm || !yy) {
    return true;
  }

  let validAge = false,
    today = new Date(),
    todayYYYY = today.getFullYear(),
    yearDiff = todayYYYY - yy;
  if (yearDiff === 121) {
    var todayMM = today.getMonth(),
      todayDD = today.getDate();

    if ((todayMM === mm - 1 && todayDD < dd) || todayMM < mm - 1) {
      validAge = true;
    }
  } else if (yearDiff < 121) {
    validAge = true;
  }
  return validAge;
};

export const validateEmailAndDomain = email => {
  let emailError = validateEmail(email);
  if (emailError) {
    return emailError;
  }

  const domainArray = email.split('@')[1].split('.');
  const hasWhitespace = !/^\S*$/.test(email.trim());
  if (
    email.slice(-1) === '.' ||
    hasWhitespace ||
    !domainArray[0] ||
    !domainArray[1]
  ) {
    return 'Please enter a valid email.';
  }

  return false;
};

export const deleteAndGetUpdatedUrlQueryString = (location, param) => {
  const urlParams = new URLSearchParams(location.search);
  urlParams.delete(param);
  return urlParams.toString();
};

export const getAffiliateLP = () => {
  let landingPage = null;
  try {
    const refLp = JSON.parse(getItem('refLp'));
    landingPage = refLp?.affiliate;
    //eslint-disable-next-line
  } catch (err) {
    logger.error('Error parsing refLp obj.');
  }
  return landingPage;
};

export const setSsn = (
  e,
  ssnInternal,
  setSsnInternal,
  handleSSNChange,
  ssnRequire9,
) => {
  const val = e.target.value.trim();
  if (val.length > ssnInternal.label.length) {
    const newChar = val.charAt(val.length - 1);
    const newVal = ssnInternal.value + newChar;

    if (/^\d*$/.test(newVal)) {
      setSsnInternal(newVal);
      handleSSNChange(newVal);
    }
  } else if (val.length < ssnInternal.label.length) {
    const subtractedCharacters = ssnInternal.label.length - val.length;
    let charactersToRemove = subtractedCharacters;

    if (ssnInternal.label.length > 6 && val.length <= 6) {
      charactersToRemove -= 1;
    }

    if (ssnRequire9 && ssnInternal.label.length > 3 && val.length <= 3) {
      charactersToRemove -= 1;
    }

    if (subtractedCharacters > ssnInternal.value.length) {
      charactersToRemove = ssnInternal.value.length;
    }

    const finalVal = ssnInternal.value.substring(
      0,
      ssnInternal.value.length - charactersToRemove,
    );
    setSsnInternal(finalVal);
    handleSSNChange(finalVal);
  }
};

export const validateSsn = (
  e,
  ssnInternal,
  setSsnInternal,
  handleSSNChange,
  ssnRequire9,
  requiredSsnCharaters,
) => {
  const val = e.target.value;
  if (val.length === 1 && ssnInternal.value.length > 2) {
    if (/^\d+$/.test(val)) {
      handleSSNChange(val);
      setSsnInternal(val);
    } else {
      handleSSNChange('');
      setSsnInternal('');
    }
  } else if (val.length <= requiredSsnCharaters) {
    setSsn(e, ssnInternal, setSsnInternal, handleSSNChange, ssnRequire9);
  }
};
