import {
  buildFetchOptionsWithHeaders,
  convertObjectToQueryString,
  standardizedFetchHandler,
} from './service_helpers';
import { buildGoalActionEndpoint, buildGoalsEndpoint } from '../../api-urls';

export async function fetchGoals(queryOptions) {
  const requestOptions = buildFetchOptionsWithHeaders();
  const queryString = convertObjectToQueryString(queryOptions);
  return standardizedFetchHandler(
    buildGoalsEndpoint() + queryString,
    requestOptions,
  );
}

export async function fetchGoalAction(actionId, queryOptions) {
  const requestOptions = buildFetchOptionsWithHeaders();
  const queryString = convertObjectToQueryString(queryOptions);
  return standardizedFetchHandler(
    buildGoalActionEndpoint(actionId) + queryString,
    requestOptions,
  );
}
