import { useState } from 'react';
import { handleTopLevelError } from 'actions/app.actions';
import { useDispatch } from 'react-redux';
import { createLogger } from 'helpers/logger';
import { addNotification } from 'actions/notification.actions';
import { useHistory } from 'react-router-dom';
import { getEnvironment, PRODUCTION } from 'helpers/env';
import { checkForGlobalAPIError } from 'actions/logger.actions';

export const useLogger = opts => {
  const dispatch = useDispatch();
  const history = useHistory();
  const finalOpts = opts ? opts : {};
  const [internalErrorList, setInternalErrorList] = useState([]);

  const logger = createLogger({
    ...finalOpts,
    errorList: undefined,
  });

  const finalExternalErrorList = finalOpts.errorList ? finalOpts.errorList : [];

  const {
    log,
    debug,
    info,
    error,
    logException,
    debugException,
    infoException,
    errorException,
    reportAPIError,
  } = logger;

  const showError = (title, description, args) => {
    const finalDescription =
      typeof description === 'object' ? description.message : description;
    dispatch(
      addNotification({
        type: 'error',
        duration: '5000',
        title,
        description: finalDescription,
        ...args,
      }),
    );
  };

  const showWarning = (title, description, args) => {
    dispatch(
      addNotification({
        type: 'warning',
        duration: '5000',
        title,
        description,
        ...args,
      }),
    );
  };

  const showSuccess = (title, description, args) => {
    dispatch(
      addNotification({
        type: 'success',
        duration: '5000',
        title,
        description,
        ...args,
      }),
    );
  };

  const createFatalApplicationError = err => {
    dispatch(handleTopLevelError(err));
  };

  const showQAError = (title, description) => {
    if (getEnvironment() !== PRODUCTION) {
      addNotification({
        type: 'error',
        duration: '5000',
        title: 'QA_NOTICE:' + title,
        description,
      });
    }
  };

  const addErrorToList = err => {
    setInternalErrorList([...internalErrorList, err]);
  };

  /**
   * If promise returns, the global handler wants the page to handle the error
   *
   * @param err
   */
  const checkForGlobalAPIErrorHandler = err => {
    return dispatch(checkForGlobalAPIError(err, opts.name, history));
  };

  const errorList = [...internalErrorList, ...finalExternalErrorList];
  return {
    log,
    logException,
    debug,
    debugException,
    info,
    infoException,
    error,
    errorException,
    reportAPIError,
    showError,
    showWarning,
    showSuccess,
    createFatalApplicationError,
    checkForGlobalAPIErrorHandler,
    errorList,
    shouldShowErrorPage: errorList.length > 0,
    showQAError,
    addErrorToList,
  };
};
