import { SET_IS_HYBRID_CONVERT_UPGRADE_FLOW } from '../types/hybridFlows.constants';

const initialState = {
  isHybridConvertUpgradeFlow: false,
};

export default function hybridFlows(state = initialState, action) {
  switch (action.type) {
    case SET_IS_HYBRID_CONVERT_UPGRADE_FLOW:
      return Object.assign({}, state, {
        isHybridConvertUpgradeFlow: action.isHybridConvertUpgradeFlow,
      });
    default:
      return state;
  }
}
