import {
  buildNotificationsEndpoint,
  buildNotificationsDetailsEndpoint,
} from 'api-urls';
import {
  buildFetchOptionsWithHeaders,
  standardizedFetchHandler,
} from 'externals/_services/service_helpers';

export const getNotificationsAlerts = async => {
  const options = buildFetchOptionsWithHeaders();
  return standardizedFetchHandler(buildNotificationsEndpoint(), options);
};

export async function getNotificationsAlertsDetails(notificationType, alertId) {
  const options = buildFetchOptionsWithHeaders();
  return standardizedFetchHandler(
    buildNotificationsDetailsEndpoint(notificationType, alertId),
    options,
  );
}
