import { mergePayloadChanges } from 'helpers/reducer.helper';
import { PAYLOAD } from 'types/payload.constants';
import {
  GET_REQUEST,
  GET_FAILURE,
  GET_SUCCESS,
} from 'types/userInfo.constants';

import {
  LOGOUT,
  LOGIN_SUCCESS,
  INVALID_ROUTE_REDIRECT,
} from 'types/app.constants';

const initialState = {
  userInfoLoading: false,
  userInfoError: null,
  userInfo: null,
  ocfUserInfo: null,
};

const userInfoReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_REQUEST:
      return Object.assign({}, initialState, {
        userInfoLoading: true,
      });
    case GET_SUCCESS:
      return Object.assign({}, initialState, {
        userInfo: action.userInfo,
      });
    case GET_FAILURE:
      return Object.assign({}, initialState, {
        userInfoError: action.userInfoError,
      });
    case LOGIN_SUCCESS:
    case PAYLOAD:
      return mergePayloadChanges(state, action);
    case LOGOUT:
    case INVALID_ROUTE_REDIRECT:
      return Object.assign({}, initialState);
    default:
      return state;
  }
};

export default userInfoReducer;
