import {
  CASH_ADD_MONEY,
  CASH_SET_PIN,
} from 'types/morph.pagePosition.constants';

export const getPageError = (
  isCashTokenTimeout,
  cashAccountError,
  cardDetails,
  bankTransferFlowError,
  logger,
) => {
  const genericMessage = 'Something went wrong!';
  try {
    if (isCashTokenTimeout) {
      return {
        type: 'Token timeout',
        message: `Your banking session has timed out. Please log out and log in again to view.`,
      };
    }
    if (cashAccountError) {
      return {
        type: 'Cash Account Error',
        message: `Something went wrong while fetching your cash profile!`,
      };
    }
    if (cardDetails && cardDetails.cardDetailsError) {
      return {
        type: 'Cash Card Details Error',
        message: `Something went wrong while fetching your card details!`,
      };
    }
    if (bankTransferFlowError) {
      return {
        type: 'Plaid Transfer Flow Error',
        message: `Something went wrong while fetching your cash accounts. Please reload your page.`,
      };
    }
  } catch (e) {
    if (logger) {
      logger.errorException('Error getting cash account error', e);
    }
    return { e, message: genericMessage };
  }
};

export const getFundCashUI = sesameCashAccount => {
  let step = null,
    fundAccountTitle = '',
    fundAccountMessage = '',
    showPinCTA = false,
    imageClass = '',
    moduleName = '';

  if (sesameCashAccount) {
    const { receivedFirstFund, changedFirstPin } = sesameCashAccount;

    if (!receivedFirstFund && !changedFirstPin) {
      step = 1;
      fundAccountTitle = 'Add money to your account';
      fundAccountMessage = 'Add some money to your account to get started.';
      showPinCTA = false;
      imageClass = 'fundImage get-started-img';
      moduleName = CASH_ADD_MONEY;
    }
    if (receivedFirstFund && !changedFirstPin) {
      step = 2;
      fundAccountTitle = 'Set Debit Card PIN';
      fundAccountMessage = 'Set a PIN for your Sesame Cash debit card.';
      showPinCTA = true;
      imageClass = 'fundImage set-pin-img';
      moduleName = CASH_SET_PIN;
    }
  }

  return {
    step,
    fundAccountTitle,
    fundAccountMessage,
    showPinCTA,
    imageClass,
    moduleName,
  };
};

export const isCardStatusFrozen = sesameCashAccount => {
  return (
    sesameCashAccount &&
    sesameCashAccount.cardStatus &&
    sesameCashAccount.cardStatus === 'INACTIVE'
  );
};

export const isCardStatusActive = sesameCashAccount => {
  return (
    sesameCashAccount &&
    sesameCashAccount.cardStatus &&
    sesameCashAccount.cardStatus === 'ACTIVE'
  );
};

export const hasSetPin = sesameCashAccount => {
  return !!(sesameCashAccount && sesameCashAccount.changedFirstPin);
};
