import { createLogger } from 'helpers/logger';
import { removeItem } from './localstorage';

const POST_LOGIN_PATH = 'POST_LOGIN_PATH';
const CLIENT_ID = 'CLIENT_ID';
const RESPONSE_TYPE = 'RESPONSE_TYPE';
const ALEXA_STATE = 'ALEXA_STATE';
const REDIRECT_URL = 'REDIRECT_URL';
const HIDE_HEADER = 'HIDE_HEADER';
const SHOULD_SHOW_EXPANDED_ENGAGEMENT_MODULE =
  'SHOULD_SHOW_EXPANDED_ENGAGEMENT_MODULE';
const HIDE_SIGN_UP_BANNER = 'HIDE_SIGN_UP_BANNER';
const FINICITY_CONNECT_URL = 'FINICITY_CONNECT_URL';
const logger = createLogger({
  name: 'sessionStorage',
});

function setItem(key, value) {
  try {
    window.sessionStorage.setItem(key, value);
  } catch (e) {
    logger.errorException('Unable to setItem', e);
  }
}

function getItem(key) {
  try {
    return window.sessionStorage.getItem(key);
  } catch (e) {
    logger.errorException('Unable to getItem', e);
  }
}

export function setPostLoginPath(path) {
  setItem(POST_LOGIN_PATH, path);
}

export function clearPostLoginPath() {
  try {
    window.sessionStorage.removeItem(POST_LOGIN_PATH);
  } catch (e) {
    logger.errorException(`Unable to removeItem "${POST_LOGIN_PATH}"`, e);
  }
}

export function getPostLoginPath() {
  return getItem(POST_LOGIN_PATH);
}

export function setClientId(value) {
  setItem(CLIENT_ID, value);
}

export function getClientId() {
  return getItem(CLIENT_ID);
}

export function setResponseType(value) {
  setItem(RESPONSE_TYPE, value);
}

export function getResponseType() {
  return getItem(RESPONSE_TYPE);
}

export function setAlexaState(value) {
  setItem(ALEXA_STATE, value);
}

export function getAlexaState() {
  return getItem(ALEXA_STATE);
}

export function setRedirectUrl(value) {
  setItem(REDIRECT_URL, value);
}

export function getRedirectUrl() {
  return getItem(REDIRECT_URL);
}

export function setHideHeader(value) {
  setItem(HIDE_HEADER, value);
}

export function getHideHeader() {
  return getItem(HIDE_HEADER);
}

export function setShouldShowExpandedEngagementModule(value) {
  setItem(SHOULD_SHOW_EXPANDED_ENGAGEMENT_MODULE, value);
}

export function getShouldShowExpandedEngagementModule() {
  return getItem(SHOULD_SHOW_EXPANDED_ENGAGEMENT_MODULE);
}

export function removeShouldShowExpandedEngagementModule() {
  window.sessionStorage.removeItem(SHOULD_SHOW_EXPANDED_ENGAGEMENT_MODULE);
}

export const setHideSignUpBanner = value => {
  setItem(HIDE_SIGN_UP_BANNER, value);
};

export const getHideSignUpBanner = () => {
  return getItem(HIDE_SIGN_UP_BANNER);
};

export const removeHideSignUpBanner = () => {
  window.sessionStorage.removeItem(HIDE_SIGN_UP_BANNER);
};

export function getStoredFinicityConnectUrl() {
  return getItem(FINICITY_CONNECT_URL);
}

export function setStoredFinicityConnectUrl(connectUrl) {
  setItem(FINICITY_CONNECT_URL, connectUrl);
}

export function removeStoredFinicityConnectUrl() {
  removeItem(FINICITY_CONNECT_URL);
}
