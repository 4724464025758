import React from 'react';

const TelNumber = props => {
  return (
    <>
      <span className="desktopHide">
        <a href={`tel:${props.callNumber}`} className="pointer">
          {props.callNumber}
        </a>
      </span>
      <span className="mobileHide">{props.callNumber}</span>
    </>
  );
};

export default TelNumber;
