import { commafy } from 'helpers/number';

export const formatUSD = (number, prefix = '$') => {
  if (!number) return prefix + '0';
  //return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' },{ maximumFractionDigits: 0}).format(number);
  return prefix + number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const formatUSDwithSign = (number, prefix = '$') => {
  let formattedNumber = new Intl.NumberFormat(
    'en-US',
    { style: 'currency', currency: 'USD' },
    { maximumFractionDigits: 2 },
  ).format(Math.abs(number));
  if (number > 0) {
    formattedNumber = '+ ' + formattedNumber;
  } else {
    formattedNumber = '- ' + formattedNumber;
  }
  return formattedNumber;
}; // unformat usa currency

export const formatIncome = (income, floatType) => {
  if (!income && income !== 0) {
    return;
  }
  const isDollar = /[$]/gi;
  if (isDollar.test(income)) {
    return income;
  } else {
    if (floatType) {
      return '$' + commafy(income.toFixed(2));
    }
    return '$' + commafy(income);
  }
};

export const formatNegativeIncome = (income, floatType) => {
  if (!income && income !== 0) {
    return;
  }
  const isDollar = /[$]/gi;
  if (isDollar.test(income)) {
    return income;
  } else {
    if (floatType) {
      return '-$' + commafy(income.toFixed(2));
    }
    return '-$' + commafy(income);
  }
};

export const unformatIncome = income => {
  income = income + '';
  const isDollarAndComma = /[$,]/gi;
  let unformat;
  unformat = income.replace(isDollarAndComma, '');
  return unformat;
};

// format usa currency
export function formatCurrency(val) {
  return val ? `$${new Intl.NumberFormat().format(val.toString())}` : null;
}

export function unFormatCurrency(val) {
  return val ? val.replace(/,/g, '').replace(' ', '').replace('$', '') : val;
}

export const formatUSDwithCents = (number, prefix = '$') => {
  if (!number) return '$0.00';
  let formattedNumber = new Intl.NumberFormat(
    'en-US',
    { style: 'currency', currency: 'USD' },
    { maximumFractionDigits: 2 },
  ).format(Math.abs(number));

  return formattedNumber;
};

export const validateIncome = (income, notEmpty) => {
  let unformatted = unformatIncome(income);
  if (notEmpty && unformatted === '') {
    return 'Please enter a valid number';
  }
  if (!unformatted) {
    return '';
  }
  const regex = /^[0-9]+$/;
  if (regex.test(unformatted)) {
    return true;
  } else {
    return 'Please enter a valid number';
  }
};

export const validateIncomeSchema = incomeValue => {
  let validateSchema = {
    value: unformatIncome(incomeValue),
    error: false,
    label: formatIncome(unformatIncome(incomeValue)),
    validated: typeof validateIncome(incomeValue) === 'boolean' ? true : false,
  };

  return validateSchema;
};

export const formatPriceByFrequency = (amount, frequency) => {
  if (!amount) return null;
  let frequencyText = '';
  const formatAmount = formatIncome(amount);
  if (frequency === 'month') {
    frequencyText = '/month';
  } else if (frequency === 'quarterly') {
    frequencyText = '/3 months';
  } else if (frequency === 'yearly') {
    frequencyText = '/yearly';
  } else if (frequency === 'mo') {
    frequencyText = '/mo';
  } else if (frequency === 'm') {
    frequencyText = '/m';
  }

  return formatAmount + frequencyText;
};
