import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveNewFeaturesToHighlight } from 'actions/highlightFeatures.actions';
import { isAnySessionActive } from 'helpers/session';

export const useHighLightFeatures = () => {
  const {
    highlightFeaturesLoading,
    highlightFeatures,
    highlightFeaturesError,
    loggedIn,
    ocfLoggedIn,
    publicLoggedIn,
    config,
  } = useSelector(state => {
    return {
      highlightFeaturesLoading:
        state.highlightFeatures.highlightFeaturesLoading,
      highlightFeatures: state.highlightFeatures.highlightFeatures,
      highlightFeaturesError: state.highlightFeatures.highlightFeaturesError,
      loggedIn: state.app.loggedIn,
      ocfLoggedIn: state.app.ocfLoggedIn,
      publicLoggedIn: state.app.publicLoggedIn,
      config: state.config.config,
    };
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const isLoggedIn = loggedIn || !ocfLoggedIn || publicLoggedIn;
    let initiateFetch =
      !highlightFeaturesLoading &&
      highlightFeatures == null &&
      highlightFeaturesError == null &&
      isLoggedIn;

    if (
      initiateFetch &&
      isAnySessionActive() &&
      config?.appManagement?.enableHighlightFeatures
    ) {
      dispatch(getActiveNewFeaturesToHighlight());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    highlightFeaturesLoading,
    highlightFeatures,
    highlightFeaturesError,
    dispatch,
    loggedIn,
    ocfLoggedIn,
    publicLoggedIn,
  ]);

  return {
    highlightFeaturesLoading,
    highlightFeatures,
    highlightFeaturesError,
  };
};
