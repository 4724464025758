//Link Token Constants
export const ACCOUNT_LINK_LINK_TOKEN_REQUEST =
  'ACCOUNT_LINK_LINK_TOKEN_REQUEST';
export const ACCOUNT_LINK_LINK_TOKEN_ERROR = 'ACCOUNT_LINK_LINK_TOKEN_ERROR';
export const ACCOUNT_LINK_LINK_TOKEN_SUCCESS =
  'ACCOUNT_LINK_LINK_TOKEN_SUCCESS';
export const ACCOUNT_LINK_LINK_TOKEN_REMOVE = 'ACCOUNT_LINK_LINK_TOKEN_REMOVE';

//Linked Accounts Constants
export const ACCOUNT_LINK_LINKED_ACCOUNTS_REQUEST =
  'ACCOUNT_LINK_LINKED_ACCOUNTS_REQUEST';
export const ACCOUNT_LINK_LINKED_ACCOUNTS_ERROR =
  'ACCOUNT_LINK_LINKED_ACCOUNTS_ERROR';
export const ACCOUNT_LINK_LINKED_ACCOUNTS_SUCCESS =
  'ACCOUNT_LINK_LINKED_ACCOUNTS_SUCCESS';

//Linked Accounts Logos Constants
export const ACCOUNT_LINK_LINKED_ACCOUNTS_LOGO_REQUEST =
  'ACCOUNT_LINK_LINKED_ACCOUNTS_LOGO_REQUEST';
export const ACCOUNT_LINK_LINKED_ACCOUNTS_LOGO_ERROR =
  'ACCOUNT_LINK_LINKED_ACCOUNTS_LOGO_ERROR';
export const ACCOUNT_LINK_LINKED_ACCOUNTS_LOGO_SUCCESS =
  'ACCOUNT_LINK_LINKED_ACCOUNTS_LOGO_SUCCESS';

//Link Account Constants
export const ACCOUNT_LINK_LINK_ACCOUNT_REQUEST =
  'ACCOUNT_LINK_LINK_ACCOUNT_REQUEST';
export const ACCOUNT_LINK_LINK_ACCOUNT_ERROR =
  'ACCOUNT_LINK_LINK_ACCOUNT_ERROR';
export const ACCOUNT_LINK_LINK_ACCOUNT_SUCCESS =
  'ACCOUNT_LINK_LINK_ACCOUNT_SUCCESS';

//Fund Transfer Constants
export const ACCOUNT_LINK_FUND_TRANSFER_REQUEST =
  'ACCOUNT_LINK_FUND_TRANSFER_REQUEST';
export const ACCOUNT_LINK_FUND_TRANSFER_ERROR =
  'ACCOUNT_LINK_FUND_TRANSFER_ERROR';
export const ACCOUNT_LINK_FUND_TRANSFER_SUCCESS =
  'ACCOUNT_LINK_FUND_TRANSFER_SUCCESS';

//Remove Account Constants
export const ACCOUNT_LINK_REMOVE_ACCOUNT_REQUEST =
  'ACCOUNT_LINK_REMOVE_ACCOUNT_REQUEST';
export const ACCOUNT_LINK_REMOVE_ACCOUNT_ERROR =
  'ACCOUNT_LINK_REMOVE_ACCOUNT_ERROR';
export const ACCOUNT_LINK_REMOVE_ACCOUNT_SUCCESS =
  'ACCOUNT_LINK_REMOVE_ACCOUNT_SUCCESS';

export const ACCOUNT_LINK_CLEAN = 'ACCOUNT_LINK_CLEAN';
