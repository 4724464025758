import { Tag } from 'components/Tag';
import classnames from 'classnames';
import 'partial_PremiumPill/PremiumPill.scss';

export const PremiumPill = ({ text, backgroundClass, className }) => {
  const premiumPillClasses = classnames('PremiumPillTag', className);
  return (
    <Tag className={premiumPillClasses} backgroundClass={backgroundClass}>
      {text}
    </Tag>
  );
};

PremiumPill.defaultProps = {
  backgroundClass: 'bgPurple0',
};
