import { LOGOUT } from 'types/app.constants';
import { creditReportTUConstants } from 'pages_CreditReportTU_constants/creditReportTU.constants';
import { mergePayloadChanges } from 'helpers/reducer.helper';

const initialState = {
  creditReports: null,
  reportsLoaded: {},
};

export default function creditReportReducer(state = initialState, action) {
  switch (action.type) {
    case creditReportTUConstants.STORE_REPORTS: {
      return mergePayloadChanges(state, {
        creditReports: action.creditReports,
      });
    }
    case creditReportTUConstants.LOAD_REPORT: {
      const reportsLoaded = {
        ...state.reportsLoaded,
        [action.reportId]: action.responseData,
      };
      const newState = Object.assign({}, state, { reportsLoaded });
      return newState;
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
