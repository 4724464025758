import {
  IN_PROGRESS,
  ENROLLED,
  UNENROLLED,
  LANDLORD_DETAILS_NEEDED,
  RENTAL_DETAILS_NEEDED,
  BANK_ACCOUNT_NOT_LINKED,
  BANK_ACCOUNT_LINKED,
  IDENTIFY_PAYMENTS,
  READY_FOR_VERIFICATION,
  PUSHED_TO_SECONDARY_VERIFICATION,
  WAITING_FOR_INFORMATION,
  VOR_SENT,
  READY_FOR_SUBMISSION,
  WAITING_FOR_PAYMENT,
  CALL_TO_VERIFY,
  VOR_REJECTED,
  ESCALATED_VOR,
  UNABLE_TO_VERIFY,
  IN_PROGRESS__LANDLORD_DETAILS_NEEDED,
  IN_PROGRESS__RENTAL_DETAILS_NEEDED,
  IN_PROGRESS__BANK_ACCOUNT_NOT_LINKED,
  IN_PROGRESS__BANK_ACCOUNT_LINKED,
  IN_PROGRESS__IDENTIFY_PAYMENTS__DISCONNECTED_BANK,
  IN_PROGRESS__IDENTIFY_PAYMENTS__NOT_DISCONNECTED_BANK,
  IN_PROGRESS__READY_FOR_VERIFICATION__DISCONNECTED_BANK,
  IN_PROGRESS__READY_FOR_VERIFICATION__NOT_DISCONNECTED_BANK,
  IN_PROGRESS__PUSHED_TO_SECONDARY_VERIFICATION__DISCONNECTED_BANK,
  IN_PROGRESS__PUSHED_TO_SECONDARY_VERIFICATION__NOT_DISCONNECTED_BANK,
  IN_PROGRESS__WAITING_FOR_INFORMATION__DISCONNECTED_BANK,
  IN_PROGRESS__WAITING_FOR_INFORMATION__NOT_DISCONNECTED_BANK,
  IN_PROGRESS__VOR_SENT,
  IN_PROGRESS__CALL_TO_VERIFY,
  IN_PROGRESS__ESCALATED_VOR,
  IN_PROGRESS__UNABLE_TO_VERIFY,
  IN_PROGRESS__WAITING_FOR_PAYMENT,
  ENROLLED__READY_FOR_SUBMISSION,
  ENROLLED__WAITING_FOR_PAYMENT__NOT_MISSING_PAYMENTS,
  ENROLLED__WAITING_FOR_PAYMENT__MISSING_PAYMENTS,
  ENROLLED__READY_FOR_VERIFICATION,
  ENROLLED__PUSHED_TO_SECONDARY_VERIFICATION,
  ENROLLED__WAITING_FOR_INFORMATION,
  ENROLLED__CALL_TO_VERIFY__MISSING_PAYMENTS,
  ENROLLED__VOR_SENT,
  ENROLLED__ESCALATED_VOR,
  ENROLLED__UNABLE_TO_VERIFY,
  UNENROLLED__VOR_REJECTED,
  STATUS_NOT_FOUND,
  CONGRATS,
  ENROLLED__WAITING_FOR_PAYMENT__DISCONNECTED_BANK,
  ENROLLED__READY_FOR_VERIFICATION__DISCONNECTED_BANK,
  ENROLLED__CALL_TO_VERIFY__DISCONNECTED_BANK,
  CAN_CALL_GET_PAYMENT_IN_PROGRESS,
} from 'types/rentReporting.constants';

export const getRentReportingStatuses = (
  userInfo,
  paymentsResponse,
  rentReportingAuth,
  isDisplayCMAlertMessage,
) => {
  const enrollmentStatus = rentReportingAuth?.enrollmentStatus;
  const rentReportingStatus =
    userInfo && userInfo.rentReporting && enrollmentStatus
      ? enrollmentStatus['status']
      : '';
  const rentReportingSubStatus =
    userInfo && userInfo.rentReporting && enrollmentStatus
      ? enrollmentStatus['subStatus']
      : '';
  const hasDisconnectedBank =
    paymentsResponse && paymentsResponse.hasDisconnectedBanks;
  const missingPayments =
    paymentsResponse && paymentsResponse.numMissingPayments;

  if (isDisplayCMAlertMessage) {
    return CONGRATS;
  } else if (rentReportingStatus === IN_PROGRESS) {
    if (rentReportingSubStatus === LANDLORD_DETAILS_NEEDED) {
      return IN_PROGRESS__LANDLORD_DETAILS_NEEDED;
    } else if (rentReportingSubStatus === RENTAL_DETAILS_NEEDED) {
      return IN_PROGRESS__RENTAL_DETAILS_NEEDED;
    } else if (rentReportingSubStatus === BANK_ACCOUNT_NOT_LINKED) {
      return IN_PROGRESS__BANK_ACCOUNT_NOT_LINKED;
    } else if (rentReportingSubStatus === BANK_ACCOUNT_LINKED) {
      return IN_PROGRESS__BANK_ACCOUNT_LINKED;
    } else if (rentReportingSubStatus === IDENTIFY_PAYMENTS) {
      if (hasDisconnectedBank) {
        return IN_PROGRESS__IDENTIFY_PAYMENTS__DISCONNECTED_BANK;
      } else {
        return IN_PROGRESS__IDENTIFY_PAYMENTS__NOT_DISCONNECTED_BANK;
      }
    } else if (rentReportingSubStatus === READY_FOR_VERIFICATION) {
      if (hasDisconnectedBank) {
        return IN_PROGRESS__READY_FOR_VERIFICATION__DISCONNECTED_BANK;
      } else {
        return IN_PROGRESS__READY_FOR_VERIFICATION__NOT_DISCONNECTED_BANK;
      }
    } else if (rentReportingSubStatus === PUSHED_TO_SECONDARY_VERIFICATION) {
      if (hasDisconnectedBank) {
        return IN_PROGRESS__PUSHED_TO_SECONDARY_VERIFICATION__DISCONNECTED_BANK;
      } else {
        return IN_PROGRESS__PUSHED_TO_SECONDARY_VERIFICATION__NOT_DISCONNECTED_BANK;
      }
    } else if (rentReportingSubStatus === WAITING_FOR_INFORMATION) {
      if (hasDisconnectedBank) {
        return IN_PROGRESS__WAITING_FOR_INFORMATION__DISCONNECTED_BANK;
      } else {
        return IN_PROGRESS__WAITING_FOR_INFORMATION__NOT_DISCONNECTED_BANK;
      }
    } else if (rentReportingSubStatus === VOR_SENT) {
      return IN_PROGRESS__VOR_SENT;
    } else if (rentReportingSubStatus === CALL_TO_VERIFY) {
      return IN_PROGRESS__CALL_TO_VERIFY;
    } else if (rentReportingSubStatus === ESCALATED_VOR) {
      return IN_PROGRESS__ESCALATED_VOR;
    } else if (rentReportingSubStatus === UNABLE_TO_VERIFY) {
      return IN_PROGRESS__UNABLE_TO_VERIFY;
    } else if (rentReportingSubStatus === WAITING_FOR_PAYMENT) {
      return IN_PROGRESS__WAITING_FOR_PAYMENT;
    } else {
      return STATUS_NOT_FOUND;
    }
  } else if (rentReportingStatus === ENROLLED) {
    if (rentReportingSubStatus === READY_FOR_SUBMISSION) {
      return ENROLLED__READY_FOR_SUBMISSION;
    } else if (
      rentReportingSubStatus === WAITING_FOR_PAYMENT &&
      hasDisconnectedBank
    ) {
      return ENROLLED__WAITING_FOR_PAYMENT__DISCONNECTED_BANK;
    } else if (
      rentReportingSubStatus === WAITING_FOR_PAYMENT &&
      missingPayments === 0
    ) {
      return ENROLLED__WAITING_FOR_PAYMENT__NOT_MISSING_PAYMENTS;
    } else if (
      rentReportingSubStatus === WAITING_FOR_PAYMENT &&
      missingPayments > 0
    ) {
      return ENROLLED__WAITING_FOR_PAYMENT__MISSING_PAYMENTS;
    } else if (rentReportingSubStatus === READY_FOR_VERIFICATION) {
      if (hasDisconnectedBank) {
        return ENROLLED__READY_FOR_VERIFICATION__DISCONNECTED_BANK;
      }
      return ENROLLED__READY_FOR_VERIFICATION;
    } else if (rentReportingSubStatus === PUSHED_TO_SECONDARY_VERIFICATION) {
      return ENROLLED__PUSHED_TO_SECONDARY_VERIFICATION;
    } else if (rentReportingSubStatus === WAITING_FOR_INFORMATION) {
      return ENROLLED__WAITING_FOR_INFORMATION;
    } else if (
      rentReportingSubStatus === CALL_TO_VERIFY &&
      hasDisconnectedBank
    ) {
      return ENROLLED__CALL_TO_VERIFY__DISCONNECTED_BANK;
    } else if (
      rentReportingSubStatus === CALL_TO_VERIFY &&
      missingPayments > 0
    ) {
      return ENROLLED__CALL_TO_VERIFY__MISSING_PAYMENTS;
    } else if (rentReportingSubStatus === VOR_SENT) {
      return ENROLLED__VOR_SENT;
    } else if (rentReportingSubStatus === ESCALATED_VOR) {
      return ENROLLED__ESCALATED_VOR;
    } else if (rentReportingSubStatus === UNABLE_TO_VERIFY) {
      return ENROLLED__UNABLE_TO_VERIFY;
    } else {
      return STATUS_NOT_FOUND;
    }
  } else if (rentReportingStatus === UNENROLLED) {
    if (rentReportingSubStatus === VOR_REJECTED) {
      return UNENROLLED__VOR_REJECTED;
    } else {
      return STATUS_NOT_FOUND;
    }
  } else {
    return STATUS_NOT_FOUND;
  }
};

export const canCallGetPayments = props => {
  const { rentReporting } = props;
  const enrollStatusEnum = rentReporting?.status;
  const enrollSubStatusEnum = rentReporting?.subStatus;
  if (enrollStatusEnum === ENROLLED) {
    return true;
  }
  if (enrollStatusEnum === IN_PROGRESS) {
    return CAN_CALL_GET_PAYMENT_IN_PROGRESS.includes(enrollSubStatusEnum);
  }
  return false;
};
