import {
  FEATURED_AD_GET_REQUEST,
  FEATURED_AD_GET_SUCCESS,
  FEATURED_AD_GET_FAILURE,
} from 'types/featuredAd.constants';

import {
  LOGOUT,
  LOGIN_SUCCESS,
  INVALID_ROUTE_REDIRECT,
} from 'types/app.constants';
import { FEATURED_AD_PAYLOAD, PAYLOAD } from 'types/payload.constants';
import { mergePayloadChanges } from 'helpers/reducer.helper';

const initialState = {
  featuredAdsLoading: false,
  featuredAdsError: null,
  featuredAds: null,
};

export default function featuredAdReducer(state = initialState, action) {
  switch (action.type) {
    case FEATURED_AD_PAYLOAD:
    case LOGIN_SUCCESS:
    case PAYLOAD:
      return mergePayloadChanges(state, action);
    case FEATURED_AD_GET_REQUEST:
      return Object.assign({}, initialState, {
        loading: true,
      });
    case FEATURED_AD_GET_SUCCESS:
      return Object.assign({}, initialState, {
        featuredAds: action.featuredAds,
      });
    case FEATURED_AD_GET_FAILURE:
      return Object.assign({}, initialState, {
        error: action.error,
      });
    case LOGOUT:
    case INVALID_ROUTE_REDIRECT:
      return Object.assign({}, initialState);
    default:
      return state;
  }
}
