import {
  getMrphPageFormFactor,
  getSiteAttributeValue,
} from 'helpers/metadataLogic.helpers';
import {
  getCStrack,
  getItem,
  getRefCode,
  testLocalStorage,
} from 'helpers/localstorage';
import { 
  getPropFromInitialRefCookie,
  resolveBooleanToYesOrNo 
} from 'helpers/tracking.helpers';
import { getCookieValue } from 'helpers/cookie.helper';
import { getTrackingInfo } from "externals/_tracking/optimizely/optimizely_metadata";
import { getMarketingAttributes, getPromoCodeTracking } from 'helpers/marketing.helper';

export const buildBaselineMixpanelAttributes = additionalAttrs => {
  let siteAttrs = {
    'Page Form Factor': getMrphPageFormFactor(),
    Site: getSiteAttributeValue(),
    'Test SSN User': getItem('isTestAccount'),
    isEncryptedEmail: resolveBooleanToYesOrNo(getItem('isEncryptedEmail')),
    promocode: getPromoCodeTracking(),
    'Event Time': new Date().toUTCString(),
    Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    'Landing Page Url': decodeURIComponent(getCookieValue('landing_page_url')),
    'Initial Referrer Url': getPropFromInitialRefCookie(),
    'Initial Referrer Url Domain': getPropFromInitialRefCookie('initial_referrer_domain')
  };

  try {
    const csTrack = getCStrack();
    if (testLocalStorage() === null) {
      siteAttrs = {
        ...siteAttrs,
        VisitorSessionID: getCookieValue('vid'),
        'Session Identifier': getCStrack(),
        'Ref Code': getRefCode(),
      };
    } else if (csTrack !== null) {
      siteAttrs = {
        ...siteAttrs,
        'Session Identifier': getCStrack(),
        'Ref Code': getRefCode(),
      };
    }
  } catch (e) {
    console.log('ERROR', e);
  }

  const optAttrs = getTrackingInfo();
  const mktAttrs = getMarketingAttributes();

  return Object.assign({}, siteAttrs, optAttrs, mktAttrs, additionalAttrs);
};
