export const CREDIT_CARD_OFFER = 'CREDIT_CARD';
export const PERSONAL_LOAN_OFFER = 'PERSONAL_LOAN';
export const AUTO_LOAN_OFFER = 'AUTO_LOAN';
export const CREDIT_REPAIR_OFFER = 'CREDIT_REPAIR';
export const SEE_ALL_CARDS = 'SEE_ALL_CARDS';
export const HOME_PAGE_CONTAINER = 'HOME_PAGE';
export const CREDIT_CARD_CONTAINER = 'CREDIT_CARDS';
export const CREDIT_CARD_CATEGORY_CONTAINER = 'CREDIT_CARD_CATEGORIES';
export const RANK_RPC = 'RPC';
export const RANK_RPC_CS_USER_SEGMENT = 'RPC_CS_USER_SEGMENT';
export const CAPITAL_ONE_OFFER = 'CAPITAL_ONE';
export const CREDIT_ONE_OFFER = 'CREDIT_ONE_BANK';
export const REWARD_TYPE_CASH_BACK = 'CASH_BACK';
export const REWARD_TYPE_MILES = 'MILES';
export const REWARD_TYPE_POINTS = 'POINTS';
export const BUSINESS_LOAN_OFFER = 'BUSINESS_LOAN';
export const AUTO_INSURANCE = 'AUTO_INSURANCE';
export const LIFE_INSURANCE = 'LIFE_INSURANCE';
export const HOME_INSURANCE = 'HOME_INSURANCE';
