export const PREMIUM_PLAN_NAME_ADVANCED_CREDIT = 'Advanced Credit';
export const PREMIUM_PLAN_NAME_PRO_CREDIT = 'Pro Credit';
export const PREMIUM_PLAN_NAME_PLATINUM_PROTECTION = 'Platinum Protection';
export const PREMIUM_PLAN_NAME_PLATINUM = 'Platinum';
export const PREMIUM_PLAN_NAME_ID_PROTECTION = 'Identity Protection';
export const PREMIUM_PLAN_NAME_SESAME_PULSE = 'Sesame Pulse';
export const PREMIUM_PLAN_NAME_SESAME_TURBO = 'Sesame Turbo';
export const PREMIUM_PLAN_NAME_FREE_TRIAL_TURBO = 'Free Trial Turbo';
export const PREMIUM_PLAN_NAME_FREE_TRIAL_TURBO_FULL_PRICE =
  'Sesame Turbo w/ Free trial';
export const PREMIUM_PLUS = 'Premium +';
export const PREMIUM_PLAN_NAME_CROA = 'CROA';
export const PREMIUM_INTRO_PLAN = 'Sesame Turbo 6Intro 1599';

export const PREMIUM_FEATURE_CREDIT_REPORT = 'CREDITREPORT';
export const PREMIUM_FEATURE_RENT_REPORTING = 'RENTREPORTING';
export const PREMIUM_FEATURE_ID_THEFT_PROTECTION = 'IDTHEFTPROTECTION';
export const PREMIUM_FEATURE_ADVANCED_MONITORING = 'MONITORING';
export const PREMIUM_FEATURE_LIVE_EXPERTS = 'LIVEEXPERTS';
export const PREMIUM_FEATURE_WALLET_PROTECTION = 'WALLETPROTECTION';
export const PREMIUM_FEATURE_ID_MONITORING = 'IDMONITORING';
export const PREMIUM_FEATURE_INSTANT_OFFERS = 'INSTANTOFFERS';

export const PREMIUM_FEATURE_KEY_WALLET = 'premiumWalletProtection';
export const PREMIUM_FEATURE_KEY_ID_THEFT_PROTECTION =
  'premiumIdTheftProtection';
export const PREMIUM_FEATURE_KEY_CREDIT_DISPUTE_RESOLUTION =
  'premiumCreditDisputeResolution';
export const PREMIUM_FEATURE_KEY_LIVE_SUPPORT = 'premiumLiveSupport';
export const PREMIUM_FEATURE_KEY_ID_MONITORING = 'premiumIdMonitoring';
export const SUBSCRIPTION_CYCLE_MONTHLY = 'Monthly';
export const SUBSCRIPTION_CYCLE_SEMI_ANNUALLY = 'Semi Annually';
export const SUBSCRIPTION_CYCLE_ANNUALLY = 'Annually';

export const DEFAULT_PLAN = {
  planName: 'Premium',
  menuPlanName: 'Premium',
  pricing: '--.--',
  price: null,
  isSubscription: true,
  bullets: [],
};
