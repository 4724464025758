import {
  buildBankingApiFetchOptionsWithHeaders,
  buildFetchOptionsWithHeaders,
  standardizedFetchHandler,
} from 'externals/_services/service_helpers';
import {
  buildBoosterWaitlist,
  buildOptInSecuredCard,
  buildPayrollToken,
  buildSecuredCardEndpoint,
} from 'api-urls';

export const getPayrollSwitchAtomicPublicToken = async token => {
  const requestOptions = buildBankingApiFetchOptionsWithHeaders(token, {
    method: 'POST',
  });

  return standardizedFetchHandler(buildPayrollToken(), requestOptions);
};

export async function enrollBoosterWaitlist(body) {
  const fetchOpts = buildFetchOptionsWithHeaders({
    method: 'POST',
    body,
  });

  return standardizedFetchHandler(buildBoosterWaitlist(), fetchOpts);
}

export async function postOptInToBooster(token) {
  const fetchOpts = buildBankingApiFetchOptionsWithHeaders(token, {
    method: 'POST',
    body: {},
  });

  return standardizedFetchHandler(buildOptInSecuredCard(), fetchOpts);
}

export async function postCreateSecuredBoosterCard(cashToken, body) {
  const fetchOpts = buildBankingApiFetchOptionsWithHeaders(cashToken, {
    method: 'POST',
    body,
  });

  return standardizedFetchHandler(buildSecuredCardEndpoint(), fetchOpts);
}
