import {
  REGISTRATION_MKT_SRC,
  SESION_MKT_SRC,
} from 'pages/SignupPage/types/marketingAttribution.constants';

const RESURRECTED_USER_EVENT = 'resurrected-user-';
const SIGNUP_CONVERSION_EVENT = 'signup-conversion-';
const CASH_CONVERSION_EVENT = 'cash-conversion-';
const INVISIBLE_BUILDER_EVENT = 'invisible-builder-registration-';
const INVISIBLE_SIGNUP = 'invisible-registration-';
const ALWAYS_TRIGGER = 'always';

const formatGTMSource = source => {
  return source.toLowerCase().trim().replace(' ', '-');
};

const getGTMTrigger = (trigger, source) => {
  return trigger + formatGTMSource(source);
};

const getGTMVariables = (userInfo, marketingAttr) => {
  if (userInfo) {
    return {
      email: userInfo.email,
      state: userInfo.primaryAddress ? userInfo.primaryAddress.state : '',
      zipcode: userInfo.primaryAddress ? userInfo.primaryAddress.zip : '',
      marketingSource: userInfo[marketingAttr],
    };
  }

  return {};
};

const basicPixelTrigger = (userInfo, attribution, eventType) => {
  if (window.dataLayer) {
    window.dataLayer.push(getGTMVariables(userInfo, attribution));
    window.dataLayer.push({
      event: getGTMTrigger(eventType, ALWAYS_TRIGGER),
    });
    if (userInfo && userInfo[attribution]) {
      window.dataLayer.push({
        event: getGTMTrigger(eventType, userInfo[attribution]),
      });
    }
  }
}

export const triggerClientPixelForInvisibleRegistration = userInfo => {
  console.log("Triggered Invisible Registration Pixel");
  basicPixelTrigger(userInfo, REGISTRATION_MKT_SRC, INVISIBLE_SIGNUP);
}

export const triggerClientPixelForInvisibleBuilderRegistration = userInfo => {
  console.log("Triggered Invisible Builder Registration Pixel");
  basicPixelTrigger(userInfo, REGISTRATION_MKT_SRC, INVISIBLE_BUILDER_EVENT);
}

export const triggerClientPixel = userInfo => {
  if (window.dataLayer) {
    window.dataLayer.push(getGTMVariables(userInfo, REGISTRATION_MKT_SRC));
    window.dataLayer.push({
      event: getGTMTrigger(SIGNUP_CONVERSION_EVENT, ALWAYS_TRIGGER),
    });

    if (userInfo && userInfo.marketingSource) {
      window.dataLayer.push({
        event: getGTMTrigger(
          SIGNUP_CONVERSION_EVENT,
          userInfo[REGISTRATION_MKT_SRC],
        ),
      });
    }
  }
};

export const triggerCashClientPixel = (userInfo, attribution) => {
  if (window.dataLayer) {
    window.dataLayer.push(getGTMVariables(userInfo, attribution));
    window.dataLayer.push({
      event: getGTMTrigger(CASH_CONVERSION_EVENT, ALWAYS_TRIGGER),
    });
    if (userInfo && userInfo[attribution]) {
      window.dataLayer.push({
        event: getGTMTrigger(CASH_CONVERSION_EVENT, userInfo[attribution]),
      });
    }
  }
};

export const triggerResurrectedUser = userInfo => {
  if (window.dataLayer) {
    window.dataLayer.push(getGTMVariables(userInfo, SESION_MKT_SRC));
    window.dataLayer.push({
      event: getGTMTrigger(RESURRECTED_USER_EVENT, ALWAYS_TRIGGER),
    });
  }
};
