import { mergePayloadChanges } from 'helpers/reducer.helper';
import { LOGIN_SUCCESS, LOGOUT } from 'types/app.constants';
import {
  CREDIT_BUILDER_ENROLL_FAIL,
  CREDIT_BUILDER_ENROLL_START,
  CREDIT_BUILDER_ENROLL_SUCCESS,
  STORE_CREDIT_BUILDER_SECURITIZATION,
} from 'types/cashBuilder.types';
import {
  ALL_CARD_DETAILS_PAYLOAD,
  BANKING_CONFIG_PAYLOAD,
  CASH_PIN_TOKEN_PAYLOAD,
  PAYLOAD,
  RECENT_BUILDER_TRANSACTIONS_PAYLOAD,
  SECURED_CARD_SETTINGS_PAYLOAD,
  SECURED_CARD_STATEMENTS_PAYLOAD,
} from 'types/payload.constants';
import {
  CASH_ACCOUNT_PAYLOAD,
  CASH_CARD_DETAILS_ERROR,
  CASH_CARD_DETAILS_REQUEST,
  CASH_CARD_DETAILS_SUCCESS,
  CASH_CARD_PIN_ERROR,
  CASH_CARD_PIN_REQUEST,
  CASH_CARD_PIN_SUCCESS,
  CASH_CARD_SET_PIN_ERROR,
  CASH_CARD_SET_PIN_FEEDBACK,
  CASH_CARD_SET_PIN_SUCCESS,
  CASH_ERROR_BANK_TRANSFER,
  CASH_ERROR_RECENT_TRANSACTIONS,
  CASH_ERROR_TRANSACTION_PAGE,
  CASH_LOAD_BANK_TRANSFER,
  CASH_LOAD_RECENT_TRANSACTIONS,
  CASH_LOAD_TRANSACTION_PAGE,
  CASH_SUCCESS_BANK_TRANSFER,
  CASH_SUCCESS_RECENT_TRANSACTIONS,
  CASH_SUCCESS_TRANSACTION_PAGE,
  CLEAR_SESAME_CASH_ERRORS,
  SET_CASH_LOADED,
  SET_JUMIO_VERIFIED,
  SET_UTILIZATION_VALUE,
  SHOW_MENUBAR_ON_ENROLLMENT,
} from 'types/sesameCash.constants';

const initialState = {
  cashLoading: false,
  cashLoaded: false,

  cashPinToken: null,
  cashPinTokenLoading: null,
  cashPinTokenSuccess: null,
  cashPinTokenError: null,
  cashPinTokenExpireTime: null,

  allCardDetails: null,
  allCardDetailsLoading: null,
  allCardDetailsError: null,

  cardDetails: null,
  cardDetailsLoading: null,
  cardDetailsError: null,
  cashAccount: null,
  cashAccountLoading: null,
  cashAccountError: null,
  cashCardPin: null,
  cashCardPinLoading: null,
  cashCardPinError: null,
  cashSetCardPinSuccess: null,
  cashSetCardPinError: null,
  cashSetCardPinFeedback: null,

  transactionsRecent: null,
  transactionsRecentLoading: false,
  transactionsRecentError: null,

  bankTransfers: null,
  bankTransfersLoading: false,
  bankTransfersError: null,

  pageStartDate: null,
  pagedTransactions: null,
  pagedTransactionsLoading: null,
  pagedTransactionsError: null,

  creditBuilderAmount: null,
  creditBuilderEnrollLoading: false,

  recentSecuredCardStatements: null,
  recentSecuredCardStatementsLoading: false,
  recentSecuredCardStatementsError: null,

  securedCardSettings: null,
  securedCardSettingsLoading: false,
  securedCardSettingsError: null,

  recentBuilderTransactionLoading: false,
  recentBuilderTransaction: null,
  recentBuilderTransactionError: null,

  bankingConfig: null,
  bankingConfigLoading: false,
  bankingConfigError: null,
  jumioVerified: false,

  maxUtilizationAmount: null,
  showMenuBarOnEnrollment: false,
};

const sesameCashReducer = function (state = initialState, action) {
  switch (action.type) {
    case CASH_PIN_TOKEN_PAYLOAD:
    case ALL_CARD_DETAILS_PAYLOAD:
    case SECURED_CARD_STATEMENTS_PAYLOAD:
    case SECURED_CARD_SETTINGS_PAYLOAD:
    case RECENT_BUILDER_TRANSACTIONS_PAYLOAD:
    case BANKING_CONFIG_PAYLOAD:
    case LOGIN_SUCCESS:
    case CASH_ACCOUNT_PAYLOAD:
    case PAYLOAD:
      return mergePayloadChanges(state, action);
    case SET_CASH_LOADED:
      return {
        ...state,
        cashLoaded: action.cashLoaded,
        cashLoading: action.cashLoading,
      };
    case CASH_CARD_DETAILS_REQUEST:
      return Object.assign({}, state, {
        cardDetailsLoading: true,
      });
    case CASH_CARD_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        cardDetailsLoading: false,
        cardDetails: action.cardDetails,
      });
    case CASH_CARD_DETAILS_ERROR:
      return Object.assign({}, state, {
        cardDetailsError: action.err,
      });
    case CLEAR_SESAME_CASH_ERRORS:
      return Object.assign({}, state, {
        cashAccountError: null,
        allCardDetailsError: null,
        cashPinTokenError: null,
      });

    case CASH_CARD_PIN_REQUEST:
      return Object.assign({}, state, { cashCardPinLoading: true });
    case CASH_CARD_PIN_SUCCESS:
      return Object.assign({}, state, {
        cashCardPin: action.pinResult,
        cashCardPinLoading: false,
      });
    case CASH_CARD_PIN_ERROR:
      return Object.assign({}, state, {
        cashCardPinError: action.err,
        cashCardPinLoading: false,
      });
    case CASH_CARD_SET_PIN_SUCCESS:
      return Object.assign({}, state, {
        cashSetCardPinSuccess: true,
      });
    case CASH_CARD_SET_PIN_ERROR:
      return Object.assign({}, state, {
        cashSetCardPinSuccess: false,
        cashSetCardPinError: action.err,
      });
    case CASH_CARD_SET_PIN_FEEDBACK:
      return Object.assign({}, state, {
        cashSetCardPinFeedback: action.feedback,
      });
    case CASH_LOAD_RECENT_TRANSACTIONS:
      return Object.assign({}, state, {
        transactionsRecent: null,
        transactionsRecentLoading: true,
        transactionsRecentError: null,
      });
    case CASH_SUCCESS_RECENT_TRANSACTIONS:
      return Object.assign({}, state, {
        transactionsRecent: action.payload,
        transactionsRecentLoading: false,
        transactionsRecentError: null,
      });
    case CASH_ERROR_RECENT_TRANSACTIONS:
      return Object.assign({}, state, {
        transactionsRecent: null,
        transactionsRecentLoading: false,
        transactionsRecentError: action.err,
      });
    case CASH_LOAD_TRANSACTION_PAGE:
      return Object.assign({}, state, {
        pageStartDate: action.pageStartDate,
        pagedTransactions: state.pagedTransactions,
        pagedTransactionsLoading: true,
        pagedTransactionsError: null,
      });
    case CASH_SUCCESS_TRANSACTION_PAGE:
      return Object.assign({}, state, {
        pagedTransactions: action.payload,
        pagedTransactionsLoading: false,
        pagedTransactionsError: null,
      });
    case CASH_ERROR_TRANSACTION_PAGE:
      return Object.assign({}, state, {
        pagedTransactions: null,
        pagedTransactionsLoading: false,
        pagedTransactionsError: action.err,
      });

    case CASH_LOAD_BANK_TRANSFER:
      return Object.assign({}, state, {
        bankTransfers: null,
        bankTransfersLoading: true,
        bankTransfersError: null,
      });
    case CASH_SUCCESS_BANK_TRANSFER:
      return Object.assign({}, state, {
        bankTransfers: action.payload,
        bankTransfersLoading: false,
        bankTransfersError: null,
      });
    case CASH_ERROR_BANK_TRANSFER:
      return Object.assign({}, state, {
        bankTransfers: null,
        bankTransfersLoading: false,
        bankTransfersError: action.err,
      });
    case STORE_CREDIT_BUILDER_SECURITIZATION:
      return Object.assign({}, state, {
        creditBuilderAmount: action.creditBuilderAmount,
      });
    case CREDIT_BUILDER_ENROLL_START:
      return Object.assign({}, state, {
        creditBuilderEnrollLoading: true,
      });
    case CREDIT_BUILDER_ENROLL_SUCCESS:
      return Object.assign({}, state, {
        creditBuilderEnrollLoading: false,
      });
    case CREDIT_BUILDER_ENROLL_FAIL:
      return Object.assign({}, state, {
        creditBuilderEnrollLoading: false,
      });
    case SET_UTILIZATION_VALUE:
      return Object.assign({}, state, {
        maxUtilizationAmount: action.maxUtilizationAmount,
      });
    case SET_JUMIO_VERIFIED:
      return Object.assign({}, state, {
        jumioVerified: action.payload,
      });
    case SHOW_MENUBAR_ON_ENROLLMENT:
      return Object.assign({}, state, {
        showMenuBarOnEnrollment: action.showMenuBarOnEnrollment,
      });
    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default sesameCashReducer;
