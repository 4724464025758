import {
  GETIDP_SUCCESS,
  GETIDP_REQUEST,
  GETIDP_FAILURE,
  PUTIDP_SUCCESS,
  PUTIDP_REQUEST,
  PUTIDP_FAILURE,
  CLEAR_ERROR,
} from 'types/idProtection.constants';

const initialState = {
  idProtectionAccounts: null,
  loading: null,
  error: null,
};

export default function idProtectionReducer(state = initialState, action) {
  switch (action.type) {
    case GETIDP_REQUEST:
      return { ...state, loading: true };
    case GETIDP_SUCCESS:
      return {
        idProtectionAccounts: action.accounts,
        loading: false,
        error: false,
      };

    case GETIDP_FAILURE:
      return {
        ...state,
        idProtectionAccounts: null,
        error: action.error,
        loading: false,
      };

    case PUTIDP_REQUEST:
      return { ...state, loading: true };
    case PUTIDP_SUCCESS:
      return { ...state, loading: false };

    case PUTIDP_FAILURE:
      return { ...state, loading: false, error: action.error };
    case CLEAR_ERROR:
      return { ...state, error: null };
    default:
      return state;
  }
}
