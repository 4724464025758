const helpers = {
  buildReactivatePremium: c => {
    const { sessionId, serviceId, priceText } = c ?? {};
    if (!sessionId || !serviceId) {
      const sampleConfig = {
        sessionId: '1234-1234',
        serviceId: 'WEB_TURBO_3BGATING_7_DAY_FREE_TRIAL_957_MONTHLY_TU',
      };
      throw new Error(
        `sessionId and serviceId required\n\n helpers.buildReactivatePremium(${JSON.stringify(
          sampleConfig,
          null,
          2,
        )})`,
      );
    }

    const redirectUri = encodeURIComponent(
      `/reactivatePremium/?sessionId=${sessionId}&sessionIdentifier=${sessionId}&serviceId=${serviceId}&priceText=${
        priceText ?? '5.00'
      }`,
    );
    const fullUri = `/mobileTransition?sessionId=${sessionId}&sessionIdentifier=${sessionId}&redirectUrl=${redirectUri}`;
    return fullUri;
  },
  buildPrecheckoutMobile: c => {
    const { sessionId, serviceId } = c ?? {};
    if (!sessionId || !serviceId) {
      const sampleConfig = {
        sessionId: '1234-1234',
        serviceId: 'WEB_TURBO_3BGATING_7_DAY_FREE_TRIAL_957_MONTHLY_TU',
      };
      throw new Error(
        `sessionId and serviceId required\n\n helpers.buildPrecheckoutMobile(${JSON.stringify(
          sampleConfig,
          null,
          2,
        )})`,
      );
    }

    const fullUri = `/precheckoutMobile?sessionId=${sessionId}&sessionIdentifier=${sessionId}&serviceId=${serviceId}`;
    return fullUri;
  },
};

window.helpers = helpers;
