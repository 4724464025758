import moment from 'moment/moment';
import { getItem, setItem } from '../helpers/localstorage';

export const useMyPendingActions = () => {
  let pendingActions = JSON.parse(getItem('pendingActions'));
  const today = moment().format('MM-DD-YYYY');

  if (!pendingActions) {
    pendingActions = {
      nextDisplay: {
        startDate: moment().format('MM-DD-YYYY'),
        endDate: moment().endOf('month').format('MM-DD-YYYY'),
      },
      lastSeen: moment().format('MM-DD-YYYY'),
    };
    setItem('pendingActions', JSON.stringify(pendingActions));
  } else {
    if (moment(today).isAfter(pendingActions.nextDisplay.endDate)) {
      setNextDisplayMyActions();
    }
  }

  function showPendingActions() {
    return (
      pendingActions &&
      moment(today, 'MM-DD-YYYY').isBetween(
        moment(pendingActions.nextDisplay.startDate, 'MM-DD-YYYY'),
        moment(pendingActions.nextDisplay.endDate, 'MM-DD-YYYY'),
        undefined,
        '[]',
      )
    );
  }

  function setNextDisplayMyActions() {
    if (
      !moment().isBefore(
        moment(pendingActions.nextDisplay.startDate, 'MM-DD-YYYY'),
      )
    ) {
      setItem(
        'pendingActions',
        JSON.stringify({
          nextDisplay: {
            startDate: moment().add(1, 'months').date(1).format('MM-DD-YYYY'),
            endDate: moment().add(1, 'months').date(8).format('MM-DD-YYYY'),
          },
          lastSeen: moment().format('MM-DD-YYYY'),
        }),
      );
    }
  }

  return {
    showPendingActions,
  };
};
