/* eslint-disable */

(function () {
  const pathname = window.location.pathname;
  if (!pathname.includes('ocf')) {
    let tag = document.createElement('script');
    tag.src =
      'https://github.com/Cognigy/WebchatWidget/releases/download/v2.58.0/webchat.js';
    tag.type = 'text/javascript';
    tag.async = 'true';
    let applyTag = document.getElementsByTagName('script')[0];
    applyTag.parentNode.insertBefore(tag, applyTag);
  }
})();
