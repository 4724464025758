import { lazy } from 'react';

function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export const AlertRoutes = lazy(() =>
  retry(() => import('routes/Alerts.routes')),
);
export const AlexaPinView = lazy(() =>
  retry(() => import('pages/AlexaIntegration')),
);
export const CashRoutes = lazy(() => retry(() => import('routes/Cash.routes')));
export const ChangeEmail = lazy(() =>
  retry(() => import('pages/Public/ChangeEmail')),
);
export const FreeOnBoarding = lazy(() =>
  import('pages/FreeUserOnboarding').then(module => ({
    default: module.FreeUserOnboarding,
  })),
);
export const Checkout = lazy(() => retry(() => import('pages/Checkout')));
export const CreditReports = lazy(() =>
  retry(() => import('pages/CreditReports')),
);
export const CreditReports3B = lazy(() =>
  retry(() => import('pages/CreditReport3B')),
);
export const CreditReportTU = lazy(() =>
  retry(() => import('pages/CreditReportTU')),
);
export const CreditLockLearnMore = lazy(() =>
  retry(() => import('pages/CreditLockLearnMore')),
);
export const DisputeResolution = lazy(() =>
  retry(() => import('pages/DisputeResolution')),
);
export const DisputeResolutionHybrid = lazy(() =>
  retry(() => import('pages/DisputeResolutionHybrid')),
);
export const DisputeResolutionPrintLetter = lazy(() =>
  retry(() => import('pages/DisputeResolutionPrintLetter')),
);
export const CreditReportTUPrint = lazy(() =>
  retry(() => import('pages/CreditReportTUPrint')),
);
export const CreditReportTULetter = lazy(() =>
  retry(() => import('pages/CreditReportTULetter')),
);
export const CreditReportTabs = lazy(() =>
  retry(() => import('pages/CreditReportTabs')),
);
export const CreditReportTabsHybrid = lazy(() =>
  retry(() => import('pages/CreditReportTabsHybrid')),
);
export const CreditReportPrintLetter = lazy(() =>
  retry(() => import('pages/CreditReportPrintLetter')),
);
export const CreditReportHybrid = lazy(() =>
  retry(() => import('pages/CreditReportHybrid')),
);
export const CreditReportHybridAction = lazy(() =>
  retry(() => import('pages/CreditReportHybridAction')),
);
export const CreditReportHybridCheckout = lazy(() =>
  retry(() => import('pages/CreditReportHybridCheckout')),
);
export const CreditReportTUHybrid = lazy(() =>
  retry(() => import('pages/CreditReportTUHybrid')),
);
export const FactorsMultiRoute = lazy(() =>
  retry(() => import('routes/Factor.routes')),
);
export const Home = lazy(() => retry(() => import('pages/RedesignDashboard')));
export const MarketplaceMultiRoute = lazy(() =>
  retry(() => import('routes_brand/Marketplace.routes')),
);
export const OcfMultiRoute = lazy(() =>
  retry(() => import('routes/Ocf.routes')),
);
export const NeedHelpLoginRoutes = lazy(() =>
  retry(() => import('routes/NeedHelpLogin.routes')),
);
export const PreCheckout = lazy(() => retry(() => import('pages/PreCheckout')));
export const PreCheckoutMobile = lazy(() =>
  retry(() => import('pages/PreCheckoutMobile')),
);
export const PremiumRoutes = lazy(() =>
  retry(() => import('routes/Premium.routes')),
);
export const PublicPremiumRoutes = lazy(() =>
  retry(() => import('routes/PublicPremium.routes')),
);
export const Profile = lazy(() => retry(() => import('pages/Profile/Profile')));
export const Signup1 = lazy(() =>
  retry(() => import('pages/SignupPage/Signup1Defaulted')),
);
export const Signup2 = lazy(() =>
  retry(() => import('pages/SignupPage/Signup2Defaulted')),
);
export const SignupCashTC = lazy(() =>
  retry(() => import('pages/SignupPage/SignupCashTC')),
);
export const OneClickSignup1 = lazy(() =>
  retry(() => import('pages/SignupPage/OneClickSignup/pages/OneClickSignup1')),
);
export const OneClickSignup1Verification = lazy(() =>
  retry(() =>
    import('pages/SignupPage/OneClickSignup/pages/Signup1Verification'),
  ),
);
export const OneClickSignup1Pixel = lazy(() =>
  retry(() => import('pages/SignupPage/OneClickSignup/pages/Signup1Pixel')),
);
export const OneClickSignupFingerPrintVerification = lazy(() =>
  retry(() =>
    import(
      'pages/SignupPage/OneClickSignup/pages/OneClickSignupFingerPrintVerification'
    ),
  ),
);
export const OneClickSignup2 = lazy(() =>
  retry(() => import('pages/SignupPage/OneClickSignup/pages/OneClickSignup2')),
);

export const DMCampaign = lazy(() =>
  retry(() => import('pages/SignupPage/DMCampaign/pages/DMCampaign')),
);
export const PrefilledSignup1 = lazy(() =>
  retry(() =>
    import('pages/SignupPage/PrefilledSignup/pages/PrefilledSignup1'),
  ),
);
export const PrefilledSignup2 = lazy(() =>
  retry(() =>
    import('pages/SignupPage/PrefilledSignup/pages/PrefilledSignup2'),
  ),
);
export const AffiliateSignup = lazy(() =>
  retry(() =>
    import('pages/SignupPage/AffiliateSignup/pages/AffiliateSignup1'),
  ),
);
export const RentReportingEmailLanding = lazy(() =>
  retry(() => import('pages/RentReportingEmailLanding')),
);
export const RentReportingDocumentsEmailLanding = lazy(() =>
  retry(() => import('pages/RentReportingDocumentsEmailLanding')),
);
export const PreQualRentReporting = lazy(() =>
  retry(() => import('pages/PreQualRentReporting')),
);
export const LiveExperts = lazy(() => retry(() => import('pages/LiveExperts')));
export const RentPaymentReportingHome = lazy(() =>
  retry(() => import('pages/RentPaymentReportingHome')),
);

export const PaymentPendingSubscriptionRoutes = lazy(() =>
  retry(() => import('routes/PaymentPendingSubscription.routes')),
);

export const ClaimOfferRoutes = lazy(() =>
  retry(() => import('routes/ClaimOfferRoutes.routes')),
);

//Modals
export const PersonalLoanPrequalificationFlow = lazy(() =>
  retry(() => import('partial/PersonalLoanPrequalificationFlow')),
);
export const CreditCardPrequalificationFlow = lazy(() =>
  retry(() => import('partial/CreditCardPrequalificationFlow')),
);
export const PremiumStarterPack = lazy(() =>
  retry(() => import('pages/PremiumStarterPack')),
);

export const Goals = lazy(() => retry(() => import('pages/Goals')));

export const CreditManager = lazy(() =>
  retry(() => import('pages/CreditManager')),
);

export const GoalDetail = lazy(() => retry(() => import('pages/GoalDetail')));

export const MobileOptimizedGoalDetail = lazy(() =>
  retry(() => import('pages/GoalActionDetailMobileController')),
);

export const AtmLocators = lazy(() => retry(() => import('pages/AtmLocators')));

export const HybridAtmLocators = lazy(() =>
  retry(() => import('pages/AtmLocatorsHybrid')),
);

export const QuarterlyReport = lazy(() =>
  retry(() => import('pages/QuarterlyReport')),
);
export const ResetPassword = lazy(() =>
  retry(() => import('pages/Public/PasswordReset/pages/ResetPassword')),
);
export const CFSBPrivacyDisclaimer = lazy(() =>
  retry(() => import('partial/disclaimer_modals/CFSBPrivacyDisclaimer')),
);
export const LegalDocumentModal = lazy(() =>
  retry(() => import('partial/disclaimer_modals/LegalDocumentModal')),
);
export const PreScreen = lazy(() =>
  retry(() => import('partial/disclaimer_modals/Prescreen')),
);
export const PrivacyDisclaimer = lazy(() =>
  retry(() => import('partial/disclaimer_modals/PrivacyDisclaimer')),
);
export const TermsDisclaimer = lazy(() =>
  retry(() => import('partial/disclaimer_modals/TermsOfUse')),
);
export const PremiumDiscountTerms = lazy(() =>
  retry(() => import('partial/disclaimer_modals/PremiumDiscountTerms/index')),
);
export const PremiumPlusTerms = lazy(() =>
  retry(() => import('partial/disclaimer_modals/PremiumPlusTerms/index')),
);
export const GradeDialController = lazy(() =>
  retry(() => import('pages/GradeDial')),
);
export const LogoutPage = lazy(() =>
  import('pages_logout').then(module => ({ default: module.LogoutPage })),
);
export const PlusCheckoutMobile = lazy(() =>
  import('pages/PlusCheckoutMobile').then(module => ({
    default: module.PlusCheckoutMobile,
  })),
);
export const PlusUpgradeMobile = lazy(() =>
  import('pages/PlusUpgradeMobile').then(module => ({
    default: module.PlusUpgradeMobile,
  })),
);
export const PlusDirectCheckout = lazy(() =>
  import('pages/PlusDirectCheckout').then(module => ({
    default: module.PlusDirectCheckout,
  })),
);
export const PlusDirectCheckoutMobile = lazy(() =>
  import('pages/PlusDirectCheckoutMobile').then(module => ({
    default: module.PlusDirectCheckoutMobile,
  })),
);
export const PlusPurchaseConfirmationLanding = lazy(() =>
  import('pages/PlusLanding/pages/PlusPurchaseConfirmationLanding').then(
    module => ({ default: module.PlusPurchaseConfirmationLanding }),
  ),
);
export const PlusCreditToolsUnlocklandingController = lazy(() =>
  import(
    'pages/PlusLanding/pages/PlusCreditToolsUnlocked/PlusCreditToolsUnlockController'
  ).then(module => ({
    default: module.PlusCreditToolsUnlocklandingController,
  })),
);
export const PlusUpgradeAndConvert = lazy(() =>
  import('pages/PlusUpgradeAndConvert').then(module => ({
    default: module.PlusUpgradeAndConvert,
  })),
);
export const PlusUpgradeAndConvertMobile = lazy(() =>
  import('pages/PlusUpgradeAndConvertMobile').then(module => ({
    default: module.PlusUpgradeAndConvertMobile,
  })),
);

export const LoginPage = lazy(() =>
  import('pages/LoginPage').then(module => ({ default: module.LoginPage })),
);
export const FatalError = lazy(() =>
  import('pages/FatalError/FatalErrorController').then(module => ({
    default: module.FatalError,
  })),
);
export const JumioRouteHandler = lazy(() =>
  import('pages/JumioRouteHandler/index').then(module => ({
    default: module.JumioRouteHandler,
  })),
);
export const UnsubscribeEmail = lazy(() =>
  import('pages/Ocf/pages/public/UnsubscribeEmail').then(module => ({
    default: module.UnsubscribeEmail,
  })),
);
export const RentPaymentReporting = lazy(() =>
  import('pages/RentPaymentReporting').then(module => ({
    default: module.RentPaymentReporting,
  })),
);
export const SesameCash2Enrollment = lazy(() =>
  import('pages/SesameCashEnrollment').then(module => ({
    default: module.SesameCash2Enrollment,
  })),
);
export const SesameCashEnrollment = lazy(() =>
  import('pages/SesameCashEnrollment').then(module => ({
    default: module.SesameCashEnrollment,
  })),
);
export const Cb20Enrollment = lazy(() =>
  import('pages/Cb20Enrollment').then(module => ({
    default: module.Cb20Enrollment,
  })),
);
export const MobileRouteHandler = lazy(() =>
  import('pages/MobileRouteHandler').then(module => ({
    default: module.MobileRouteHandler,
  })),
);
export const MobileTransition = lazy(() =>
  import('pages/MobileTransition').then(module => ({
    default: module.MobileTransition,
  })),
);
export const PublicTransferToPartner = lazy(() =>
  import('pages/Public/PublicTransferToPartner').then(module => ({
    default: module.PublicTransferToPartner,
  })),
);
export const ReactivatePremium = lazy(() =>
  import('pages/ReactivatePremium').then(module => ({
    default: module.ReactivatePremium,
  })),
);
export const TransferToPartner = lazy(() =>
  import('pages/TransferToPartner').then(module => ({
    default: module.TransferToPartner,
  })),
);
export const TransitionTo3rdParty = lazy(() =>
  retry(() =>
    import('pages/TransitionTo3rdParty/TransitionTo3rdPartyController'),
  ),
);
export const OffersDetailsModal = lazy(() =>
  import('partial/OfferDetailsModal').then(module => ({
    default: module.OffersDetailsModal,
  })),
);
export const PremiumModal = lazy(() =>
  import('partial/PremiumModal').then(module => ({
    default: module.PremiumModal,
  })),
);
export const PremiumPaymentPendingModal = lazy(() =>
  import('partial/PremiumPaymentPendingModal').then(module => ({
    default: module.PremiumPaymentPendingModal,
  })),
);
export const PremiumReactivateCancelModal = lazy(() =>
  import('partial/PremiumReactivateCancelModal').then(module => ({
    default: module.PremiumReactivateCancelModal,
  })),
);
export const PostPremiumLandingModalController = lazy(() =>
  import('partial/PremiumModal/PostPremiumLandingModalController').then(
    module => ({ default: module.PostPremiumLandingModalController }),
  ),
);
export const PostPlusConvertModal = lazy(() =>
  import('partial/PlusModal').then(module => ({
    default: module.PostPlusConvertModal,
  })),
);
export const CashAccountModalController = lazy(() =>
  import('partial/CashAccountModal/CashAccountModalController').then(
    module => ({ default: module.CashAccountModalController }),
  ),
);
export const PremiumReactivatePendingCancellationModal = lazy(() =>
  import('partial/PremiumReactivatePendingCancellationModal').then(module => ({
    default: module.PremiumReactivatePendingCancellationModal,
  })),
);
export const TwoFactorModal = lazy(() =>
  import('partial/TwoFactorModal').then(module => ({
    default: module.TwoFactorModal,
  })),
);
export const QuarterlyReportWebView = lazy(() =>
  retry(() => import('pages/QuarterlyReportWebView')),
);
export const OfferDetails = lazy(() =>
  retry(() => import('partial/OfferDetails')),
);

export const PlusRoutes = lazy(() => retry(() => import('routes/Plus.routes')));
export const SesameCash2EnrollmentSuccessRoutes = lazy(() =>
  retry(() => import('routes/SesameCash2EnrollmentSuccess.routes')),
);

export const GlobalReviewController = lazy(() =>
  retry(() => import('partial/OfferDetails/GlobalReviewPage')),
);

export const DebtPayOffCalculator = lazy(() =>
  retry(() => import('pages/DebtPayOffCalculator')),
);
export const OrderHistory = lazy(() =>
  import('pages/OrderHistory').then(module => ({
    default: module.OrderHistory,
  })),
);
export const CustomerSupport = lazy(() =>
  import('pages/CustomerSupport').then(module => ({
    default: module.CustomerSupport,
  })),
);

export const ScoreSimulator = lazy(() =>
  retry(() => import('pages/ScoreSimulator')),
);

export const IDProtection = lazy(() =>
  retry(() => import('pages/IDProtection')),
);

export const PremiumCancelPlanModal = lazy(() =>
  import('pages/PlusLanding/pages/PremiumCancelPlan').then(module => ({
    default: module.PremiumCancelPlan,
  })),
);
