import {
  START_LOADING,
  LOAD_FAILURE,
  LOAD_SUCCESS,
} from 'types/datacache.constants';
import { LOGIN_SUCCESS, LOGOUT } from 'types/app.constants';
import { mergePayloadChanges } from 'helpers/reducer.helper';
import { PAYLOAD } from 'types/payload.constants';

/**
 * FYI, this reducer is a little bit more complex than other. Please see datacache.actions for instructions
 * on how to setup a cache.
 *
 * If you just want to add an api call into the cache, try to use api.hook with a cachekey added
 */

const mergeStartLoading = (state, action) => {
  const newValues = {
    ...action,
    type: undefined,
  };

  delete newValues.type;
  return Object.assign({}, state, newValues);
};

const mergeLoadSuccess = (state, action) => {
  const newValues = {
    ...action,
    type: undefined,
  };

  delete newValues.type;

  return Object.assign({}, state, newValues);
};

const mergeLoadFailure = (state, action) => {
  const newValues = {
    ...action,
    type: undefined,
  };

  delete newValues.type;

  return Object.assign({}, state, newValues);
};

const initialState = {
  CREDIT_CARD_MODULES_KEY: null,
  HOME_PAGE_MODULES_KEY: null,
  NEW_CARD_SCORE_SIM_KEY: null,
  NEW_CARD_SCORE_INCREASE_SIM_KEY: null,
  CREDIT_REPAIR_EXPERT_SIM_KEY: null,
  SELF_SIM_KEY: null,
  PL_PREQUAL_RESULT_KEY: null,
  PL_PREQUAL_RESULT_KEY_LOADING: null,
  PL_PREQUAL_RESULT_KEY_ERROR: null,
  LOGIN_SCORE_REFRESH_LOADING: null,
  KICKBOX_VERIFICATION: null,
  HOME_EQUITY_ENABLE: false,
  AUTO_INSURANCE_KEY: null,
  LIFE_INSURANCE_KEY: null,
  HOME_INSURANCE_KEY: null,
  MARKETPLACE_FILTERS_KEY: null,
  CC_COMPARISON_KEY: null,
};

const dataCacheReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYLOAD:
    case LOGIN_SUCCESS:
      return mergePayloadChanges(state, action);
    case START_LOADING:
      return mergeStartLoading(state, action);
    case LOAD_SUCCESS:
      return mergeLoadSuccess(state, action);
    case LOAD_FAILURE:
      return mergeLoadFailure(state, action);
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};

export default dataCacheReducer;
