import { isObj } from './objectHelpers';
import { getDateWithoutTime, monthsToYears } from 'helpers/dateHelper';
import { commafy } from 'helpers/number';
import { isMobileByOS } from '../externals/_tracking/dom/navigator';
import { formatUSD } from './money.helper';

export const calculateDTIWithPaymentAndIncome = (
  monthlyPayment,
  annualIncome,
) => {
  let annualIncomeNumber = Number(annualIncome);
  let finalAnnualIncome = annualIncomeNumber <= 0 ? 1 : annualIncomeNumber;
  return Math.round((monthlyPayment / (finalAnnualIncome / 12)) * 100);
};

export const FULL_CREDIT_PROFILE = 'FULL_CREDIT_PROFILE';

export const calculateDTI = (creditInfo, annualIncome) => {
  let finalAnnualIncome = null;
  if (typeof annualIncome === 'number') finalAnnualIncome = annualIncome;
  else if (typeof annualIncome === 'string') {
    finalAnnualIncome = Number(annualIncome);

    if (Number.isNaN(finalAnnualIncome)) {
      throw new Error('Annual income is not valid');
    }
  } else {
    throw new Error('Annual income is not valid');
  }

  if (finalAnnualIncome === 0.0)
    throw new Error('Annual income cannot be 0 for calculation');

  const finalDebtAmount = creditInfo.debtAnalysis.totalMonthlyPayment;

  return finalDebtAmount / (finalAnnualIncome / 12);
};

export const getOpenHomeLoans = creditInfo => {
  const homeLoanAccounts =
    creditInfo && creditInfo.debtAnalysis
      ? creditInfo.debtAnalysis.homeLoanDebt.accounts
      : [];
  return homeLoanAccounts.filter(account => account.status === 'Open');
};

const compareLastToSecondToLast = (
  arr,
  displayCreator,
  comparator,
  returnLatestOrZero,
  diplaySumUp,
  hasOneElement,
) => {
  if (arr && arr.length > 1) {
    const last = arr[arr.length - 1];
    const second = arr[arr.length - 2];

    const data = comparator(last, second);
    data.difference = Math.round(data.current - data.previous);
    data.projection = Math.floor(data.difference + data.current);
    data.display = diplaySumUp ? data.projection : data.difference;

    const final = Object.assign({}, data, displayCreator(data));
    return final;
  } else if (returnLatestOrZero) {
    if (arr.length > 0) {
      return { current: Math.round(arr[0].value), previous: null };
    } else {
      return { current: 0, previous: 0 };
    }
  } else if (hasOneElement) {
    return { current: arr[0]?.value, previous: '--' };
  } else {
    return { current: '--', previous: '--' };
  }
};

const compareLastToSecondToLastByRefresh = (
  arr,
  displayCreator,
  comparator,
) => {
  const refreshes = arr;

  let latestRefreshDate = null;
  let latestRefreshTrend = null;
  let previousRefreshDate = null;
  let previousRefreshTrend = null;
  for (var i = refreshes.length - 1; i >= 0; i--) {
    const currentTrend = refreshes[i];

    if (latestRefreshTrend === null) {
      if (currentTrend.type === FULL_CREDIT_PROFILE) {
        latestRefreshDate = getDateWithoutTime(currentTrend.date);
        latestRefreshTrend = currentTrend;
      }
    } else if (previousRefreshDate === null) {
      if (currentTrend.type === FULL_CREDIT_PROFILE) {
        const currentTrendDate = getDateWithoutTime(currentTrend.date);
        if (currentTrendDate && currentTrendDate?.isBefore(latestRefreshDate)) {
          previousRefreshDate = currentTrend.date;
          previousRefreshTrend = currentTrend;
        }
      }
    }
  }

  if (latestRefreshTrend === null || previousRefreshTrend === null) {
    return { current: '--', previous: '--' };
  }

  const data = comparator(latestRefreshTrend, previousRefreshTrend);
  data.difference = Math.round(data.current - data.previous);
  const final = Object.assign({}, data, displayCreator(data.difference));
  return final;
};

const getTimestampForPreviousFullRefresh = creditInfo => {
  const refreshes = creditInfo.trends.creditScoreRefresh;

  let latestRefresh = null;
  let previousRefresh = null;
  for (var i = refreshes.length - 1; i >= 0; i--) {
    const currentTrend = refreshes[i];

    if (latestRefresh === null) {
      if (currentTrend.type === FULL_CREDIT_PROFILE) {
        latestRefresh = getDateWithoutTime(currentTrend.date);
      }
    } else if (previousRefresh === null) {
      if (currentTrend.type === FULL_CREDIT_PROFILE) {
        const currentTrendDate = getDateWithoutTime(currentTrend.date);
        if (currentTrendDate && currentTrendDate?.isBefore(latestRefresh)) {
          previousRefresh = currentTrend.date;
        }
      }
    }
  }

  return previousRefresh;
};

export const getWhatsChanged = (
  creditInfo,
  isOcf,
  returnLatestOrZero,
  displaySumUp,
) => {
  const timestampForLastFullRefresh =
    getTimestampForPreviousFullRefresh(creditInfo);
  let creditScore = compareLastToSecondToLastByRefresh(
    creditInfo.trends.creditScoreRefresh,
    v => ({ display: `${Math.abs(v)}pts`, isPositive: v >= 0 }),
    (l, s) => ({ current: l.score, previous: s.score }),
  );

  const negativeMarks = compareLastToSecondToLast(
    creditInfo.trends.negativeMarks,
    v => ({
      display: Math.abs(v.display),
      isPositive: v.difference <= 0,
      label: 'Negative Marks',
    }),
    (l, s) => ({
      current: l.minor + l.major + l.extreme,
      previous: s.minor + s.major + s.extreme,
    }),
    returnLatestOrZero,
    displaySumUp,
  );

  const creditUsage = compareLastToSecondToLast(
    creditInfo?.trends?.creditUsages,
    v => ({
      display: `${Math.abs(v.display)}%`,
      isPositive: v.difference <= 0,
      label: 'Credit Usage',
    }),
    (l, s) => ({ current: l?.value, previous: s?.value }),
    returnLatestOrZero,
    displaySumUp,
  );

  const creditLimit = compareLastToSecondToLast(
    creditInfo.trends.creditData,
    v => ({
      display: `$${Math.abs(v.display).toLocaleString(undefined, {
        maximumFractionDigits: 2,
      })}`,
      isPositive: v.difference >= 0,
      label: 'Total Credit Limit',
    }),
    (l, s) => ({ current: l.totalLimit, previous: s.totalLimit }),
    returnLatestOrZero,
    displaySumUp,
  );

  const creditAge = compareLastToSecondToLast(
    creditInfo.trends.averageCreditAges,
    v => ({
      display: `${monthsToYears(Math.abs(v.display))}`,
      isPositive: v.difference >= 0,
      label: 'Credit Age',
    }),
    (l, s) => ({ current: l.value, previous: s.value }),
    returnLatestOrZero,
    displaySumUp,
  );

  const creditInquiries = compareLastToSecondToLast(
    creditInfo.trends.numEnquiries,
    v => ({
      display: Math.abs(v.display),
      isPositive: v.difference <= 0,
      label: 'Credit Inquiries',
    }),
    (l, s) => ({ current: l.value, previous: s.value }),
    returnLatestOrZero,
    displaySumUp,
  );

  const creditCardBalances = compareLastToSecondToLast(
    creditInfo.trends.creditCardBalances,
    v => ({ display: Math.abs(v.display), isPositive: v.difference <= 0 }),
    (l, s) => ({ current: l.value, previous: s.value }),
    returnLatestOrZero,
    displaySumUp,
  );

  const totalBalances = compareLastToSecondToLast(
    creditInfo.trends.totalBalances,
    v => ({ display: Math.abs(v.display), isPositive: v.difference <= 0 }),
    (l, s) => ({ current: l.value, previous: s.value }),
    returnLatestOrZero,
    displaySumUp,
  );

  const hasOneElement = creditInfo?.trends?.grades?.length === 1;
  const paymentHistoryGrade = compareLastToSecondToLast(
    creditInfo?.trends?.grades?.map(grade => {
      return { ...grade, value: grade?.paymentHistoryGrade };
    }),
    v => ({ display: Math.abs(v.display), isPositive: v.difference <= 0 }),
    (l, s) => ({
      current: l.paymentHistoryGrade,
      previous: s.paymentHistoryGrade,
    }),
    returnLatestOrZero,
    displaySumUp,
    hasOneElement,
  );

  const creditUsageGrade = compareLastToSecondToLast(
    creditInfo.trends.grades,
    v => ({ display: Math.abs(v.display), isPositive: v.difference <= 0 }),
    (l, s) => ({
      current: l.creditUtilizationGrade,
      previous: s.creditUtilizationGrade,
    }),
    returnLatestOrZero,
    displaySumUp,
  );

  const collections = compareLastToSecondToLast(
    creditInfo.trends.negativeMarks,
    v => ({ display: Math.abs(v.display), isPositive: v.difference <= 0 }),
    (l, s) => ({ current: l.extreme, previous: s.extreme }),
    returnLatestOrZero,
    displaySumUp,
  );

  const monthlyPayments = compareLastToSecondToLast(
    creditInfo.trends.monthlyPayments,
    v => ({ display: Math.abs(v.display), isPositive: v.difference <= 0 }),
    (l, s) => ({ current: l.value, previous: s.value }),
    returnLatestOrZero,
    displaySumUp,
  );

  const debtToIncome = compareLastToSecondToLast(
    creditInfo.trends.dtiTrends,
    v => ({ display: Math.abs(v.display), isPositive: v.difference <= 0 }),
    (l, s) => ({ current: l.value, previous: s.value }),
    returnLatestOrZero,
    displaySumUp,
  );

  const anythingChanged =
    [
      creditScore,
      negativeMarks,
      creditUsage,
      creditLimit,
      creditAge,
      creditInquiries,
    ].filter(v => isObj(v) && v.current !== v.previous).length > 0;

  if (isOcf) {
    creditScore = compareLastToSecondToLast(
      creditInfo.trends.creditScores,
      v => ({
        display: `${Math.abs(v.difference)}pts`,
        isPositive: v.difference >= 0,
      }),
      (l, s) => ({ current: l.value, previous: s.value }),
      isOcf,
      displaySumUp,
    );
  }

  const whatsChanged = {
    timestampForLastFullRefresh,
    anythingChanged,
    creditScore,
    negativeMarks,
    creditUsage,
    creditLimit,
    creditAge,
    creditInquiries,
    creditCardBalances,
    totalBalances,
    paymentHistoryGrade,
    creditUsageGrade,
    collections,
    monthlyPayments,
    debtToIncome,
  };

  return whatsChanged;
};

export const isZeroScoreUser = creditInfo => {
  if (creditInfo) {
    const creditScore = creditInfo.creditScore;
    return creditScore === 0 || creditScore === 4;
  }
  return false;
};

export const hasAutoLoanAccounts = creditInfo => {
  if (creditInfo?.debtAnalysis?.autoLoanDebt) {
    const userAutoLoans = creditInfo.debtAnalysis.autoLoanDebt.accounts;

    return (
      userAutoLoans.filter(loan => {
        return loan.status === 'Open' && loan.balance > 0;
      }).length > 0
    );
  }

  return false;
};

export const getOpenAutoLoanAccounts = creditInfo => {
  if (creditInfo?.debtAnalysis?.autoLoanDebt) {
    const userAutoLoans = creditInfo.debtAnalysis.autoLoanDebt.accounts;

    return userAutoLoans.filter(loan => {
      return loan.status === 'Open' && loan.balance > 0;
    });
  }

  return null;
};

export const hasMortgage = creditInfo => {
  if (
    creditInfo &&
    creditInfo.debtAnalysis &&
    creditInfo.debtAnalysis.homeLoanDebt
  ) {
    const userMortgages = creditInfo.debtAnalysis.homeLoanDebt.accounts;

    return (
      userMortgages.filter(loan => {
        return loan.status === 'Open' && loan.balance > 0;
      }).length > 0
    );
  }

  return false;
};

export const getRefinanceAmount = (creditInfo, defaultAmount = null) => {
  if (creditInfo) {
    let amount = null;
    creditInfo.debtAnalysis.homeLoanDebt.accounts.forEach(m => {
      if (m.status === 'Open') {
        if (amount === null || m.balance > amount) {
          amount = m.balance;
        }
      }
    });
    if (amount === null) amount = defaultAmount;

    return amount;
  } else return null;
};

export const getTotalCreditCardDebt = creditInfo => {
  let totalDebt = 0;
  if (creditInfo?.debtAnalysis?.creditCardDebt) {
    totalDebt = creditInfo.debtAnalysis.creditCardDebt.totalDebt;
  }
  return totalDebt;
};

export const getNegativeRemarksCount = creditInfo => {
  if (creditInfo && creditInfo.creditScoreAnalysis) {
    return (
      creditInfo.creditScoreAnalysis.paymentHistory.negativeRemarkAccounts
        .length + creditInfo.publicRecordsCount
    );
  }

  return 0;
};

export const getDerogatoryAccountsCount = creditInfo => {
  if (creditInfo && creditInfo.creditScoreAnalysis) {
    return creditInfo.creditScoreAnalysis.paymentHistory.negativeRemarkAccounts.filter(
      a => !/.*[Ll]ate.*/.test(a.remarks),
    ).length;
  }
  return 0;
};

export const getBankruptcyAccountsCount = creditInfo => {
  return creditInfo?.publicRecordsCount || 0;
};

export const getDerogatoryOpenAccountsCount = creditInfo => {
  if (creditInfo && creditInfo.creditScoreAnalysis) {
    return creditInfo.creditScoreAnalysis.paymentHistory.negativeRemarkAccounts.filter(
      a => !/.*[Ll]ate.*/.test(a.remarks) && /[Oo]pen/.test(a.status),
    ).length;
  }
  return 0;
};

export const userCanSeeCollectionGuideBanner = creditInfo =>
  isMobileByOS() &&
  getDerogatoryOpenAccountsCount(creditInfo) +
    getBankruptcyAccountsCount(creditInfo) >
    0;

export const getDebtAnalysisDisplayConfig = (
  prefix,
  postfix,
  data,
  difference,
) => {
  const displayPrefix = prefix ? prefix : '';
  const displayPostfix = postfix ? postfix : '';
  const dataDisplay = `${displayPrefix}${commafy(data)}${displayPostfix}`;
  const diffDisplay =
    difference && difference > 0
      ? `${displayPrefix}${commafy(difference)}${displayPostfix}`
      : null;
  return {
    dataDisplay,
    diffDisplay,
  };
};

export const getUsageColorByCreditFactor = creditUsagePercentage => {
  let usageColor = '';

  if (creditUsagePercentage >= 0 && creditUsagePercentage <= 10) {
    usageColor = 'DarkGreen5';
  } else if (creditUsagePercentage > 10 && creditUsagePercentage <= 30) {
    usageColor = 'LightGreen5';
  } else if (creditUsagePercentage > 30 && creditUsagePercentage <= 50) {
    usageColor = 'Yellow2';
  } else if (creditUsagePercentage > 50 && creditUsagePercentage <= 70) {
    usageColor = 'Orange3';
  } else if (creditUsagePercentage > 70) {
    usageColor = 'Red100';
  }

  return usageColor;
};
export const convertAccountTypeToReadable = type => {
  switch (type) {
    case 'CreditCard':
      return 'Credit Card';
    case 'PersonalLoan':
      return 'Personal Loan';
    case 'StudentLoan':
      return 'Student Loan';
    case 'AutoLoan':
      return 'Auto Loan';
    case 'OtherLoan':
    default:
      return 'Other Loan';
  }
};
export const convertAccountFromSlapiToCmp = a => ({
  name: a.accountName,
  accountType: convertAccountTypeToReadable(a.loanType),
  pastDue: formatUSD(a.pastDue, ''),
  remark: a.remarks,
  idHash: a.idHash,
  negativeMarkType: a?.negativeMarkType,
  noClickItem: a.loanType === 'N/A',
});
