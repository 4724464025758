export const SELECT_PAYMENT_HISTORY_CONTAINER_DATA =
  'SELECT_PAYMENT_HISTORY_CONTAINER_DATA';
export const START_PAYMENT_HISTORY_CONTAINER_LOAD =
  'START_PAYMENT_HISTORY_CONTAINER_LOAD';
export const FINISH_PAYMENT_HISTORY_CONTAINER_LOAD =
  'FINISH_PAYMENT_HISTORY_CONTAINER_LOAD';
export const SELECT_PAYMENT_HISTORY_CONTAINER_DATA_ATTRIBUTE =
  'paymentHistoryContainer';
export const RESET_PAYMENT_HISTORY_CONTAINER_DATA =
  'RESET_PAYMENT_HISTORY_CONTAINER_DATA';
