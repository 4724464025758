import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { testLocalStorage } from 'helpers/localstorage';
import { areCookiesEnabled } from 'externals/_tracking/dom/navigator';
import { activityTracker } from 'helpers/session';
import { useEventHandler } from 'hook/event.hook';
import { useLogger } from 'hook/error.hook';
import { CLICK } from 'externals/_tracking/types/eventTypes.constants';
import {
  activateSessionTimeoutModal,
  quitSessionTimeoutModal,
  resetSession,
} from './sessionManager.actions';
import {
  getOcfSessionTimeout,
  getSessionTimeout,
} from 'helpers/sessionLocalStorageReader.helper';

/**
 * Handle normal logged in session monitoring differently
 * than OCF session monitoring
 *
 * Normal authenticated session:
 *   - Set up cron to monitor session state after 1 sec
 *   - Set up listeners to track user activity
 *   - Prompt for session extension 1 minute before it expires
 *
 * OCF authenticated session:
 *   - Set up cron to monitor session state after 1 sec
 *   - Redirect to session timed out page when session expires
 */

const setupSessionMonitoring = (dispatch, history, getState) => {
  const intervalId = setInterval(cron(dispatch, history, getState), 1000);

  const removeTracker = activityTracker();
  return function () {
    clearTimeout(intervalId);
    removeTracker();
  };
};

const cron = (dispatch, history, getState) => () => {
  if (!areCookiesEnabled() || testLocalStorage() !== null) {
    return;
  }

  const { ocfLoggedIn } = (state => ({
    showSessionTimeout: state.app.showSessionTimeoutModal,
    loggedIn: state.app.loggedIn,
    ocfLoggedIn: state.app.ocfLoggedIn,
  }))(getState());

  if (ocfLoggedIn) {
    const sessionTimeout = getOcfSessionTimeout();
    if (sessionTimeout !== null && moment().isAfter(sessionTimeout)) {
      history.push('/logout?reason=SESSION_TIMEOUT');
    }
  } else {
    const sessionTimeout = getSessionTimeout();
    if (sessionTimeout !== null) {
      const potentialTimeout = moment().add(1, 'minutes');
      if (potentialTimeout.isAfter(sessionTimeout)) {
        dispatch(activateSessionTimeoutModal());
      }
    }
  }
};

export const useSessionManager = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const eventHandler = useEventHandler();

  const logger = useLogger({
    name: 'sessionManager.hook',
    categories: ['session'],
  });

  const { showSessionTimeout, loggedIn, ocfLoggedIn } = useSelector(state => ({
    showSessionTimeout: state.app.showSessionTimeoutModal,
    loggedIn: state.app.loggedIn,
    ocfLoggedIn: state.app.ocfLoggedIn,
  }));

  const store = useStore();
  const getState = store.getState;

  const [sessionTimeoutModalOpen, setSessionTimeoutModalOpen] = useState(null);
  const monitoringInterval = useRef(null);

  useEffect(() => {
    if (showSessionTimeout) {
      stopMonitoring();
      setSessionTimeoutModalOpen(showSessionTimeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSessionTimeout]);

  useEffect(() => {
    if ((loggedIn || ocfLoggedIn) && !monitoringInterval.current) {
      startMonitoring();
    } else if (!loggedIn && !ocfLoggedIn && monitoringInterval.current) {
      stopMonitoring();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, ocfLoggedIn]);

  function startMonitoring() {
    monitoringInterval.current = setupSessionMonitoring(
      dispatch,
      history,
      getState,
    );
  }

  function stopMonitoring() {
    if (typeof monitoringInterval.current === 'function') {
      monitoringInterval.current();
      monitoringInterval.current = null;
    }
  }

  const extendSession = clickType => {
    eventHandler(CLICK, {
      'Click Type': clickType,
    });
    setSessionTimeoutModalOpen(false);
    dispatch(quitSessionTimeoutModal());
    dispatch(resetSession())
      .then(_ => {
        startMonitoring();
        logger.info('Session Timeout Modal - Session continued');
      })
      .catch(err => {
        logger.reportAPIError(err);
        logger.errorException(
          'Session Timeout Modal - Unable to extend user session',
          err,
        );
        history.push('/logout?reason=SESSION_TIMEOUT_ERROR');
      });
  };

  const endSession = reason => {
    setSessionTimeoutModalOpen(false);
    dispatch(quitSessionTimeoutModal());
    logger.info(
      'Session Timeout Modal - Session ended with reason = ' + reason,
    );
    history.push('/logout?reason=' + reason);
  };

  const onContinueSessionTimeoutModal = () => {
    extendSession('Continue Session Timeout Modal');
  };

  const onCloseSessionTimeoutModal = () => {
    extendSession('Close Session Timeout Modal');
  };

  const onLogOutSessionTimeoutModal = () => {
    eventHandler(CLICK, {
      'Click Type': 'Log Out Session Timeout Modal',
    });

    endSession('MANUAL');
  };

  return {
    onContinueSessionTimeoutModal,
    onCloseSessionTimeoutModal,
    onLogOutSessionTimeoutModal,
    sessionTimeoutModalOpen,
    endSession,
  };
};
