import React from 'react';
import { Button } from 'components/form/Button';

const OpenInAppBtnView = ({ label, onClick, isPlusUser }) => {
  return (
    <div className="global-header-app-download-container">
      <Button
        name="global-header-app-download-btn"
        mrphType={!isPlusUser ? 'primary' : ''}
        className={`fullWidth global-header-app-download-btn ${
          isPlusUser ? 'bgLBlue085' : ''
        }`}
        onClick={onClick}
      >
        {label}
      </Button>
    </div>
  );
};

export default OpenInAppBtnView;
