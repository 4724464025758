export const dmCampaignSignupConstants = {
  LOADING_START: 'DM_CAMPAIGN_LOADING_START',
  LOADING_FINISH: 'DM_CAMPAIGN_LOADING_FINISH',
  CREATE_USER_SUCCESS: 'DM_CAMPAIGN_CREATE_USER_SUCCESS',
  UPDATE_ADDRESS_FAIL: 'DM_CAMPAIGN_UPDATE_ADDRESS_FAIL',
  UPDATE_PASSWORD_SUCCESS: 'DM_CAMPAIGN_UPDATE_PASSWORD_SUCCESS',
  REPORT_TU_ERROR: 'DM_CAMPAIGN_REPORT_TU_ERROR',
  CLEAR_TU_ERROR: 'DM_CAMPAIGN_CLEAR_TU_ERROR',
  INIT_SIGNUP_2: 'DM_CAMPAIGN_INIT_SIGNUP_2',
  INIT_DUPE_SSN_LOADING: 'DM_CAMPAIGN_INIT_DUPE_SSN_LOADING',
  SET_SIGNUP_2_STEP: 'SET_DM_CAMPAIGN_SIGNUP_2_STEP',
};

//signup 2
export const DM_CAMPAIGN_FORM = 'DM_CAMPAIGN_FORM';
export const DUPE_SSN_LOADING = 'DUPE_SSN_LOADING';
export const CONFIRM_INFORMATION = 'CONFIRM_INFORMATION';
export const MORE_INFORMATION = 'MORE_INFORMATION';

//signup flow for tracking
export const DUPE_SSN_FLOW = 'dupssn';
export const DM_CAMPAIGN_FLOW = 'TU';

export const optEventsDMCampaign = {
  [DM_CAMPAIGN_FORM]: 'viewed_dm_campaign_dm_campaign_form',
  [DUPE_SSN_LOADING]: 'viewed_dm_campaign_dupe_ssn_loading',
};

export const optEventsSignupSubmit = {
  [DM_CAMPAIGN_FORM]: 'one_click_signup_2_no_prefill_form_submit',
  [CONFIRM_INFORMATION]: 'dm_campaign_signup1_form_submit',
  [MORE_INFORMATION]: 'dm_campaign_signup2_form_submit',
};

export const mxpMapDmCampaign = {
  [DM_CAMPAIGN_FORM]: 'DM Campaign - DM Campaign Form',
  [DUPE_SSN_LOADING]: 'DM Campaign - Dupe Ssn Loading',
};

export const dmCampaignFlowTracking = {
  [DM_CAMPAIGN_FLOW]: 'TU_flow',
  [DUPE_SSN_FLOW]: 'dupssn_flow',
};

export const mxpMapDMCampaignSignup = {
  [CONFIRM_INFORMATION]: 'Promocode_name_address_confirmation',
  [MORE_INFORMATION]: 'Promocode_Phone_Email_DOB',
};
