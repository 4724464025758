import {
  SET_PAGE_POSITION,
  SET_PAGE_META,
  SET_SESSION_TOKEN,
  SET_REF_CODE,
} from 'types/tracking.constants';
import * as pageNames from 'types/mrph.pageName.constants';
import { setRefCode as setRefCodeInLocalStorage } from 'helpers/localstorage';

export const setPageMeta =
  (newPageName, oldPageName) => (dispatch, getState) => {
    const state = getState();
    if (!newPageName) return;
    const { pageName: existingPageName } = state?.tracking;
    if (existingPageName === newPageName) return;

    const newPageNameMeta = pageNames[newPageName];
    const oldPageNameMeta = pageNames[oldPageName];
    dispatch({
      type: SET_PAGE_META,
      pageNameMeta: newPageNameMeta,
      pageName: newPageName,
      oldPageNameMeta,
      oldPageName,
    });
  };

export const setSessionToken = sessionToken => ({
  type: SET_SESSION_TOKEN,
  sessionToken,
});

export const setRefCode = refCode => {
  setRefCodeInLocalStorage(refCode);
  return { type: SET_REF_CODE, refCode };
};

export const setPagePosition = pagePosition => {
  return { type: SET_PAGE_POSITION, pagePosition };
};
