import React from 'react';
import { RouteContainer } from 'components/Containers/RouteContainer/RouteContainer';
import PageLoadingMask from 'components/masks/PageLoadingMask';
import BodyContainer from 'components/Containers/BodyContainer/index';
import { useScrollToTop } from 'hook/helper.hook';
import './Loading.scss';

function Loading({ description }) {
  useScrollToTop();
  return (
    <RouteContainer type="public">
      <BodyContainer>
        <PageLoadingMask description={description} />
      </BodyContainer>
    </RouteContainer>
  );
}

export { Loading };
