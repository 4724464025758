/* global __insp */

import { createLogger } from 'helpers/logger';

const logger = createLogger({
  name: 'Inspectlet.service',
});

/**
 * known attrs
 *  usrTkn
 *  sessionIdentifier
 *
 * @param attrs
 */
export const tagInspectletSession = attrs => {
  try {
    if (typeof window.__inspld != 'undefined') {
      __insp.push(['tagSession', attrs]);
    }
  } catch (e) {
    logger.errorException('Unable to tag session', e);
  }
};
