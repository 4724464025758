import {
  formatAsFullWithCompleteMonth,
  formatAsYear,
} from 'helpers/dateHelper';

export const DISCREPANCIES_3 = '3 DISCREPANCIES';
export const DISCREPANCY_1 = '1 DISCREPANCY';
export const DISCREPANCY = 'DISCREPANCY';
export const DISCREPANCIES = 'DISCREPANCIES';
export const TransUnion = 'TransUnion';
export const Equifax = 'Equifax';
export const Experian = 'Experian';
export const Dispute_This = 'Dispute This';
export const Write_a_Letter = 'Write a Letter';
export const linkForDispute = '/liveExperts?tab=creditDisputeResolution';
export const GoodWillLetterPrint = 'GoodWillLetterPrint';
export const THREE_BUREAUS = 'THREE_BUREAUS';
export const ONE_BUREAU = 'ONE_BUREAU';
export const INQUIRY = 'Inquiry';
export const DATE_OF_INQUIRY = 'Date of Inquiry';

export const PLUS_IMAGE = 'plusImg';
export const ICON_CHECK = 'iconCheck';
export const ICON_CROSS = 'iconCross';
export const ICON_UNKNOW = 'iconUnknown';

export const BUREAU = {
  [TransUnion]: 'TUC',
  [Equifax]: 'EQF',
  [Experian]: 'EXP',
};

export const BUREAU_INFO = {
  [TransUnion]: [
    'TransUnion LLC',
    'Consumer Dispute Center ',
    'P.O. Box 2000',
    'Chester, PA 19016',
  ],
  [Equifax]: [
    'Equifax Information Services LLC',
    'P.O. Box 740256',
    'Atlanta, GA 30374',
  ],
  [Experian]: ['Experian', 'P.O. Box 4500', 'Allen, TX 75013'],
};

export const REPORT_FAKE = {
  is_3BReport: false,
  reportId: -1,
  label: formatAsFullWithCompleteMonth(),
  dateFormatted: formatAsFullWithCompleteMonth(),
  value: 0,
  jsonVersionAvailable: false,
};
export const STATE_FAKE = {
  reportId: REPORT_FAKE.reportId,
  is3B: REPORT_FAKE.is_3BReport,
  openPanel: 'WhatsDifferent',
  doNotInit: true,
  creditReportSelectedIndex: 0,
  genericFetchCreditReportById: null,
  loading: false,
  accountListData: [],
  accountListDataAll: null,
  isReportFake: true,
};

export const openSubSectionPanelKeysFake = {
  'Account Type': ['AccountSummaryaccountAccountType0'],
};

export const creditReportTUConstants = {
  STORE_REPORTS: 'STORE_REPORTS',
  LOAD_REPORT: 'LOAD_REPORT',
};

export const discrepancesIgnoreList = [
  'credit score',
  'current address',
  'previous address',
];

export const discrepancesParsers = {
  'date of birth': data => (data ? formatAsYear(data) : data),
};

export const BANNER = 'BANNER';
export const DIALS = 'DIALS';
export const CAROUSEL = 'CAROUSEL';
export const CREDIT_PROFILE = 'CREDIT_PROFILE';
export const PERSONAL_INFORMATION = 'PERSONAL_INFORMATION';
export const INQUIRIES = 'INQUIRIES';
export const ACCOUNTS = 'ACCOUNTS';
export const PUBLIC_RECORDS = 'PUBLIC_RECORDS';

export const pageNameForFreemium = 'Credit Report TU View Freemium';
export const pageNameForFreeTrial = 'Credit Report TU View Free Trial';

export const eventsForFreemiumFreeTrial = {
  [BANNER]: isFreemium => ({
    'Click Type': 'TU3B Report Banner CTA',
    'Page Name': isFreemium ? pageNameForFreemium : pageNameForFreeTrial,
  }),
  [DIALS]: isFreemium => ({
    'Click Type': 'TU3B Report Score Dials CTA',
    'Page Name': isFreemium ? pageNameForFreemium : pageNameForFreeTrial,
  }),
  [CAROUSEL]: isFreemium => ({
    'Click Type': 'TU3B Report Score Carousel CTA',
    'Page Name': isFreemium ? pageNameForFreemium : pageNameForFreeTrial,
  }),
  [CREDIT_PROFILE]: isFreemium => ({
    'Click Type': 'TU3B Report Score Credit Profile CTA',
    'Page Name': isFreemium ? pageNameForFreemium : pageNameForFreeTrial,
  }),
  [PERSONAL_INFORMATION]: isFreemium => ({
    'Click Type': 'TU3B Report Score Personal Information CTA',
    'Page Name': isFreemium ? pageNameForFreemium : pageNameForFreeTrial,
  }),
  [INQUIRIES]: isFreemium => ({
    'Click Type': 'TU3B Report Score Inquiries CTA',
    'Page Name': isFreemium ? pageNameForFreemium : pageNameForFreeTrial,
  }),
  [ACCOUNTS]: isFreemium => ({
    'Click Type': 'TU3B Report Score Accounts CTA',
    'Page Name': isFreemium ? pageNameForFreemium : pageNameForFreeTrial,
  }),
  [PUBLIC_RECORDS]: isFreemium => ({
    'Click Type': 'TU3B Report Score Public Records CTA',
    'Page Name': isFreemium ? pageNameForFreemium : pageNameForFreeTrial,
  }),
};

export const DEROGATORY_MARKS = 'Derogatory Marks';
export const OPEN = 'OPEN';
export const IN_PROGRESS = 'IN_PROGRESS';
export const NOT_A_DISCREPANCY = 'NOT_A_DISCREPANCY';
export const MISTAKENLY_RESOLVED = 'MISTAKENLY_RESOLVED';
export const NOT_REMOVED_FROM_REPORT = 'NOT_REMOVED_FROM_REPORT';
export const RESOLVED_MYSELF = 'RESOLVED_MYSELF';
export const CLOSED = 'CLOSED';
export const REOPEN = 'REOPEN';
export const IGNORED = 'IGNORED';

export const STATUSES_VALID = [OPEN, IN_PROGRESS];
export const STATUSES_IN_PROGRESS = [
  IN_PROGRESS,
  MISTAKENLY_RESOLVED,
  NOT_REMOVED_FROM_REPORT,
];
export const STATUSES_RESOLVED = [
  NOT_A_DISCREPANCY,
  RESOLVED_MYSELF,
  IGNORED,
  CLOSED,
];

export const STATUSES_MAP = {
  [OPEN]: { text: 'Resolve Now', className: 'secondary_text_light' },
  [IN_PROGRESS]: { text: 'In progress', className: 'system_orange_o100_text' },
  [NOT_A_DISCREPANCY]: {
    text: 'Not A Discrepancy',
    className: 'system_orange_o100_text',
  },
  [MISTAKENLY_RESOLVED]: {
    text: 'Mistakenly Resolved',
    className: 'system_orange_o100_text',
  },
  [NOT_REMOVED_FROM_REPORT]: {
    text: 'Not Removed From Report',
    className: 'system_orange_o100_text',
  },
  [RESOLVED_MYSELF]: { text: 'Resolved', className: 'system_orange_o100_text' },
  [CLOSED]: { text: 'Closed', className: 'system_orange_o100_text' },
  [REOPEN]: { text: 'In progress', className: 'system_orange_o100_text' },
  [IGNORED]: { text: 'Ignored', className: 'system_orange_o100_text' },
};

export const MAX_YEARS_TO_SHOW_PAYMENT_HISTORY = 7;
export const MIN_YEARS_TO_SHOW_PAYMENT_HISTORY = 2;

export const ACCOUNT_TYPES_MAP = {
  Collection: 'Collection',
  CreditCard: 'Credit Card',
  Installment: 'Installment',
  Other: 'Other',
  RealEstate: 'Real Estate',
  Revolving: 'Revolving',
  Unknown: 'Unknown',
};
export const TAXES_APPLICABLE_TEXT = '';
