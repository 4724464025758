import { LOGOUT } from 'types/app.constants';
import {
  SELECT_PROFILE_CONTAINER_DATA,
  RESET_PROFILE_CONTAINER_DATA,
} from 'types/profileContainer.constants';

const initialState = {
  profileContainer: null,
  containerLoading: false,
  containerError: null,
};

export default function profileContainerReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_PROFILE_CONTAINER_DATA: {
      return {
        ...state,
        profileContainer: action?.profileContainer,
        containerLoading: action?.containerLoading,
        containerError: action?.containerError,
      };
    }
    case LOGOUT:
    case RESET_PROFILE_CONTAINER_DATA:
      return initialState;
    default:
      return state;
  }
}
