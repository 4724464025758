//pages
export const CHANGE_EMAIL_FORM = 'CHANGE_EMAIL_FORM';
export const MAX_ATTEMPTS = 'MAX_ATTEMPTS';
export const PHONE_NOT_FOUND = 'PHONE_NOT_FOUND';
export const VERIFY_PHONE = 'VERIFY_PHONE';
export const VERIFY_IDENTITY = 'VERIFY_IDENTITY';
export const CODE_NOT_RECEIVED = 'CODE_NOT_RECEIVED';
export const EMAIL_UPDATED = 'EMAIL_UPDATED';
export const LOADING = 'LOADING';

//actions
export const SET_CHANGE_EMAIL_STEP = 'SET_CHANGE_EMAIL_STEP';
export const SUBMIT_FORM_SUCCESS = 'SUBMIT_FORM_SUCCESS';
export const SUBMIT_FORM_ERROR = 'SUBMIT_FORM_ERROR';
export const VERIFY_PHONE_SUCCESS = 'VERIFY_PHONE_SUCCESS';
export const VERIFY_PHONE_ERROR = 'VERIFY_PHONE_ERROR';
export const CLEAR_VERIFY_PHONE_ERROR = 'CLEAR_VERIFY_PHONE_ERROR';
export const VERIFY_IDENTITY_SUCCESS = 'VERIFY_IDENTITY_SUCCESS';
export const VERIFY_IDENTITY_ERROR = 'VERIFY_IDENTITY_ERROR';
export const SET_CODE_VALUE = 'SET_CODE_VALUE';
export const RESEND_CODE_SUCCESS = 'RESEND_CODE_SUCCESS';
export const RESEND_CODE_ERROR = 'RESEND_CODE_ERROR';
export const CLEAR_CHANGE_EMAIL_STATE = 'CLEAR_CHANGE_EMAIL_STATE';
