export function pinkyPromise(payload) {
  return new Promise((resolve, reject) => {
    resolve(payload);
  });
}

export async function a_pinkyPromise(o) {
  try {
    if (o?.then) {
      const v = await o.then;
      return v;
    }
  } catch (ex) {
    return {};
  }
}

export function rejection(payload) {
  return new Promise((resolve, reject) => reject(payload));
}

export function createDelayedPromise(payload, delay = 500) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(payload);
    }, delay);
  });
}

export function forceSuccess(promise) {
  return new Promise((resolve, reject) => {
    promise.then(_ => resolve(_)).catch(err => resolve(err));
  });
}
