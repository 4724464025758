import { isNully } from 'helpers/objectHelpers';
import { convertNormalTextToCamelCase } from 'helpers/string.helper';
import { getActionName } from 'partial/OfferManager/module.constants';
import {
  CREDIT_CARD_OFFER,
  PERSONAL_LOAN_OFFER,
  AUTO_INSURANCE,
  LIFE_INSURANCE,
  HOME_INSURANCE,
  CREDIT_REPAIR_OFFER,
  BUSINESS_LOAN_OFFER,
} from 'partial/OfferManager/offer.constants';
import {
  CREDIT_CARD_DETAIL,
  PERSONAL_LOAN_DETAIL,
} from 'types/morph.pagePosition.constants';
import { AUTOLOAN_DETAILS } from 'types/mrph.pageName.constants';

import moment from 'moment';
import { getWhatsChanged } from 'helpers/credit_info';
import * as UAParser from 'ua-parser-js';
import * as navigator from 'externals/_tracking/dom/navigator';
import { getRefCode, getCStrack, getUsrTkn } from 'helpers/localstorage';
import { cleanSrcValue } from 'helpers/src.helper';
import { getDiffMonthsBetweenDates } from 'helpers/dateHelper';
import {
  getMrphPageFormFactor,
  getSiteAttributeValue,
} from 'helpers/metadataLogic.helpers';
import { buildBaselineMixpanelAttributes } from 'externals/_tracking/mixpanel/mixpanelLocalStorageReader';
import {
  getDaysSinceWithNull,
  getDaysSince as getDaysSinceDateHelper,
} from 'helpers/dateHelper';
import { getCookieValue } from 'helpers/cookie.helper';
import {
  CREDIT_REPAIR_VERTICAL,
  DEBT_RELIEF_VERTICAL,
  MORTGAGE_VERTICAL,
} from 'types/mrph.vertical.constants';
import {
  AUTO_INSURANCE_VERTICAL,
  AUTO_LOAN_VERTICAL,
  BUSINESS_LOANS_VERTICAL,
  CREDIT_CARD_VERTICAL,
  HOME_INSURANCE_VERTICAL,
  LIFE_INSURANCE_VERTICAL,
  PERSONAL_LOAN_VERTICAL,
} from 'types/marketplaceTiles.constants';
import { getUserType } from './userHelper';

const ua = new UAParser.UAParser(navigator.getUserAgent());

export const getViewModuleTrackingAttributes = (
  pagePositionOrAttribute,
  modulePosition,
  pageName,
) => {
  let defaultProperties = {};
  if (
    typeof pagePositionOrAttribute === 'object' &&
    pagePositionOrAttribute !== null
  ) {
    defaultProperties['Module Name'] = pagePositionOrAttribute.moduleName
      ? pagePositionOrAttribute.moduleName
      : pagePositionOrAttribute.pagePosition;
    defaultProperties['Module Position'] =
      pagePositionOrAttribute.modulePosition;

    if (pagePositionOrAttribute.pageName) {
      defaultProperties['Page Name'] = pagePositionOrAttribute.pageName;
    }

    if (pagePositionOrAttribute.vertical) {
      defaultProperties['Vertical'] = pagePositionOrAttribute.vertical;
    }
    if (pagePositionOrAttribute.moduleHeading) {
      defaultProperties['Module Heading'] =
        pagePositionOrAttribute.moduleHeading;
    }
    if (pagePositionOrAttribute.moduleDetail) {
      defaultProperties['Module Detail'] = pagePositionOrAttribute.moduleDetail;
    }
  } else {
    defaultProperties['Module Name'] = pagePositionOrAttribute;
    defaultProperties['Module Position'] = modulePosition;

    if (pageName) {
      defaultProperties['Page Name'] = pageName;
    }
  }

  return defaultProperties;
};

export const getViewModalTrackingAttributes = (
  pagePosition,
  modalName,
  pageName,
) => {
  return {
    'Page Position': pagePosition,
    'Pop Up Name': modalName,
    'Page Name': pageName,
  };
};

export const cleanCardName = name => {
  let clean = name;
  clean = clean
    .replace('\u2120', '')
    .replace(/<[^>]+>/g, '')
    .replace(/&#[0-9]{1,9};/g, '');
  return clean;
};

export const getViewOfferTileCard = attributes => {
  return {
    'Module Name': attributes.moduleName,
    'Module Position': attributes.modulePosition,
    'Offer Module Position': attributes.offerModulePosition,
    'Card Name': attributes.cardName,
    Vertical: attributes.vertical,
  };
};

export const getAttributesSeenNotification = attributes => {
  return {
    'Notification Name': attributes.notificationName,
  };
};

const getDefaultEventPropertiesForDebtRelief = (attrs = {}) => {
  const {
    pagePosition,
    modulePosition,
    offerModulePosition,
    offerMetadata,
    attributes,
  } = attrs;
  let defaultProps = {
    Partner: offerMetadata.partnerCode,
    Vertical: DEBT_RELIEF_VERTICAL,
    'Offer Module Position': 1,
    'Module Position': modulePosition,
  };
  if (pagePosition) {
    defaultProps['Module Name'] = pagePosition;
  }
  if (offerModulePosition) {
    defaultProps['Offer Module Position'] = offerModulePosition;
  }
  if (offerMetadata && offerMetadata.debtReliefId) {
    // Temp solution  until SVC ticket SVC-8835 is resolved
    defaultProps['Product Key'] = offerMetadata.name.toLowerCase();
  }

  return Object.assign({}, defaultProps, attributes);
};

export const getDefaultEventPropertiesForAutoInsurance = (attrs = {}) => {
  const { pagePosition, offerMetadata, attributes, includeBackendRefCode } =
    attrs;
  let defaultProps = {
    Partner: offerMetadata.displayName,
    'Insurance Name': offerMetadata.displayName,
    Vertical: AUTO_INSURANCE_VERTICAL,
    'Product Key': offerMetadata?.accountId,
  };
  if (pagePosition) {
    defaultProps['Module Name'] = pagePosition;
  }
  if (includeBackendRefCode) {
    defaultProps['Ref Code'] = getRefCode();
  }
  return Object.assign({}, defaultProps, attributes);
};

export const getDefaultEventPropertiesForLifeInsurance = (attrs = {}) => {
  const { pagePosition, offerMetadata, attributes, includeBackendRefCode } =
    attrs;
  let defaultProps = {
    Partner: offerMetadata.company,
    'Insurance Name': offerMetadata.displayName,
    Vertical: LIFE_INSURANCE_VERTICAL,
    'Product Key': offerMetadata?.accountId,
  };
  if (pagePosition) {
    defaultProps['Module Name'] = pagePosition;
  }
  if (includeBackendRefCode) {
    defaultProps['Ref Code'] = getRefCode();
  }
  return Object.assign({}, defaultProps, attributes);
};

export const getDefaultEventPropertiesForHomeInsurance = (attrs = {}) => {
  const { pagePosition, offerMetadata, attributes, includeBackendRefCode } =
    attrs;
  let defaultProps = {
    Partner: offerMetadata.displayName,
    'Insurance Name': offerMetadata.displayName,
    Vertical: HOME_INSURANCE_VERTICAL,
    'Product Key': offerMetadata?.accountId,
  };
  if (pagePosition) {
    defaultProps['Module Name'] = pagePosition;
  }
  if (includeBackendRefCode) {
    defaultProps['Ref Code'] = getRefCode();
  }
  return Object.assign({}, defaultProps, attributes);
};

const getDefaultEventPropertiesForCreditRepair = (attrs = {}) => {
  const {
    pagePosition,
    modulePosition,
    position,
    extraAttributes,
    offerMetadata,
    includeBackendRefCode,
  } = attrs;
  let defaultProps = {
    Vertical: CREDIT_REPAIR_VERTICAL,
    'Offer Module Position': 1,
    'Module Position': modulePosition,
  };
  if (pagePosition) {
    defaultProps['Module Name'] = pagePosition;
  }
  if (offerMetadata && offerMetadata.productKey) {
    defaultProps['Product Key'] = offerMetadata.productKey;
    defaultProps['Partner'] = offerMetadata.partnerCode;
  } else if (offerMetadata && offerMetadata.id) {
    defaultProps['Product Key'] = offerMetadata.id;
    defaultProps['Partner'] = offerMetadata.partnerCode;
  }
  if (includeBackendRefCode) {
    defaultProps['Ref Code'] = getRefCode();
  }
  defaultProps['Offer Module Position'] = position ? position : 1;

  if (offerMetadata.creditRepairId) {
    defaultProps['Credit Repair Id'] = offerMetadata.creditRepairId;
  }

  return Object.assign({}, defaultProps, extraAttributes);
};

const getDefaultEventPropertiesForCreditCards = (attrs = {}) => {
  const {
    offerMetadata,
    containerType,
    position,
    issuerOverwrite,
    pagePositionOverwrite,
    modulePosition,
    isModal,
    includeBackendRefCode,
    moduleHeading,
  } = attrs;
  const criteriaId = offerMetadata.offerRankingDetails
    ? offerMetadata.offerRankingDetails.criteriaId
    : null;
  const offerRank = offerMetadata.offerRankingDetails
    ? offerMetadata.offerRankingDetails.offerRank
    : null;

  let defaultProps = {
    'Card Name': cleanCardName(offerMetadata.name).replace(/<[^>]+>/g, ''),
    'Potential Credit Limit': offerMetadata.predictedCreditLimit,
    'Review Count': offerMetadata.totalComments,
    'Review Rating': offerMetadata.reviewRating,
    Vertical: CREDIT_CARD_VERTICAL,
    'Product Offer': offerMetadata.productId,
    'Product Id': offerMetadata.creditCardId,
    'Product Key': offerMetadata.productKey,
    'Page Form Factor': getMrphPageFormFactor(),
    'Criteria Id': criteriaId,
    'Offer Rank': offerRank,
    Partner: offerMetadata.partnerCode,
    Issuer: offerMetadata.issuer,
  };

  if (offerMetadata.isFeaturedAd) {
    defaultProps = {
      'Card Name': cleanCardName(offerMetadata.name),
      Vertical: CREDIT_CARD_VERTICAL,
      'Product Key': offerMetadata.productKey,
      'Page Form Factor': getMrphPageFormFactor(),
      Partner: offerMetadata.issuer,
    };
  }
  if (isModal) {
    defaultProps['Pop Up Name'] = CREDIT_CARD_DETAIL;
    if (!position) {
      defaultProps['Offer Module Position'] = 1;
    }
  } else {
    defaultProps['Module Position'] = modulePosition;
  }
  if (pagePositionOverwrite) {
    defaultProps['Module Name'] = pagePositionOverwrite;
  } else if (containerType) {
    defaultProps['Module Name'] = containerType;
  }
  if (typeof position === 'number') {
    defaultProps['Offer Module Position'] = position;
  }
  if (issuerOverwrite) {
    defaultProps['Issuer'] = issuerOverwrite;
  }
  if (includeBackendRefCode) {
    defaultProps['Ref Code'] = getRefCode();
  }
  if (offerMetadata.approvalOdds) {
    defaultProps['Approval Odds'] = offerMetadata.approvalOdds;
  }
  if (offerMetadata.approvalOdds) {
    defaultProps['Approval Odds'] = offerMetadata.approvalOdds;
  }
  if (offerMetadata.approvalOddsSource) {
    defaultProps['Approval Odds Source'] = offerMetadata.approvalOddsSource;
  }
  if (moduleHeading) {
    defaultProps['Module Heading'] = moduleHeading;
  }
  if (offerMetadata.observedApprovalOdds) {
    defaultProps['Observed Approval Odds'] = offerMetadata.observedApprovalOdds;
  }
  if (offerMetadata.experienceName) {
    defaultProps['Experience Name'] = offerMetadata.experienceName;
  }
  if (offerMetadata.initialApprovalOdds) {
    defaultProps['Data Science Approval Odds'] =
      offerMetadata.initialApprovalOdds;
  }
  if (offerMetadata.initialApprovalOddsModelId) {
    defaultProps['Data Science Approval Odds Model Id'] =
      offerMetadata.initialApprovalOddsModelId;
  }
  if (offerMetadata.initialApprovalOddsPredictedProbability) {
    defaultProps['Data Science Approval Odds Predicted Probability'] =
      offerMetadata.initialApprovalOddsPredictedProbability;
  }
  if (offerMetadata.cciModelId) {
    defaultProps['Cci Model Id'] = offerMetadata.cciModelId;
  }

  if (offerMetadata.ccInterestRank) {
    defaultProps['Cc Interest Rank'] = offerMetadata.ccInterestRank;
  }

  return defaultProps;
};

const getDefaultEventPropertiesForPersonalLoans = (attrs = {}) => {
  const {
    offerMetadata,
    position,
    pagePosition,
    modulePosition,
    pagePositionOverwrite,
    isModal,
    includeBackendRefCode,
  } = attrs;
  let defaultProps = {
    'Loan Name': offerMetadata.name,
    'Product Key': offerMetadata.productKey ? offerMetadata.productKey : null,
    'Potential Credit Limit': offerMetadata.predictedCreditLimit,
    'Review Count': offerMetadata.totalComments,
    'Review Rating': offerMetadata.reviewRating,

    'Offer Module Position': position,
    Vertical: PERSONAL_LOAN_VERTICAL,
    'Product Offer': offerMetadata.productId,
    'Product Id': offerMetadata.offerId,
  };

  if (offerMetadata.isFeaturedAd) {
    defaultProps = {
      'Loan Name': offerMetadata.name,
      'Product Key': offerMetadata.productKey ? offerMetadata.productKey : null,
      'Offer Module Position': position,
      Vertical: PERSONAL_LOAN_VERTICAL,
    };
  }

  if (offerMetadata.name && !offerMetadata.partnerCode) {
    defaultProps['Partner'] = offerMetadata.name;
  } else {
    defaultProps['Partner'] = offerMetadata.partnerCode;
    defaultProps['Issuer'] = offerMetadata.name;
  }

  if (isModal) {
    defaultProps['Pop Up Name'] = PERSONAL_LOAN_DETAIL;
    if (!position) {
      defaultProps['Offer Module Position'] = 1;
    }
    defaultProps['Module Position'] = 1;
  } else {
    defaultProps['Module Position'] = modulePosition;
  }

  if (pagePositionOverwrite) {
    defaultProps['Module Name'] = pagePositionOverwrite;
  } else if (pagePosition) {
    defaultProps['Module Name'] = pagePosition;
  }

  if (includeBackendRefCode) {
    defaultProps['Ref Code'] = getRefCode();
  }

  return defaultProps;
};

const getDefaultEventPropertiesForAutoLoans = (attrs = {}) => {
  const {
    offerMetadata,
    position,
    pagePosition,
    modulePosition,
    pagePositionOverwrite,
    isModal,
    includeBackendRefCode,
  } = attrs;
  let defaultProps = {
    'Loan Name': offerMetadata.provider.lenderName,
    'Product Key': offerMetadata.productKey ? offerMetadata.productKey : null,
    'Offer Module Position': position,
    Partner: offerMetadata.provider.partnerCode,
    Issuer: offerMetadata.provider.lenderName,
    Vertical: AUTO_LOAN_VERTICAL,
  };

  if (offerMetadata.isFeaturedAd) {
    defaultProps = {
      'Card Name': cleanCardName(offerMetadata.name),
      Vertical: AUTO_LOAN_VERTICAL,
      'Product Key': offerMetadata.productKey,
      'Page Form Factor': getMrphPageFormFactor(),
      Partner: offerMetadata.issuer,
    };
  }

  if (isModal) {
    defaultProps['Pop Up Name'] = AUTOLOAN_DETAILS;
    if (!position) {
      defaultProps['Offer Module Position'] = 1;
    }
    defaultProps['Module Position'] = 1;
  } else {
    defaultProps['Module Position'] = modulePosition;
  }

  if (pagePositionOverwrite) {
    defaultProps['Module Name'] = pagePositionOverwrite;
  } else if (pagePosition) {
    defaultProps['Module Name'] = pagePosition;
  }

  if (includeBackendRefCode) {
    defaultProps['Ref Code'] = getRefCode();
  }

  return defaultProps;
};

const getDefaultEventPropertiesForHomeLoans = (attrs = {}) => {
  const { offerMetadata, position, pagePosition, modulePosition } = attrs;
  const offerDetails = offerMetadata.bestProduct.lender;
  let defaultProps = {
    'Product Key': offerDetails.lenderKey ? offerDetails.lenderKey : null,
    'Product Id': offerMetadata.mortgageId ? offerMetadata.mortgageId : null,
    'Offer Module Position': position,
    Partner: offerDetails.name ? offerDetails.name : null,
    Vertical: MORTGAGE_VERTICAL,
  };

  defaultProps['Module Position'] = modulePosition;

  defaultProps['Module Name'] = pagePosition;

  return defaultProps;
};

const getDefaultEventPropertiesForBusinessLoan = (attrs = {}) => {
  const { pagePosition, offerMetadata, attributes } = attrs;
  let defaultProps = {
    Partner: offerMetadata.name,
    Vertical: BUSINESS_LOANS_VERTICAL,
    'Product Key': offerMetadata.productKey,
  };
  if (pagePosition) {
    defaultProps['Module Name'] = pagePosition;
  }
  return Object.assign({}, defaultProps, attributes);
};
export const getFullAttributes = (attrs = {}) => {
  const {
    partner,
    pagePosition,
    modulePosition,
    offerMetadata,
    position,
    extraAttributes,
    attributes,
    isModal,
    isPrequal,
    containerType,
    includeBackendRefCode,
    partnersCustomParams,
    moduleHeading,
  } = attrs;
  let defaultAttributes = {};
  const pagePositionOverwrite = pagePosition;

  if (offerMetadata) {
    if (offerMetadata.offerType === CREDIT_CARD_OFFER) {
      const issuerOverwrite = isPrequal ? partner : false;
      defaultAttributes = getDefaultEventPropertiesForCreditCards({
        offerMetadata: offerMetadata,
        containerType: containerType,
        position: position,
        issuerOverwrite,
        modulePosition: modulePosition,
        isModal: isModal,
        includeBackendRefCode: includeBackendRefCode,
        moduleHeading: moduleHeading,
        pagePositionOverwrite,
      });
    } else if (offerMetadata.offerType === PERSONAL_LOAN_OFFER) {
      defaultAttributes = getDefaultEventPropertiesForPersonalLoans({
        offerMetadata: offerMetadata,
        position: position,
        pagePosition: pagePosition,
        modulePosition: modulePosition,
        isModal: isModal,
        includeBackendRefCode: includeBackendRefCode,
      });
    } else if (offerMetadata.autoLoanRateUuid) {
      defaultAttributes = getDefaultEventPropertiesForAutoLoans({
        offerMetadata: offerMetadata,
        position: position,
        pagePosition: pagePosition,
        modulePosition: modulePosition,
        isModal: isModal,
        includeBackendRefCode: includeBackendRefCode,
      });
    } else if (offerMetadata.mortgageId !== undefined) {
      defaultAttributes = getDefaultEventPropertiesForHomeLoans({
        offerMetadata: offerMetadata,
        position: position,
        pagePosition: pagePosition,
        modulePosition: modulePosition,
        includeBackendRefCode: includeBackendRefCode,
      });
    } else if (
      offerMetadata.creditRepairId ||
      offerMetadata?.offerType === CREDIT_REPAIR_OFFER
    ) {
      defaultAttributes = getDefaultEventPropertiesForCreditRepair({
        pagePosition: pagePosition,
        modulePosition: modulePosition,
        position: position,
        extraAttributes: extraAttributes,
        offerMetadata: offerMetadata,
        includeBackendRefCode: includeBackendRefCode,
      });
    } else if (offerMetadata.debtReliefId) {
      defaultAttributes = getDefaultEventPropertiesForDebtRelief({
        pagePosition: pagePosition,
        modulePosition: modulePosition,
        position: position,
        offerMetadata: offerMetadata,
        attributes: attributes,
      });
    } else if (offerMetadata.isFeaturedAd) {
      defaultAttributes = {
        'Product Key': offerMetadata.productKey,
        Partner: offerMetadata.productKey,
      };
    } else if (offerMetadata.offerType === AUTO_INSURANCE) {
      defaultAttributes = getDefaultEventPropertiesForAutoInsurance({
        pagePosition: pagePosition,
        offerMetadata: offerMetadata,
        attributes: attributes,
        includeBackendRefCode: includeBackendRefCode,
      });
    } else if (offerMetadata.offerType === LIFE_INSURANCE) {
      defaultAttributes = getDefaultEventPropertiesForLifeInsurance({
        pagePosition: pagePosition,
        offerMetadata: offerMetadata,
        attributes: attributes,
        includeBackendRefCode: includeBackendRefCode,
      });
    } else if (offerMetadata.offerType === HOME_INSURANCE) {
      defaultAttributes = getDefaultEventPropertiesForHomeInsurance({
        pagePosition: pagePosition,
        offerMetadata: offerMetadata,
        attributes: attributes,
        includeBackendRefCode: includeBackendRefCode,
      });
    } else if (offerMetadata.offerType === BUSINESS_LOAN_OFFER) {
      defaultAttributes = getDefaultEventPropertiesForBusinessLoan({
        pagePosition: pagePosition,
        offerMetadata: offerMetadata,
        attributes: attributes,
      });
    }
    const parentAttributes =
      typeof extraAttributes === 'object' ? extraAttributes : {};
    const partnersParams =
      typeof partnersCustomParams === 'object' ? partnersCustomParams : {};

    const commonAttributes = {
      Site: getSiteAttributeValue(),
    };

    const fullAttributes = Object.assign(
      {},
      attributes,
      commonAttributes,
      defaultAttributes,
      parentAttributes,
      partnersParams,
    );
    return fullAttributes;
  }
};

export const getAttributesForRefreshProfile = creditInfo => {
  const whatsChanged = getWhatsChanged(creditInfo);
  const score = whatsChanged.creditScore;
  const trackingMetadata = {
    'CS Band': getCreditScoreBandIII(score.current),
    'Number of Points Change in Score': score.difference,
    'CC Debt': whatsChanged.creditCardBalances.current,
    'Old CC Debt': whatsChanged.creditCardBalances.previous,
    'Credit Utilization Ratio': whatsChanged.creditUsage.current,
    'Old Credit Utilization Ratio': whatsChanged.creditUsage.previous,
    'Total Debt': whatsChanged.totalBalances.current,
    'Old Total Debt': whatsChanged.totalBalances.previous,
    'Credit Score Band': getCreditScoreBandIII(score.current),
    'Old Credit Score Band': getCreditScoreBandIII(score.previous),
    'Credit Usage Grade': whatsChanged.creditUsageGrade.current,
    'Old Credit Usage Grade': whatsChanged.creditUsageGrade.previous,
    'Payment History Grade': whatsChanged.paymentHistoryGrade.current,
    'Old Payment History Grade': whatsChanged.paymentHistoryGrade.previous,
    'Change in # of Inquiries': whatsChanged.creditInquiries.difference,
    'Change in # of Collections': whatsChanged.collections.difference,
    'Change in # of Negative Marks': whatsChanged.negativeMarks.difference,
  };

  return trackingMetadata;
};

export const getAttributesForRegister = creditInfo => {
  let creditCardLimit = 0;
  creditInfo.debtAnalysis.creditCardDebt.accounts.forEach(x => {
    creditCardLimit += x.creditLimit;
  });
  const creditCardDebt = creditInfo.debtAnalysis.creditCardDebt.totalDebt;
  const negativeMarks =
    creditInfo.publicRecordsCount +
    creditInfo.creditScoreAnalysis.paymentHistory.negativeRemarkAccounts.length;
  const trackingMetadata = {
    'CS Band': getCreditScoreBandIII(creditInfo.creditScore),
    'CC Debt': creditInfo.debtAnalysis.creditCardDebt.totalDebt,
    'Total Debt': creditInfo.debtAnalysis.totalDebt,
    'Total Personal Loan Debt': creditInfo.debtAnalysis.otherLoanDebt.totalDebt,
    'Total Student Loan Debt':
      creditInfo.debtAnalysis.studentLoanDebt.totalDebt,
    'Total Mortgage Balance': creditInfo.debtAnalysis.homeLoanDebt.totalDebt,
    'Number of Credit Cards':
      creditInfo.debtAnalysis.creditCardDebt.accounts.length,
    'Number of Personal Loans':
      creditInfo.debtAnalysis.otherLoanDebt.accounts.length,
    'Number of Mortgages': creditInfo.debtAnalysis.homeLoanDebt.accounts.length,
    'CC Utilization Ratio': Math.round(
      (creditCardDebt / creditCardLimit) * 100,
    ),
    'Credit Score Band': getCreditScoreBandIII(creditInfo.creditScore),
    'Number of negative marks': negativeMarks,
    'Number of collections':
      creditInfo.creditScoreAnalysis.paymentHistory.collections,
    'Number of Inquiries':
      creditInfo.creditScoreAnalysis.creditInquiries.last12Months,
  };

  return trackingMetadata;
};

export const getPublicMetadata = (attrs = {}, defaultTracking) => {
  const finalExtraAttrs = Object.assign(
    {},
    buildBaselineMixpanelAttributes(),
    {
      'Logged In': 'No',
      'OS Version': ua.getOS().version,
      'Page Form Factor': getMrphPageFormFactor(),
      Site: getSiteAttributeValue(),
      'User Browser': ua.getBrowser().name,
      VisitorSessionID: getCookieValue('vid'),
    },
    defaultTracking,
    attrs,
  );

  return finalExtraAttrs;
};

const parseValueFromUtmzCookie = name => {
  let gtmVal = getCookieValue('__utmz');

  if (gtmVal && gtmVal.indexOf(`${name}=`) !== false) {
    let start = gtmVal.indexOf(`${name}=`) + 7;
    let end = gtmVal.indexOf('|', start);
    if (end) {
      return gtmVal.substr(start, end - start);
    }
  }

  return '';
};

export const getSrc = () => {
  let src = cleanSrcValue(getCookieValue('src'));
  if (!src) {
    src = parseValueFromUtmzCookie('utmccn');
  }
  return src;
};

export const getSubSrc = () => {
  let subSrc = cleanSrcValue(getCookieValue('subSrc'));
  if (!subSrc) {
    subSrc = parseValueFromUtmzCookie('utmcsr');
  }
  return subSrc;
};

export function getCreditScoreBandI(creditScore) {
  return Math.round(Math.floor(creditScore / 25));
}

export function getCreditScoreBandII(creditScore) {
  if (creditScore >= 750) {
    return 'Excellent';
  } else if (700 <= creditScore && creditScore <= 749) {
    return 'Very Good';
  } else if (650 <= creditScore && creditScore <= 699) {
    return 'Good';
  } else if (600 <= creditScore && creditScore <= 649) {
    return 'Average';
  } else {
    return 'Bad';
  }
}

export function getCreditScoreBandIII(creditScore) {
  if (creditScore === 4) {
    return 'No Credit';
  } else {
    if (creditScore >= 720) {
      return 'Excellent';
    } else if (640 <= creditScore && creditScore <= 719) {
      return 'Good';
    } else if (550 <= creditScore && creditScore <= 639) {
      return 'Fair';
    } else if (500 <= creditScore && creditScore <= 549) {
      return 'Poor';
    } else {
      return 'Very Poor';
    }
  }
}

export function resolveBooleanToYesOrNo(val) {
  if (val) {
    return 'Yes';
  } else {
    return 'No';
  }
}

export function getCreditLimitShiftSinceRefresh(creditTrends) {
  if (creditTrends.creditData.length > 1) {
    const creditDataTrends = creditTrends.creditData;
    const currentLimit = creditDataTrends[creditDataTrends.length - 1].value;
    const previousLimit = creditDataTrends[creditDataTrends.length - 2].value;
    return currentLimit - previousLimit;
  }
}

export function getCSShiftSinceRefresh(creditTrends) {
  if (creditTrends?.creditScores.length > 1) {
    const creditScoreTrends = creditTrends?.creditScores;
    const currentScore = creditScoreTrends[creditScoreTrends.length - 1].value;
    const previousScore = creditScoreTrends[creditScoreTrends.length - 2].value;
    return currentScore - previousScore;
  } else {
    return 0;
  }
}

export function getCSShiftSinceSignup(creditTrends) {
  if (creditTrends?.creditScores?.length > 1) {
    const creditScoreTrends = creditTrends?.creditScores;
    const currentScore = creditScoreTrends[creditScoreTrends.length - 1].value;
    const previousScore = creditScoreTrends[0].value;
    return currentScore - previousScore;
  } else {
    return 0;
  }
}

export function getCUShiftSinceRefresh(creditTrends) {
  if (creditTrends.creditUsages.length > 1) {
    const creditUsageTrends = creditTrends.creditUsages;
    const currentCU = creditUsageTrends[creditUsageTrends.length - 1].value;
    const previousCU = creditUsageTrends[creditUsageTrends.length - 2].value;
    return currentCU - previousCU;
  }
}

export function getCUShiftSinceSignup(creditTrends) {
  if (creditTrends.creditUsages.length > 1) {
    const creditUsageTrends = creditTrends.creditUsages;
    const currentCU = creditUsageTrends[creditUsageTrends.length - 1].value;
    const previousCU = creditUsageTrends[0].value;
    return currentCU - previousCU;
  }
}

function getCookieInitialReferrer() {
  const cookieValue = getCookieValue('initial_referrer_url');
  if (cookieValue) {
    const ckv = decodeURIComponent(cookieValue);
    const ckv_obj = JSON.parse(ckv);
    return ckv_obj;
  }
}

export function getPropFromInitialRefCookie(cookieProp = 'initial_referrer') {
  const cookieInitialReferrer = getCookieInitialReferrer();
  if (cookieInitialReferrer) {
    return cookieInitialReferrer[cookieProp];
  }
}

export function getShiftInInquiries(creditTrends) {
  if (creditTrends.numEnquiries.length > 1) {
    const creditInquiryTrend = creditTrends.numEnquiries;
    const currentInquiries =
      creditInquiryTrend[creditInquiryTrend.length - 1].value;
    const previousInquiries =
      creditInquiryTrend[creditInquiryTrend.length - 2].value;
    return currentInquiries - previousInquiries;
  }
}

export function __monthSinceTradelineWithNameMatch(ccAccounts, name) {
  let newestTradelineDate = null;
  let creditCardAccount = null;
  let creditCardStringCleaned = null;

  for (var i = 0; i < ccAccounts.length; i++) {
    creditCardAccount = ccAccounts[i];
    creditCardStringCleaned = creditCardAccount.name.toLowerCase();

    if (creditCardStringCleaned.indexOf(name) >= 0) {
      if (!newestTradelineDate) {
        newestTradelineDate = creditCardAccount.openedDate;
      } else {
        if (
          getDiffMonthsBetweenDates(
            creditCardAccount.openedDate,
            newestTradelineDate,
          ) >= 0
        ) {
          newestTradelineDate = creditCardAccount.openedDate;
        }
      }
    }
  }

  if (newestTradelineDate !== null) {
    return getDiffMonthsBetweenDates(null, newestTradelineDate);
  } else {
    return null;
  }
}

export function getDaysSince(stringDate) {
  if (!stringDate) {
    return 0;
  }
  const dateMoment = moment(stringDate);
  return moment().diff(dateMoment, 'days');
}

export function getLastCharacter(s) {
  return s.substr(s.length - 1);
}

export function getLast3Character(s) {
  if (s.length > 2) {
    return s.substr(s.length - 3);
  } else {
    return s;
  }
}

export const isUserEnrolledInCash = userInfo => {
  return userInfo?.banking?.enrollStatus ?? 'Unknown';
};

export const buildUserMeta = (userProfile, userCreditInfo) => {
  const isFunded = userProfile?.banking
    ? userProfile.banking?.accountFunded
    : false;
  const isEnrolledInCash = userProfile?.banking
    ? userProfile?.banking?.enrollStatus === 'REGISTERED'
    : false;
  const daysSinceRegistration = getDaysSinceWithNull(userProfile?.createdDate);
  const planType = userProfile?.premiumPlanInfo?.serviceId ?? 'Free';
  const daysSinceLastLogin = getDaysSinceDateHelper(
    userProfile?.previousLoginDate,
  );
  const creditTrends = userCreditInfo?.trends;
  const csShiftSinceRefresh = getCSShiftSinceRefresh(creditTrends);
  const userType = getUserType(userProfile);
  const userTypeMeta = userType ? { 'User Type': userType } : {};
  return {
    'Cash Enroll Status': isUserEnrolledInCash(userProfile),
    City: userProfile?.primaryAddress?.city,
    Country: userProfile?.primaryAddress?.country,
    'Enrolled in Sesame Cash': isEnrolledInCash,
    'Funded Sesame Cash account': isFunded,
    'Last 3 Digit Account': getLast3Character(String(userProfile?.accountId)),
    'Last Login': userProfile?.previousLoginDate,
    'Logged In': 'Yes',
    'Page Form Factor': getMrphPageFormFactor(),
    'State of Residence': userProfile?.primaryAddress?.state,
    'Zip Code': userProfile?.primaryAddress?.zip,
    'CS Shift Since Refresh': csShiftSinceRefresh,
    'CS Shift Since Sign Up': getCSShiftSinceSignup(creditTrends),
    'Days Since Last Login': daysSinceLastLogin,
    Homeowner: userProfile?.homeOwnerStatus,
    'Logged In Count': userProfile?.loggedInCount,
    'Logged In Count Per Month': userProfile?.loggedInCountPerMonth,
    'Number of Days Since Registration': daysSinceRegistration,
    'Delinquent Accounts': userCreditInfo?.delinquentAccountsCount,
    'Derogatory Accounts': userCreditInfo?.derogatoryAccountsCount,
    'Inquiry Count':
      userCreditInfo?.creditScoreAnalysis?.creditInquiries?.last12Months,
    'Plan Type': planType,
    Status: userProfile?.rentReporting?.status,
    'Sub Status': userProfile?.rentReporting?.subStatus,
    'User Segment': userProfile?.userSegmentationNumber,
    'Credit Utilization':
      userCreditInfo?.creditScoreAnalysis?.creditUsage?.creditUtilization,
    'Credit Score': userCreditInfo?.creditScore,
    'Late Payments':
      userCreditInfo?.creditScoreAnalysis?.paymentHistory?.latePaymentAccounts,
    'CS Band I': getCreditScoreBandI(userCreditInfo?.creditScore),
    'Last Refresh Date': userCreditInfo?.lastRefreshDate,
    'Product Offer': userProfile?.premiumPlanInfo?.serviceId,
    VisitorSessionID: getCookieValue('vid'),
    'Sign Up Status': userProfile?.signUpStatus,
    ...userTypeMeta,
  };
};

export const buildUserMetaAsCamelCase = (userInfo, creditInfo) => {
  if (!creditInfo || !userInfo) return {};
  const userMeta = {
    ...buildUserMeta(userInfo, creditInfo),
    ...getAttributesForRefreshProfile(creditInfo),
    ...getAttributesForRegister(creditInfo),
  };

  return Object.assign(
    {},
    ...Object.keys(userMeta)?.map(k => {
      if (isNully(k) || isNully(k.replaceAll)) {
        return {};
      }
      return {
        [convertNormalTextToCamelCase(k?.replaceAll('#', 'Num'))]: userMeta[k],
      };
    }),
  );
};

export const buildCreditProfileMeta = (
  creditProfile,
  sessionToken,
  refCode,
) => {
  return {
    CreditReportId: creditProfile.creditProfileId,
    CreditScoreId: creditProfile.creditProfileId,
    'Last Refresh Date': creditProfile.lastRefreshDate,
    'Ref Code': refCode, //Need to pull from GET parameters
    'Session Identifier': sessionToken,
    Site: 'Morpheus',
    VisitorSessionID: getCookieValue('vid'),
    whatsChanged: getWhatsChanged(creditProfile),
  };
};

export const getZingoTrackingParams = () => {
  return {
    userToken: getUsrTkn(),
    sessionIdentifier: getCStrack(),
  };
};

export const delightMessageMetaData = creditInfo => {
  const creditTrends = creditInfo?.trends;
  return {
    'CS Band I': getCreditScoreBandI(creditInfo?.creditScore),
    'CS Band II': getCreditScoreBandII(creditInfo?.creditScore),
    'CS Band III': getCreditScoreBandIII(creditInfo?.creditScore),
    'CS Shift Since Refresh': getCSShiftSinceRefresh(creditTrends),
    'CS Shift Since Sign Up': getCSShiftSinceSignup(creditTrends),
  };
};

export const getSeenOfferActionTrackingAttributes = ({
  action,
  offerModulePosition,
  modulePosition,
  moduleName,
  moduleHeading,
  pageName,
  vertical,
  moduleMetadata,
}) => {
  const extraTileTrackingAttrs = moduleMetadata?.extraTileTrackingAttrs ?? {};
  const defaultAttr = {
    'Action Name':
      moduleMetadata?.tileNameOverride ?? getActionName(action) ?? action,
    'Offer Module Position': offerModulePosition,
    'Module Position': modulePosition,
    'Module Name': moduleName,
    'Page Name': pageName,
    'Page Form Factor': getMrphPageFormFactor(),
    ...extraTileTrackingAttrs,
  };

  if (moduleMetadata?.tileVertical) {
    defaultAttr['Vertical'] = moduleMetadata?.tileVertical;
  }
  if (moduleHeading) {
    defaultAttr['Module Heading'] = moduleHeading; // text shown at the top of the module
  }

  if (vertical) {
    defaultAttr.Vertical = vertical;
  }

  return defaultAttr;
};

export const getClickSliderTrackingAttributes = ({ clickType, vertical }) => {
  const defaultAttr = {
    'Click Type': clickType,
    'Page Form Factor': getMrphPageFormFactor(),
    Vertical: vertical,
  };

  return defaultAttr;
};

export const getAutoloanClickTrackingAttributes = ({
  clickType,
  vertical,
  module,
}) => {
  const trackingAttributes = {
    'Click Type': clickType,
    'Page Form Factor': getMrphPageFormFactor(),
    Vertical: vertical,
  };

  if (module) {
    trackingAttributes['Module Name'] = module;
  }

  return trackingAttributes;
};

export const getHomeloanClickTrackingAttributes = ({
  clickType,
  vertical,
  module,
}) => {
  const trackingAttributes = {
    'Click Type': clickType,
    'Page Form Factor': getMrphPageFormFactor(),
    Vertical: vertical,
  };

  if (module) {
    trackingAttributes['Module Name'] = module;
  }

  return trackingAttributes;
};
