export const CSID_INITIAL_ENROLLMENT_ERROR = 'CSID_INITIAL_ENROLLMENT_ERROR';
export const CSID_PREMIUM_ENROLLMENT_ERROR = 'CSID_PREMIUM_ENROLLMENT_ERROR';
export const CSID_UNABLE_TO_PULL_3B_REPORT = 'CSID_UNABLE_TO_PULL_3B_REPORT';

export const THREE_BUREAU_ENROLLMENT_TEXT = '3B Enrollment';
export const THREE_BUREAU_ENROLLMENT_WARNING_TYPE_TEXT = '3B Enrollment Warning Type';
export const THREE_BUREAU_ENROLLMENT_WARNING_MESSAGE_TEXT = '3B Enrollment Warning Message';

export const THN_1001 = 'THN_1001';
export const THN_1002 = 'THN_1002';

export const ADD_THIN_FILE_STORAGE = 'ADD_THIN_FILE_STORAGE';
export const REMOVE_THIN_FILE_STORAGE = 'REMOVE_THIN_FILE_STORAGE';
