import {
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION,
} from 'types/notification.constants';

const initialState = {
  notifications: [],
  index: 0,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return Object.assign({}, state, {
        notifications: [...state.notifications, action.notification],
        index: action.index,
      });
    case DELETE_NOTIFICATION:
      return Object.assign({}, state, {
        notifications: [...state.notifications.filter(n => n.id !== action.id)],
      });
    default:
      return state;
  }
};

export default notificationReducer;
