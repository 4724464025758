import { createLogger } from 'helpers/logger';
import { getUsrTkn } from 'helpers/localstorage';
import { doesMarketingUrlIncludeIrclickid } from 'helpers/marketing.helper';

const logger = createLogger({
  name: 'impact.track',
});

export const identifyUser = usrTkn => {
  const userToken = usrTkn || getUsrTkn();
  try {
    window.ire('identify', {
      customerId: userToken,
      customerEmail: '',
    });
    logger.info('User has been registered on Impact', {});
  } catch (err) {
    logger.error(err.toString(), {});
  }
};

export const trackConversionPostSignup = usrTkn => {
  const userToken = usrTkn || getUsrTkn();
  // Only send information to Impact when 'inbound_trk_params' cookie has 'marketingUrl' and its value includes 'irclickid'
  if (doesMarketingUrlIncludeIrclickid()) {
    try {
      window.ire(
        'trackConversion',
        36582,
        {
          orderId: userToken,
          customerId: userToken,
          customerEmail: '',
        },
        {
          verifySiteDefinitionMatch: true,
        },
      );
      logger.info('Track Conversion executed', {});
    } catch (err) {
      logger.error(err.toString(), {});
    }
  }
};
