import { HIGHLIGHT_FEATURES_PAYLOAD, PAYLOAD } from 'types/payload.constants';
import { LOGIN_SUCCESS } from 'types/app.constants';
import { mergePayloadChanges } from 'helpers/reducer.helper';
import { LOGOUT } from 'externals/_tracking/mixpanel/types/Mixpanel.event.types';

const initialState = {
  highlightFeaturesLoading: false,
  highlightFeatures: null,
  highlightFeaturesError: null,
};

export default function highlightFeaturesReducer(state = initialState, action) {
  switch (action.type) {
    case HIGHLIGHT_FEATURES_PAYLOAD:
    case LOGIN_SUCCESS:
    case PAYLOAD:
      return mergePayloadChanges(state, action);
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
}
