import React from 'react';
import 'partial/GoogleAutocomplete/GoogleAutocomplete.scss';

export const GoogleMapsEl = () => {
  return <div id="mapsHolder"></div>;
};

export const GoogleAutocomplete = ({ autoCompleteObj }) => {
  if (
    autoCompleteObj.autosuggestions &&
    autoCompleteObj.autosuggestions.length > 0
  ) {
    return (
      <div className="autocomplete-container">
        {autoCompleteObj.autosuggestions.map((suggestion, index) => {
          let classes = `autocomplete-item ${
            suggestion.index === autoCompleteObj.highlighted ? 'is-active' : ''
          }`;
          let color =
            suggestion.index === autoCompleteObj.highlighted ? 'red' : 'grey';
          let icon = `autocomplete-icon CSiconz ${color}`;

          return (
            <div
              key={index}
              data={suggestion.index}
              className={classes}
              data-testid={'autocomplete-address' + index}
              onMouseEnter={autoCompleteObj.handleMouseEnter}
              onMouseLeave={autoCompleteObj.handleMouseLeave}
              onMouseDown={autoCompleteObj.handleMouseDown}
              onTouchEnd={autoCompleteObj.handleMouseDown}
            >
              <span className={icon}>m</span>
              {suggestion.format.main_text}{' '}
              <span className="secondary-text">
                {suggestion.format.secondary_text}
              </span>
            </div>
          );
        })}
        <div className="autocomplete-item-footer">
          <img
            alt="google logo"
            src="https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png"
            className="powered-by-google"
          />
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};
