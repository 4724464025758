import { LOGOUT } from 'types/app.constants';
import {
  SELECT_AUTO_LOAN_OFFER_TAB,
  SELECT_DETAILS_OFFER,
  SET_AUTO_LOAN_DETAILS_PURCHASE,
  SET_AUTO_LOAN_DETAILS_REFINANCE,
} from 'types/offersDetails.constants';

const initialState = {
  offerDetailsData: null,

  autoLoanOfferDetailRefinance: null,
  autoLoanOfferDetailPurchase: null,
  autoLoanOfferTab: null,
};

export default function offerDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_DETAILS_OFFER: {
      return {
        ...state,
        offerDetailsData: action.offerDetailsData,
      };
    }
    case SET_AUTO_LOAN_DETAILS_REFINANCE:
      return {
        ...state,
        autoLoanOfferDetailRefinance: action?.autoLoanOfferDetailRefinance,
      };
    case SET_AUTO_LOAN_DETAILS_PURCHASE:
      return {
        ...state,
        autoLoanOfferDetailPurchase: action?.autoLoanOfferDetailPurchase,
      };
    case SELECT_AUTO_LOAN_OFFER_TAB:
      return { ...state, autoLoanOfferTab: action?.autoLoanOfferTab };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
