export const clearTypeFromAction = action => {
  return Object.assign({}, action, { type: undefined });
};

export const mergePayloadChanges = (reducerState, action) => {
  const actionKeys = Object.keys(action);
  const newStateKeys = {};

  let isChange = false;
  actionKeys.forEach(k => {
    if (k === 'type') return;
    if (action[k] === undefined) return;
    if (reducerState[k] !== undefined) {
      newStateKeys[k] = action[k];
      isChange = true;
    }
  });

  if (isChange) {
    return Object.assign({}, reducerState, newStateKeys);
  } else {
    return reducerState;
  }
};
