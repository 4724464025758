import { useLogger } from 'hook/error.hook';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { OVERVIEW_URL } from 'types/mrph.routes.constants';

const _404Controller = props => {
  const history = useHistory();
  const logger = useLogger({ name: '404' });

  useEffect(_ => {
    logger.error(`User is on invalid route=${history?.location?.path}`);
    history.push(OVERVIEW_URL);
    //eslint-disable-next-line
  }, []);

  return '';
};

export default _404Controller;
