import {
  buildOffersEndpoint,
  buildFeaturedAdsEndpoint,
  buildCustomerInfoToCreditCardPrequalification,
} from 'api-urls';
import {
  buildFetchOptionsWithHeaders,
  convertObjectToQueryString,
  standardizedFetchHandler,
} from './service_helpers';

export async function fetchOffers(getParams) {
  const requestOptions = buildFetchOptionsWithHeaders();

  const defaultReqParams = {
    clientType: 'WEB',
    userId: 'me',
  };

  /* only applicable to HOME_PAGE container atm */
  const defaultHomeContainerParams = {
    container: 'HOME_PAGE',
    loanAmount: 5000,
  };

  const isHomePageContainer =
    (getParams && getParams.container === 'HOME_PAGE') ||
    !getParams ||
    (getParams && !getParams.container);
  const defaultParams = isHomePageContainer
    ? Object.assign({}, defaultHomeContainerParams, defaultReqParams)
    : defaultReqParams;

  const queryStringParams = Object.assign({}, defaultParams, getParams);
  const queryString = convertObjectToQueryString(queryStringParams);

  return standardizedFetchHandler(
    buildOffersEndpoint() + queryString,
    requestOptions,
  );
}

export async function getFeaturedAds(params) {
  const requestOptions = buildFetchOptionsWithHeaders();

  const finalParams = Object.assign(
    {
      userId: 'me',
    },
    params,
  );

  const queryString = convertObjectToQueryString(finalParams);

  return standardizedFetchHandler(
    buildFeaturedAdsEndpoint() + queryString,
    requestOptions,
  );
}

export async function postCustomerInfoToPreApproval(customerInfo) {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body: customerInfo,
  });

  return standardizedFetchHandler(
    buildCustomerInfoToCreditCardPrequalification(),
    requestOptions,
  );
}
