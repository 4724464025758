import {
  UPDATE_PERSONALINFO_REQUEST,
  UPDATE_PERSONALINFO_SUCCESS,
  UPDATE_PERSONALINFO_FAIL,
} from '../types/profileOverview.constants';

import { LOGOUT } from 'types/app.constants';

const initialState = {
  updateProfileLoading: false,
  updateProfileError: null,
  updateProfileSuccess: null,
};

const profileOverviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PERSONALINFO_REQUEST:
      return Object.assign({}, initialState, {
        updateProfileLoading: true,
      });
    case UPDATE_PERSONALINFO_SUCCESS:
      return Object.assign({}, initialState, {
        updateProfileSuccess: action.successMessage,
      });
    case UPDATE_PERSONALINFO_FAIL:
      return Object.assign({}, initialState, {
        updateProfileError: action.error,
      });
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default profileOverviewReducer;
