import { setTransferData, setItem } from 'helpers/localstorage';
import { isTestEnv } from 'helpers/env';

export function plantMetadataInLocalStorageAndReturnTransferUrl(
  url,
  id,
  prefillApplication,
  isOcfRole,
  isPublicUser,
) {
  let transferUrl = '';
  const metadata = { url, prefillApplication, isOcfRole };
  const metadataSerialized = JSON.stringify(metadata);
  const transferSegmentUrl = isPublicUser ? 'public/transfer' : 'transfer';
  let transferUrlDefault = `${window.location.origin}/${transferSegmentUrl}/${id}`;
  let transferUrlTest = `${window.location.origin}/${transferSegmentUrl}/${id}`;

  setTransferData(id, metadataSerialized);

  if (isTestEnv()) {
    transferUrl = transferUrlTest;
  } else {
    transferUrl = transferUrlDefault;
  }
  return transferUrl;
}

export const convertProps = props => {
  return btoa(unescape(encodeURIComponent(JSON.stringify(props))));
};

export const setPartnerNameAndImageWithId = (partnerName, partnerImage) => {
  const id = Math.floor(Math.random() * 1000000);
  setItem(`partnerName-${id}`, partnerName);
  setItem(`partnerImage-${id}`, partnerImage);
  return id;
};
