/* eslint-disable */
/*
This file is a copy and paste of the snippet from the vendor. It has been
modified as little as possible for simplicity's sake
 */

(function () {
  var ada = document.createElement('script');
  ada.type = 'text/javascript';
  ada.id = '__ada';
  ada.dataset.lazy = true;
  ada.dataset.handle = 'creditsesame';
  ada.src = 'https://static.ada.support/embed2.js';
  document.head.appendChild(ada);
})();
