import * as csidAlertTypes from 'pages/Alerts/types/csid.constants';
import { getPremiumCategoryText } from 'pages/Alerts/types/csid.alert.constants';

import { successAlerts as successCsidAlerts } from './csidAlerts.actions';
import {
  saveAlertView,
  getCSIDAlerts,
} from 'externals/_services/alert.service';
import { wrapService } from 'actions/service_wrapper.actions';
import { CSIDALERTS_PAYLOAD } from 'types/payload.constants';

const a_saveAlertView = wrapService(saveAlertView, {
  name: 'saveAlertView',
});
const a_getCSIDAlerts = wrapService(getCSIDAlerts, {
  name: 'getCSIDAlerts',
});

function csidAlertsPayload(csidAlerts, csidAlertsLoading, csidAlertsError) {
  return {
    type: CSIDALERTS_PAYLOAD,
    csidAlerts,
    csidAlertsError,
    csidAlertsLoading,
  };
}
export function requestAlerts() {
  return csidAlertsPayload(null, true, null);
}

export function successAlerts(alertsData) {
  return csidAlertsPayload(alertsData, false, null);
}

export function failureAlerts(error) {
  return csidAlertsPayload(null, false, error);
}

function shouldShowPremiumAlert(category) {
  switch (category) {
    case csidAlertTypes.CHANGE_OF_ADDRESS_ALERT:
    case csidAlertTypes.CHANGE_OF_ADDRESS_REPORT:
    case csidAlertTypes.CYBER_AGENT_ALERT:
    case csidAlertTypes.CYBER_AGENT_REPORT:
    case csidAlertTypes.NON_CREDIT_ALERT:
    case csidAlertTypes.NON_CREDIT_REPORT:
    case csidAlertTypes.SSN_TRACE_ALERT:
    case csidAlertTypes.SSN_TRACE_REPORT:
      return true;

    case csidAlertTypes.IDENTITY_TRACE_ALERT:
    case csidAlertTypes.SEX_OFFENDER_ALERT:
    case csidAlertTypes.COURT_RECORDS_ALERT:
    default:
      return false;
  }
}

export function getFilteredAlerts(alertsData) {
  let processedPremium = [];
  alertsData.items.forEach(alert => {
    if (shouldShowPremiumAlert(alert.alertCategory)) {
      processedPremium.push({
        viewed: !!alert.viewedDate,
        category: getPremiumCategoryText(alert.alertCategory),
        ...alert,
      });
    }
  });
  return { items: processedPremium, totalCount: processedPremium.length };
}

export function getCsidAlerts(
  premiumOptions,
  count = 100000,
  forceStart = false,
) {
  return (dispatch, getState) => {
    const { csidAlertsLoading } = getState().csidAlerts;
    if (csidAlertsLoading && !forceStart)
      return Promise.resolve('Request already in process');

    dispatch(requestAlerts());
    return new Promise((resolve, reject) => {
      dispatch(a_getCSIDAlerts({ ...premiumOptions, itemsPerPage: count }))
        .then(data => {
          return getFilteredAlerts(data);
        })
        .then(processedData => {
          dispatch(successAlerts(processedData));
          resolve(processedData);
        })
        .catch(err => {
          dispatch(failureAlerts(err));
          reject(err);
        });
    });
  };
}

export function setCsidViewAlert(alertId, viewStatus, notificationType) {
  return (dispatch, getState) => {
    const state = getState();
    const { userInfo } = state.userInfo;
    const { csidAlerts } = state.csidAlerts;

    if (!userInfo) return Promise.reject('Userinfo missing');

    return new Promise((resolve, reject) => {
      dispatch(a_saveAlertView(alertId, viewStatus, notificationType))
        .then(() => {
          const newCSIDAlerts = csidAlerts.items.map(alert => {
            if (alert.id === alertId) {
              alert.viewed = viewStatus;
            }
            return alert;
          });
          dispatch(successCsidAlerts({ items: newCSIDAlerts }));
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
}
