import {
  CREDIT_REPAIR_VERTICAL,
  PERSONAL_LOAN_VERTICAL,
  AUTO_LOAN_VERTICAL,
  CREDIT_CARD_VERTICAL,
} from 'types/mrph.vertical.constants';

const parsedVerticals = {
  credit_card: { offerType: 'CREDIT_CARD', vertical: CREDIT_CARD_VERTICAL },
  personal_loan: {
    offerType: 'PERSONAL_LOAN',
    vertical: PERSONAL_LOAN_VERTICAL,
  },
  credit_repair: {
    offerType: 'CREDIT_REPAIR',
    vertical: CREDIT_REPAIR_VERTICAL,
  },
  auto_loan: { offerType: 'AUTO_LOAN', vertical: AUTO_LOAN_VERTICAL },
};

export const parseOfferType = vertical => {
  return parsedVerticals[vertical]?.offerType ?? '';
};

export const parseOfferVertical = vertical => {
  return parsedVerticals[vertical]?.vertical ?? '';
};

export const parseFeatureAdOffer = featuredAd => {
  if (featuredAd) {
    return {
      linkUrl: featuredAd.applyNowAdCardUrl,
      tcUrl: featuredAd.tcUrl,
      name: featuredAd.adCardName,
      issuer: featuredAd.adCardPartner,
      partnerCode: featuredAd.adCardPartner,
      adOfferPosition: featuredAd.adOfferPosition,
      adName: featuredAd.adName,
      showAd: featuredAd.showAd,
      productKey: featuredAd.productKey,
      adProductOfferId: featuredAd.adProductOfferId,
      adTitleWeb: featuredAd.adTitleWeb,
      adSubTitleWeb: featuredAd.adSubTitleWeb,
      adTitleMobile: featuredAd.adTitleMobile,
      adSubTitleMobile: featuredAd.adSubTitleMobile,
      imageLink: featuredAd.adCardImageUrl,
      buttonCopy: featuredAd.buttonCopy,
      tcText: featuredAd.tcText,
      vertical: parseOfferVertical(featuredAd.vertical),
      offerType: parseOfferType(featuredAd.vertical),
      applyNowLabelFlag: featuredAd.applyNowLabelFlag,
      applyNowLabelText: featuredAd.applyNowLabelText,
      isFeaturedAd: true,
      tooltip: featuredAd.tooltip,
    };
  }
  return null;
};
