import React from 'react';
import { ResponsiveModal } from 'components/Modals/ResponsiveModal';
import { useConfig } from 'hook/config.hook';

const LearnMoreModal = props => {
  const {
    visible,
    onCloseClick,
    isRecurlyHybridCheckout,
    isTurboSubscriptionCheckout,
  } = props;
  const { activateAlternativeLearnMoreModal } = props;
  const { config } = useConfig();

  let srcLinkForModal = config?.learnMoreModal?.srcLinkForModal_trial2;

  if (activateAlternativeLearnMoreModal || isRecurlyHybridCheckout) {
    srcLinkForModal = config?.learnMoreModal?.srcLinkForModal_trial;
  }

  if (isTurboSubscriptionCheckout) {
    srcLinkForModal = config?.learnMoreModal?.srcLinkForModal_trialdescription;
  }

  return (
    <ResponsiveModal
      visible={visible}
      centered={true}
      width="720px"
      wrapClassName="noFooter iframe"
      closable={true}
      onCancel={onCloseClick}
      footer={null}
    >
      <div className="height100">
        <iframe
          className="format100 cleaniframe"
          src={srcLinkForModal}
          title="Learn More"
        >
          <p>Your browser does not support iFrames.</p>
        </iframe>
      </div>
    </ResponsiveModal>
  );
};

export default LearnMoreModal;
