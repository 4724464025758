import { LOGIN_SUCCESS, LOGOUT } from 'types/app.constants';
import {
  SUBSCRIPTION_PAYLOAD,
  BILLING_PAYLOAD,
  PAYLOAD,
} from 'types/payload.constants';
import { mergePayloadChanges } from 'helpers/reducer.helper';

const initialState = {
  subscription: null,
  subscriptionError: null,
  subscriptionLoading: null,
  billing: null,
  billingLoading: null,
  billingError: null,
};

export default function subscriptionReducer(state = initialState, action) {
  switch (action.type) {
    case SUBSCRIPTION_PAYLOAD:
    case BILLING_PAYLOAD:
    case LOGIN_SUCCESS:
    case PAYLOAD:
      return mergePayloadChanges(state, action);
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
