import { LOGOUT } from 'types/app.constants';
import { SELECT_ONE_CLICK_APPLICATION_OFFER } from 'types/oneClickApplication.constants';

const initialState = {
  oneClickApplicationOffer: null,
};

export default function oneClickApplicationReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case SELECT_ONE_CLICK_APPLICATION_OFFER: {
      return {
        ...state,
        oneClickApplicationOffer: action.oneClickApplicationOffer,
      };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
