import moment from 'moment';
import {
  setUserLogoutTime,
  setOcfLogoutTime,
  setApiKeyLogoutTime,
  setCSPublicTrack,
  getItem,
  setItem,
  LOGIN_COUNT_COLLECTION_GUIDE,
  LOGIN_COUNT_SUGGESTED_CARD_BANNER,
} from 'helpers/localstorage';
import { getUserProfile } from 'externals/_services/user.service';
import { debounce } from 'helpers/common';
import { createLogger } from 'helpers/logger';
import {
  getApiKeySessionTimeout,
  getSessionTimeout,
  isOcfSessionValid,
  isSessionValid,
} from 'helpers/sessionLocalStorageReader.helper';
import { getCookieValue, setCookieWithExpiration } from 'helpers/cookie.helper';
import { loginSuccess } from 'actions/login.actions';
import { getMoment } from 'helpers/dateHelper';
import { retrieveCreditProfile } from '../actions/creditInfo.actions';
import { loadGoalAction } from '../actions/goals.actions';
import { getUserInfo } from 'actions/userInfo.actions';

const logger = createLogger({
  name: 'session.helper',
  categories: ['session'],
});

/**
 * The session is managed by 3 variables in local storage
 *   * USER_LOGOUT_TIME
 *   * OCF_LOGOUT_TIME
 *   * API_KEY_LOGOUT_TIME
 *
 * Each variable indicates the session for the current session
 * @type {string}
 */

export const USER_ROLE = 'USER_ROLE';
export const OCF_ROLE = 'OCF_ROLE';
export const API_KEY_ROLE = 'API_KEY_ROLE';

export { getSessionTimeout, isSessionValid };

export const clearSessionTimeoutInLocal = () => {
  setUserLogoutTime(null);
  setOcfLogoutTime(null);
  setApiKeyLogoutTime(null);
};

export const isAnySessionActive = () => getSessionRole() !== null;

export const getSessionRole = () => {
  if (isSessionValid()) return USER_ROLE;
  else if (isOcfSessionValid()) return OCF_ROLE;
  else if (isApiKeySessionValid()) return API_KEY_ROLE;
  else return null;
};

export const updateSessionTimeoutInLocal = () => {
  if (!isSessionValid()) {
    return;
  }

  const current = moment();
  current.add(14.9, 'm');
  const newTime = current.format();

  setUserLogoutTime(newTime);
};

export const updateApiKeySessionTimeoutInLocal = () => {
  if (!isSessionValid()) {
    return;
  }

  const current = moment();
  current.add(14.9, 'm');
  const newTime = current.format();

  setApiKeyLogoutTime(newTime);
};

export const setPublicSessionIdentifier = loginDataCollector => {
  const sessionIdentifier =
    loginDataCollector?.loginResponse?.sessionIdentifier;
  if (sessionIdentifier) {
    setCookieWithExpiration(
      'sessionIdentifier',
      sessionIdentifier,
      null,
      null,
      20,
      true,
    );
    getItem('oneClickIpAddress') && setCSPublicTrack(sessionIdentifier);
  }
};

export const clearUserLogin = () => {
  setUserLogoutTime(null);
};

export const clearOcfLogin = () => {
  setOcfLogoutTime(null);
};

export const clearApiKeyLogin = () => {
  setApiKeyLogoutTime(null);
};

export const isApiKeySessionValid = () => {
  const sessionTimeout = getApiKeySessionTimeout();
  if (sessionTimeout === null) {
    return false;
  } else {
    const now = moment();
    return sessionTimeout.isAfter(now);
  }
};

export const intiateWebViewSession = sessionIdentifier => dispatch => {
  if (!sessionIdentifier) return;
  return new Promise((resolve, reject) => {
    dispatch(getUserInfo())
      .then(user => {
        dispatch(loginSuccess(null, user, sessionIdentifier));
        setUserLogoutTime(getMoment().add(5, 'minutes').format());
        resolve();
      })
      .catch(err => reject(err));
  });
};

export const forceInitiationOfUserSessionImd = props => {
  const {
    sessionId,
    sessionIdentifier,
    forceSetCookies,
    retry,
    resolve,
    reject,
    dispatch,
  } = props;

  if (forceSetCookies) {
    setCookieWithExpiration('session_id', sessionId, null, null, 20, true);
    setCookieWithExpiration(
      'sessionIdentifier',
      sessionIdentifier,
      null,
      null,
      20,
      true,
    );
  }

  if (sessionIdentifier) {
    dispatch(getUserInfo())
      .then(user => {
        if (!user) {
          if (retry > 0) {
            setTimeout(() => {
              forceInitiationOfUserSessionImd({ ...props, retry: retry - 1 });
            }, 500);
          } else {
            resolve();
          }
        } else {
          dispatch(loginSuccess(null, user, sessionIdentifier));
          setUserLogoutTime(getMoment().add(5, 'minutes').format());
          resolve();
        }
      })
      .catch(err => reject(err));
  } else {
    reject();
  }
};

export const forceInitiationOfUserSession =
  (sessionId, sessionIdentifier, forceSetCookies = true, retry = 3) =>
  dispatch => {
    return new Promise((resolve, reject) => {
      forceInitiationOfUserSessionImd({
        sessionId,
        sessionIdentifier,
        forceSetCookies,
        retry,
        resolve,
        reject,
        dispatch,
      });
    });
  };

export const isThereSessionIdentifier = sessionIdentifier => {
  return getCookieValue('sessionIdentifier') === sessionIdentifier;
};

export const forceInitiationSessionForGoals =
  (sessionId, sessionIdentifier, actionId) => dispatch => {
    return new Promise((resolve, reject) => {
      if (sessionIdentifier) {
        Promise.all([
          getUserProfile,
          retrieveCreditProfile,
          loadGoalAction(actionId),
        ]).then(values => {
          const user = values[0];
          dispatch(loginSuccess(null, user, sessionIdentifier));
          setUserLogoutTime(getMoment().add(5, 'minutes').format());
          resolve();
        });
      } else {
        reject();
      }
    });
  };

/**
 *
 * A user activity tracker. It maintains an internal state of user activity status
 * ACTIVE : If user is active
 * IDLE: If there is no activity in last 30 secs
 *
 * The tracker registers listeners for various mouse/keyboard events
 * https://developer.mozilla.org/en-US/docs/Web/Events#Keyboard_events
 * https://developer.mozilla.org/en-US/docs/Web/Events#Mouse_events
 * https://developer.mozilla.org/en-US/docs/Web/Events#touch_events
 *
 *
 * If user state change from Idle to Active, OR if user has been active but the session timeout is less than 14 mins (15 max - 1 min):
 * it triggers a request to pull userInfo and refersh the SLAPI session
 *
 * */
export const activityTracker = _ => {
  const ACTIVE = 'active';
  const IDLE = 'idle';

  // Inactivity time after which user is treated as idle
  const TIME_TO_IDLE = 30000;

  // Mouse and Keyboard events
  // @TODO: Should we handle "visibilitychange" event as for tab changes
  const activityEvents = [
    'keydown',
    'click',
    'mousemove',
    'wheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'focus',
  ];

  let state = null;
  let timer = null;

  const setState = newState => {
    clearTimeout(timer);

    // if new state is active , then reset any idle time tracker
    if (newState === ACTIVE) {
      timer = setTimeout(() => setState(IDLE), TIME_TO_IDLE);
    }

    const sessionTimeOut = getSessionTimeout();
    if (sessionTimeOut !== null) {
      if (
        newState === ACTIVE &&
        (state === IDLE || moment().add(14, 'minute').isAfter(sessionTimeOut))
      ) {
        getUserProfile()
          .then(_ => {
            updateSessionTimeoutInLocal();
            logger.debug('User session activity - session extended');
          })
          .catch(err => {
            logger.reportAPIError(err);
            logger.errorException(
              'User session activity - Unable to extend user session',
              err,
            );
          });
      }
    } else {
      removeListeners();
      activateSessionTracker();
    }

    state = newState;
  };

  // callback function for event handlers. it is debounced for every 500ms to avoid multiple calls on events like mouse scroll
  const handleUserActivity = debounce(() => {
    setState(ACTIVE);
  }, 500);

  const activateSessionTracker = () => {
    // if session is valid intiate the tracker by setting listeners, else setTimeout to check for valid session.
    if (getSessionTimeout() !== null) {
      //set initial state to Active
      setState(ACTIVE);

      // add listeners for activity events
      addListeners();
    } else {
      // Check for session to activate
      setTimeout(activateSessionTracker, 1000);
    }
  };

  const addListeners = () => {
    activityEvents.forEach(event =>
      window.addEventListener(event, handleUserActivity),
    );
  };

  const removeListeners = () => {
    activityEvents.forEach(event =>
      window.removeEventListener(event, handleUserActivity),
    );
  };

  activateSessionTracker();

  return removeListeners;
};

export const setUserLoginCountCollectionGuideBanner = count => {
  setItem(LOGIN_COUNT_COLLECTION_GUIDE, count);
};

export const setUserLoginCountSuggestedCardFilterBanner = count => {
  setItem(LOGIN_COUNT_SUGGESTED_CARD_BANNER, count);
};

export const showCollectionGuideBanner = () => {
  const getUserLoggingCollectionGuideTimes = Number(
    getItem(LOGIN_COUNT_COLLECTION_GUIDE),
  );
  if (!getUserLoggingCollectionGuideTimes) {
    setUserLoginCountCollectionGuideBanner(1);
  }
  return getUserLoggingCollectionGuideTimes < 2;
};

export const showSuggestedCardsFilterBanner = () => {
  const suggestedCardsLoginCount = Number(
    getItem(LOGIN_COUNT_SUGGESTED_CARD_BANNER),
  );
  const getUserLoggingCollectionGuideTimes = Number(
    getItem(LOGIN_COUNT_COLLECTION_GUIDE),
  );

  if (getUserLoggingCollectionGuideTimes > 1 && !suggestedCardsLoginCount) {
    setUserLoginCountSuggestedCardFilterBanner(1);
  }

  return getUserLoggingCollectionGuideTimes > 1 && suggestedCardsLoginCount < 2;
};

export const isFromNativeApp = () => {
  return getCookieValue('fromNativeApp') === 'true';
};

export const getSessionIdFromSessionStorage = () =>
  window.sessionStorage.getItem('shmktpl:sessionId');

export const updateSessionTimeoutSSO = () => {
  const current = moment();
  current.add(14.9, 'm');
  const newTime = current.format();

  setUserLogoutTime(newTime);
};
