export const hasAtmLocatorsHybridUrl = props => {
  let result = false;
  if (props?.location?.pathname) {
    result =
      result || props?.location?.pathname?.indexOf('/hybridAtmLocators') > -1;
  }
  if (props?.location?.state) {
    result =
      result || props?.location?.state?.indexOf('/hybridAtmLocators') > -1;
  }
  return result;
};
