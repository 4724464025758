export const SUBSCRIPTION_VERTICAL = 'Subscription';
export const MORTGAGE_VERTICAL = 'Mortgage';
export const BANKING_VERTICAL = 'Banking';
export const CREDIT_REPAIR_VERTICAL = 'Credit Repair';
export const CREDIT_BUILDER_VERTICAL = 'Credit Builder';
export const USER_QUESTIONNAIRE_VERTICAL = 'Not Applicable';
export const AUTO_REFINANCE_VERTICAL = 'Auto Refinance';
export const RENT_REPORTING_VERTICAL = 'Rent Reporting';
export const DEBT_RELIEF_VERTICAL = 'Debt Relief';
export const HOME_INSURANCE = 'Home Insurance';
export const CREDIT_CARD_VERTICAL = 'Credit Card';
export const PERSONAL_LOAN_VERTICAL = 'Personal Loan';
export const AUTO_LOAN_VERTICAL = 'Auto Loan';
export const AUTO_INSURANCE_VERTICAL = 'Auto Insurance';
