import React from 'react';
import { ResponsiveModal } from 'components/Modals/ResponsiveModal';
import Label from 'components/form/Label/Label';

const PreQualTermsModal = props => {
  const { visible, onCloseClick } = props;

  return (
    <ResponsiveModal
      visible={visible}
      centered={true}
      width="720px"
      wrapClassName="noFooter iframe"
      closable={true}
      onCancel={onCloseClick}
      footer={null}
      bodyStyle={{ overflowY: 'scroll' }}
    >
      <div className="format100 padR24 m-padR16">
        <Label typeHeader={'h2'} className="H2 mH4 margB24">
          Pre-Qualification Program Terms
        </Label>
        <div className="contentSection Body-1 mBody-2">
          <Label className="margTB20">
            The Credit Sesame Pre-qualification Program (the “Program”) is a
            personalized tool that matches you with pre-qualified offers from
            our participating partners (“Participating Partners”). By
            participating in the Program you agree to these terms and conditions
            (“Terms”). Please read these Terms carefully.
          </Label>

          <Label className="margTB20">
            <span className="bold">Participating Partners may vary.</span>{' '}
            Credit Sesame will determine, in its sole discretion, the
            Participating Partners that may participate in the Program.
          </Label>

          <Label className="margTB20">
            <span className="bold">
              You are instructing Credit Sesame to share your information with
              Participating Partners.
            </span>{' '}
            By participating in the Program, you are consenting to have your
            information shared with Participating Partners so they can
            pre-qualify you for personalized offers.
          </Label>

          <Label className="margTB20">
            <span className="bold">
              You are consenting to soft inquiries on your credit, which will
              not impact your credit score.
            </span>{' '}
            This means you are authorizing Participating Partners to access your
            credit report information from one or more consumer credit reporting
            agencies (e.g. Equifax, Experian, or Transunion). Some Participating
            Partners may first use your information to determine if there are
            any previously matched offers available without pulling a new credit
            report, and if no offers are available, you are authorizing each
            Participating Partner to make a soft inquiry on your credit. These
            soft inquiries are used to match you with pre-qualified offers and
            will not impact your credit score, but understand that your consumer
            report record may indicate that a Participating Partner has made a
            promotional inquiry about you. h pre-qualified offers and will not
            impact your credit score.
          </Label>

          <Label>
            <span className="bold">Offers are not guaranteed.</span> The
            products offered by Participating Partners are subject to change at
            any time and pre-qualified offers are not guaranteed. If you are
            pre-qualified for any offers, please understand these pre-qualified
            offers will expire.
          </Label>

          <Label className="margTB20">
            <span className="bold">Approval is not guaranteed.</span> It is
            important for you to understand that this is not a credit or loan
            application, and approval is not guaranteed. Even if you are
            pre-qualified for a product, you must still complete and submit an
            application with the Participating Partner. If you choose to apply
            with the Participating Partner for a pre-qualified offer you have
            received, the Participating Partner may run a hard inquiry on your
            credit, which could impact your credit score. Credit Sesame will not
            be able to provide justification to you for qualification or
            non-qualification for any particular credit card product.
          </Label>

          <Label className="margTB20">
            <span className="bold">
              You are consenting to receive communications from Credit Sesame.
            </span>{' '}
            You understand that by participating in the Program you are agreeing
            to receive communications or notifications about pre-qualified
            offers from Credit Sesame.
          </Label>

          <Label className="margTB20">
            <span className="bold">Opting Out.</span> If you no longer wish to
            participate in the Program, you can opt-out by visiting the
            “Notification Settings” tab of the “Profile” page in your Credit
            Sesame account. Please note this page is only accessible on web and
            not through the mobile app.
          </Label>
        </div>
      </div>
    </ResponsiveModal>
  );
};

export default PreQualTermsModal;
