export const LOGIN_REQUEST = 'USERS_LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'USERS_LOGIN_FAILURE';
export const FATAL_ERROR = 'FATAL_ERROR';
export const SESAME_CASH_FAILURE = 'SESAME_CASH_FAILURE';
export const END_SESSION = 'END_SESSION';
export const LOGOUT = 'USERS_LOGOUT';
export const INVALID_ROUTE_REDIRECT = 'INVALID_ROUTE_REDIRECT';
export const OCF_LOGIN_REQUEST = 'OCF_LOGIN_REQUEST';
export const OCF_LOGIN_SUCCESS = 'OCF_LOGIN_SUCCESS';
export const OCF_LOGOUT = 'OCF_LOGOUT';
export const PUBLIC_LOGIN_REQUEST = 'PUBLIC_LOGIN_REQUEST';
export const PUBLIC_LOGIN_SUCCESS = 'PUBLIC_LOGIN_SUCCESS';

export const ACTIVATE_AUTOMATIC_UPDATE_MODAL =
  'ACTIVATE_AUTOMATIC_UPDATE_MODAL';
export const QUIT_AUTOMATIC_UPDATE_MODAL = 'QUIT_AUTOMATIC_UPDATE_MODAL';

export const ACTIVATE_SESSION_TIMEOUT_MODAL = 'ACTIVATE_SESSION_TIMEOUT_MODAL';
export const QUIT_SESSION_TIMEOUT_MODAL = 'QUIT_SESSION_TIMEOUT_MODAL';
export const TOGGLE_OFFLINE_MODE = 'TOGGLE_OFFLINE_MODE';
export const FORCE_HIDE_MENU_BAR = 'FORCE_HIDE_MENU_BAR';
export const SET_INITIAL_REFERRER = 'SET_INITIAL_REFERRER';
export const SET_DATA_FOR_CASH_ACCOUNT_MODAL =
  'SET_DATA_FOR_CASH_ACCOUNT_MODAL';
export const SET_DATA_FOR_CASH_PANELS = 'SET_DATA_FOR_CASH_PANELS';
