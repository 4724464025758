import {
  RESET_POTENTIAL_CREDIT_PROFILE_CONTAINER_DATA,
  SELECT_POTENTIAL_CREDIT_PROFILE_CONTAINER_DATA,
} from 'types/potentialContainer.constans';
import { LOGOUT } from 'types/app.constants';

const initialState = {
  potentialCreditProfileContainer: null,
  containerLoading: false,
  containerError: null,
};

export default function potentialCreditProfileContainerReducer(
  state = initialState,
  action,
) {
  switch (action?.type) {
    case SELECT_POTENTIAL_CREDIT_PROFILE_CONTAINER_DATA: {
      return {
        ...state,
        potentialCreditProfileContainer:
          action?.potentialCreditProfileContainer,
        containerLoading: action?.containerLoading,
        containerError: action?.containerError,
      };
    }
    case LOGOUT:
    case RESET_POTENTIAL_CREDIT_PROFILE_CONTAINER_DATA:
      return initialState;
    default:
      return state;
  }
}
