/* eslint-disable */

(function () {
  window.sendPixelToTvScientific = function (
    orderId,
    type,
    lastTouchChannel = '',
  ) {
    var l = 'tvscientific-pix-o-888949d9-b5f0-4f88-bfe8-e508311ba13f',
      s,
      e = encodeURIComponent,
      d = document,
      w = window.location,
      p = d.createElement('IMG');
    s =
      w.protocol +
      '//tvspix.com/t.png?t=' +
      new Date().getTime() +
      '&l=' +
      l +
      '&u3=' +
      e(w.href) +
      '&u1=' +
      type +
      '&u4=' +
      e(orderId) +
      '&u5=' +
      e(lastTouchChannel);
    p.setAttribute('src', s);
    p.setAttribute('height', '0');
    p.setAttribute('width', '0');
    p.setAttribute('alt', '');
    p.style.display = 'none';
    p.style.position = 'fixed';
    d.body.appendChild(p);
  };
})();
