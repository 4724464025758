import {
  getOptimizelyKey,
  isPreprod2,
  getOptimizelyUrlTemplate,
} from 'helpers/env';
import {
  createInstance,
  OptimizelyDecideOption,
  setLogger,
  setLogLevel,
} from '@optimizely/react-sdk';
import { uuidv4 } from 'helpers/number';
import {
  getOptimizelyUUID,
  setOptimizelyUUID,
  getUsrTkn,
} from 'helpers/localstorage';

/**
 * On recommendation from optimizely, it is better to have a single identifier
 * that is not connected to our internal data model.
 */
export const initializeOptimizelysUniqueIdentifier = () => {
  const uuid = uuidv4();
  if (!getOptimizelyUUID()) {
    const usrToken = getUsrTkn();
    usrToken ? setOptimizelyUUID(usrToken) : setOptimizelyUUID(uuid);
  }
};

export const getOptimizelyUniqueIdentifier = () => {
  return getOptimizelyUUID() ?? '1234-12312412-1231231';
};
export const optimizelyClient = createInstance({
  sdkKey: getOptimizelyKey(),
  eventBatchSize: 100,
  eventFlushInterval: 3000,
  datafileOptions: {
    // autoUpdate: true,
    // updateInterval: 600000, // 10 minutes in milliseconds
    urlTemplate: getOptimizelyUrlTemplate(),
  },
  errorHandler: {
    handleError: () => undefined,
  },
});
setLogger(null);
setLogLevel('error');
window.zzOpt = optimizelyClient;

export const getVariationMap = () => {
  const metadata = optimizelyClient.decideAll([
    OptimizelyDecideOption.DISABLE_DECISION_EVENT,
  ]);
  const config = optimizelyClient.getOptimizelyConfig();

  const map = {};
  Object.keys(metadata).forEach(x => {
    const featureMap = config?.featuresMap[x];
    const variation = metadata[x].variationKey;
    let experimentId = null;
    let variationId = null;

    featureMap?.experimentRules?.forEach(experimentMetadata => {
      Object.keys(experimentMetadata?.variationsMap)?.forEach(
        experimentVariationKey => {
          if (experimentVariationKey === variation) {
            experimentId = experimentMetadata.id;
            variationId =
              experimentMetadata?.variationsMap[experimentVariationKey]?.id;
          }
        },
      );
    });
    const formattedExperimentId = experimentId ? ` (${experimentId})` : ``;
    const formattedVariationId = variationId ? ` (${variationId})` : ``;
    map[
      `Flag ${x}${formattedExperimentId}`
    ] = `${variation}${formattedVariationId}`;
  });

  return map;
};

export const getDecisionForFlag = (flag, opt) => {
  const decision = optimizelyClient?.decide(flag);
  const defaultConfig = opt?.defaultConfig ?? {};
  let config =
    decision?.enabled && decision?.variables
      ? decision?.variables
      : defaultConfig;

  const isOptimizelyGloballyDisabled = isPreprod2();
  const variationKey = isOptimizelyGloballyDisabled
    ? 'off'
    : decision?.variationKey;

  if (variationKey === 'off' && isOptimizelyGloballyDisabled) {
    config = defaultConfig;
  }

  return {
    config,
    isEnabled: decision?.enabled,
    flag,
    variationKey,
  };
};
