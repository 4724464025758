import { LOGOUT } from 'types/app.constants';
import { SET_DISCREPANCIES, SET_DISPUTES } from 'types/disputes.constants';
const initialState = {
  discrepanciesLoaded: {},
  disputesLoaded: {},
};

export default function disputesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DISCREPANCIES: {
      const discrepanciesLoaded = {
        ...state.discrepanciesLoaded,
        [action.reportId]: action.discrepancies,
      };
      return Object.assign({}, state, { discrepanciesLoaded });
    }
    case SET_DISPUTES: {
      const disputesLoaded = action.disputes;
      return Object.assign({}, state, { disputesLoaded });
    }
    case LOGOUT:
      return Object.assign({}, initialState);
    default:
      return state;
  }
}
