import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveConfig, __success } from 'actions/config.actions';
import { useLogger } from 'hook/error.hook';
import { useFlag } from 'hook/flag.hook';
import { getEnvironment, LOCAL, PERF, PREPROD_LOCAL, TEST } from 'helpers/env';

export function useConfig(publicAllowed) {
  const { configState } = useSelector(state => ({
    configState: state.config,
  }));
  const { config, configLoading: loading, configError: error } = configState;

  return {
    config: config,
    configLoading: loading,
    configError: error,
  };
}

export const useConfigLoader = publicAllowed => {
  const environment = getEnvironment();
  const useLocal =
    environment === LOCAL ||
    environment === TEST ||
    environment === PREPROD_LOCAL ||
    environment === PERF;

  const clientConfiguration = useFlag('client_configuration');

  const isClientConfigurationisEnabled =
    clientConfiguration.loading === false && clientConfiguration.isEnabled;

  const { configState, loggedIn, ocfLoggedIn } = useSelector(state => ({
    configState: state.config,
    loggedIn: state.app.loggedIn,
    ocfLoggedIn: state.app.ocfLoggedIn,
  }));

  const logger = useLogger({ name: 'config.hook' });
  const dispatch = useDispatch();
  const { config, configError: error, configLoading: loading } = configState;
  const requestWillStart = !config && clientConfiguration.loading === false;

  useEffect(() => {
    if (requestWillStart && (loggedIn || publicAllowed || ocfLoggedIn)) {
      if (isClientConfigurationisEnabled && !useLocal) {
        dispatch(__success(clientConfiguration?.config?.client_config));
      } else {
        dispatch(retrieveConfig(true)).catch(err => {
          logger.checkForGlobalAPIErrorHandler(err).then(_ => {
            logger.errorException('Unable to retrieve config', err);
          });
        });
      }
    }
  });

  return {
    config: config,
    configLoading: clientConfiguration.loading || loading,
    configError: error,
  };
};
