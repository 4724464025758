import React from 'react';
import './Tag.scss';
import classnames from 'classnames';

function Tag({
  children,
  backgroundClass,
  colorClass,
  boldTag,
  newTag,
  datatestid,
  extClass,
  style,
  className
}) {
  const classNames = classnames(
    'tagStyle inblock',
    { boldTag },
    { newTag },
    {
      [extClass]: extClass,
      [backgroundClass]: backgroundClass,
      [colorClass]: colorClass,
    },
    className
  );

  return (
    <div
      data-testid={datatestid ? `${datatestid}-tag` : null}
      className={classNames}
      style={style}
    >
      {children}
    </div>
  );
}

export { Tag };
