export const ADD_ITEM_TO_CHECKOUT = 'ADD_ITEM_TO_CHECKOUT';
export const CLEAR_CHECKOUT_CART = 'CLEAR_CHECKOUT_CART';

export const SEVEN_DAY_REFRESH_ID = 'WEEKLY_SCORE_UPDATE';
export const THIRTY_DAY_REFRESH_ID = 'MONTHLY_SCORE_UPDATE';
export const ADVANCED_PREMIUM_PLAN_ID =
  'EXPERIAN_ADVANCED_CREDIT_3B_995_MONTHLY';
export const PRO_PREMIUM_PLAN_ID = 'EXPERIAN_PRO_CREDIT_3B_1595_MONTHLY';
export const PLATINUM_PREMIUM_PLAN_ID = 'EXPERIAN_PLATINUM_3B_1995_MONTHLY';

export const SINGLE_CREDIT_REPORT_BUNDLE = 'SINGLE_CREDIT_REPORT_BUNDLE';
export const SINGLE_3B_CREDIT_REPORT_BUNDLE = 'SINGLE_3B_CREDIT_REPORT_BUNDLE';
export const CREDIT_REPORT_BUNDLE = 'CREDIT_REPORT_BUNDLE';
export const SINGLE_3B_CREDIT_REPORT = 'SINGLE_3B_CREDIT_REPORT';

// Constants used for WEBNXT-5039
export const ADD_ITEM_TO_PRECHECKOUT = 'ADD_ITEM_TO_PRECHECKOUT';
export const PLATINUM_FREE_TRIAL = 'PLATINUM_3B_7_DAY_FREE_TRIAL_1995_MONTHLY';

export const TURBO_2_MONTHS_FREE_URL = 'turbo2MonthsFree';
export const TURBO_2_MONTHS_FREE_PLAN =
  'WEB_SESAME_TURBO_PROMO_2MONTH_FREE_60D_1595_MONTHLY';

export const TURBO_1_WEEK_FREE_URL = 'turbo1WeekFree';

export const TURBO_1_WEEK_FREE_PLAN_FULL_PRICE =
  'SESAME_TURBO_RENT_REPORTING_3B_7_DAY_FREE_TRIAL_1595_MONTHLY';
export const TURBO_1_WEEK_FREE_PLAN =
  'WEB_SESAME_TURBO_FREE_TRIAL_PROMO_7D_957_MONTHLY';
export const CROA_PLAN = 'WEB_CROA_3B_949_MONTHLY';
export const TURBO_NO_DISCOUNT_VAR6 = 'WEB_TURBO_6INTRO_1599_MONTHLY';
export const TURBO_NO_DISCOUNT_VAR7 = 'WEB_TURBO_60_ANNUAL';
export const TURBO_NO_DISCOUNT_VAR8 = 'WEB_TURBO_36_SEMIANNUAL';
export const TURBO_NO_DISCOUNT_VAR9 =
  'SESAME_TURBO_RENT_REPORTING_3B_7_DAY_FREE_TRIAL_1595_MONTHLY';

export const PUBLIC_PREMIUM_TURBO_FREE_PLAN =
  'WEB_SESAME_TURBO_FREE_TRIAL_PROMO_7D_957_MONTHLY';

export const PREMIUM_PLUS_7D_1595_MONTHLY = 'WEB_PREMIUM_PLUS_7D_1595_MONTHLY';

export const MOBILE_IOS_PP_3BGATING_7_DAY_FREE_PLAN =
  'IOS_PP_3BGATING_7_DAY_FREE_TRIAL';
export const MOBILE_IOS_PP_3BGATING_7_DAY_FREE_PLAN_TU =
  'IOS_PP_3BGATING_7_DAY_FREE_TRIAL_TU';
export const MOBILE_ANRDOID_PP_3BGATING_7_DAY_FREE_PLAN =
  'AND_PP_3BGATING_7_DAY_FREE_TRIAL';
export const MOBILE_ANRDOID_PP_3BGATING_7_DAY_FREE_PLAN_TU =
  'AND_PP_3BGATING_7_DAY_FREE_TRIAL_TU';

export const WEB_PP_3BGATING_7_DAY_FREE_TRIAL =
  'WEB_PP_3BGATING_7_DAY_FREE_TRIAL';
export const WEB_PP_3BGATING_7_DAY_FREE_TRIAL_TU =
  'WEB_PP_3BGATING_7_DAY_FREE_TRIAL_TU';

export const SESAME_TURBO_PLAN = 'SESAME_TURBO_RENT_REPORTING_3B_1595_MONTHLY';
export const SESAME_TURBO_PLAN_TU =
  'SESAME_TURBO_RENT_REPORTING_3B_1595_MONTHLY_TU';
export const TURBO_3BGATING_7_DAY_FREE_PLAN =
  'WEB_TURBO_3BGATING_7_DAY_FREE_TRIAL_957_MONTHLY';
export const TURBO_3BGATING_7_DAY_FREE_PLAN_TU =
  'WEB_TURBO_3BGATING_7_DAY_FREE_TRIAL_957_MONTHLY_TU';
export const MOBILE_TURBO_3BGATING_7_DAY_FREE_PLAN_TU =
  'MOBILE_TURBO_3BGATING_7_DAY_FREE_TRIAL_957_MONTHLY_TU';
export const MOBILE_TURBO_3BGATING_7_DAY_FREE_PLAN =
  'MOBILE_TURBO_3BGATING_7_DAY_FREE_TRIAL_957_MONTHLY';
export const WEB_999FT_4M3B = 'WEB_999FT_4M3B';
