import {
  PERSONAL_LOAN_PREQUAL_SELECTED,
  AUTO_LOAN_FORM_COMPLETED,
} from 'types/product.constants';

import { LOGIN_SUCCESS, LOGOUT } from 'types/app.constants';
import { mergePayloadChanges } from 'helpers/reducer.helper';
import {
  CREDIT_CARDS_CONTAINER_PAYLOAD,
  DEBT_RELIEF_PAYLOAD,
  HOME_PAGE_CONTAINER_PAYLOAD,
  PAYLOAD,
} from 'types/payload.constants';

const initialState = {
  prequalPersonalLoan: null,
  autoLoanFormConfig: null,

  creditCardContainer: null,
  creditCardContainerLoading: false,
  creditCardContainerFailure: null,

  homePageContainer: null,
  homePageContainerLoading: false,
  homePageContainerFailure: null,

  creditCardCategoriesContainer: null,
  creditCardCategoriesContainerLoading: false,
  creditCardCategoriesContainerFailure: null,

  debtReliefProducts: null,
  debtReliefProductsLoading: false,
  debtReliefProductsFailure: null,
};

const productReducer = function (state = initialState, action) {
  switch (action.type) {
    case DEBT_RELIEF_PAYLOAD:
    case HOME_PAGE_CONTAINER_PAYLOAD:
    case CREDIT_CARDS_CONTAINER_PAYLOAD:
    case LOGIN_SUCCESS:
    case PAYLOAD:
      return mergePayloadChanges(state, action);
    case PERSONAL_LOAN_PREQUAL_SELECTED:
      return Object.assign({}, state, {
        prequalPersonalLoan: action.prequalPersonalLoan,
      });
    case AUTO_LOAN_FORM_COMPLETED:
      return Object.assign({}, state, {
        autoLoanFormConfig: action.autoLoanFormConfig,
      });
    case LOGOUT:
      return Object.assign({}, initialState);
    default:
      return state;
  }
};

export default productReducer;
