import {
  QUIT_SESSION_TIMEOUT_MODAL,
  ACTIVATE_SESSION_TIMEOUT_MODAL,
} from 'types/app.constants';
import { wrapService } from 'actions/service_wrapper.actions';
import { getSessionStatus } from 'externals/_services/session.service';
import { updateSessionTimeoutInLocal } from '../../helpers/session';

export const activateSessionTimeoutModal = () => {
  return { type: ACTIVATE_SESSION_TIMEOUT_MODAL };
};

export const quitSessionTimeoutModal = () => {
  return { type: QUIT_SESSION_TIMEOUT_MODAL };
};

const a_getSessionStatus = wrapService(getSessionStatus, {
  name: 'getSessionStatus',
});

export const resetSession = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(a_getSessionStatus())
      .then(() => {
        updateSessionTimeoutInLocal();
        resolve();
      })
      .catch(err => {
        reject(err);
      });
  });
};
