import {
  SET_OFFER_STATE,
  INCREASE_OFFER_COUNT,
  REDUCE_OFFER_COUNT,
} from 'types/offerState.constants';
import { standardEventHandler } from 'actions/eventHandler.actions';
import {
  SEEN_ACTIONS,
  SEEN_OFFER,
  VIEW_ACTIONS,
  VIEW_OFFER,
} from 'externals/_tracking/types/eventTypes.constants';

export const incrementOfferCount = fingerprint => ({
  type: INCREASE_OFFER_COUNT,
  fingerprint,
});

export const setOfferState = (fingerprint, state) => ({
  type: SET_OFFER_STATE,
  fingerprint,
  state,
});

export const reduceOfferCount = fingerprint => ({
  type: REDUCE_OFFER_COUNT,
  fingerprint,
});

export const setTriggeredForViewOffer =
  (fingerprint, attrs, isAction) => (dispatch, getState) => {
    const state = getState();
    const fingerprintState = state.offerState[fingerprint];
    const f = fingerprintState ? fingerprintState : {};
    const event = isAction ? VIEW_ACTIONS : VIEW_OFFER;

    if (!f.isViewTriggered) {
      dispatch(standardEventHandler(event, attrs));
      dispatch(setOfferState(fingerprint, { isViewTriggered: true }));
    }
  };

export const setTriggeredForSeenOffer =
  (fingerprint, attrs, isAction) => (dispatch, getState) => {
    const state = getState();
    const fingerprintState = state.offerState[fingerprint];
    const f = fingerprintState ? fingerprintState : {};
    const event = isAction ? SEEN_ACTIONS : SEEN_OFFER;

    if (!f.isSeenTriggered) {
      dispatch(standardEventHandler(event, attrs));
      dispatch(setOfferState(fingerprint, { isSeenTriggered: true }));
    }
  };

export const setTriggeredForViewAction =
  (fingerprint, attrs) => (dispatch, getState) => {
    const state = getState();
    const fingerprintState = state.offerState[fingerprint];
    const f = fingerprintState ? fingerprintState : {};

    if (!f.isViewActionTriggered) {
      dispatch(standardEventHandler(VIEW_ACTIONS, attrs));
      dispatch(setOfferState(fingerprint, { isViewActionTriggered: true }));
    }
  };
