/* eslint-disable */
/*
This file is a copy and paste of the snippet from the vendor. It has been
modified as little as possible for simplicity's sake
 */
import { getEnvironment } from 'helpers/env';

var ENV = getEnvironment();

window.__insp = window.__insp || [];
window.__insp.push(['wid', 991119679]);
(function () {
  var ldinsp = function () {
    if (typeof window.__inspld != 'undefined') return;
    window.__inspld = 1;
    var insp = document.createElement('script');
    insp.type = 'text/javascript';
    insp.async = true;
    insp.id = 'inspsync';
    insp.src =
      ('https:' == document.location.protocol ? 'https' : 'http') +
      '://cdn.inspectlet.com/inspectlet.js?wid=991119679&r=' +
      Math.floor(new Date().getTime() / 3600000);
    var x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(insp, x);
  };
  setTimeout(ldinsp, 0);
})();
//Tagging server environment attribute
(function () {
  if (typeof __insp != 'undefined') {
    var n = 'test';

    if (ENV === 'production') {
      n = 'prod';
    }

    window.__insp.push(['tagSession', { environment: n }]);
    window.__insp.push(['virtualPage']);
  }
})();
