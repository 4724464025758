import { cleanPopupPaths } from 'helpers/path.helper';
import { useFlag } from 'hook/flag.hook';
import { isNonControlVariationLoadedAndEnabled } from 'helpers/optimizely.helper';
import { CLICK } from 'externals/_tracking/mixpanel/types/Mixpanel.event.types';
import { SUBSCRIPTION_VERTICAL } from 'types/mrph.vertical.constants';
import { getGetParam } from 'helpers/domHelper';
import { cloneDataObject } from 'helpers/objectHelpers';
import { isTrialEligible } from 'helpers/userHelper';
import { useSubscriptionLandingUrl } from 'hook/subscriptionLandingUrl.hook';

export const usePremiumReactivateCancel = props => {
  const { eventHandler, history, userInfo } = props;
  const { subscriptionLandingUrl, reactivateUrl } = useSubscriptionLandingUrl();

  const expPremiumReactivateCancel = useFlag('PremiumReactivateCancel_web');
  const isPremiumReactivateCancelUser = isNonControlVariationLoadedAndEnabled(
    expPremiumReactivateCancel,
  );

  let premiumReactivateCancelConfig = cloneDataObject(
    expPremiumReactivateCancel?.config,
  );

  const premiumDataPoints = {
    coupon_code:
      getGetParam('couponCode') ?? premiumReactivateCancelConfig?.coupon_code,
    serviceId:
      getGetParam('serviceId') ??
      premiumReactivateCancelConfig?.default_plan?.serviceId,
    price:
      getGetParam('priceText') ??
      premiumReactivateCancelConfig?.default_plan?.price,
    header_text:
      getGetParam('discountText') ??
      premiumReactivateCancelConfig?.modal?.header_text,
  };

  const openPremiumReactivateCancelModal = clickType => {
    const newPath = cleanPopupPaths(history.location.pathname);
    eventHandler(CLICK, {
      'Click Type': `Premium reactivate ${clickType} cta - cancelled user`,
      Vertical: SUBSCRIPTION_VERTICAL,
    });
    history.replace(`${newPath}/premiumReactivateCancel`);
  };

  const onClickReactivatePlus = () => {
    if (isTrialEligible(userInfo)) {
      history.push(subscriptionLandingUrl);
    } else {
      history.push(reactivateUrl);
    }
  };

  return {
    openPremiumReactivateCancelModal,
    isPremiumReactivateCancelUser,
    premiumReactivateCancelConfig,
    premiumDataPoints,
    onClickReactivatePlus,
  };
};
