import {
  getDaysSinceWithNull,
  getMoment,
  isDateAfterTarget,
  isDateSame,
} from 'helpers/dateHelper';
import {
  PREMIUM_PLAN_NAME_ADVANCED_CREDIT,
  PREMIUM_PLAN_NAME_PRO_CREDIT,
  PREMIUM_PLAN_NAME_PLATINUM_PROTECTION,
  PREMIUM_PLAN_NAME_SESAME_TURBO,
  PREMIUM_PLUS,
  PREMIUM_PLAN_NAME_PLATINUM,
  PREMIUM_FEATURE_CREDIT_REPORT,
} from 'types/premium.constants';

import {
  PLATINUM_FREE_TRIAL,
  TURBO_2_MONTHS_FREE_PLAN,
  TURBO_1_WEEK_FREE_PLAN,
  TURBO_1_WEEK_FREE_PLAN_FULL_PRICE,
  CROA_PLAN,
  TURBO_3BGATING_7_DAY_FREE_PLAN,
  TURBO_3BGATING_7_DAY_FREE_PLAN_TU,
  WEB_PP_3BGATING_7_DAY_FREE_TRIAL,
  WEB_PP_3BGATING_7_DAY_FREE_TRIAL_TU,
} from 'types/checkout.constants';
import { get, isNully } from './objectHelpers';

import {
  NOT_FOUND_IN_BUREAU,
  BUREAU_GET_QS_FAILED,
  SSN_VERIFIED,
  CS_ENROLLED,
  ID_VERIFICATION_PENDING,
  ID_VERIFICATION_SUBMITTED,
  ID_VERIFICATION_FAILED,
  ID_VERIFICATION_VERIFIED,
} from 'types/signupStatus.constants';
import { UNENROLLED } from '../types/rentReporting.constants';
import {
  AND_PLUS_1B_1299,
  AND_PLUS_1B_999,
  AND_PLUS_1B_FTG_1299,
  AND_PLUS_1B_FTG_999,
  AND_PLUS_3B_1999,
  AND_PLUS_3B_2499,
  AND_PLUS_3B_FTG_1999,
  AND_PLUS_3B_FTG_2499,
  IOS_PLUS_1B_1299,
  IOS_PLUS_1B_999,
  IOS_PLUS_1B_FTG_1299,
  IOS_PLUS_1B_FTG_999,
  IOS_PLUS_3B_1999,
  IOS_PLUS_3B_2499,
  IOS_PLUS_3B_FTG_1999,
  IOS_PLUS_3B_FTG_2499,
  TU_CREDIT_PREMIUM_3B_QTR_1495,
  TU_CREDIT_PREMIUM_3B_QTR_1995,
  TU_CREDIT_PREMIUM_3B_QTR_2495,
  TU_CREDIT_PREMIUM_3B_QTR_2995,
  TU_CREDIT_PREMIUM_3B_QTR_995,
  WEB_PLUS_1B_1299,
  WEB_PLUS_1B_999,
  WEB_PLUS_1B_FTG_1299,
  WEB_PLUS_1B_FTG_999,
  WEB_PLUS_3B_1999,
  WEB_PLUS_3B_2499,
  WEB_PLUS_3B_FTG_1999,
  WEB_PLUS_3B_FTG_2499,
} from '../types/plus.constants';
import { isEmpty } from './string.helper';
import {
  CANCEL_PENDING,
  FREE_NEVER_SUBSCRIBED,
  FREE_TRIAL_ELIGIBLE_PREV_SUBSCRIBED,
  FREE_TRIAL_USED_PREV_SUBSCRIBED,
  IN_TRIAL_ACTIVE,
  PAID_ACTIVE,
  PAID_PAST_DUE,
} from 'global_types/userType.constants';

export const BANKING_ENROLL_STATUS_NONE = null;
export const BANKING_ENROLL_STATUS_REGISTERED = 'REGISTERED';
export const BANKING_ENROLL_STATUS_DELETED = 'DELETED';
export const BANKING_ENROLL_STATUS_FAILED = 'FAILED_KYC';

export const USER_STATUS_PENDING = 'pending';
export const SIGN_UP_STATUS_REGISTERED_UESR = 'registeredUser';
export const SIGN_UP_STATUS_CREDIT_DATA_FILED = 'creditDataFiled';

export const UserType = {
  FREE_USER: 'FREE_USER',
  PREMIUM_MEMBER_TRIAL: 'PREMIUM_MEMBER_TRIAL',
  PAID_PREMIUM_MEMBER: 'PAID_PREMIUM_MEMBER',
};

export const isPremium = userInfo => {
  if (!userInfo) return false;

  return userInfo?.premiumAccount;
};

export const isPlus = userInfo => {
  if (!userInfo) return false;

  return isPremium(userInfo) && userInfo?.premiumPlanInfo?.isPlus;
};

export const isFree = userInfo => {
  if (!userInfo) return false;
  return isEmpty(userInfo?.premiumPlanInfo);
};

export const isPreviouslyPremium = userInfo => {
  if (!userInfo) return false;

  return !userInfo?.premiumAccount && userInfo?.previouslyPremiumAccount;
};

export const isCashUser = userInfo => {
  if (!userInfo) return false;
  if (isEmpty(userInfo?.banking)) return false;

  return userInfo?.banking?.enrollStatus === 'REGISTERED';
};

export const isBuilderUser = cashAccount => {
  if (!cashAccount) return false;
  if (isEmpty(cashAccount?.securedCard)) return false;

  return cashAccount?.securedCard?.optedIn;
};

export const isPremiumCancelPending = userInfo => {
  if (!userInfo) return false;
  if (!isPremium(userInfo)) return false;
  return !isEmpty(userInfo?.premiumPlanInfo?.cancelDate);
};

export const isPremiumFromOcf = ocfInfo => {
  if (!ocfInfo) return false;

  return ocfInfo?.userInfo?.premiumPlanServiceId !== null;
};

export const isPulse = userInfo => {
  if (!userInfo) return false;

  return (
    get(userInfo, ['premiumPlanInfo', 'serviceId'], null) ===
    'SESAME_PULSE_1B_499_MONTHLY'
  );
};

export const isCROACompliant = userInfo => {
  const CROAcompliantStates = [
    'NY',
    'FL',
    'AL',
    'AK',
    'MT',
    'NJ',
    'NM',
    'ND',
    'RI',
    'SD',
    'VT',
    'WY',
  ];

  return CROAcompliantStates.includes(userInfo?.primaryAddress?.state);
};

export const isSignupStatusFullyRegistered = signUpStatus => {
  return (
    signUpStatus === SIGN_UP_STATUS_CREDIT_DATA_FILED ||
    signUpStatus === SIGN_UP_STATUS_REGISTERED_UESR
  );
};

export const isPremiumPlatinumPlan = userInfo => {
  if (!userInfo) return false;

  if (!isPremium(userInfo)) return false;

  const premiumPlan = userInfo?.premiumPlanInfo;

  return premiumPlan?.uiDisplayName === PREMIUM_PLAN_NAME_PLATINUM_PROTECTION;
};

export const isPlatinumPlan = userInfo => {
  if (!userInfo) return false;

  if (!isPremium(userInfo)) return false;

  const premiumPlan = userInfo?.premiumPlanInfo;

  return premiumPlan?.uiDisplayName?.includes(PREMIUM_PLAN_NAME_PLATINUM);
};

export const isCROAPlan = userInfo => {
  if (!userInfo) return false;
  if (!isPremium(userInfo)) return false;
  const premiumPlan = userInfo?.premiumPlanInfo;
  return premiumPlan?.serviceId === CROA_PLAN;
};

export const isFreeTrialPlatinumPlan = userInfo => {
  if (!userInfo) return false;
  if (!isPremium(userInfo)) return false;
  const premiumPlan = userInfo?.premiumPlanInfo;
  const isFreeTrialPlatinumPlan =
    premiumPlan?.serviceId === PLATINUM_FREE_TRIAL;
  return isFreeTrialPlatinumPlan;
};

export const isPremiumProPlan = userInfo => {
  if (!userInfo) return false;

  if (!isPremium(userInfo)) return false;

  const premiumPlan = userInfo?.premiumPlanInfo;

  return premiumPlan?.uiDisplayName === PREMIUM_PLAN_NAME_PRO_CREDIT;
};

export const isPremiumAdvancedPlan = userInfo => {
  if (!userInfo) return false;

  if (!isPremium(userInfo)) return false;

  const premiumPlan = userInfo?.premiumPlanInfo;

  return premiumPlan?.uiDisplayName === PREMIUM_PLAN_NAME_ADVANCED_CREDIT;
};

export const isPremiumSesameTurboPlan = userInfo => {
  if (!userInfo) return false;

  if (!isPremium(userInfo)) return false;

  const premiumPlan = userInfo?.premiumPlanInfo;

  return premiumPlan?.uiDisplayName === PREMIUM_PLAN_NAME_SESAME_TURBO;
};

export const isPremiumSesameTurbo2MonthsFreePlan = userInfo => {
  if (!userInfo) return false;

  if (!isPremium(userInfo)) return false;

  const premiumPlan = userInfo?.premiumPlanInfo;

  return premiumPlan?.serviceId === TURBO_2_MONTHS_FREE_PLAN;
};

export const isPremiumSesameTurbo1WeekFreePlan = userInfo => {
  if (!userInfo) return false;

  if (!isPremium(userInfo)) return false;

  const premiumPlan = userInfo?.premiumPlanInfo;

  return (
    premiumPlan.serviceId === TURBO_1_WEEK_FREE_PLAN ||
    premiumPlan.serviceId === TURBO_1_WEEK_FREE_PLAN_FULL_PRICE
  );
};

export const isPremiumTurbo3BGating1WeekFreePlan = userInfo => {
  if (!userInfo) return false;

  if (!isPremium(userInfo)) return false;

  const premiumPlan = userInfo?.premiumPlanInfo;
  return (
    premiumPlan?.serviceId === TURBO_3BGATING_7_DAY_FREE_PLAN ||
    premiumPlan?.serviceId === TURBO_3BGATING_7_DAY_FREE_PLAN_TU ||
    premiumPlan?.serviceId === WEB_PP_3BGATING_7_DAY_FREE_TRIAL ||
    premiumPlan?.serviceId === WEB_PP_3BGATING_7_DAY_FREE_TRIAL_TU
  );
};

export const getPlanIndex = userInfo => {
  if (isPremiumPlatinumPlan(userInfo)) {
    return 2;
  } else if (isPremiumProPlan(userInfo)) {
    return 1;
  } else if (isPremiumAdvancedPlan(userInfo)) {
    return 0;
  } else {
    return -1;
  }
};

export const hasFreeTrialBeenUsedBefore = userInfo => {
  if (!userInfo) return false;
  return userInfo?.trialPeriodUsed;
};

export function maskPhoneNumber(phoneNumber) {
  if (!phoneNumber) return '***-***-****';
  return phoneNumber
    .toString()
    .replace(/(\d{1,3})(\d{1,3})(\d{1,2})(\d{1,2})/g, '***-***-**$4');
}

export function maskEmailFirstLastLetter(email) {
  if (!email) return '****@***.***';
  let pos = email.indexOf('@');
  let sb = '';
  let emailShort = email.substring(0, pos);
  if (emailShort.length > 4) {
    let emailFirst = emailShort.substring(0, 1);
    let emailLast = emailShort.substring(emailShort.length - 1);
    for (let i = 0; i < emailShort.length - 2; i++) {
      sb += '*';
    }
    return emailFirst + sb + emailLast + email.substring(pos);
  } else if (emailShort.length <= 4 && emailShort.length > 1) {
    let emailFirst = emailShort.substring(0, 1);
    for (let i = 0; i < emailShort.length - 1; i++) {
      sb += '*';
    }
    return emailFirst + sb + email.substring(pos);
  } else {
    return email;
  }
}

export const formatFirstAndLastName = (firstName, lastName) => {
  return `${formatName(firstName)} ${formatName(lastName)}`;
};

export const formatName = name => {
  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
};

export const isPremiumPlusPlan = userInfo => {
  if (!userInfo) return false;

  if (!isPremium(userInfo)) return false;

  const premiumPlan = userInfo?.premiumPlanInfo;

  return premiumPlan?.uiDisplayName === PREMIUM_PLUS;
};

export const isInvisibleUser = userInfo => {
  if (!userInfo) return false;
  if (userInfo?.status !== 'pending') return false;

  return isInvisibleUserByStatus(userInfo?.signUpStatus);
};

export const isInvisibleUserByStatus = signUpStatus => {
  const invisibleUserSignupStatuses = [
    SSN_VERIFIED,
    CS_ENROLLED,
    ID_VERIFICATION_PENDING,
    ID_VERIFICATION_SUBMITTED,
    ID_VERIFICATION_FAILED,
    ID_VERIFICATION_VERIFIED,
  ];

  return invisibleUserSignupStatuses.includes(signUpStatus);
};
export const isPendingUser = userInfo => {
  return userInfo?.status === 'pending';
};
export const isRegisteredUser = userInfo => {
  return (
    userInfo?.signUpStatus === 'registeredUser' ||
    userInfo?.signUpStatus === 'creditDataFiled'
  );
};

export const isInvisibleUserForSignup = userInfo => {
  if (!userInfo) return false;
  if (userInfo?.status !== 'pending') return false;

  const invisibleUserSignupStatuses = [
    NOT_FOUND_IN_BUREAU,
    BUREAU_GET_QS_FAILED,
    SSN_VERIFIED,
    CS_ENROLLED,
    ID_VERIFICATION_PENDING,
    ID_VERIFICATION_SUBMITTED,
    ID_VERIFICATION_FAILED,
    ID_VERIFICATION_VERIFIED,
  ];

  return invisibleUserSignupStatuses.includes(userInfo?.signUpStatus);
};

export const isVerificationInProgress = userInfo => {
  if (!userInfo) return false;
  if (userInfo?.status !== 'pending') return false;

  const inProgressStatuses = [
    ID_VERIFICATION_PENDING,
    ID_VERIFICATION_SUBMITTED,
  ];
  return inProgressStatuses.includes(userInfo?.signUpStatus);
};

export const isPremiumPaymentPending = userInfo => {
  if (!userInfo) return false;
  if (!isPremium(userInfo)) return false;

  return userInfo?.premiumPlanInfo?.status === 'PAYMENT_PAST_DUE';
};

export const isNewUser = userInfo => {
  if (!userInfo) return false;
  return getDaysSinceWithNull(userInfo?.createdDate) >= 30;
};

//PERS-548
export const isRentReportingUser = userInfo => {
  if (!userInfo) return false;
  return (
    userInfo?.rentReporting?.status &&
    userInfo?.rentReporting?.status !== UNENROLLED
  );
};

export const getCreditReportBundleOfferType = isPremiumUser => {
  return isPremiumUser
    ? 'SINGLE_3B_CREDIT_REPORT_PREMIUM'
    : 'SINGLE_3B_CREDIT_REPORT';
};

export const has3BReportAvailable = userInfo => {
  if (!userInfo) return false;
  const premiumPlanInfo = userInfo?.premiumPlanInfo;
  return (
    premiumPlanInfo?.planSupportsFree3bReportDownload &&
    premiumPlanInfo?.eligibleFor3BCreditReport
  );
};

export const has1BReportAvailable = userInfo => {
  if (!userInfo) return false;
  const premiumPlanInfo = userInfo?.premiumPlanInfo;
  return (
    premiumPlanInfo?.planSupports1bReportDownload &&
    userInfo?.eligibleFor1BCreditReport
  );
};

export const has1BDailyReportAvailable = userInfo => {
  if (!userInfo) return false;
  return userInfo?.eligibleFor1BCreditReport;
};

export const is1BFreeTrialOrPaid = userInfo => {
  if (!userInfo) return false;

  return userInfo?.premiumPlanInfo?.planSupports1bReportDownload;
};

// 3b paid: freeTrialPeriod false  and planSupportsFree3bReportDownload": true
export const is3BPaid = userInfo => {
  if (!userInfo) return false;
  return (
    !userInfo?.premiumPlanInfo?.freeTrialPeriod &&
    userInfo?.premiumPlanInfo?.planSupportsFree3bReportDownload
  );
};

// 3b Trial: freeTrialPeriod TRUE and planSupportsFree3bReportDownload": true
export const is3BFreeTrial = userInfo => {
  if (!userInfo) return false;
  return (
    userInfo?.premiumPlanInfo?.freeTrialPeriod &&
    userInfo?.premiumPlanInfo?.planSupportsFree3bReportDownload
  );
};

// 1b trial: freeTrialPeriod TRUE and planSupportsFree3bReportDownload": false
export const is1BFreeTrial = userInfo => {
  if (!userInfo) return false;
  return (
    userInfo?.premiumPlanInfo?.freeTrialPeriod &&
    !userInfo?.premiumPlanInfo?.planSupportsFree3bReportDownload
  );
};

// 1b paid: freeTrialPeriod false and planSupportsFree3bReportDownload": false
export const is1BPaid = userInfo => {
  if (!userInfo) return false;
  return (
    !userInfo?.premiumPlanInfo?.freeTrialPeriod &&
    !userInfo?.premiumPlanInfo?.planSupportsFree3bReportDownload
  );
};

export const isFreemium = userInfo => {
  if (!userInfo) return false;
  return !isPremium(userInfo);
};

export const isFreeTrial = userInfo => {
  if (!userInfo) return false;
  if (isPremium(userInfo)) {
    return userInfo?.premiumPlanInfo?.freeTrialPeriod;
  } else {
    return false;
  }
};

/**
 *
 * @param userInfo
 * @returns boolean whether or not this user registers for the 1st time
 * Ref: https://creditsesame.atlassian.net/browse/EPOD-2481
 */
export const isUserRegisteredBefore = userInfo => {
  return isDateAfterTarget(getMoment(), userInfo?.previousLoginDate);
};

export const isPlus3BFreeTrial = userInfo => {
  if (!userInfo) return false;
  if (!isFreeTrial(userInfo)) return false;
  if (!isPlus(userInfo)) return false;
  const premiumPlanInfo = userInfo?.premiumPlanInfo;
  return premiumPlanInfo?.planSupportsFree3bReportDownload;
};

export const isPlus3B = userInfo => {
  if (!userInfo) return false;
  if (!isPlus(userInfo)) return false;
  const premiumPlanInfo = userInfo?.premiumPlanInfo;
  return premiumPlanInfo?.planSupportsFree3bReportDownload;
};

export const isConvertedToPaidUser = userInfo => {
  if (!userInfo) return false;
  if (isFreeTrial(userInfo)) return false;
  const premiumPlanInfo = userInfo?.premiumPlanInfo;
  return (
    !isNully(premiumPlanInfo?.trialEndDate) && !premiumPlanInfo?.freeTrialPeriod
  );
};

export const isPlus3BTrial = userInfo => {
  if (!userInfo) return false;

  if (!isPlus(userInfo)) return false;

  const premiumPlanInfo = userInfo?.premiumPlanInfo;
  return (
    premiumPlanInfo?.planSupportsFree3bReportDownload &&
    premiumPlanInfo?.freeTrialPeriod
  );
};

export const isPlus3BPaid = (userInfo, profileContainer) => {
  const [scoreDial] = profileContainer?.modules || [];
  const { bureauScores = {} } = scoreDial?.additionalData || {};

  if (!userInfo) return false;
  if (!isPlus(userInfo)) return false;

  return (
    !isEmpty(bureauScores?.['experian']) || !isEmpty(bureauScores?.['equifax'])
  );
};

export const isPlus1BFreeTrial = userInfo => {
  if (!userInfo) return false;
  if (!isFreeTrial(userInfo)) return false;
  if (!isPlus(userInfo)) return false;
  const premiumPlanInfo = userInfo?.premiumPlanInfo;
  return !premiumPlanInfo?.planSupportsFree3bReportDownload;
};

export const isPlus1B = userInfo => {
  if (!userInfo) return false;
  if (!isPlus(userInfo)) return false;
  const premiumPlanInfo = userInfo?.premiumPlanInfo;
  return !premiumPlanInfo?.planSupportsFree3bReportDownload;
};

export const isPlusUpgradePending = userInfo => {
  if (!userInfo) return false;

  if (!isPlus(userInfo)) return false;

  const premiumPlan = userInfo?.premiumPlanInfo;

  return premiumPlan?.subscriptionChangePending === 'UPGRADE';
};

export const isPlusDowngradePending = userInfo => {
  if (!userInfo) return false;

  if (!isPlus(userInfo)) return false;

  const premiumPlan = userInfo?.premiumPlanInfo;

  return premiumPlan?.subscriptionChangePending === 'DOWNGRADE';
};

export const isPlusPlanChangePending = userInfo => {
  if (!userInfo) return false;

  if (!isPlus(userInfo)) return false;

  const premiumPlan = userInfo?.premiumPlanInfo;

  return premiumPlan?.subscriptionChangePending === 'PLAN_CHANGE_SAME_LEVEL';
};

export const getPremiumPlanUiDisplayName = (userInfo, plusLaunch) => {
  return userInfo?.premiumPlanInfo?.uiDisplayName
    ? userInfo?.premiumPlanInfo?.uiDisplayName
    : plusLaunch?.textMapped?.long?.title;
};

export const isTrialEligible = userInfo => {
  if (!userInfo) return false;
  return !userInfo?.trialPeriodUsed;
};

export const isFreetrialUsedAndCanceledInvoluntarily = userInfo => {
  if (!userInfo) return false;
  return userInfo?.trialPeriodUsed && userInfo?.canceledInvoluntarily;
};

export const isUpgradeQualified = userInfo => {
  if (!userInfo) return false;
  if (isPlus1BFreeTrial(userInfo) && !isFreeTrial(userInfo)) return true;
  if (isPlus1B(userInfo)) return true;
  return false;
};

export const getUserRedirectPath = props => {
  const {
    userInfo,
    isPremiumReactivatePendingCancellationUser,
    isPremiumReactivateCancelUser,
    isPlusLaunchWebFlagEnabled,
    subscriptionLandingUrl,
  } = props;

  let redirectPath =
    subscriptionLandingUrl + '?feature=' + PREMIUM_FEATURE_CREDIT_REPORT;

  if (isFreeTrial(userInfo)) {
    if (isPlus1B(userInfo)) {
      redirectPath = `${document.location?.pathname}/plusUpgradeAndConvert`;
    } else {
      redirectPath = '/creditReports/view?nav=premiumCreditReports&modal=TRIAL';
    }
  } else if (isPlus1B(userInfo)) {
    redirectPath = '/plus/upgrade';
  }

  if (isPremiumReactivatePendingCancellationUser) {
    redirectPath = `${document.location?.pathname}/premiumReactivatePendingCancellation`;
  }
  if (isPremiumReactivateCancelUser && !isPlusLaunchWebFlagEnabled) {
    redirectPath = `${document.location?.pathname}/premiumReactivateCancel`;
  }
  return redirectPath;
};

export const isCancelScheduled = userInfo => {
  if (!userInfo) return false;
  return !isEmpty(userInfo?.premiumPlanInfo?.cancelDate);
};

export const plusPlans = [
  AND_PLUS_1B_1299,
  AND_PLUS_1B_999,
  AND_PLUS_1B_FTG_1299,
  AND_PLUS_1B_FTG_999,
  AND_PLUS_3B_2499,
  AND_PLUS_3B_FTG_2499,
  IOS_PLUS_1B_1299,
  IOS_PLUS_1B_999,
  IOS_PLUS_1B_FTG_1299,
  IOS_PLUS_1B_FTG_999,
  IOS_PLUS_3B_2499,
  IOS_PLUS_3B_FTG_2499,
  WEB_PLUS_1B_1299,
  WEB_PLUS_1B_999,
  WEB_PLUS_1B_FTG_1299,
  WEB_PLUS_1B_FTG_999,
  WEB_PLUS_3B_2499,
  WEB_PLUS_3B_FTG_2499,
];

export const plus1BPlans = [
  AND_PLUS_1B_1299,
  AND_PLUS_1B_999,
  AND_PLUS_1B_FTG_1299,
  AND_PLUS_1B_FTG_999,
  IOS_PLUS_1B_1299,
  IOS_PLUS_1B_999,
  IOS_PLUS_1B_FTG_1299,
  IOS_PLUS_1B_FTG_999,
  WEB_PLUS_1B_1299,
  WEB_PLUS_1B_999,
  WEB_PLUS_1B_FTG_1299,
  WEB_PLUS_1B_FTG_999,
];

export const plus3BPlans = [
  AND_PLUS_3B_1999,
  AND_PLUS_3B_FTG_1999,
  AND_PLUS_3B_2499,
  AND_PLUS_3B_FTG_2499,
  IOS_PLUS_3B_1999,
  IOS_PLUS_3B_FTG_1999,
  IOS_PLUS_3B_2499,
  IOS_PLUS_3B_FTG_2499,
  WEB_PLUS_3B_1999,
  WEB_PLUS_3B_FTG_1999,
  WEB_PLUS_3B_2499,
  WEB_PLUS_3B_FTG_2499,

  TU_CREDIT_PREMIUM_3B_QTR_995,
  TU_CREDIT_PREMIUM_3B_QTR_1995,
  TU_CREDIT_PREMIUM_3B_QTR_1495,
  TU_CREDIT_PREMIUM_3B_QTR_2495,
  TU_CREDIT_PREMIUM_3B_QTR_2995,
];

export const isEnrolledToCreditLock = userInfo => {
  if (!userInfo) return false;
  const premiumPlanInfo = userInfo?.premiumPlanInfo;
  return premiumPlanInfo?.enrolledInCreditLock;
};

export const isTUEnrollementFailed = userInfo => {
  if (!userInfo) return false;
  return userInfo?.tuEnrollmentFailed;
};

export const isActivateApplePay = props => {
  const {
    isClientConfigApplePayEnabled,
    isApplePayLogicEnabled,
    isTrialEligibleUser,
    paymentMethod,
  } = props ?? {};

  if (!isApplePayLogicEnabled) return false;
  if (paymentMethod === 'apple_pay' && !isTrialEligibleUser) {
    return true;
  } else {
    return isClientConfigApplePayEnabled;
  }
};

export const isRecurlyUser = userInfo => {
  if (!userInfo) return false;
  const billingEntity = userInfo?.premiumPlanInfo?.billingEntity;
  return billingEntity === 'RECURLY';
};

export const getUserType = userInfo => {
  if (!userInfo) return null;
  if (!isRegisteredUser(userInfo)) return null;
  if (isPremium(userInfo)) {
    const premiumPlanInfo = userInfo?.premiumPlanInfo;
    if (premiumPlanInfo?.cancelDate) {
      return CANCEL_PENDING;
    } else if (premiumPlanInfo?.freeTrialPeriod) {
      return IN_TRIAL_ACTIVE;
    } else if (premiumPlanInfo?.status === 'PAYMENT_PAST_DUE') {
      return PAID_PAST_DUE;
    } else {
      return PAID_ACTIVE;
    }
  } else {
    if (!isPreviouslyPremium(userInfo)) {
      return FREE_NEVER_SUBSCRIBED;
    } else {
      if (hasFreeTrialBeenUsedBefore(userInfo)) {
        return FREE_TRIAL_USED_PREV_SUBSCRIBED;
      } else {
        return FREE_TRIAL_ELIGIBLE_PREV_SUBSCRIBED;
      }
    }
  }
};

export const isEnrolledToday = userInfo => {
  if (!userInfo) return false;
  return isDateSame(new Date(), userInfo?.premiumPlanInfo?.enrolledDate);
};
