import React from 'react';
import classnames from 'classnames';
import './Card.scss';

const Card = props => {
  const classes = classnames('Card', props.cardStyle, props.className);
  return (
    <div className={classes} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

Card.defaultProps = {
  cardStyle: '',
};

export default Card;
