import { useConfigLoader } from './config.hook';
import { useEffect, useState } from 'react';

export const useSubscriptionLandingUrl = props => {
  const [landingUrl, setLandingUrl] = useState('');
  const { config, configLoading } = useConfigLoader(true);

  useEffect(() => {
    if (!configLoading && config) {
      setLandingUrl(config?.subscriptionLandingUrl);
    }
  }, [configLoading, config]);

  return {
    subscriptionLandingUrl: landingUrl,
    reactivateUrl: landingUrl + '/reactivate',
    configLoading,
  };
};
