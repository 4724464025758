import * as UAParser from 'ua-parser-js';
import { getSrc, getSubSrc } from 'helpers/tracking.helpers';
import { getCookieValue } from 'helpers/cookie.helper';
import { getDefaultNetworkTimeout } from 'helpers/env';
import { getDecisionForFlag } from '../_tracking/optimizely/optFlags';

const ua = new UAParser.UAParser();
const os = ua.getOS().name;

const stringifyError = finalError => {
  return new Promise(resolve => {
    resolve(JSON.stringify(finalError));
  });
};

const checkIfFailedToFetchError = error =>
  /^(?=.*\bfailed\b)(?=.*\bfetch\b).*$/gi.test(error);

const handleFetchTimeout = (url, controller, defaultTimeout) => {
  return new Promise(resolve => {
    setTimeout(() => {
      let timeoutError = {
        message: 'Request Timed Out',
        code: 'FETCH_TIMEOUT',
        path: url,
        status: 504,
        timestamp: new Date().toISOString(),
      };
      resolve({
        url,
        type: 'cors',
        redirected: false,
        statusText: 'Timeout Request',
        ok: false,
        status: 504,
        text: () => {
          return stringifyError(timeoutError);
        },
      });
      if (controller) {
        controller.abort();
      }
    }, defaultTimeout);
  });
};

export async function standardizedFetch(url, options) {
  if (options.forceError) {
    return new Promise((resolve, reject) => reject(options.forceError));
  }
  const abortController = window.AbortController ? new AbortController() : null;
  const signal = abortController ? abortController.signal : null;
  const finalFetchOptions = Object.assign({}, options, { signal });
  const defaultTimeout = options.timeout
    ? options.timeout
    : getDefaultNetworkTimeout();
  const handleTimeOutFetch = handleFetchTimeout(
    url,
    abortController,
    defaultTimeout,
  );

  try {
    const responseFetchRequest = fetch(url, finalFetchOptions);
    const promises = Promise.race([handleTimeOutFetch, responseFetchRequest]);
    return await promises;
  } catch (err) {
    const isFetchToFetchError = checkIfFailedToFetchError(String(err));
    if (!isFetchToFetchError) {
      let finalError = {
        message: String(err),
        code: 'FETCH_01',
        path: url,
        status: 605,
        timestamp: new Date().toISOString(),
      };
      return {
        url,
        type: 'cors',
        redirected: false,
        statusText: 'Fetching error',
        ok: false,
        status: 605,
        text: () => {
          return stringifyError(finalError);
        },
      };
    }
  }
}

export const standardizedFetchHandler = (fetchUrl, fetchOptions) => {
  return new Promise((resolve, reject) => {
    standardizedFetch(fetchUrl, fetchOptions)
      .then(standardResponseHandler)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export function standardResponseHandler(response) {
  return new Promise((resolve, reject) => {
    let responseOuter = null;
    return response
      .text()
      .then(text => {
        const data = text && JSON.parse(text);
        responseOuter = response;
        if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          const errorCode = data && data.code ? data.code : 0;

          return reject({ error, isApiError: true, errorCode, ...data });
        } else {
          return resolve(data);
        }
      })
      .catch(err => {
        const isSLAPIError =
          typeof err === 'object' && typeof err.message === 'string';

        if (isSLAPIError) {
          reject(err);
        } else {
          const finalError = {
            message: String(err),
            error: 'UNKNOWN',
            errorCode: 'FETCH_HARD_FAILURE',
            timestamp: new Date().toISOString(),
            path: responseOuter.url,
            status: responseOuter.status,
            ...err,
          };

          reject(finalError);
        }
      });
  });
}

export const standardizedFetchBlobHandler = (fetchUrl, fetchOptions) => {
  return new Promise((resolve, reject) => {
    standardizedFetch(fetchUrl, fetchOptions)
      .then(standardBlobResponseHandler)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export function standardBlobResponseHandler(response) {
  return new Promise((resolve, reject) => {
    if (!response.ok) {
      const data = response && JSON.parse(response.text());
      const error = (data && data.message) || response.statusText;
      const errorCode = data && data.code ? data.code : 0;
      return reject({ error, isApiError: true, errorCode, ...data });
    } else {
      return response
        .blob()
        .then(function (myBlob) {
          return resolve(URL.createObjectURL(myBlob));
        })
        .catch(err => {
          const isSLAPIError =
            typeof err === 'object' && typeof err.message === 'string';
          if (isSLAPIError) {
            reject(err);
          } else {
            const finalError = {
              message: String(err),
              error: 'UNKNOWN',
              errorCode: 'FETCH_HARD_FAILURE',
              timestamp: new Date().toISOString(),
              path: response.url,
              status: response.status,
              ...err,
            };
            reject(finalError);
          }
        });
    }
  });
}

export function convertObjectToQueryString(obj, queryStringInitialized) {
  const variableList = [];

  if (typeof obj !== 'object') {
    return '';
  }
  const pushPair = (k, v) =>
    variableList.push(encodeURIComponent(k) + '=' + encodeURIComponent(v));

  Object.keys(obj).forEach(key => {
    let value = obj[key];

    if (Array.isArray(value)) {
      value.forEach(subValue => pushPair(key, subValue));
    } else {
      if (value !== null && value !== undefined) {
        pushPair(key, value);
      }
    }
  });
  if (queryStringInitialized) {
    return '&' + variableList.join('&');
  }
  return '?' + variableList.join('&');
}

export function buildBankingApiFetchOptionsWithHeaders(
  token,
  customOpts,
  customHeaders,
) {
  const defaultHeaders = {
    'X-Requested-With': 'XMLHttpRequest',
    org: 'credit-sesame',
    'Content-Type': 'application/json',
    'pin-api-key': token,
    'app-version': '2.0.0',
    'api-version': 2,
    'app-agent': 'web',
    'device-id': getCookieValue('cs_device_id'),
    Accept: 'application/json',
  };
  const finalCustomOpts = !customOpts ? {} : customOpts;
  const finalCustomHeaders = !customHeaders ? {} : customHeaders;

  const defaultOptions = {
    method: 'GET',
    headers: Object.assign(defaultHeaders, finalCustomHeaders),
  };

  const mergedOptions = Object.assign(defaultOptions, finalCustomOpts);

  if (typeof mergedOptions.body === 'object') {
    mergedOptions.body = JSON.stringify(mergedOptions.body);
  }
  return mergedOptions;
}

export function buildDefaultFetchOptionsWithHeaders(customOpts, customHeaders) {
  const defaultHeaders = {
    'Content-Type': 'application/json',
  };
  const finalCustomOpts = !customOpts ? {} : customOpts;
  const finalCustomHeaders = !customHeaders ? {} : customHeaders;

  delete finalCustomOpts.headers;

  const defaultOptions = {
    headers: Object.assign(defaultHeaders, finalCustomHeaders),
  };

  const mergedOptions = Object.assign(defaultOptions, finalCustomOpts);

  if (typeof mergedOptions.body === 'object') {
    mergedOptions.body = JSON.stringify(mergedOptions.body);
  }
  return mergedOptions;
}

export function buildFetchOptionsWithHeaders(customOpts, customHeaders) {
  const trackingHeaders = trkSrcHeaders();
  const defaultHeaders = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-cs-platform': 'csmorpheus',
    'X-cs-os': os ? os : 'unknown',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  const finalCustomOpts = !customOpts ? {} : customOpts;
  const finalCustomHeaders = !customHeaders ? {} : customHeaders;

  delete finalCustomOpts.headers;

  const defaultOptions = {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: Object.assign(defaultHeaders, trackingHeaders, finalCustomHeaders),
  };

  const mergedOptions = Object.assign(defaultOptions, finalCustomOpts);

  if (typeof mergedOptions.body === 'object') {
    mergedOptions.body = JSON.stringify(mergedOptions.body);
  }

  return mergedOptions;
}

function trkSrcHeaders() {
  let src = getSrc();
  let subSrc = getSubSrc();

  let srcHeaders = src ? { 'X-cs-src': src } : {};

  if (subSrc) {
    srcHeaders = Object.assign(srcHeaders, { 'X-cs-sub-src': subSrc });
  }

  return srcHeaders;
}

export const formatToHttps = url => {
  return url.replace('http://', 'https://');
};
