import React from 'react';
import Label from 'components/form/Label/Label';
import { Button } from 'components/form/Button/index';
import { ErrorTemplate } from './ErrorTemplate';
import { useConfigLoader } from 'hook/config.hook';

export const AccountLockedError = ({
  errorType,
  dynamicErrorMessage,
  ...props
}) => {
  const { config } = useConfigLoader(true);

  let errorMessage = dynamicErrorMessage
    ? dynamicErrorMessage
    : 'Your account has been locked for 30 days due to too many failed sign-up attempts or incorrect answers.';
  return (
    <ErrorTemplate
      errorType={errorType}
      title="Account locked"
      imgSrc={config?.signUpError?.accountLocked?.imgSrc}
    >
      <Label className="padTB8 margB16">{errorMessage}</Label>
      {!dynamicErrorMessage && (
        <Label className="padTB8 margB16">
          We will send you an email to inform you when that 30-day lock is over,
          and you can continue signing up.
        </Label>
      )}
      <Label className="padTB8 margB16">
        While you wait, consider building your credit by checking out some of
        the best offers from our partners.
      </Label>
      {config?.signUpError?.showButtonToExploreOffers && (
        <Button mrphType="primary" onClick={props.onButtonClick} rounded>
          Explore Offers
        </Button>
      )}
    </ErrorTemplate>
  );
};
