import React from 'react';
import './TitleContainer.scss';
/*

type: pageTitle
  - adds gutter on sides for mobile

*/
function TitleContainer({
  children,
  type,
  fullWidth,
  className,
  datatestid,
  style,
  ...props
}) {
  let tempClass = `TitleContainer`;
  tempClass = fullWidth ? `${tempClass} fullWidth` : tempClass;
  tempClass = type ? `${tempClass} ${type}` : tempClass;
  tempClass = className ? `${tempClass} ${className}` : tempClass;

  return (
    <div
      className={tempClass}
      style={style}
      data-testid={`${datatestid ? datatestid : null}-title`}
    >
      {children}
    </div>
  );
}

export { TitleContainer };
