//signup 1
export const ACCT_CREATION_EMAIL_FORM = 'ACCT_CREATION_EMAIL_FORM';
export const ACCT_CREATION_INFO_FORM = 'ACCT_CREATION_INFO_FORM';

//signup 2
export const DOB_SSN_FORM = 'DOB_SSN_FORM';

export const prefilledSignupConstants = {
  START_INFO_FORM_SUBMIT: 'PREFILLED_SIGNUP_START_INFO_FORM_SUBMIT',
  INFO_FORM_SUCCESS: 'PREFILLED_SIGNUP_INFO_FORM_SUCCESS',
  DECRYPT_DATA_REQUEST: 'PREFILLED_SIGNUP_DECRYPT_DATA_REQUEST',
  DECRYPT_DATA_SUCCESS: 'PREFILLED_SIGNUP_DECRYPT_DATA_SUCCESS',
  DECRYPT_DATA_FAIL: 'PREFILLED_SIGNUP_DECRYPT_DATA_FAIL',
  CREATE_USER_REQUEST: 'PREFILLED_SIGNUP_CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS: 'PREFILLED_SIGNUP_CREATE_USER_SUCCESS',
  CREATE_USER_FAIL: 'PREFILLED_SIGNUP_CREATE_USER_FAIL',
  UPDATE_ADDRESS_REQUEST: 'PREFILLED_SIGNUP_UPDATE_ADDRESS_REQUEST',
  UPDATE_ADDRESS_SUCCESS: 'PREFILLED_SIGNUP_UPDATE_ADDRESS_SUCCESS',
  UPDATE_ADDRESS_FAIL: 'PREFILLED_SIGNUP_UPDATE_ADDRESS_FAIL',
  UPDATE_PHONE_REQUEST: 'PREFILLED_SIGNUP_UPDATE_PHONE_REQUEST',
  UPDATE_PHONE_SUCCESS: 'PREFILLED_SIGNUP_UPDATE_PHONE_SUCCESS',
  UPDATE_PHONE_FAIL: 'PREFILLED_SIGNUP_UPDATE_PHONE_FAIL',
  START_SSN_FORM_SUBMIT: 'PREFILLED_SIGNUP_START_SSN_FORM_SUBMIT',
  SSN_FORM_SUCCESS: 'PREFILLED_SIGNUP_SSN_FORM_SUCCESS',
  UPDATE_DOB_REQUEST: 'PREFILLED_SIGNUP_UPDATE_DOB_REQUEST',
  UPDATE_DOB_SUCCESS: 'PREFILLED_SIGNUP_UPDATE_DOB_SUCCESS',
  UPDATE_DOB_FAIL: 'PREFILLED_SIGNUP_UPDATE_DOB_FAIL',
  UPDATE_SSN_REQUEST: 'PREFILLED_SIGNUP_UPDATE_SSN_REQUEST',
  UPDATE_SSN_SUCCESS: 'PREFILLED_SIGNUP_UPDATE_SSN_SUCCESS',
  UPDATE_SSN_FAIL: 'PREFILLED_SIGNUP_UPDATE_SSN_FAIL',
  SET_SIGNUP_1_STEP: 'SET_PREFILLED_SIGNUP_1_STEP',
  CLEAR_PREFILLED_SIGNUP_STATE: 'CLEAR_PREFILLED_SIGNUP_STATE',
};

export const optEventsPrefilledSignup = {
  [ACCT_CREATION_EMAIL_FORM]: 'viewed_prefilled_signup_1_email_form',
  [ACCT_CREATION_INFO_FORM]: 'viewed_prefilled_signup_1_info_form',
  [DOB_SSN_FORM]: 'viewed_prefilled_signup_2_dob_ssn_form',
};

export const mxpMapPrefilledSignup = {
  [ACCT_CREATION_EMAIL_FORM]: 'Prefilled Signup 1 - Email Form',
  [ACCT_CREATION_INFO_FORM]: 'Prefilled Signup 1 - Info Form',
  [DOB_SSN_FORM]: 'Prefilled Signup 2 - Dob Ssn Form',
};
