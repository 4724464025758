import { pinkyPromise } from 'helpers/promiseHelper';
import {
  selectCreditInfo,
  selectShouldInitiateCreditInfoLoad,
} from 'selectors/api.selector';
import { CREDIT_INFO_RESET } from 'types/creditInfo.constants';
import { updateSessionTimeoutInLocal } from 'helpers/session';
import { getCreditProfile } from 'externals/_services/credit.service';
import { wrapService } from 'actions/service_wrapper.actions';
import { CREDIT_INFO_PAYLOAD } from 'types/payload.constants';

const a_getCreditProfile = wrapService(getCreditProfile, {
  name: 'getCreditProfile',
});

function payload(creditInfo, creditInfoLoading, creditInfoError) {
  return {
    creditInfo,
    creditInfoError,
    creditInfoLoading,
    type: CREDIT_INFO_PAYLOAD,
  };
}

function request() {
  return payload(false, true, false);
}

export function __success(creditInfo) {
  return payload(creditInfo, false, null);
}

function failure(error) {
  return payload(null, false, error);
}

export function resetCreditInfo() {
  return { type: CREDIT_INFO_RESET };
}

export const retrieveCreditProfileLogin = () => dispatch => {
  return new Promise(resolve => {
    dispatch(a_getCreditProfile())
      .then(creditInfo => {
        dispatch(__success(creditInfo));
        resolve(creditInfo);
      })
      .catch(err => {
        dispatch(failure(err));
        resolve();
      });
  });
};

export const checkForPremiumCreditInfoRefresh = () => dispatch => {
  return new Promise(resolve => {
    dispatch(a_getCreditProfile())
      .then(creditInfo => {
        dispatch(__success(creditInfo));
        resolve(creditInfo);
      })
      .catch(() => {
        resolve();
      });
  });
};

export function retrieveCreditProfile(doNotClear, forceClear = false) {
  return function (dispatch, getState) {
    const state = getState();
    if (selectShouldInitiateCreditInfoLoad(state) || forceClear) {
      if (!state.creditInfo.creditInfoLoading) {
        if (!doNotClear) {
          dispatch(request());
        }
        return new Promise((resolve, reject) => {
          dispatch(a_getCreditProfile())
            .then(creditInfo => {
              dispatch(__success(creditInfo));
              updateSessionTimeoutInLocal();
              resolve(creditInfo);
            })
            .catch(err => {
              dispatch(failure(err));
              reject(err);
            });
        });
      } else {
        return new Promise((resolve, reject) => {
          const checker = () => {
            const state = getState();

            if (state.creditInfo.creditInfo) {
              resolve(state.creditInfo.creditInfo);
            } else if (state.creditInfo.creditInfoError) {
              reject(state.creditInfo.creditInfoError);
            } else {
              setTimeout(checker, 10);
            }
          };
          setTimeout(checker, 10);
        });
      }
    } else {
      return pinkyPromise(selectCreditInfo(state).creditInfo);
    }
  };
}
