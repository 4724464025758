import {
  addStorageByAttributeName,
  getStorageByAttributeName,
  updateStorageByAttributeName,
} from 'externals/_services/storage.service';
import { wrapService } from '../actions/service_wrapper.actions';

const a_getStorage = wrapService(getStorageByAttributeName, {
  name: 'getStorageByAttributeName',
});

const a_addStorage = wrapService(addStorageByAttributeName, {
  name: 'addStorageByAttributeName',
});

const a_updateStorage = wrapService(updateStorageByAttributeName, {
  name: 'updateStorageByAttributeName',
});

export const getGeneralStorage = (attributeName, parseJson) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(a_getStorage(attributeName))
      .then(res => {
        if (res?.length > 0) {
          const value = res[0]?.attributeValue;
          if (parseJson) {
            resolve(JSON.parse(value));
          } else {
            resolve(value);
          }
        } else {
          reject();
        }
      })
      .catch(reject);
  });
};

export const setGeneralStorage = (attributeName, value) => dispatch => {
  return new Promise((resolve, reject) => {
    const attributeValue =
      typeof value === 'string' ? value : JSON.stringify(value);
    dispatch(getGeneralStorage(attributeName, true))
      .then(() => {
        dispatch(a_updateStorage({ attributeName, attributeValue }))
          .then(resolve)
          .catch(reject);
      })
      .catch(() => {
        dispatch(a_addStorage({ attributeName, attributeValue }))
          .then(resolve)
          .catch(reject);
      });
  });
};

export const delGeneralStorage = attributeName => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(setGeneralStorage(attributeName, '{}'))
      .then(() => {
        resolve();
      })
      .catch(reject);
  });
};
