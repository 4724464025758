export const CANCEL_PENDING = 'Cancel Pending';
export const IN_TRIAL_ACTIVE = 'In Trial - Active';
export const PAID_PAST_DUE = 'Paid - Past Due';
export const PAID_ACTIVE = 'Paid - Active';

export const FREE_NEVER_SUBSCRIBED = 'Free Trial Eligible - Never Subscribed';
export const FREE_TRIAL_USED_PREV_SUBSCRIBED =
  'Free Trial Ineligible - Prev Subscribed';
export const FREE_TRIAL_ELIGIBLE_PREV_SUBSCRIBED =
  'Free Trial Eligible - Prev Subscribed';
