import React, { useState, useEffect, createContext } from 'react';
import useNotification from './useNotification';
import './Notification.scss';
import { ReactComponent as CloseIcon } from 'style/_assets/icons/close.svg';
import { ReactComponent as Error } from 'style/_assets/icons/error.svg';
import { ReactComponent as Info } from 'style/_assets/icons/info.svg';
import { ReactComponent as Warning } from 'style/_assets/icons/warning.svg';
import { ReactComponent as Success } from 'style/_assets/icons/check-circle.svg';
import classnames from 'classnames';

export const NotificationContext = createContext({});

export function NotificationIcon({ type }) {
  switch (type) {
    case 'success':
      return <Success />;
    case 'info':
      return <Info />;
    case 'error':
      return <Error />;
    case 'warning':
      return <Warning />;
    default:
      return <Info />;
  }
}

export function NotificationCenter(props) {
  const notificationStateManager = useNotification();
  const { notifications, deleteNotification } = notificationStateManager;

  return (
    <NotificationContext.Provider value={notificationStateManager}>
      <NotificationList coordinate={props.coordinate}>
        {notifications.map(notification => {
          const { id } = notification;
          return (
            <Notification
              key={id}
              isInline={false}
              {...notification}
              deleteNotification={() => deleteNotification(id)}
              coordinate={props.coordinate}
            />
          );
        })}
      </NotificationList>
      {props.children}
    </NotificationContext.Provider>
  );
}

function NotificationList(props) {
  return (
    <div className={`notification-container ${props.coordinate}`}>
      <div className="ContainerCorrection">{props.children}</div>
    </div>
  );
}

function Description({
  description,
  fontSize,
  descriptionClassName,
  isCustomDescription,
}) {
  const className = classnames(
    'notification-description',
    fontSize,
    descriptionClassName,
  );
  if (isCustomDescription) {
    return <div className={className}>{description}</div>;
  } else {
    return (
      <div
        className={className}
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    );
  }
}

export function Notification({
  style,
  id,
  deleteNotification,
  duration,
  transient,
  type,
  title,
  description,
  coordinate,
  notClosable,
  isInline,
  className,
  descriptionClassName,
  bodyClassName,
  fontSize,
  isCustomDescription,
  hideIcon,
}) {
  const [open, setOpen] = useState(true);
  useEffect(() => {
    if (!transient) {
      let timer = setTimeout(() => {
        setOpen(false);
        if (typeof deleteNotification === 'function') deleteNotification();
      }, duration);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [deleteNotification, duration, transient]);

  const onClose = e => {
    e.preventDefault();
    const { id } = e.target;
    setOpen(false);
    if (deleteNotification) {
      deleteNotification(id);
    }
  };

  if (!open) return <></>;

  return (
    <div
      data-testid={'notification-' + id}
      tabIndex={'0'}
      role="status"
      key={id}
      className={classnames(
        className,
        'notification',
        coordinate,
        `notification-${type}`,
        {
          'notification-open': open,
          'notification-close': !open,
          'inline-row-notification': isInline,
          floater: !isInline,
        },
      )}
      style={style}
    >
      {!hideIcon && (
        <div className="notificationIconSection">
          <NotificationIcon type={type} />
        </div>
      )}
      <div
        className={classnames('notification-body', bodyClassName, {
          format100: isCustomDescription,
        })}
      >
        <div
          className={classnames('notification-title', fontSize)}
          data-testid={'notificationTitle-' + id}
        >
          {title}
        </div>
        <Description
          description={description}
          fontSize={fontSize}
          descriptionClassName={descriptionClassName}
          isCustomDescription={isCustomDescription}
        />
      </div>
      <div id={id} className="notification-close-button" onClick={onClose}>
        {!notClosable && <CloseIcon></CloseIcon>}
      </div>
    </div>
  );
}

Notification.defaultProps = {
  isInline: true,
  hideIcon: false,
  fontSize: 'text-16',
};
