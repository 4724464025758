import React from 'react';
import classnames from 'classnames';
import './PageContainer.scss';

function PageContainer({
  datatestid,
  children,
  type,
  className,
  layoutClasses,
  ...props
}) {
  const finalLayoutClasses = Array.isArray(layoutClasses) ? layoutClasses : [];
  const extraClasses = ['PageContainer', ...finalLayoutClasses];

  const classList = classnames(className, type, ...extraClasses);
  return (
    <div
      className={classList}
      data-testid={datatestid ? `${datatestid}-page` : null}
      {...props}
    >
      {children}
    </div>
  );
}

PageContainer.DESKTOP_ROW = 'desktop-row';
PageContainer.MOBILE_STACK = 'mobile-stack';

export { PageContainer };
