import {
  brazeClickEvents,
  brazeEventNames,
  brazeEventProps,
} from 'externals/_tracking/braze/Braze.events.config';
import * as brazeEventTypes from 'externals/_tracking/braze/types/Braze.event.types';
import { FILTERS } from '../types/clickEventTypes.constants';

/*
  Check the braze.events.config for this click type
*/
export function isValidBrazeClickEvent(clickType, eventData) {
  if (eventData['Credit Alert Details']) return true;
  if (eventData.__cash) return true;
  if (eventData.__alert) return true;
  if (
    clickType === 'Navigation' &&
    (eventData['Link'] === 'Credit Report' ||
      eventData['Link'] === "What's impacting my score")
  ) {
    return true;
  }

  return brazeClickEvents[clickType] || false;
}

/*
  Braze events names are not logged the same way that mixpanel events are,
   the braze events follow a specific naming convention which is why we need to expand the details
   and be more descriptive in the event name depending on the click type.
  https://docs.google.com/document/d/1zNkyTvy_KzdKFs-A0XnWqnKMUu2eVb6xlG2_J4VaSaY/edit
*/
export function expandBrazeClickType(clickType, eventData) {
  if (eventData['Credit Alert Details']) {
    return brazeEventTypes.CLICK_ALERT;
  }
  if (eventData.__cash || clickType === brazeClickEvents['Score change tab']) {
    return 'Click ' + eventData['Click Type'];
  }

  if (clickType === 'Navigation') {
    return 'Click to Navigate to ' + eventData['Link'];
  }
  if (eventData)
    switch (clickType) {
      case `Main Nav`:
        return brazeEventTypes.CLICK_MAIN_NAV;
      case `Reviews`:
        return brazeEventTypes.CLICK_REVIEWS;
      case `Slider`:
        return brazeEventTypes.CLICK_SLIDER;
      case `Alert`:
        return brazeEventTypes.CLICK_ALERT;
      case `Prequal`:
        return brazeEventTypes.CLICK_PREQUAL;
      case FILTERS:
        return brazeEventTypes.CLICK_FILTERS;
      case brazeClickEvents['See My CU']:
        return brazeEventTypes.CLICK_SEE_MY_CU;
      case 'Transfer Money':
        return brazeEventTypes.CLICK_TRANSFER_MONEY;
      case 'View Pop Up':
        return brazeEventTypes.VIEW_POPUP;
      case 'Dismiss Pop Up':
        return brazeEventTypes.DISMISS_POPUP;
      case 'Buy Now Premium':
        return brazeEventTypes.CHECKOUT_BUY_NOW_PREMIUM;
      default:
        return brazeEventTypes.CLICK;
    }
}

export function getBrazeEventName(event, eventData, eventContext) {
  const { pageNameMeta } = eventContext;
  if (event === brazeEventTypes.CLICK && eventData.__alert)
    return `Click Alert`;
  if (event.indexOf('Click') >= 0) return event;
  switch (event) {
    case brazeEventTypes.CLICK_TRANSFER_MONEY:
      return brazeEventNames.CLICK_TRANSFER_MONEY;
    case brazeEventTypes.VIEW_MODAL:
      return `${brazeEventNames.VIEW_MODAL} ${eventData['Pop Up Name']}`;
    case brazeEventTypes.VIEW_PAGE:
      return `${brazeEventNames[event]} ${
        eventData['Page Name']
          ? eventData['Page Name']
          : pageNameMeta
          ? pageNameMeta
          : null
      }`;
    case brazeEventTypes.VIEW_POPUP:
      return `${brazeEventNames[event]} ${
        eventData['Pop Up Name'] ? eventData['Pop Up Name'] : ''
      }`;
    case brazeEventTypes.CLICK_APPLY:
    case brazeEventTypes.CLICK:
    case brazeEventTypes.CLICK_FILTERS:
    case brazeEventTypes.CLICK_REVIEWS:
    case brazeEventTypes.CLICK_ALERT:
    default:
      return brazeEventNames[event];
  }
}

export function getBrazeMeta(event, eventData, eventContext) {
  let eventMetaData = {};
  const { creditProfileMeta, pageNameMeta } = eventContext;
  const finalEventData = Object.assign({}, eventData);

  if (brazeEventProps[event]) {
    brazeEventProps[event].forEach(attribute => {
      if (creditProfileMeta && creditProfileMeta[attribute] !== undefined) {
        eventMetaData[attribute] = creditProfileMeta[attribute];
      } else if (finalEventData[attribute] !== undefined) {
        eventMetaData[attribute] = finalEventData[attribute];
      } else if (attribute === 'Page Name') {
        eventMetaData[attribute] = finalEventData['Page Name']
          ? finalEventData['Page Name']
          : pageNameMeta
          ? pageNameMeta
          : null;
      }
    });
  }
  return eventMetaData;
}
