import moment from 'moment';
import { setItem, getItem } from './localstorage';
import { createLogger } from 'helpers/logger';
import { FEATURE_NAME_MY_ACTIONS } from 'types/highlightFeatures.constants';

const logger = createLogger({
  name: 'highlightFeatures.helper',
});

// local storage item name for highlighted features
const HIGHLIGHT_FEATURE = 'cs.highlighFeatures';

const getExistingHiglights = () => {
  const existingHighlights = getItem(HIGHLIGHT_FEATURE);
  if (existingHighlights) {
    try {
      return JSON.parse(existingHighlights);
    } catch (e) {
      logger.errorException('Unable to parse Json', e);
    }
  }

  return {};
};

const addToExistingHighlights = (featureName, value, update) => {
  const existingHighlights = getExistingHiglights();

  if (!update || (update && existingHighlights[featureName])) {
    existingHighlights[featureName] = value;
  }

  try {
    setItem(HIGHLIGHT_FEATURE, JSON.stringify(existingHighlights));
  } catch (e) {
    logger.errorException('Unable to stringify Json Obj', e);
  }
};

const shouldHighlight = dateString => {
  const expirationTime = moment(dateString);
  return expirationTime.isValid() && expirationTime.isAfter(moment());
};

const getFeatureObj = (selectedFeature, featureList) => {
  if (featureList && featureList.items) {
    for (let i = 0; i < featureList.items.length; i++) {
      if (featureList.items[i].featureName === selectedFeature) {
        return featureList.items[i];
      }
    }
  }

  return null;
};

export const featureVisited = featureName => {
  addToExistingHighlights(featureName, 'visited', true);
};

/**
 * Validate for 40 days expiration from first view and/or active status from admin portal
 */
export const getFeatureDetails = (selectedFeature, featureList, isAction) => {
  let feature = getFeatureObj(selectedFeature, featureList);
  const existingHighlights = getExistingHiglights();
  const isActionEligible =
    isAction ||
    (selectedFeature === FEATURE_NAME_MY_ACTIONS &&
      existingHighlights[FEATURE_NAME_MY_ACTIONS]);

  if (isActionEligible) {
    feature = {
      markerType: 'RedDot',
      featureName: selectedFeature,
    };
  }

  if (feature) {
    // if feature was already highlighted for user, check for its 40 day validity
    if (existingHighlights[selectedFeature]) {
      return shouldHighlight(existingHighlights[selectedFeature])
        ? feature
        : null;
    } else {
      // set expiration for 40 days
      const current = moment();
      current.add(40, 'days');
      addToExistingHighlights(selectedFeature, current.format());
      if (isAction) {
        addToExistingHighlights(FEATURE_NAME_MY_ACTIONS, current.format());
      }
      return feature;
    }
  }

  return null;
};

export const initPersonalizedActions = (actions, highlightFeatures) => {
  const existingHighlights = getExistingHiglights();
  if (existingHighlights.isFirstVisit !== false) {
    actions.forEach(elem => {
      let feature = getFeatureObj(elem.module, highlightFeatures);
      if (!feature) {
        addToExistingHighlights(elem.module, 'visited');
      }
    });
    addToExistingHighlights('isFirstVisit', false);
  }
};
