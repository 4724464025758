import React from 'react';
import './RouteContainer.scss';

function RouteContainer({ children, type, ...props }) {
  let computedClass = type ? `RouteContainer ${type}` : `RouteContainer`;
  computedClass = props.className
    ? `${computedClass} ${props.className}`
    : `${computedClass}`;

  return (
    <div
      className={computedClass}
      data-testid="RouteContainer"
      style={props.style}
    >
      {children}
    </div>
  );
}

export { RouteContainer };
