import { buildInHouseSimulationEndpoint } from 'api-urls';
import {
  buildFetchOptionsWithHeaders,
  convertObjectToQueryString,
  standardizedFetchHandler
} from "./service_helpers";

export async function fetchInHouseSimulation(queryAttrs) {
  const requestOptions = buildFetchOptionsWithHeaders();
  queryAttrs = queryAttrs || {};

  const queryString = convertObjectToQueryString(queryAttrs);

  return standardizedFetchHandler(
    buildInHouseSimulationEndpoint() + queryString,
    requestOptions,
  );
}
