import { fetchHighlightFeatures } from 'externals/_services/highlight_feature.service';
import { createLogger } from 'helpers/logger';
import { wrapService } from 'actions/service_wrapper.actions';
import { HIGHLIGHT_FEATURES_PAYLOAD } from 'types/payload.constants';

const a_fetchHighlightFeatures = wrapService(fetchHighlightFeatures, {
  name: 'fetchHighlightFeatures',
});

const logger = createLogger({
  name: 'highlightFeatures.actions',
});

function payload(
  highlightFeatures,
  highlightFeaturesLoading,
  highlightFeaturesError,
) {
  return {
    type: HIGHLIGHT_FEATURES_PAYLOAD,
    highlightFeatures,
    highlightFeaturesLoading,
    highlightFeaturesError,
  };
}

function request() {
  return payload(null, true, null);
}

function success(features) {
  return payload(features, false, null);
}

function failure(error) {
  return payload(null, false, error);
}

export const getActiveNewFeaturesToHighlight =
  _ => async (dispatch, getState) => {
    const state = getState();
    if (state.highlightFeatures.highlightFeaturesLoading) return;

    dispatch(request());

    try {
      const features = await dispatch(a_fetchHighlightFeatures());
      dispatch(success(features));
    } catch (error) {
      logger.reportAPIError(error);
      dispatch(failure(error));
    }
  };
