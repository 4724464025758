import { cloneDataObject } from 'helpers/objectHelpers';
import { SECOND_TAB_NAME } from 'pages_CreditManager_helpers/CreditCalendar.constants';

export const SHOW_CALENDAR_WELCOME_MODAL = 'SHOW_CALENDAR_WELCOME_MODAL';
export const SHOW_CONFFETI = 'SHOW_CONFFETI';
export const SHOW_RENT_REPORTING_CARD = 'SHOW_RENT_REPORTING_CARD';
export const FIRST_VISIT_DONE = 'FIRST_VISIT_DONE';
export const THREE_BUREAUS = 'THREE_BUREAUS';
export const PREFIX_CATCH_ME_BEFORE_LATE = 'catch_me_before_late-';
export const PREFIX_DELINQUENT = 'delinquent-';
export const PREFIX_CURRENT_TRADELINE = 'current_tradeline-';

export const TAB_CALENDAR = {
  label: 'Calendar',
  key: 'calendar',
};
export const TAB_GOALS = {
  label: SECOND_TAB_NAME,
  key: 'goals',
};
export const TABS = {
  goals: TAB_GOALS,
  calendar: TAB_CALENDAR,
};

export const ACCOUNT_TYPES_DEBT = {
  autoLoanDebt: { symbols: ['AU'], label: 'Auto Loan' },
  studentLoanDebt: { symbols: ['ST'], label: 'Student Loan' },
  otherLoanDebt: {
    symbols: ['US', 'UC', 'IS', 'Y', 'SE'],
    label: 'Other Loan',
  },
  creditCardDebt: { symbols: ['CC', 'CH'], label: 'Credit Card' },
  homeLoanDebt: { symbols: [''], label: 'Home Loan' },
  rentalAccountDebt: { symbols: [''], label: 'Rental Account' },
};

export const ACCOUNT_TYPES_SYMBOL_DEBT = {
  AU: 'autoLoanDebt',
  ST: 'studentLoanDebt',
  US: 'otherLoanDebt',
  UC: 'otherLoanDebt',
  IS: 'otherLoanDebt',
  SE: 'otherLoanDebt',
  Y: 'otherLoanDebt',
  LC: 'otherLoanDebt',
  CEL: 'otherLoanDebt',
  RD: 'autoLoanDebt',
  CC: 'creditCardDebt',
  CH: 'creditCardDebt',
  FX: 'creditCardDebt',
  FR: 'homeLoanDebt',
  CV: 'homeLoanDebt',
};

export const LOAN_TYPE_ACCOUNT_TYPES = {
  CREDIT_CARD: 'creditCardDebt',
  HOME_LOAN: 'homeLoanDebt',
  AUTO_LOAN: 'autoLoanDebt',
  STUDENT_LOAN: 'studentLoanDebt',
  OTHER_LOAN: 'otherLoanDebt',
  RENTAL_ACCOUNT: 'rentalAccount',
};

export const PAST_DAYS = {
  LATE_30_DAYS: 30,
  LATE_60_DAYS: 60,
  LATE_90_DAYS: 90,
  LATE_120_DAYS: 120,
};

export const CATCH_ME_BEFORE_LATE_DAYS = {
  CATCH_ME_BEFORE_LATE_30: 30,
  CATCH_ME_BEFORE_LATE_60: 60,
  CATCH_ME_BEFORE_LATE_90: 90,
  CATCH_ME_BEFORE_LATE_120: 120,
};

export const PAST_TIME_LINES = [
  'PAST_TWO_PLUS_WEEKS',
  'PAST_TWO_WEEKS',
  'LAST_WEEK',
  'TAKE_ACTION',
];
export const DEFAULT_SCROLL_SECTION = 'TODAY';
export const TIME_LINE_TODAY = 'TODAY';
export const FUTURE_TIME_LINES = [
  'THIS_WEEK',
  'NEXT_TWO_WEEKS',
  'NEXT_TWO_PLUS_WEEKS',
];
export const ON_TOP_JUMP = {
  label: 'Jump to recent',
  arrowDirection: 'arrow-down',
  dissolve: false,
  hideJumpSection: false,
};
export const ON_BOTTOM_JUMP = {
  label: 'Jump to recent',
  arrowDirection: 'arrow-up',
  dissolve: false,
  hideJumpSection: false,
};
export const IN_MIDDLE_JUMP = {
  label: 'Past events',
  arrowDirection: 'arrow-up',
  dissolve: false,
  hideJumpSection: false,
};
export const TIME_LINES = [
  {
    timeLineId: 'PAST_TWO_PLUS_WEEKS',
    label: 'Past 2 weeks +',
    className: 'bgGrayN060',
  },
  {
    timeLineId: 'PAST_TWO_WEEKS',
    label: 'Past 2 weeks',
    className: 'bgGrayN060',
  },
  { timeLineId: 'LAST_WEEK', label: 'Last week', className: 'bgGrayN060' },
  { timeLineId: 'TAKE_ACTION', label: 'Take action', className: 'bgGray1' },
  {
    timeLineId: 'TODAY',
    label: 'Today',
    classNameRow: 'bgWhite secondary_text_light',
    className: 'bgGray1',
    defaultMessage:
      'No events to take action today. Visit regularly to be on top of your credit',
  },
  {
    timeLineId: 'THIS_WEEK',
    label: 'This week',
    classNameRow: 'bgWhite',
    className: 'bgGray1',
  },
  {
    timeLineId: 'NEXT_TWO_WEEKS',
    label: 'In 2 weeks',
    classNameRow: 'bgWhite',
    className: 'bgGray1',
  },
  {
    timeLineId: 'NEXT_TWO_PLUS_WEEKS',
    label: 'In 2 weeks +',
    classNameRow: 'bgWhite',
    className: 'bgGray1',
  },
];

export const CATCH_ME_BEFORE_LATE = [
  {
    name: 'idHash',
    type: 'string',
    ifNullGenerateId: true,
    prefixId: PREFIX_CATCH_ME_BEFORE_LATE,
  },
  { name: 'dateReported', type: 'date', pattern: 'MMMM DD, YYYY' },
  { name: 'amountPastDue', type: 'money' },
  { name: 'creditor', type: 'string' },
  { name: 'scoreImpact', type: 'string' },
  { name: 'creditManagerTags', type: 'array' },
  { name: 'creditManagerDateReported', type: 'date', pattern: 'MMMM DD, YYYY' },
  {
    name: 'creditManagerDateReportedInDays',
    type: 'number',
    operation: 'absolute',
  },
  { name: 'balance', parent: ['annotation', 'creditUsage'], type: 'number' },
  { name: 'apr', type: 'number' },
  { name: 'totalMonthlyPayments', type: 'number' },
  {
    name: 'creditLimit',
    parent: ['annotation', 'creditUsage'],
    type: 'number',
  },
  { name: 'accountCondition', type: 'string' },
  { name: 'dateOpened', type: 'date' },
  { name: 'accountTypeSymbol', type: 'string' },
  { name: 'creditTypeSymbol', type: 'string' },
  { name: 'latestPayStatusEnum', type: 'string' },
];

export const CREDIT_MANAGER_TAGS = {
  CATCH_ME_BEFORE_LATE_30: CATCH_ME_BEFORE_LATE?.map(e => cloneDataObject(e)),
  CATCH_ME_BEFORE_LATE_60: CATCH_ME_BEFORE_LATE?.map(e => cloneDataObject(e)),
  CATCH_ME_BEFORE_LATE_90: CATCH_ME_BEFORE_LATE?.map(e => cloneDataObject(e)),
  CATCH_ME_BEFORE_LATE_120: CATCH_ME_BEFORE_LATE?.map(e => cloneDataObject(e)),
  DELINQUENT_TRADE_LINE: [
    {
      name: 'idHash',
      type: 'string',
      ifNullGenerateId: true,
      prefixId: PREFIX_DELINQUENT,
    },
    { name: 'dateReported', type: 'date', pattern: 'MMMM DD, YYYY' },
    { name: 'amountPastDue', type: 'string' },
    { name: 'creditor', type: 'string' },
    {
      name: 'creditManagerDateReported',
      type: 'date',
      pattern: 'MMMM DD, YYYY',
    },
    {
      name: 'creditManagerDateReportedInDays',
      type: 'number',
      operation: 'absolute',
    },
    { name: 'payStatus', type: 'string' },
    { name: 'latestPayStatusEnum', type: 'string' },

    { name: 'balance', parent: ['annotation', 'creditUsage'], type: 'number' },
    { name: 'apr', type: 'number' },
    { name: 'totalMonthlyPayments', type: 'number' },
    {
      name: 'creditLimit',
      parent: ['annotation', 'creditUsage'],
      type: 'number',
    },
    { name: 'accountCondition', type: 'string' },
    { name: 'dateOpened', type: 'date' },
    { name: 'accountTypeSymbol', type: 'string' },
    { name: 'creditTypeSymbol', type: 'string' },
  ],
  CURRENT_TRADE_LINE: [
    {
      name: 'idHash',
      type: 'string',
      ifNullGenerateId: true,
      prefixId: PREFIX_CURRENT_TRADELINE,
    },
    { name: 'dateReported', type: 'date', pattern: '[AS OF] MM/YYYY' },
    { name: 'amountPastDue', type: 'string' },
    { name: 'totalMonthlyPayments', type: 'number' },
    { name: 'creditor', type: 'string' },
    { name: 'loanType2', type: 'string' },
    {
      name: 'creditManagerDateReported',
      type: 'date',
      pattern: 'MMMM DD, YYYY',
    },
    {
      name: 'creditManagerDateReportedInDays',
      type: 'number',
      operation: 'absolute',
    },
    { name: 'payStatus', type: 'string' },
    { name: 'latestPayStatusEnum', type: 'string' },
    { name: 'balance', parent: ['annotation', 'creditUsage'], type: 'number' },
    { name: 'apr', type: 'number' },
    {
      name: 'creditLimit',
      parent: ['annotation', 'creditUsage'],
      type: 'number',
    },
    {
      name: 'currentUsageOverSuggested',
      parent: ['annotation', 'creditUsage'],
      type: 'number',
    },
    {
      name: 'suggestedMaxAmount',
      parent: ['annotation', 'creditUsage'],
      type: 'money',
    },
    {
      name: 'suggestedMaxPercentage',
      parent: ['annotation', 'creditUsage'],
      type: 'percentage',
      quantityDecimals: 0,
    },
    {
      name: 'utilizationPercentage',
      parent: ['annotation', 'creditUsage'],
      type: 'percentage',
      quantityDecimals: 0,
    },
    { name: 'accountCondition', type: 'string' },
    { name: 'dateOpened', type: 'date' },
    { name: 'accountTypeSymbol', type: 'string' },
    { name: 'creditTypeSymbol', type: 'string' },
  ],
};

export const SUB_MODULES = [
  'CATCH_ME_BEFORE_LATE_30',
  'CATCH_ME_BEFORE_LATE_60',
  'CATCH_ME_BEFORE_LATE_90',
  'CATCH_ME_BEFORE_LATE_120',
  'DELINQUENT_TRADE_LINE',
  'CURRENT_TRADE_LINE',
  'CREDIT_REPORT',
  'GRADE_CHANGES',
  'CREDIT_BUILDER',
  'RENT_REPORTING',
  'INQUIRIES',
  'CREDIT_USAGE',
];

export const SCROLL_FOCUS_MODULES = [
  'DELINQUENT_TRADE_LINE',
  'CATCH_ME_BEFORE_LATE_30',
  'CATCH_ME_BEFORE_LATE_60',
  'CATCH_ME_BEFORE_LATE_90',
  'CATCH_ME_BEFORE_LATE_120',
];

// Credit Reports
export const CREDIT_REPORT_MODULE = 'CREDIT_REPORT';
export const CREDIT_REPORT_MAP = [
  {
    name: 'idHash',
    type: 'string',
    ifNullGenerateId: true,
    prefixId: 'credit_report-',
  },
  { name: 'availableDate', type: 'date', pattern: 'MMMM DD, YYYY' },
  { name: 'availableInDays', type: 'number', operation: 'absolute' },
  { name: 'type', type: 'string' },
  { name: 'creditManagerTags', type: 'array' },
];

export const CREDIT_REPORT_CONFIG = {
  identifier: 'creditReports',
  map: CREDIT_REPORT_MAP,
  creditManagerTag: CREDIT_REPORT_MODULE,
};

// Grade changes

export const GRADE_FACTOR_MAP = {
  PAYMENT_HISTORY: {
    label: 'Payment History',
    buttonText: 'Payment History',
    A: 'grades-payment-history-a',
    B: 'grades-payment-history-b',
    C: 'grades-payment-history-c',
    D: 'grades-payment-history-d',
    F: 'grades-payment-history-f',
  },
  CREDIT_USAGE: {
    label: 'Credit Usage',
    buttonText: 'Credit Usage',
    A: 'grades-credit-usage-a',
    B: 'grades-credit-usage-b',
    C: 'grades-credit-usage-c',
    D: 'grades-credit-usage-d',
    F: 'grades-credit-usage-f',
  },
  CREDIT_AGE: {
    label: 'Credit Age',
    buttonText: 'Credit Age',
    A: 'grades-credit-age-a',
    B: 'grades-credit-age-b',
    C: 'grades-credit-age-c',
    D: 'grades-credit-age-d',
    F: 'grades-credit-age-f',
  },
  ACCOUNT_MIX: {
    label: 'Account Mix',
    buttonText: 'Account Mix',
    A: 'grades-account-mix-a',
    B: 'grades-account-mix-b',
    C: 'grades-account-mix-c',
    D: 'grades-account-mix-d',
    F: 'grades-account-mix-f',
  },
  CREDIT_INQUIRIES: {
    label: 'Credit Inquiries',
    buttonText: 'Inquiries',
    A: 'grades-inquiries-a',
    B: 'grades-inquiries-b',
    C: 'grades-inquiries-c',
    D: 'grades-inquiries-d',
    F: 'grades-inquiries-f',
  },
};

export const GRADE_CHANGES_MODULE = 'GRADE_CHANGES';
export const GRADE_CHANGES_MAP = [
  {
    name: 'idHash',
    type: 'string',
    ifNullGenerateId: true,
    prefixId: 'grade_changes-',
  },
  { name: 'currentGrade', type: 'string' },
  { name: 'previousGrade', type: 'string' },
  { name: 'creditFactor', type: 'string' },
  { name: 'positive', type: 'boolean' },
];

export const GRADE_CONFIG = {
  identifier: 'gradeChanges',
  map: GRADE_CHANGES_MAP,
  creditManagerTag: GRADE_CHANGES_MODULE,
  timeLineId: TIME_LINE_TODAY,
};

// Credit Builder

export const CREDIT_BUILDER_MODULE = 'CREDIT_BUILDER';
export const CREDIT_BUILDER_MAP = [
  {
    name: 'idHash',
    type: 'string',
    ifNullGenerateId: true,
    prefixId: 'credit_builder-',
  },
  { name: 'hasEnrolled', type: 'boolean' },
];

export const CREDIT_BUILDER_CONFIG = {
  identifier: 'creditBuilderDetails',
  map: CREDIT_BUILDER_MAP,
  creditManagerTag: CREDIT_BUILDER_MODULE,
  timeLineId: TIME_LINE_TODAY,
};

// RENT REPORTING

export const RENT_REPORTING_MODULE = 'RENT_REPORTING';
export const RENT_REPORTING_MAP = [
  {
    name: 'idHash',
    type: 'string',
    ifNullGenerateId: true,
    prefixId: 'rent_reporting-',
  },
  { name: 'enrollmentStatus', type: 'string' },
  { name: 'hasEnrollmentStarted', type: 'boolean' },
  { name: 'creditManagerTags', type: 'array' },
  { name: 'enrollmentComplete', type: 'boolean' },
];

export const RENT_REPORTING_CONFIG = {
  identifier: 'rentReportingDetails',
  map: RENT_REPORTING_MAP,
  creditManagerTag: RENT_REPORTING_MODULE,
  timeLineId: TIME_LINE_TODAY,
};

// Inquiries

export const INQUIRIES_MODULE = 'INQUIRIES';
export const INQUIRIES_MAP = [
  {
    name: 'idHash',
    type: 'string',
    ifNullGenerateId: true,
    prefixId: 'inquiry-',
  },
  { name: 'createDate', type: 'date' },
  { name: 'updateDate', type: 'date' },
  { name: 'subscriber', type: 'string' },
  { name: 'inquiryDate', type: 'date', pattern: 'MMMM DD, YYYY' },
  { name: 'inquiryType', type: 'string' },
  { name: 'industryCode', type: 'string' },
  { name: 'creditInfoId', type: 'string' },
  { name: 'subscriberNumber', type: 'string' },
  { name: 'inquiryDateDaysAgo', type: 'number' },
  { name: 'bureauInquiryDate', type: 'date' },
  { name: 'creditManagerTags', type: 'array' },
  { name: 'fallOffDate', type: 'date', pattern: 'MMMM DD, YYYY' },
  { name: 'fallOffInDays', type: 'number', operation: 'absolute' },
];

export const INQUIRIES_CONFIG = {
  identifier: 'inquiries',
  map: INQUIRIES_MAP,
  creditManagerTag: INQUIRIES_MODULE,
  timeLineId: TIME_LINE_TODAY,
};

// Credit Usage

const getLineTextFields = ({ bgColor }) => {
  return {
    line: <div className={`${bgColor} borderRad3 height-6px format100`}></div>,
    text: minUsage => (
      <>
        {minUsage} <br /> balance{' '}
        <span className="d-hide">
          <br />
        </span>{' '}
        or less
      </>
    ),
  };
};

export const CREDIT_USAGE_FACTOR_MAP = {
  CREDIT_USAGE: {
    A: 'grades-credit-usage-a',
    B: 'grades-credit-usage-b',
    C: 'grades-credit-usage-c',
    D: 'grades-credit-usage-d',
    F: 'grades-credit-usage-f',
  },
  USAGE_LEVEL: [
    {
      grade: 'F',
      percentage: '71%',
      potentialPercentage: '70%',
      ...getLineTextFields({ bgColor: 'bgRedR100' }),
    },
    {
      grade: 'D',
      percentage: '70%',
      potentialPercentage: '50%',
      ...getLineTextFields({ bgColor: 'bgYellowT100' }),
    },
    {
      grade: 'C',
      percentage: '50%',
      potentialPercentage: '30%',
      line: <div className="bgYellow7 borderRad3 height-6px format100"></div>,
      ...getLineTextFields({ bgColor: 'bgYellow7' }),
    },
    {
      grade: 'B',
      percentage: '30%',
      potentialPercentage: '10%',
      line: <div className="bgGreenT100 borderRad3 height-6px format100"></div>,
      ...getLineTextFields({ bgColor: 'bgGreenT100' }),
    },
    {
      grade: 'A',
      percentage: '10%',
      potentialPercentage: '0%',
      ...getLineTextFields({ bgColor: 'bgGreenG100' }),
    },
  ],
};

export const CREDIT_USAGE_MODULE = 'CREDIT_USAGE';
export const CREDIT_USAGE_MAP = [
  {
    name: 'idHash',
    type: 'string',
    ifNullGenerateId: true,
    prefixId: 'credit_usage-',
  },
  { name: 'currentUsageGrade', type: 'string' },
  { name: 'potentialUsageGrade', type: 'string' },
  { name: 'gradeAMinUsage', type: 'number' },
  { name: 'gradeBMinUsage', type: 'number' },
  { name: 'gradeCMinUsage', type: 'number' },
  { name: 'gradeDMinUsage', type: 'number' },
  { name: 'gradeFMinUsage', type: 'number' },
  { name: 'minPaymentToPotentialUsage', type: 'number' },
  { name: 'potentialPercentage', type: 'number' },
];

export const CREDIT_USAGE_CONFIG = {
  identifier: 'creditUsageForGrade',
  map: CREDIT_USAGE_MAP,
  creditManagerTag: CREDIT_USAGE_MODULE,
  timeLineId: TIME_LINE_TODAY,
};
