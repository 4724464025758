import {
  ACCOUNT_LINK_LINK_TOKEN_REQUEST,
  ACCOUNT_LINK_LINK_TOKEN_ERROR,
  ACCOUNT_LINK_LINK_TOKEN_SUCCESS,
  ACCOUNT_LINK_LINKED_ACCOUNTS_REQUEST,
  ACCOUNT_LINK_LINKED_ACCOUNTS_ERROR,
  ACCOUNT_LINK_LINKED_ACCOUNTS_SUCCESS,
  ACCOUNT_LINK_LINK_ACCOUNT_REQUEST,
  ACCOUNT_LINK_LINK_ACCOUNT_ERROR,
  ACCOUNT_LINK_LINK_ACCOUNT_SUCCESS,
  ACCOUNT_LINK_FUND_TRANSFER_REQUEST,
  ACCOUNT_LINK_FUND_TRANSFER_ERROR,
  ACCOUNT_LINK_FUND_TRANSFER_SUCCESS,
  ACCOUNT_LINK_REMOVE_ACCOUNT_REQUEST,
  ACCOUNT_LINK_REMOVE_ACCOUNT_ERROR,
  ACCOUNT_LINK_REMOVE_ACCOUNT_SUCCESS,
  ACCOUNT_LINK_LINKED_ACCOUNTS_LOGO_REQUEST,
  ACCOUNT_LINK_LINKED_ACCOUNTS_LOGO_ERROR,
  ACCOUNT_LINK_LINKED_ACCOUNTS_LOGO_SUCCESS,
  ACCOUNT_LINK_CLEAN,
  ACCOUNT_LINK_LINK_TOKEN_REMOVE,
} from '../types/accountLink.constants';

const initialState = {
  linkTokenLoading: null,
  linkTokenError: null,
  linkToken: null,
  linkedAccountsLoading: null,
  linkedAccountsError: null,
  linkedAccounts: null,
  linkedAccountsWithLogoLoading: null,
  linkedAccountsWithLogoError: null,
  linkedAccountsWithLogo: null,
  linkAccountLoading: null,
  linkAccountError: null,
  linkAccountSuccess: null,
  fundTransferLoading: null,
  fundTransferError: null,
  fundTransferSuccess: null,
  removeAccountLoading: null,
  removeAccountError: null,
  removeAccountSuccess: null,
};

export default function accountLinkReducer(state = initialState, action) {
  switch (action.type) {
    case ACCOUNT_LINK_LINK_TOKEN_REQUEST:
      return Object.assign({}, state, {
        linkTokenLoading: true,
      });
    case ACCOUNT_LINK_LINK_TOKEN_ERROR:
      return Object.assign({}, state, {
        linkTokenLoading: false,
        linkTokenError: action.error,
      });
    case ACCOUNT_LINK_LINK_TOKEN_SUCCESS:
      return Object.assign({}, state, {
        linkTokenLoading: false,
        linkToken: action.linkToken,
      });
    case ACCOUNT_LINK_LINK_TOKEN_REMOVE:
      return Object.assign({}, state, {
        linkTokenLoading: false,
        linkToken: null,
      });

    // Linked accounts
    case ACCOUNT_LINK_LINKED_ACCOUNTS_REQUEST:
      return Object.assign({}, state, {
        linkedAccountsWithLogoLoading: true,
        linkedAccountsLoading: true,
        linkedAccountsError: false,
      });
    case ACCOUNT_LINK_LINKED_ACCOUNTS_ERROR:
      return Object.assign({}, state, {
        linkedAccountsLoading: false,
        linkedAccountsError: action.error,
      });
    case ACCOUNT_LINK_LINKED_ACCOUNTS_SUCCESS:
      return Object.assign({}, state, {
        linkedAccountsLoading: false,
        linkedAccountsError: false,
        linkedAccounts: action.linkedAccounts,
      });

    // Linked accounts with logos
    case ACCOUNT_LINK_LINKED_ACCOUNTS_LOGO_REQUEST:
      return Object.assign({}, state, {
        linkedAccountsWithLogoLoading: true,
        linkedAccountsWithLogoError: false,
      });
    case ACCOUNT_LINK_LINKED_ACCOUNTS_LOGO_ERROR:
      return Object.assign({}, state, {
        linkedAccountsWithLogoLoading: false,
        linkedAccountsWithLogoError: action.error,
      });
    case ACCOUNT_LINK_LINKED_ACCOUNTS_LOGO_SUCCESS:
      return Object.assign({}, state, {
        linkedAccountsWithLogoLoading: false,
        linkedAccountsWithLogoError: false,
        linkedAccountsWithLogo: action.linkedAccountsWithLogo,
      });

    //Link Account
    case ACCOUNT_LINK_LINK_ACCOUNT_REQUEST:
      return Object.assign({}, state, {
        linkAccountLoading: true,
        linkAccountSuccess: null,
        linkAccountError: null,
      });
    case ACCOUNT_LINK_LINK_ACCOUNT_ERROR:
      return Object.assign({}, state, {
        linkAccountLoading: false,
        linkAccountSuccess: false,
        linkAccountError: action.error,
      });
    case ACCOUNT_LINK_LINK_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        linkAccountLoading: false,
        linkAccountSuccess: true,
        linkAccountError: false,
      });

    //Fund Transfer
    case ACCOUNT_LINK_FUND_TRANSFER_REQUEST:
      return Object.assign({}, state, {
        fundTransferLoading: true,
        fundTransferSuccess: null,
        fundTransferError: null,
      });
    case ACCOUNT_LINK_FUND_TRANSFER_ERROR:
      return Object.assign({}, state, {
        fundTransferLoading: false,
        fundTransferSuccess: false,
        fundTransferError: action.error,
      });
    case ACCOUNT_LINK_FUND_TRANSFER_SUCCESS:
      return Object.assign({}, state, {
        fundTransferLoading: false,
        fundTransferSuccess: true,
        fundTransferError: false,
      });

    // Remove Account
    case ACCOUNT_LINK_REMOVE_ACCOUNT_REQUEST:
      return Object.assign({}, state, {
        removeAccountLoading: true,
        removeAccountSuccess: null,
        removeAccountError: null,
      });
    case ACCOUNT_LINK_REMOVE_ACCOUNT_ERROR:
      return Object.assign({}, state, {
        removeAccountLoading: false,
        removeAccountSuccess: false,
        removeAccountError: action.error,
      });
    case ACCOUNT_LINK_REMOVE_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        removeAccountLoading: false,
        removeAccountSuccess: true,
        removeAccountError: false,
      });
    case ACCOUNT_LINK_CLEAN:
      return initialState;
    default:
      return state;
  }
}
