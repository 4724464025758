import {
  CREDIT_LOCK,
  CREDIT_LOCK_UPSELL,
  CREDIT_REPORT,
  DISCREPANCIES,
} from '../constants/CreditReportTUModules.constants';
import { isFree } from 'helpers/userHelper';

export const createModulesContainer = deps => {
  if (!deps?.userInfo) return { modules: [] };
  const showCreditLockForFreemium =
    deps?.config?.creditReport?.showCreditLockForFreemium &&
    isFree(deps?.userInfo);
  const { creditReportTabContainer } = deps?.creditReportTabContainer || {};

  let hasCreditReports = false;
  let hasDiscrepancies = false;
  let hasCreditLock = false;
  let disputeDashboardIncluded = false;

  creditReportTabContainer?.modules?.forEach(m => {
    switch (m?.module) {
      case CREDIT_REPORT:
        hasCreditReports = true;
        break;
      case DISCREPANCIES: {
        hasDiscrepancies = true;
        disputeDashboardIncluded = m?.additionalData?.disputeDashboardIncluded;
        break;
      }
      case CREDIT_LOCK:
        hasCreditLock = true;
        break;
      default:
    }
  });

  const modules =
    creditReportTabContainer?.modules
      ?.filter(m => m?.module !== DISCREPANCIES)
      ?.map(m => {
        const module = {
          module: m?.module,
          ...deps,
          globalLoadingFlag: false,
        };
        if (m?.module === CREDIT_REPORT) {
          module.hasCreditReports = hasCreditReports;
          module.hasDiscrepancies = hasDiscrepancies;
          module.disputeDashboardIncluded = disputeDashboardIncluded;
        }
        if (m?.module === CREDIT_LOCK) {
          module.hasCreditLock = hasCreditLock;
        }
        return module;
      }) ?? [];

  if (showCreditLockForFreemium) {
    modules.splice(1, 0, {
      module: CREDIT_LOCK_UPSELL,
      ...deps,
    });
  }

  return {
    modules,
    hasCreditReports,
    hasDiscrepancies,
    hasCreditLock,
    disputeDashboardIncluded,
  };
};
