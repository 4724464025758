export const STARTED = 'STARTED';
export const COMPLETE = 'COMPLETE';
export const FAILED = 'FAILED';

export const convertPromiseMapToSinglePromise = promiseMap => {
  return new Promise((resolveOuter, reject) => {
    const statusKeyMap = {};
    Promise.all(
      Object.keys(promiseMap).map(k => {
        const p = promiseMap[k];
        statusKeyMap[k] = STARTED;
        return new Promise(resolve => {
          p.then(x => {
            statusKeyMap[k] = COMPLETE;
            resolve(x);
          }).catch(x => {
            statusKeyMap[k] = FAILED;
            resolve(x);
          });
        });
      }),
    )
      .then(resolveOuter)
      .catch(resolveOuter);
  });
};
