import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { userActions } from './actions/user.actions';
import { getUserInfo } from 'actions/userInfo.actions.js';
import { Loading } from 'pages/SignupPage/components/Loading/Loading';
import { withConfigLoader } from 'hoc/config.hoc';
import { getEncryptedEmail } from 'helpers/marketing.helper';

class PrivateRoute extends React.Component {
  componentDidMount() {
    const { loggedIn, dispatch, loggingIn, userInfo } = this.props;
    const signupRedirect = () => this.props.history.push('/signup');
    if (getEncryptedEmail()) {
      signupRedirect();
      return;
    }
    if (!loggedIn && !loggingIn) {
      dispatch(userActions.authorizeSession()).catch(() => {
        signupRedirect();
      });
    }
    if (loggedIn && !userInfo.userInfo) {
      dispatch(getUserInfo()).catch(() => {
        signupRedirect();
      });
    }
  }

  render() {
    const {
      component: Component,
      loggedIn,
      loggingIn,
      signup,
      userInfo,
      config,
      configLoading,
    } = this.props;

    if (signup.errorPage) {
      return <Redirect to={`/signupError?type=${signup.errorPage}`} />;
    }

    if (
      (!loggedIn && !loggingIn) ||
      !userInfo.userInfo ||
      !config ||
      configLoading
    ) {
      return <Loading />;
    }

    if (!loggedIn) {
      return (
        <Route
          render={props => (
            <Redirect
              to={{ pathname: '/signup', state: { from: props.location } }}
            />
          )}
        />
      );
    }

    return <Route render={props => <Component {...props} config={config} />} />;
  }
}

function mapStateToProps(state) {
  const { userInfo, alert, app, signup } = state;
  const { loggedIn, loggingIn } = app;
  return {
    alert,
    signup,
    userInfo,
    loggedIn,
    loggingIn,
  };
}

const connectedApp = withRouter(
  connect(mapStateToProps)(withConfigLoader(PrivateRoute)),
);
export { connectedApp as PrivateRoute };
