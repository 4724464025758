import React from 'react';
import { useHighLightFeatures } from 'partial/HighlightFeatureLabel/hooks/highlightFeatures.hook';
import { getFeatureDetails } from 'helpers/highlightFeatures.helper';
import 'partial/HighlightFeatureLabel/highlightFeatures.scss';

function HighlightFeatureLabel(props) {
  const { featureToHighlight } = props;

  const { highlightFeatureLoading, highlightFeatures } = useHighLightFeatures();

  const feature =
    !highlightFeatureLoading && highlightFeatures
      ? getFeatureDetails(featureToHighlight, highlightFeatures, props.isAction)
      : null;

  if (feature && feature.featureName) {
    const { markerType, labelName } = feature;

    let featureClass = 'hightlightFeature';
    featureClass += markerType === 'Label' ? ' featuredLabel' : ' featuredDot';
    if (props.isAction) {
      featureClass += props.isSmallSize ? ' ' : ' expandedPosition';
    }

    return (
      <span
        className={featureClass}
        data-testid={`highlight-feature-${featureToHighlight}`}
      >
        {markerType === 'Label' && labelName}
      </span>
    );
  }

  return <></>;
}

export default HighlightFeatureLabel;
