import { getStandardTimestamp } from './dateHelper';
import { getCurrentUrl } from './domHelper';

export const buildStandardException = args => {
  if (!args)
    throw buildStandardException({
      code: 'ERR_111',
      status: '500',
      message: `Unable to create exception without args: ${JSON.stringify(
        args,
      )}`,
    });
  if (!args.code)
    throw buildStandardException({
      code: 'ERR_111',
      status: '500',
      message: `Unable to create exception without code: ${JSON.stringify(
        args,
      )}`,
    });

  const finalStatus = args.status ? args.status : '500';

  const err = new Error(args.message);
  err.code = args.code;
  err.path = getCurrentUrl();
  err.timestamp = getStandardTimestamp();
  err.status = finalStatus;
  return err;
};

export const throwException = (arg1, arg2) => {
  if (typeof arg1 === 'object') throw buildStandardException(arg1);
  if (typeof arg1 === 'string') {
    throw buildStandardException({
      code: arg1,
      message: arg2,
    });
  }
  buildStandardException(arg1);
};

export const standardizeException = err => {
  if (err === null || err === undefined) {
    const error = {
      message:
        'An unknown error occured. A null or undefined error object was found.',
      code: 'NullError',
      stackTrace: null,
      path: window.location.href,
    };

    return error;
  } else if (typeof err === 'string') {
    const error = {
      message: err,
      code: 'StringError',
      stackTrace: 'StringError',
      path: window.location.href,
    };

    return error;
  } else if (err instanceof Error) {
    const error = {
      message: err.message,
      stackTrace: err.stack,
      code: err.name,
      path: window.location.href,
    };

    return error;
  } else if (typeof err === 'object' && err.message) {
    return err;
  } else {
    let jsonBody = JSON.stringify(err);
    let message = `Unable to find message on error ${err}`;

    const error = {
      code: 'UnknownException',
      message: message,
      stackTrace: jsonBody,
      path: window.location.href,
    };

    return error;
  }
};
