export const OVERVIEW_URL = '/dashboard';
export const FACTORS_URL = '/factors';
export const PAYMENT_HISTORY_URL = '/factors/paymentHistory';
export const CREDIT_USAGE_URL = '/factors/creditUsage';
export const CREDIT_AGE_URL = '/factors/creditAge';
export const ACCOUNT_MIX_URL = '/factors/accountMix';
export const CREDIT_INQUIRIES_URL = '/factors/creditInquiries';
export const ACTIONS_URL = '/actions';
export const SELF_LENDER_URL = '/actions/SELF_LENDER';
export const NEW_CARD_SCORE_INCREASE_URL = '/actions/NEW_CARD_SCORE_INCREASE';
export const NEGATIVE_MARKS_URL = '/actions/NEGATIVE_MARKS';
export const NEW_CARD_SCORE_OVER_TIME_URL = '/actions/NEW_CARD_SCORE_OVER_TIME';
export const NEGATIVE_MARKS_SIMULATION_URL = '/actions/CREDIT_REPAIR_EXPERT';
export const REWARDS_URL = '/actions/CC_REWARDS';
export const CREDIT_FOUNDATION_URL = '/actions/CREDIT_FOUNDATION';
export const MARKETPLACE_URL = '/offers';
export const CC_MARKETPLACE_URL = '/offers/creditCards';
export const CC_CATEGORIES_URL = '/offers/creditCardCategories';
export const CC_DETAILS_URL =
  '/offers/creditCardCategories/offerDetails/creditCard/';
export const CC_PREAPPROVAL_URL =
  '/offers/creditCardCategories/credit-card-pre-approval/CAPITALONE';
export const LOANS_PERSONAL_URL = '/offers?vertical=PERSONAL_LOAN';
export const HOME_INSURANCE_PERSONAL_URL = '/offers?vertical=HOME_INSURANCE';
export const LOANS_PERSONAL_DETAILS_URL =
  '/offers/personalLoan/offerDetails/personalLoan/';
export const PROFILE_URL = '/profile';
export const PERSONAL_INFO_URL = '/profile/personalinfo';
export const ACCOUNT_SETTINGS_URL = '/profile/accountSettings';
export const SIGNOUT_URL = '/logout';
export const SIGNUP_URL = '/signup';
export const ALERTS = '/alerts';
export const LOGIN_URL = '/login';

//???
export const CREDIT_LIMIT_ACTION_URL = '/actions/CREDIT_LIMIT';
export const CREDIT_USGAGE_ACTION_URL = '/actions/CREDIT_USAGE';
