import { cleanPopupPaths, cleanPopupPathsCustom } from 'helpers/path.helper';

export const closeModal = (history, keepQueryParameters) => {
  const cleanUrl = cleanPopupPaths(history.location.pathname);
  const url = keepQueryParameters
    ? cleanUrl + `${history?.location?.search}`
    : cleanUrl;
  history.replace(url);
};
export const openModalWithUrlReplace = (history, newPath) => {
  const currentPath = history.location.pathname;
  const cleanPath = cleanPopupPaths(currentPath);
  const finalUrl = `${cleanPath}${newPath}`;
  history.replace(finalUrl);
};
export const openModalWithUrlReplaceCustom = (
  history,
  newPath,
  urlsToRemove,
) => {
  const currentPath = history.location.pathname;
  const cleanPath = cleanPopupPathsCustom(currentPath, urlsToRemove);
  const finalUrl = `${cleanPath}${newPath}`;
  history.replace(finalUrl);
};
export const openModalWithUrlPush = (history, newPath) => {
  const currentPath = history.location.pathname;
  const cleanPath = cleanPopupPaths(currentPath);
  const finalUrl = `${cleanPath}${newPath}`;
  history.push(finalUrl);
};
