import { cleanPopupPaths } from 'helpers/path.helper';
import { useHistory, useLocation } from 'react-router-dom';
import { useFlag } from 'hook/flag.hook';
import { isVariationActive } from 'helpers/optimizely.helper';
import { CLICK } from 'externals/_tracking/mixpanel/types/Mixpanel.event.types';
import { SUBSCRIPTION_VERTICAL } from 'types/mrph.vertical.constants';
import { useEventHandler } from 'hook/event.hook';
import {
  getCStrack,
  getItem,
  PREMIUM_PAYMENT_MODAL_DISPLAYED,
} from 'helpers/localstorage';

export const usePremiumPaymentPendingAlert = userInfo => {
  const eventHandler = useEventHandler();
  const history = useHistory();
  const location = useLocation();

  const expPremiumPaymentPending = useFlag('premiumpaymentpending_web');
  const isPremiumPaymentPendingUser = isVariationActive(
    expPremiumPaymentPending,
  );

  const openFixPaymentModal = () => {
    const newPath = cleanPopupPaths(location.pathname);
    eventHandler(CLICK, {
      'Click Type': 'Premium fix payment',
      Vertical: SUBSCRIPTION_VERTICAL,
    });
    history.replace(`${newPath}/premiumPaymentPending`);
  };

  const isPremiumPaymentPendingModalDisplayed = () => {
    const modalDisplayedValue = getItem(PREMIUM_PAYMENT_MODAL_DISPLAYED);
    const csSessionId = getCStrack();
    return modalDisplayedValue === csSessionId;
  };
  const daysToUpdateBillingInfo =
    userInfo?.premiumPlanInfo?.daysToUpdateBillingInfo || 21;

  return {
    openFixPaymentModal,
    isPremiumPaymentPendingUser,
    isPremiumPaymentPendingModalDisplayed,
    daysToUpdateBillingInfo,
  };
};
