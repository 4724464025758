/* eslint-disable */

import { isDataDomeEnabled } from 'helpers/env';

if (isDataDomeEnabled()) {
  !(function (a, b, c, d, e, f) {
    a.ddjskey = e;
    a.ddoptions = f || null;
    var m = b.createElement(c),
      n = b.getElementsByTagName(c)[0];
    (m.async = 1), (m.src = d), n.parentNode.insertBefore(m, n);
  })(
    window,
    document,
    'script',
    'https://js.datadome.co/tags.js',
    'BF05D1BFFCC88773D42B7109F02D85',
  );

  !(function (a, b, c, d, e) {
    a.ddCaptchaOptions = e || null;
    var m = b.createElement(c),
      n = b.getElementsByTagName(c)[0];
    (m.async = 0), (m.src = d), n.parentNode.insertBefore(m, n);
  })(window, document, 'script', 'https://js.captcha-display.com/xhr_tag.js', {
    ajaxListenerPath: 'api.creditsesame.com/api',
    withCredentials: true,
    enableTagEvents: true,
  });
}
