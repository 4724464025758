import { retrieveConfigForLogin } from 'actions/config.actions';
import { convertPromiseMapToSinglePromise } from 'actions/login_flows/loading_utils.actions';
import {
  retrieveBaseSesameCash,
  retrieveSesameCashPinForLogin,
} from 'actions/sesameCash.actions';
import { getNotifications } from 'actions/notificationsAlerts.actions';
import { retrieveCreditProfileLogin } from 'actions/creditInfo.actions';
import { loadUserQuestionaire } from 'actions/userGoals.actions';
import { retrieveUserInfoLogin } from 'actions/userInfo.actions';
import { loadAutoRefinance } from 'actions/actions.actions';
import { getNotificationsSettings } from 'actions/alertSettings.actions';
import {
  runCreditInfoDependencies,
  runSessionResponseDependencies,
  runUserAndCreditInfoDependencies,
  runUserInfoDependencies,
  runUserAndCreditInfoOptmizelyDependecies,
} from 'actions/login_flows/platform_dependencies.actions';
import { getFeaturedAdsForLogin } from 'actions/featuredAd.actions';
import { buildDispatchPooler } from 'helpers/redux.helper';
import { isSignupStatusFullyRegistered } from 'helpers/userHelper';
import { forceSuccess } from 'helpers/promiseHelper';
import { PAYLOAD } from 'types/payload.constants';
import { createLogger } from 'helpers/logger';
import { loadTipOfTheDay } from '../tipOfTheDay.actions';
import { updateRentReportingInfo } from 'actions/rentReporting.actions';
import { getDecisionForFlag } from 'externals/_tracking/optimizely/optFlags';

const logger = createLogger({
  name: 'login_loader.actions',
});

const defaultLoadFlow = (loginDataCollector, c) => {
  let userQuestionnaireLoaded = null;

  loginDataCollector.dispatch({
    type: PAYLOAD,
    userInfoLoading: true,
    creditInfoLoading: true,
    actionsLoading: true,
    autoRefinanceLoading: true,
    featuredAdsLoading: true,
    cashPinTokenLoading: true,
    configLoading: true,
    rentReportingAuthLoading: true,
    rentReportingPaymentsLoading: true,
    PL_PREQUAL_RESULT_KEY_LOADING: false,
    cashLoading: true,
    cashLoaded: false,
    notificationsAlertsLoading: true,
  });

  const firstRenderPool = buildDispatchPooler({
    poolingAction: { type: PAYLOAD },
    getState: loginDataCollector.getState,
  });

  const finalSuccessPool = buildDispatchPooler({
    poolingAction: { type: PAYLOAD },
    getState: loginDataCollector.getState,
  });

  const userInfoAction = firstRenderPool.dispatch(retrieveUserInfoLogin());
  const creditInfoAction = forceSuccess(
    firstRenderPool.dispatch(retrieveCreditProfileLogin()),
  );

  const notificationsAlertsAction = forceSuccess(
    finalSuccessPool.dispatch(getNotifications(true)),
  );

  const loginSuccessActionMap = {
    sessionPlatformDependencies:
      runSessionResponseDependencies(loginDataCollector),
  };

  const firstRenderActionMap = {
    userInfoAction,
    creditInfoAction,
  };

  const allActionMap = {
    sessionPlatformDependencies:
      runSessionResponseDependencies(loginDataCollector),
    userPlatformDependencies: userInfoAction.then(_ =>
      runUserInfoDependencies({
        ...loginDataCollector,
        ...firstRenderPool.poolingAction,
        ...finalSuccessPool.poolingAction,
      }),
    ),
    creditInfoPlatformDependencies: creditInfoAction.then(() =>
      runCreditInfoDependencies({
        ...loginDataCollector,
        ...firstRenderPool.poolingAction,
        ...finalSuccessPool.poolingAction,
      }),
    ),
    creditAndUserInfoPlatformDependencies: userInfoAction
      .then(_ => creditInfoAction)
      .then(_ => notificationsAlertsAction)
      .then(() =>
        runUserAndCreditInfoOptmizelyDependecies({
          ...loginDataCollector,
          ...firstRenderPool.poolingAction,
          ...finalSuccessPool.poolingAction,
        }),
      )
      .then(() => {
        //refreshing attributes in optimizely report
        getDecisionForFlag('capital_one_prescreen');

        return runUserAndCreditInfoDependencies(
          {
            ...loginDataCollector,
            ...firstRenderPool.poolingAction,
            ...finalSuccessPool.poolingAction,
          },
          userQuestionnaireLoaded,
        );
      }),
    featuredAdAction: finalSuccessPool.dispatch(getFeaturedAdsForLogin()),
    configAction: finalSuccessPool.dispatch(retrieveConfigForLogin()),
    premiumCreditReportRefresh: userInfoAction.then(_ => creditInfoAction),
    autoRefinance: userInfoAction
      .then(_ => creditInfoAction)
      .then(_ =>
        finalSuccessPool.dispatch(
          loadAutoRefinance(
            firstRenderPool.poolingAction.creditInfo,
            firstRenderPool.poolingAction.userInfo,
          ),
        ),
      ),
    getAlertSettings: finalSuccessPool.dispatch(getNotificationsSettings()),
    loadUserQuestionaire: finalSuccessPool.dispatch(loadUserQuestionaire()),
    sesameCashAction: userInfoAction.then(() => {
      return finalSuccessPool.dispatch(
        retrieveBaseSesameCash(firstRenderPool.poolingAction, true),
      );
    }),
    getDailyTips: userInfoAction.then(res => {
      return finalSuccessPool.dispatch(
        loadTipOfTheDay(firstRenderPool.poolingAction.userInfo),
      );
    }),
    sesameCashPinAction: userInfoAction.then(response => {
      return finalSuccessPool.dispatch(
        retrieveSesameCashPinForLogin({ userInfo: response }),
      );
    }),
    updateRentReportingInfo: userInfoAction.then(response => {
      return finalSuccessPool.dispatch(
        updateRentReportingInfo({ userInfo: response }),
      );
    }),
  };

  convertPromiseMapToSinglePromise(firstRenderActionMap).then(response => {
    logger.debug('Completed firstRenderAction map');
    loginDataCollector.dispatch(firstRenderPool.poolingAction);
    return loginDataCollector;
  });

  convertPromiseMapToSinglePromise(allActionMap)
    .then(_ => {
      logger.debug('Completed allAction map');
      finalSuccessPool.poolingAction.actionsLoading = false;
      finalSuccessPool.poolingAction.actionsLoaded = true;
      loginDataCollector.dispatch(finalSuccessPool.poolingAction);
      return loginDataCollector;
    })
    .then(() => {
      if (loginDataCollector.startTime) {
        loginDataCollector.totalTime =
          performance.now() - loginDataCollector.startTime;
      }
    });

  return convertPromiseMapToSinglePromise(loginSuccessActionMap);
};

const nonRegisteredUserLoadFlow = (loginDataCollector, c) => {
  const loginSuccessAction = loginDataCollector.finalAction;
  loginSuccessAction.actionsLoaded = true;

  const { dispatch } = buildDispatchPooler({
    poolingAction: loginSuccessAction,
    getState: loginDataCollector.getState,
  });

  const userInfoAction = dispatch(retrieveUserInfoLogin());
  const loadActionMap = {
    sessionPlatformDependencies:
      runSessionResponseDependencies(loginDataCollector),
    userPlatformDependencies: userInfoAction.then(_ =>
      runUserInfoDependencies(loginDataCollector),
    ),
    userInfoAction,
  };

  return convertPromiseMapToSinglePromise(loadActionMap);
};
export const loadAllAPIData = loginDataCollector => {
  logger.debug('Entering load all data');
  let p = null;
  if (
    !isSignupStatusFullyRegistered(
      loginDataCollector?.loginResponse?.userStatus,
    )
  ) {
    p = nonRegisteredUserLoadFlow(loginDataCollector);
  } else {
    p = defaultLoadFlow(loginDataCollector);
  }

  return p.then(_ => {
    if (loginDataCollector.startTime) {
      loginDataCollector.totalTime =
        performance.now() - loginDataCollector.startTime;
    }
    return _;
  });
};
