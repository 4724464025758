import React from 'react';
import './FlexContainer.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';

function FlexContainer({
  datatestid,
  className,
  children,
  wrapperRef,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onClick,
  ...props
}) {
  let tempClass = `FlexContainer flex-container`;
  tempClass = className ? `${tempClass} ${className}` : tempClass;
  tempClass = classnames(tempClass, {
    'flex-row': props.row,
    'flex-column': props.column,
    'justify-between': props.justifyBetween,
    'justify-center': props.justifyCenter,
    'justify-end': props.justifyEnd,
    'items-align-center': props.alignItemsCenter,
    'items-align-start': props.alignItemsStart,
    'items-align-end': props.alignItemsEnd,
    'align-baseline': props.alignItemsBaseline,
    'align-items-stretch': props.alignItemsStretch,
    noWrap: props.noWrap,
    wrap: props.wrap,
  });

  const finalProps = {
    ...props,
  };
  delete finalProps.justifyCenter;
  delete finalProps.justifyBetween;
  delete finalProps.alignItemsCenter;
  delete finalProps.row;
  delete finalProps.noWrap;
  delete finalProps.column;
  delete finalProps.alignItemsStart;
  delete finalProps.alignItemsBaseline;
  delete finalProps.justifyEnd;
  delete finalProps.wrap;
  delete finalProps.alignItemsStretch;
  delete finalProps.alignItemsEnd;

  return (
    <div
      data-testid={`${datatestid ? datatestid : null}`}
      className={tempClass}
      style={props.style}
      ref={wrapperRef ? wrapperRef : null}
      id={props.id}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onClick={onClick}
      {...finalProps}
    >
      {children}
    </div>
  );
}

FlexContainer.propTypes = {
  row: PropTypes.bool,
  column: PropTypes.bool,
  m_row: PropTypes.bool,
  justifyBetween: PropTypes.bool,
  justifyCenter: PropTypes.bool,
  alignItemsCenter: PropTypes.bool,
  noWrap: PropTypes.bool,
  alignItemsBaseline: PropTypes.bool,
  justifyEnd: PropTypes.bool,
  alignItemsStretch: PropTypes.bool,
};

export { FlexContainer };
