import { SET_PAGE_STATE } from 'types/page.constants';
import { SET_PAGE_META } from 'types/tracking.constants';

const initialState = {
  pageName: null,
  pageNameMeta: null,
  oldPageNameMeta: null,
  oldPageName: null,
  pageState: {},
};

export default function pageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PAGE_META:
      return Object.assign({}, state, {
        pageNameMeta: action.pageNameMeta,
        pageName: action.pageName,
        oldPageNameMeta: action.oldPageNameMeta,
        oldPageName: action.oldPageName,
      });
    case SET_PAGE_STATE:
      return Object.assign({}, initialState, {
        loading: true,
        pageState: {
          ...state.pageState,
          [action.pageName]: {
            ...state?.pageState[action.pageName],
            ...action.newStateKeys,
          },
        },
      });
    default:
      return state;
  }
}
