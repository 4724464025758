import { useComplexState } from 'hook/helper.hook';
import React from 'react';

export const DisclaimerContext = React.createContext({
  domNode: null,
});
/*
  in DisclaimerPortal
    useContext()


 */
export const DisclaimerContextHoc = props => {
  const [state, setState] = useComplexState({});

  const addDisclaimer = (key, cmp) => {
    const newState = { [key]: cmp };
    setState(newState);
  };
  const removeDisclaimer = key => {
    const newState = { [key]: undefined };
    setState(newState);
  };
  const value = {
    disclaimers: state,
    addDisclaimer,
    removeDisclaimer,
  };
  return (
    <DisclaimerContext.Provider value={value}>
      {props.children}
    </DisclaimerContext.Provider>
  );
};
