import React from 'react';
import { useViewModal } from 'hook/helper.hook';
import LearnMoreModal from 'components/LearnMoreModal/LearnMoreModal';
import { LEARN_MORE_MODAL } from 'types/morph.pagePosition.constants';
import { useVariationForExperiment } from 'externals/_tracking/optimizely/optimizely.hook';
import { TURBO_1_WEEK_FREE_DISCOUNT } from 'externals/_tracking/optimizely/optimizely.exp.constants';
import { closeModal } from 'helpers/modal.helper';
import { getGetParam } from 'helpers/domHelper';

const LearnMoreController = props => {
  useViewModal({
    'Pop Up Name': LEARN_MORE_MODAL,
    'Page Position': LEARN_MORE_MODAL,
  });

  const expFreeTrialPremiumBanner = useVariationForExperiment(
    TURBO_1_WEEK_FREE_DISCOUNT,
  );

  const { variation } = expFreeTrialPremiumBanner;
  const activateDiscountFor1WeekTrial = variation === 'VAR1' ? false : true;
  const isRecurlyHybridCheckout = getGetParam('isRecrulyHybrid');
  const isTurboSubscriptionCheckout = getGetParam('isTurboSubscription');

  return (
    <LearnMoreModal
      visible={true}
      onCloseClick={() => {
        closeModal(props.history);
      }}
      activateAlternativeLearnMoreModal={activateDiscountFor1WeekTrial}
      isRecurlyHybridCheckout={isRecurlyHybridCheckout}
      isTurboSubscriptionCheckout={isTurboSubscriptionCheckout}
    />
  );
};

export default LearnMoreController;
