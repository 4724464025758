import React from 'react';
import { getImageBasePath } from 'helpers/env';
import Label from 'components/form/Label/Label';
import { Button } from 'components/form/Button/index';
import { ErrorTemplate } from './ErrorTemplate';
import { FlexContainer } from 'components/Containers/FlexContainer/FlexContainer';

export const DefaultError = ({
  errorType,
  hideCustomerSupportCTA,
  showThirtyDayMessage,
  showButtonToExploreOffers,
  ...props
}) => {
  return (
    <ErrorTemplate
      errorType={errorType}
      title="We're Sorry"
      imgSrc={`${getImageBasePath()}images/page_error.png`}
      centerAlign={true}
    >
      <Label className="padTB8 margB16">
        We were unable to complete your registration based on the information
        you provided.{' '}
        {showThirtyDayMessage && `Please try back again in 30 days.`}
      </Label>
      {showButtonToExploreOffers && (
        <FlexContainer justifyCenter className={'margB14'}>
          <Button mrphType="primary" onClick={props.onBtnClick}>
            Explore Offers
          </Button>
        </FlexContainer>
      )}
      {!hideCustomerSupportCTA && (
        <Button mrphType="primary" onClick={props.onButtonClick}>
          Contact Customer Support
        </Button>
      )}
    </ErrorTemplate>
  );
};
