import {
  mixpanelAdditionalProps,
  mixpanelEvents,
} from 'externals/_tracking/mixpanel/Mixpanel.events.config';
import { isObj } from 'helpers/objectHelpers';
import { isPlus, isPremium } from 'helpers/userHelper';
import { buildBaselineMixpanelAttributes } from 'externals/_tracking/mixpanel/mixpanelLocalStorageReader';
import {
  getCashTrackingProperties,
  getInViewBrowserNativeAppTrackingProperties,
} from 'helpers/metadataLogic.helpers';
import { resolveBooleanToYesOrNo } from 'helpers/tracking.helpers';

export function getMixpanelEventName(event, eventContext) {
  return mixpanelEvents[event];
}

const cleanBrazeAttrs = eventData => {
  if (eventData && isObj(eventData)) {
    delete eventData['__cash'];
    delete eventData['__alert'];
    delete eventData['__braze'];
    delete eventData.forceSendUnified;
  }
  return eventData;
};

const getCustomerType = userInfo => {
  if (isPlus(userInfo)) {
    return 'Plus';
  } else if (isPremium(userInfo)) {
    return 'Premium';
  } else {
    return 'Free';
  }
};

export function getMixpanelMeta(event, eventData, eventContext) {
  let eventMetaData = cleanBrazeAttrs(eventData);
  const { creditProfileMeta, userMeta, pageNameMeta, sesameCash, userInfo } =
    eventContext;

  const isUserPremium = getCustomerType(userInfo);

  const defaultProperties = {
    ...buildBaselineMixpanelAttributes(),
    ...getCashTrackingProperties(userInfo, sesameCash),
    ...getInViewBrowserNativeAppTrackingProperties(),
    'Customer Type': isUserPremium,
    '2FAEnabled': userInfo && userInfo.is2FAActive,
    'Test SSN User': userInfo && resolveBooleanToYesOrNo(userInfo.testAccount),
    ...userMeta,
  };
  //check if event has additional properties required
  if (mixpanelAdditionalProps[event] && eventMetaData) {
    mixpanelAdditionalProps[event].forEach(attribute => {
      //check credit profile meta data obj, check user meta data obj, or finally check the additional event data for this attribute
      if (creditProfileMeta && creditProfileMeta[attribute] !== undefined) {
        eventMetaData[attribute] = creditProfileMeta[attribute];
      } else if (userMeta && userMeta[attribute] !== undefined) {
        eventMetaData[attribute] = userMeta[attribute];
      } else if (attribute === 'Page Name') {
        // overwrite the pageName meta set by passing in the "Page Name" as a property of eventData if necessary
        eventMetaData[attribute] = eventData['Page Name']
          ? eventData['Page Name']
          : pageNameMeta
          ? pageNameMeta
          : null;
      } else if (eventData[attribute] !== undefined) {
        eventMetaData[attribute] = eventData[attribute];
      }
    });
  }
  //Merge the required list of data properties from the array with additional event data passed into track event
  return Object.assign({}, defaultProperties, eventMetaData, eventData);
}
