import {
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION,
} from 'types/notification.constants';

const DEFAULT_TRANSIENT_TIMEOUT = '5000';
const SUCCESS_TYPE = 'success';
const INFO_TYPE = 'info';
const WARNING_TYPE = 'warning';
const ERROR_TYPE = 'error';

export const success = (title, description) => dispatch => {
  const notification = {
    type: SUCCESS_TYPE,
    duration: DEFAULT_TRANSIENT_TIMEOUT,
    title,
    description,
  };

  dispatch(addNotification(notification));
};

export const info = (title, description) => dispatch => {
  const notification = {
    type: INFO_TYPE,
    duration: DEFAULT_TRANSIENT_TIMEOUT,
    title,
    description,
  };

  dispatch(addNotification(notification));
};

export const warning = (title, description) => dispatch => {
  const notification = {
    type: WARNING_TYPE,
    duration: DEFAULT_TRANSIENT_TIMEOUT,
    title,
    description,
  };

  dispatch(addNotification(notification));
};

export const error = (title, description) => dispatch => {
  const notification = {
    type: ERROR_TYPE,
    duration: DEFAULT_TRANSIENT_TIMEOUT,
    title,
    description,
  };

  dispatch(addNotification(notification));
};

export const stickySuccess = (title, description) => dispatch => {
  const notification = {
    type: SUCCESS_TYPE,
    transient: false,
    title,
    description,
  };

  dispatch(addNotification(notification));
};

export const stickyInfo = (title, description) => dispatch => {
  const notification = {
    type: INFO_TYPE,
    transient: false,
    title,
    description,
  };

  dispatch(addNotification(notification));
};

export const stickyWarning = (title, description) => dispatch => {
  const notification = {
    type: WARNING_TYPE,
    transient: false,
    title,
    description,
  };

  dispatch(addNotification(notification));
};

export const addStickyError = (title, description) => dispatch => {
  const notification = {
    type: ERROR_TYPE,
    transient: false,
    title,
    description,
  };

  dispatch(addNotification(notification));
};

export const addNotification = dirtyNotification => (dispatch, getState) => {
  const state = getState();
  const id = state.notification.index;
  const notification = Object.assign({ id }, dirtyNotification);

  dispatch({ type: ADD_NOTIFICATION, notification, index: id + 1 });
};

export const deleteNotification = id => ({ type: DELETE_NOTIFICATION, id });
