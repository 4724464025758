import { LOGOUT } from 'types/app.constants';
import {
  RESET_SELECT_VERTICAL_TO_MARKETPLACE,
  SELECT_STATIC_MODULES_TO_MARKETPLACE,
  SELECT_VERTICAL_TO_MARKETPLACE,
} from '../types/offerMarketplace.constants';

const initialState = {
  selectedVertical: null,
  staticModules: null,
};

export const offersMarketplace = (state = initialState, action) => {
  switch (action?.type) {
    case SELECT_VERTICAL_TO_MARKETPLACE:
      return {
        ...state,
        selectedVertical: action?.selectedVertical,
        staticModules: action?.staticModules,
      };
    case SELECT_STATIC_MODULES_TO_MARKETPLACE:
      return {
        ...state,
        staticModules: action?.staticModules,
      };
    case LOGOUT:
    case RESET_SELECT_VERTICAL_TO_MARKETPLACE:
      return initialState;
    default:
      return state;
  }
};

export default offersMarketplace;
