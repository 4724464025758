import { QuestionCircleOutlined } from '@ant-design/icons';
import Card from 'components/Card';
import { FlexContainer } from 'components/Containers/FlexContainer/FlexContainer';
import { Row } from 'components/Containers/Row/Row';
import BodyFooterDisclaimer from 'components/Disclaimer/BodyFooterDisclaimer';
import FullProgramTerms from 'components/Disclaimer/FullProgramTerms';
import Label from 'components/form/Label/Label';
import { Tag } from 'components/Tag';
import { formatUSD, formatUSDwithCents } from 'helpers/money.helper';
import { OptimizelyContext } from 'hoc/optimizely.hoc';
import React, { useContext } from 'react';
import JsxParser from 'react-jsx-parser';
import TelNumber from 'components/TelNumber/TelNumber';

const OText = props => {
  const optimizelyContext = useContext(OptimizelyContext);
  return (
    <JsxParser
      bindings={{
        formatUSDwithCents,
        formatUSD,
        ...optimizelyContext?.userReplacementKeysAttributes,
      }}
      components={{
        Label,
        FlexContainer,
        BodyFooterDisclaimer,
        FullProgramTerms,
        Row,
        Card,
        Tag,
        QuestionCircleOutlined,
        TelNumber,
      }}
      jsx={props.text}
    />
  );
};

export default OText;
