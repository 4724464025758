import {
  RENT_REPORTING_PAYMENTS_REQUEST,
  RENT_REPORTING_PAYMENTS_ERROR,
  RENT_REPORTING_PAYMENTS_SUCCESS,
  RENT_REPORTING_AUTH_REQUEST,
  RENT_REPORTING_AUTH_ERROR,
  RENT_REPORTING_AUTH_SUCCESS,
  CAN_RESTART_RENT_REPORTING,
} from 'types/rentReporting.constants';

import {
  getRentPaymentInfo,
  postReportEnrollment,
  postRentReportingAuthentication,
  postRentRentalInfo,
} from 'externals/_services/rentPaymentReport.service';

import { createLogger } from 'helpers/logger';
import { wrapService } from 'actions/service_wrapper.actions';
import { canCallGetPayments } from 'helpers/rentReporting.helper';

export const a_postReportEnrollment = wrapService(postReportEnrollment, {
  name: 'postReportEnrollment',
});

export const a_postRentReportingAuthentication = wrapService(
  postRentReportingAuthentication,
  {
    name: 'postRentReportingAuthentication',
  },
);

export const a_postRentRentalInfo = wrapService(postRentRentalInfo, {
  name: 'postRentRentalInfo',
});

const a_getRentPaymentInfo = wrapService(getRentPaymentInfo, {
  name: 'getRentPaymentInfo',
});

const logger = createLogger({
  name: 'rentReporting.actions',
  categories: ['rentReporting'],
});

function rentReportingPaymentsPayload(
  type,
  rentReportingPayments,
  rentReportingPaymentsLoading,
  rentReportingPaymentsError,
) {
  return {
    type,
    rentReportingPayments,
    rentReportingPaymentsLoading,
    rentReportingPaymentsError,
  };
}

function rentReportingPaymentsRequest() {
  return rentReportingPaymentsPayload(
    RENT_REPORTING_PAYMENTS_REQUEST,
    null,
    true,
    null,
  );
}

export function rentReportingPaymentsSuccess(payments) {
  return rentReportingPaymentsPayload(
    RENT_REPORTING_PAYMENTS_SUCCESS,
    payments,
    false,
    null,
  );
}

function rentReportingPaymentsError(error) {
  return rentReportingPaymentsPayload(
    RENT_REPORTING_PAYMENTS_ERROR,
    null,
    false,
    error,
  );
}

function rentReportingAuthPayload(
  type,
  rentReportingAuth,
  rentReportingAuthLoading,
  rentReportingAuthError,
) {
  return {
    type,
    rentReportingAuth,
    rentReportingAuthLoading,
    rentReportingAuthError,
  };
}

function rentReportingAuthRequest() {
  return rentReportingAuthPayload(
    RENT_REPORTING_AUTH_REQUEST,
    null,
    true,
    null,
  );
}

function rentReportAuthSuccess(apiData) {
  return rentReportingAuthPayload(
    RENT_REPORTING_AUTH_SUCCESS,
    apiData,
    false,
    null,
  );
}

function rentReportingAuthError(error) {
  return rentReportingAuthPayload(
    RENT_REPORTING_AUTH_ERROR,
    null,
    false,
    error,
  );
}

export const getRentReportingAuthData = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(rentReportingAuthRequest());
    dispatch(a_postRentReportingAuthentication())
      .then(res => {
        dispatch(rentReportAuthSuccess(res));
        resolve(res);
      })
      .catch(err => {
        logger.errorException('Error trying to fetch rent reporting Auth', err);
        dispatch(rentReportingAuthError(err));
        reject(err);
      });
  });
};

export const getRentReportingPayments = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(rentReportingPaymentsRequest());
    dispatch(a_getRentPaymentInfo())
      .then(res => {
        dispatch(rentReportingPaymentsSuccess(res));
        resolve(res);
      })
      .catch(err => {
        logger.errorException('Error trying to fetch rent payments', err);
        dispatch(rentReportingPaymentsError(err));
        reject(err);
      });
  });
};

export const canStartRentReporting = props => {
  const { userInfo } = props || {};
  const { rentReporting } = userInfo || {};
  return (
    rentReporting === null || CAN_RESTART_RENT_REPORTING.includes(rentReporting)
  );
};

export const updateRentReportingInfo = props => dispatch => {
  return new Promise((resolve, reject) => {
    const { userInfo } = props || {};
    const { premiumPlanInfo, rentReporting } = userInfo || {};
    let errorMsg = false;
    if (premiumPlanInfo?.rentReportingIncluded) {
      if (!canStartRentReporting(props)) {
        dispatch(getRentReportingAuthData())
          .then(() => {
            if (rentReporting && canCallGetPayments({ rentReporting })) {
              dispatch(getRentReportingPayments()).then(resolve).catch(reject);
            } else {
              resolve();
            }
          })
          .catch(err => {
            reject(err);
          });
      } else {
        errorMsg = 'Current account cannot peroform auth for Rent Reporting';
      }
    } else {
      errorMsg = 'Current account is not enrolled to Rent Reporting';
    }
    if (errorMsg) {
      reject?.({
        error: errorMsg,
        message: errorMsg,
        code: 'RENT_REPORTING_ERROR',
      });
    }
  });
};
