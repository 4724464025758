import { HELMET_TITLE } from 'helpers/env';
import { Helmet } from 'react-helmet';

const HelmetContainer = props => {
  const { title, metaContent } = props;

  return (
    <Helmet>
      <title>{`${HELMET_TITLE} - ${title}`}</title>
      {metaContent && <meta name="description" content={`${metaContent}`} />}
    </Helmet>
  );
};

export default HelmetContainer;
