import {
  CLEAR_NEED_ACCESS_PHONE_STATE,
  RESEND_CODE_ERROR,
  RESEND_CODE_SUCCESS,
  SET_CODE_VALUE,
  SET_NEED_ACCESS_PHONE_STEP,
  SUBMIT_PHONE_FORM_ERROR,
  SUBMIT_PHONE_FORM_SAVE_PHONE,
  UPDATE_PHONE_ERROR,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_ERROR,
} from '../../PasswordReset.constants';
import { LOGOUT } from 'types/app.constants';

const initialState = {
  step: 'UPDATE_PHONE_FORM',
  originalEmail: null,
  verifyPhoneError: null,
  verifyOtpMessage: null,
  codeValue: '',
  resendAttempts: 0,
  codeAttempts: 0,
  token: null,
  updateEmailError: null,
  originalPhoneNumber: null,
  originalDob: null,
  originalSsn: null,
  updatePhoneError: null,
};

const needAccessPhoneReducer = (
  state = Object.assign({}, initialState),
  action,
) => {
  switch (action.type) {
    case SET_NEED_ACCESS_PHONE_STEP:
      return Object.assign({}, state, {
        step: action.step,
      });
    case SUBMIT_PHONE_FORM_ERROR:
      return Object.assign({}, state, {
        phoneFormError: action.phoneFormError,
      });
    case SUBMIT_PHONE_FORM_SAVE_PHONE:
      return Object.assign({}, state, {
        originalEmail: action.originalEmail,
        originalPhoneNumber: action.originalPhoneNumber,
        originalDob: action.originalDob,
        originalSsn: action.originalSsn,
      });
    case SET_CODE_VALUE:
      return Object.assign({}, state, {
        codeValue: action.codeValue,
      });
    case RESEND_CODE_SUCCESS:
      return Object.assign({}, state, {
        verifyOtpMessage: action.verifyOtpMessage,
        codeValue: '',
        resendAttempts: action.resendAttempts,
        codeAttempts: 0,
      });
    case RESEND_CODE_ERROR:
      return Object.assign({}, state, {
        verifyOtpMessage: action.verifyOtpMessage,
      });
    case VERIFY_OTP_SUCCESS:
      return Object.assign({}, state, {
        token: action.token,
        codeValue: '',
        codeAttempts: 0,
        resendAttempts: 0,
        verifyOtpMessage: null,
      });
    case VERIFY_OTP_ERROR:
      return Object.assign({}, state, {
        verifyOtpMessage: action.verifyOtpMessage,
        codeValue: '',
        codeAttempts: action.codeAttempts,
      });
    case UPDATE_PHONE_ERROR:
      return Object.assign({}, state, {
        updatePhoneError: action.updatePhoneError,
      });
    case CLEAR_NEED_ACCESS_PHONE_STATE:
    case LOGOUT:
      return Object.assign({}, initialState);
    default:
      return state;
  }
};

export default needAccessPhoneReducer;
