import {
  getCStrack,
  getLoggingFilters,
  getRefCode,
  getUsrTkn,
  testLocalStorage,
} from 'helpers/localstorage';
import { getEnvironment, isProduction, TEST } from 'helpers/env';
import { standardizeException } from 'helpers/error.helper';
import { mixpanelEvents } from 'externals/_tracking/mixpanel/Mixpanel.events.config';
import {
  mixpanelTrack,
  mixpanelVisitorTrack,
} from 'externals/_tracking/mixpanel/mixpanel.api';
import { buildBaselineMixpanelAttributes } from 'externals/_tracking/mixpanel/mixpanelLocalStorageReader';
import { getMrphPageFormFactor } from 'helpers/metadataLogic.helpers';

export const DEBUG = 'DEBUG';
export const INFO = 'INFO';
export const ERROR = 'ERROR';

const filterLevel = {
  [DEBUG]: 10,
  [INFO]: 20,
  [ERROR]: 30,
  MAX: 100,
};

export const PERF = 'PERF';
export const TRACKING = 'TRACKING';

const mergeOptions = (...options) => {
  const o = { categories: [] };

  options.forEach(extraOpt => {
    const catArr = o.categories;
    const newOpts = Object.assign(o, extraOpt);
    if (Array.isArray(newOpts.categories)) {
      catArr.concat(...newOpts.categories);
    }
    if (extraOpt?.includeVisitor) {
      o.includeVisitor = true;
    }
  });

  return o;
};

const getFiltersFromLocalStorage = () => {
  // given a failure in local storage, assume this baseline
  const baseLineLogLevel = [{ level: INFO }];

  if (testLocalStorage() === null) {
    try {
      const filterString = getLoggingFilters();
      const filters = JSON.parse(filterString);
      if (Array.isArray(filters)) {
        return filters.filter(f => f.level || f.category);
      } else {
        return [];
      }
    } catch (e) {
      return baseLineLogLevel;
    }
  } else {
    return baseLineLogLevel;
  }
};

const doesLogMatchFilter = (msg, options, filters) => {
  let shouldRelease = false;

  if (filters.length === 0) return false;

  filters.forEach(f => {
    let levelMatch = undefined;
    if (f.level) {
      const filterLevelIndex =
        filterLevel[f.level] > 0 ? filterLevel[f.level] : filterLevel.MAX;
      levelMatch = filterLevel[options.level] >= filterLevelIndex;
    }
    let categoryMatch = undefined;
    if (f.category) {
      const filterCats = options.categories.filter(c => c === f.category);
      categoryMatch = filterCats.length >= 0;
    }

    let refMatch = undefined;
    if (f.ref) {
      refMatch = f.ref === options.ref;
    }

    shouldRelease =
      shouldRelease ||
      [levelMatch, categoryMatch, refMatch]
        .filter(x => x !== undefined)
        .reduce((p, c) => p && c, true);
  });

  return shouldRelease;
};

/**
 *
 * @param msg
 * @param options {
 *   level: DEBUG|INFO|ERROR|FATAL,
 *   category:[],
 *   filters: [],
 *   name: "REFERENCE_TO_LOGGER"
 * }
 */
export const log = (msg, fOptions) => {
  let options;
  if (fOptions instanceof Error) {
    options = Object.assign({}, standardizeException(fOptions));
  } else {
    options = mergeOptions({ level: DEBUG, categories: [] }, fOptions);
  }
  let extraStackTraces;
  if (fOptions.stackTrace) {
    extraStackTraces = breakUpStackTraceIntoStringsAppropriateForMixpanel(
      fOptions.stackTrace,
    );
  }

  const filters = getFiltersFromLocalStorage();
  const shouldLog = doesLogMatchFilter(msg, options, filters);

  const cleanStackTrace =
    typeof options.stackTrace === 'string'
      ? options.stackTrace.substring(0, 255)
      : undefined;

  //Mixpanel requires keys to capitalized with spaces
  let attrs = {
    Level: options.level,
    'Logger Name': options.name,
    'Elapsed Time': options.elapsedTime,
    Message: msg,
    'Stack Trace': cleanStackTrace,
    Status: options.status,
    'Error Message': options.message,
    'Error Code': options.code,
    Categories:
      options.categories.length > 0 ? options.categories.join(', ') : undefined,
    Path: options.path ? options.path : window.location.href,
    'Page Form Factor': getMrphPageFormFactor(),
    ...extraStackTraces,
    ...buildBaselineMixpanelAttributes(),
  };

  if (testLocalStorage() === null) {
    attrs = {
      ...attrs,
      'Session Identifier': getCStrack(),
      'Ref Code': getRefCode(),
      'User Token': getUsrTkn(),
    };
  }

  Object.keys(attrs).forEach(x => {
    if (attrs[x] === undefined) {
      delete attrs[x];
    }
  });

  if (shouldLog) {
    mixpanelTrack('Log', attrs);
    if (options.includeVisitor) {
      mixpanelVisitorTrack('Log', attrs);
    }
  }
  if (!isProduction()) {
    let attrsString = '';
    Object.keys(attrs).forEach(k => {
      if (
        k !== 'Level' &&
        k !== 'Message' &&
        k !== 'Session Identifier' &&
        k !== 'User Token' &&
        k !== 'Path' &&
        k !== 'Optimizely Experiments' &&
        k !== 'isEncryptedEmail' &&
        k !== 'VisitorSessionID' &&
        k !== 'Ref Code' &&
        k !== 'Initial Referrer Url Domain' &&
        k !== 'Page Form Factor' &&
        k !== 'Landing Page Url' &&
        k !== 'Site' &&
        k !== 'Timezone' &&
        k !== 'Initial Referrer Url' &&
        k !== 'token' &&
        k !== 'Event Time' &&
        k !== 'Test SSN User' &&
        k !== 'promocode' &&
        k !== 'ReferringURLMarketingSource' &&
        k.indexOf('Stack Trace') < 0 &&
        k.indexOf('OPT-') < 0 &&
        k.indexOf('Flag') < 0 &&
        attrs[k] !== undefined
      ) {
        attrsString += `[${k}=${attrs[k]}]`;
      }
    });
    const finalMessage = attrs.Message ? attrs.Message : '';
    const finalStackTrace = fOptions.stackTrace ? fOptions.stackTrace : '';
    if (getEnvironment() !== TEST) {
      console.log(
        options.level,
        attrsString,
        finalMessage,
        '\n',
        finalStackTrace,
      );
    }
  }
};

const logException = (msg, e, options) => {
  const standardException = standardizeException(e);
  const fullOpts = Object.assign({}, options, standardException);
  const finalMessage = msg;
  log(finalMessage, fullOpts);
};

const breakUpStackTraceIntoStringsAppropriateForMixpanel = stackTrace => {
  let extraProps = {};

  if (typeof stackTrace !== 'string' || stackTrace.length < 255)
    return extraProps;

  for (var i = 0; i < stackTrace.length; i += 225) {
    let finalEnd = i + 225 > stackTrace.length ? stackTrace.length : i + 225;
    extraProps['Stack Trace-' + Math.round(i / 225)] = stackTrace.substring(
      i,
      finalEnd,
    );
  }

  return extraProps;
};

const logFatalClientError = (err, ref) => {
  const error = standardizeException(err);
  const stackTraces = breakUpStackTraceIntoStringsAppropriateForMixpanel(
    error.stackTrace,
  );

  const metadata = {
    'Error Message': error.message,
    'Error Code': error.code,
    'Stack Trace': error.stackTrace,
    'Error Path': error.path,
    Ref: ref,
    ...stackTraces,
    ...buildBaselineMixpanelAttributes(),
  };
  if (!global.isUnitTestSuite) {
    console.log('Fatal Client', metadata);
  }

  mixpanelTrack(mixpanelEvents.FATAL_CLIENT_ERROR, metadata);
};

const codesForInfoLevel = [];

const assessLogLevel = err => {
  if (!err) return ERROR;
  const code = err.code;

  if (codesForInfoLevel.indexOf(code) >= 0) return INFO;
  else return ERROR;
};

export const reportApiErrorList = errorList => {
  if (errorList && errorList.length > 0) {
    errorList.forEach(err => {
      if (err) {
        reportAPIError(err);
      }
    });
  }
};

const reportAPIError = (err, ref = 'Unknown Ref', includeVisitor) => {
  const finalErr = err ? err : {};
  const { message, path, status, code } = finalErr;

  const level = assessLogLevel(finalErr);

  const props = {
    'Error Code': code,
    'Error Message': message,
    'Error Path': path,
    Level: level,
    'Page Form Factor': getMrphPageFormFactor(),
    'Stack Trace': JSON.stringify(finalErr),
    'Status Code': status,
    Ref: ref,
    ...buildBaselineMixpanelAttributes(),
  };

  if (!global.isUnitTestSuite) {
    console.log(mixpanelEvents.ERROR_API, props);
  }

  mixpanelTrack(mixpanelEvents.ERROR_API, props);
  if (includeVisitor) {
    mixpanelVisitorTrack(mixpanelEvents.ERROR_API, props);
  }
};

export const debug = (msg, options) => {
  const finalOptions = Object.assign(options, { level: DEBUG });

  log(msg, finalOptions);
};

export const info = (msg, options) => {
  const finalOptions = Object.assign(options, { level: INFO });

  log(msg, finalOptions);
};

export const error = (msg, options) => {
  const finalOptions = mergeOptions(options, { level: ERROR });

  log(msg, finalOptions);
};

export const debugException = (msg, err, options) => {
  const finalOptions = Object.assign(options, { level: DEBUG });

  logException(msg, err, finalOptions);
};

export const infoException = (msg, err, options) => {
  const finalOptions = Object.assign(options, { level: INFO });

  logException(msg, err, finalOptions);
};

export const errorException = (msg, err, options) => {
  const finalOptions = mergeOptions(options, { level: ERROR });

  logException(msg, err, finalOptions);
};

export const createLogger = loggerOptions => {
  const finalLogOptions = loggerOptions ? loggerOptions : {};
  const wrapWithOptions = f => (msg, logMessageOptions) =>
    f(msg, mergeOptions(loggerOptions, logMessageOptions));
  const wrapExceptionWithOptions = f => (msg, err, logMessageOptions) =>
    f(msg, err, mergeOptions(loggerOptions, logMessageOptions));
  const _log = wrapWithOptions(log);
  const _debug = wrapWithOptions(debug);
  const _info = wrapWithOptions(info);
  const _error = wrapWithOptions(error);
  const _logException = wrapExceptionWithOptions(logException);
  const _debugException = wrapExceptionWithOptions(debugException);
  const _infoException = wrapExceptionWithOptions(infoException);
  const _errorException = wrapExceptionWithOptions(errorException);

  const _reportApiError = (err, ref) => {
    let finalRef = ref ? ref : finalLogOptions.name;
    reportAPIError(err, finalRef, finalLogOptions.includeVisitor);
  };

  const _reportApiErrorList = (errList, ref) => {
    let finalRef = ref ? ref : finalLogOptions.name;
    reportApiErrorList(errList, finalRef);
  };

  const _logFatalClientError = (err, ref) => {
    let finalRef = ref ? ref : finalLogOptions.name;
    logFatalClientError(err, finalRef);
  };

  return {
    log: _log,
    debug: _debug,
    info: _info,
    error: _error,
    logException: _logException,
    debugException: _debugException,
    infoException: _infoException,
    errorException: _errorException,
    logFatalClientError: _logFatalClientError,
    reportAPIError: _reportApiError,
    reportApiErrorList: _reportApiErrorList,
  };
};
