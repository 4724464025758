import partnerPageNameCodes from 'types/partners.pageCodes';

const getPageNameCode = pageName => {
  return partnerPageNameCodes[pageName] ? partnerPageNameCodes[pageName] : null;
};

const getIsLoggedInCode = loggedIn => {
  return loggedIn ? '1' : '0';
};

const getIsPayoutRankedCode = sortBy => {
  return sortBy ? '1' : '0';
};

export const getPartnersCustomTrackingMeta = containerData => {
  let customParamsValues = {};

  if (containerData) {
    customParamsValues['sortBy'] = containerData.sortBy
      ? containerData.sortBy
      : null;
  }

  return customParamsValues;
};

export const getPartnersCutomTrackingParams = (offerMetadata, attributes) => {
  let defaultProps = {};

  if (offerMetadata?.linkUrlExtraArguments) {
    let linkArgs = offerMetadata.linkUrlExtraArguments;
    for (var i in linkArgs) {
      switch (linkArgs[i]) {
        case 'pageNameCode':
          defaultProps['pageNameCode'] = getPageNameCode(
            attributes.pageNameMeta,
          );
          break;
        case 'isLoggedIn':
          defaultProps['isLoggedIn'] = getIsLoggedInCode(
            attributes.ocfLoggedIn || attributes.loggedIn,
          );
          break;
        case 'isPayoutRanked':
          defaultProps['isPayoutRanked'] = getIsPayoutRankedCode(
            attributes.partnersCustomTrackingMeta
              ? attributes.partnersCustomTrackingMeta.sortBy
              : '',
          );
          break;
        default:
          break;
      }
    }
  }

  return defaultProps;
};
