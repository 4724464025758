import React from 'react';
import Label from 'components/form/Label/Label';
import { Button } from 'components/form/Button';
import { FlexContainer } from 'components/Containers/FlexContainer/FlexContainer';
import OText from 'components/CText/OText';

const PremiumReactivateCancelMenuListView = props => {
  const {
    collapsed,
    openPremiumReactivateCancelModal,
    isPremiumReactivateCancelUser,
    premiumReactivateCancelConfig,
    seenPixel,
  } = props;

  if (collapsed || !isPremiumReactivateCancelUser) {
    return '';
  } else {
    return (
      <FlexContainer row alignItemsCenter className="marg10">
        {seenPixel}
        <Label className="Subtitle-3 margB12 margB16">
          <OText text={premiumReactivateCancelConfig?.nav_menu?.text} />
        </Label>
        <Button
          mrphType="primary"
          className="fullWidth"
          onClick={() => openPremiumReactivateCancelModal('nav banner')}
        >
          <OText text={premiumReactivateCancelConfig?.nav_menu?.cta} />
        </Button>
      </FlexContainer>
    );
  }
};
PremiumReactivateCancelMenuListView.defaultProps = {
  collapsed: false,
};
export default PremiumReactivateCancelMenuListView;
