import {
  buildFetchOptionsWithHeaders,
  standardizedFetchHandler
} from "./service_helpers";
import {
  buildRentPaymentReport,
  buildPostRentReportingAuthentication,
  buildPostRentRentalInfo,
  buildGetRentPaymentInfo,
} from 'api-urls';
import { getZingoTrackingParams } from 'helpers/tracking.helpers';

export const postReportEnrollment = async () => {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body: getZingoTrackingParams(),
  });
  return standardizedFetchHandler(buildRentPaymentReport(), requestOptions);
};

export const postRentReportingAuthentication = async () => {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body: getZingoTrackingParams(),
  });

  return standardizedFetchHandler(
    buildPostRentReportingAuthentication(),
    requestOptions,
  );
};

export const postRentRentalInfo = async purpose => {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body: getZingoTrackingParams(),
  });
  return standardizedFetchHandler(buildPostRentRentalInfo(purpose), requestOptions);
};

export const getRentPaymentInfo = async body => {
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'GET',
    body,
  });
  return standardizedFetchHandler(buildGetRentPaymentInfo(), requestOptions);
};
