import React from 'react';
import { Button } from 'components/form/Button/Button';
import './SkipToContent.scss';

const SkipToContent = () => {
  return (
    <a
      href="#main"
      className="skip-to-content-link pos-abs pad12 borderBlack fontWhite bgNeutralN500 borderRadius100"
    >
      Skip to Content
      <Button
        mrphType="standardLigthBlue"
        className="margL12"
        rounded
        neverFullWidth
      >
        Enter
      </Button>
    </a>
  );
};

export default SkipToContent;
