import { Button as AntButton } from 'antd';
import 'antd/dist/antd.min.css';
import classnames from 'classnames';
import * as PropTypes from 'prop-types';
import 'style/main.scss';
import './Button.scss';

function Button({
  children,
  datatestid,
  mrphType,
  mrphAction,
  neverFullWidth,
  hideFocusShadow,
  fullWidth,
  disable,
  rounded,
  style,
  ...props
}) {
  let bgCLass = '';
  let fontClass = '';
  let borderClass = '';
  let typeClass = '';
  let restStyle = 'mrphbutton STDpat01 padLR16 padTB8'; // btnHeight padLR16 padTB8 subTitle2
  let type = null;

  if (mrphType) {
    if (mrphType === 'primary') {
      bgCLass = ' '; // bgBlue3
      fontClass = ' '; // fontWhite
      typeClass = 'primary';
    }
    if (mrphType === 'secondary') {
      bgCLass = ' '; // bgBlue3
      fontClass = ' '; // fontWhite
      typeClass = 'secondary';
    }
    if (mrphType === 'primaryLink') {
      bgCLass = ' '; //
      fontClass = ' '; //
      typeClass = 'primaryLink';
    }
    if (mrphType === 'primaryLight') {
      bgCLass = ' '; //
      fontClass = ' '; //
      typeClass = 'primaryLight';
    }
    if (mrphType === 'standardBlue') {
      bgCLass = 'bgWhite'; // bgBlue3
      fontClass = ' '; // fontWhite
      typeClass = 'standardBlue';
      borderClass = 'borderBlue100';
    }
    if (mrphType === 'standardWhite') {
      bgCLass = ''; // bgBlue3
      fontClass = ' '; // fontWhite
      typeClass = 'standardWhite';
      borderClass = '';
    }
    if (mrphType === 'standardGray') {
      bgCLass = ''; // bgBlue3
      fontClass = ''; // fontWhite
      typeClass = 'standardGray';
      restStyle =
        'mrphbutton STDpat01 heightAuto borderRadius6-7 padLR16 padTB8';
    }
    if (mrphType === 'standardGrayMobile') {
      bgCLass = 'bgTransparent';
      fontClass = 'blueB100';
      typeClass = '';
      borderClass = 'border2pxBlueB100';
      restStyle = 'mrphbutton heightAuto borderRadius6-7 padLR16 padTB8';
    }
    if (mrphType === 'standardBlueMobile') {
      bgCLass = ' '; // bgGray5
      fontClass = ' '; // fontGray6
      borderClass = ' '; // borderGray5
      type = 'standard';
      typeClass = 'standard';
      restStyle = 'mrphbutton STDpat01 padLR8 padTB8';
    }
    if (mrphType === 'standard') {
      bgCLass = ' '; // bgGray5
      fontClass = ' '; // fontGray6
      borderClass = ' '; // borderGray5
      type = 'standard';
      typeClass = 'standard';
    }
    if (mrphType === 'standardFilled') {
      bgCLass = ' '; // bgGray5
      fontClass = ' '; // fontGray6
      borderClass = ''; // borderGray5
      type = 'standardFilled';
      typeClass = 'standardFilled';
    }
    if (mrphType === 'destructive') {
      bgCLass = ' '; // bgRed3
      fontClass = ' '; // fontWhite
      type = 'destructive';
      typeClass = 'destructive';
    }

    if (mrphType === 'destructiveLink') {
      bgCLass = ' '; // bgRed3
      fontClass = ' '; // fontWhite
      type = 'destructive-link';
      typeClass = 'destructive-link';
    }
    if (mrphType === 'link') {
      fontClass = ''; // fontBlue3
      type = 'link';
      typeClass = 'link';
    }
    if (mrphType === 'linkbutton') {
      fontClass = ''; // fontBlue3
      type = 'link';
      typeClass = 'linkbutton';
    }
    if (mrphType === 'standardThick') {
      bgCLass = ' '; // bgGray5
      fontClass = ' '; // fontGray6
      borderClass = ' '; // borderGray5Thick
      type = 'standard';
      typeClass = 'standard';
    }
    if (mrphType === 'filterButton') {
      bgCLass = ' '; // bgGray5
      fontClass = ' '; // fontGray6
      borderClass = 'borderBlue100';
      type = 'standard';
      typeClass = 'standard';
    }
    if (mrphType === 'standardLigthBlue') {
      bgCLass = 'bgBlue050 bold';
      fontClass = 'fontNeutral800';
    }
    if (mrphType === 'yellowButton') {
      typeClass = 'yellowButton';
    }
    if (mrphType === 'blackButton') {
      typeClass = 'blackButton';
    }
  }
  let className = classnames(
    typeClass,
    bgCLass,
    fontClass,
    borderClass,
    restStyle,
    { neverFullWidth },
    { fullWidth },
    { 'border-rad100px ': rounded },
    props.className,
    { hideFocusShadow },
  );

  const disabled = disable ? true : false;

  return (
    <AntButton
      data-testid={datatestid ? `${datatestid}-button` : null}
      disabled={disabled}
      {...props}
      className={className}
      type={type}
      style={style}
    >
      {children}
    </AntButton>
  );
}

Button.propTypes = {
  /**
   * Description of prop "foo".
   */
  mrphType: PropTypes.oneOf([
    'primary',
    'standard',
    'standardBlue',
    'standardWhite',
    'standardFilled',
    'destructive',
    'destructiveLink',
    'link',
    'linkbutton',
    'standardThick',
    'filterButton',
    'neutral',
    'standardLigthBlue',
    'yellowButton',
    'blackButton',
    'standardGray',
  ]),
  datatestid: PropTypes.string,
};

export { Button };
