import { fetchInHouseSimulation } from 'externals/_services/simulation.service';
import { createLogger } from 'helpers/logger';
import { getRefinanceAutoLoanConfig } from 'helpers/autoOffer.helpers';
import { fetchAutoLoanOffers } from 'externals/_services/product.service';
import { wrapService } from 'actions/service_wrapper.actions';
import {
  AUTO_REFINANCE_PAYLOAD,
  buildPayloadDispatcher,
  CREDIT_REPAIR_EXPERT_SIM_PAYLOAD,
  AUTO_INSURANCE_ACTIONS_PAYLOAD,
  HOME_INSURANCE_ACTIONS_PAYLOAD,
  SELF_SIM_PAYLOAD,
} from 'types/payload.constants';

import { AUTO_LOAN_FORM_COMPLETED } from 'types/product.constants';
import { loadInsuranceOffers } from 'actions/insurance.actions';
import { hasMortgage } from 'helpers/credit_info';

const a_fetchInHouseSimulation = wrapService(fetchInHouseSimulation, {
  name: 'actions_fetchInHouseSimulation',
});
const a_fetchAutoLoanOffers = wrapService(fetchAutoLoanOffers, {
  name: 'actions_fetchAutoLoanOffers',
});

const logger = createLogger({
  name: 'actions.actions',
});

export const autoInsuranceOffersPayload = buildPayloadDispatcher(
  'autoInsuranceOffers',
  AUTO_INSURANCE_ACTIONS_PAYLOAD,
);

const homeInsuranceOffersPayload = buildPayloadDispatcher(
  'homeInsuranceOffers',
  HOME_INSURANCE_ACTIONS_PAYLOAD,
);

/**
 * This simulation is similar to 'newCardScoreIncrease'. The Slapi request use the same simulation modifier.
 *
 * The differences are:
 *   - Eligibility criteria: offerMaxLimit > 0.15 * totalLimit
 *   - Display criteria: This simulation displays regardless of minimum score improvement requirments
 *
 * Also, for actions -- this simulation should only be run if user is not eligible for 'newCardScoreIncrease' simulation.
 */

const payloadCreditRepairExpert = buildPayloadDispatcher(
  'creditRepairExpertSim',
  CREDIT_REPAIR_EXPERT_SIM_PAYLOAD,
);

export const loadCreditRepairExpertSim = creditInfo => dispatch => {
  return new Promise(resolve => {
    dispatch(payloadCreditRepairExpert(null, true, null));
    const negativeMarkCount =
      creditInfo.creditScoreAnalysis.paymentHistory.negativeRemarkAccounts
        .length + creditInfo.publicRecordsCount;
    if (negativeMarkCount >= 2) {
      dispatch(a_fetchInHouseSimulation({ newCreditRepairEnrollment: true }))
        .then(results => {
          dispatch(payloadCreditRepairExpert(results, false, null));
          resolve(results);
        })
        .catch(error => {
          dispatch(payloadCreditRepairExpert(null, false, error));
          resolve(null);
        });
    } else {
      const error = {
        message: 'Negative Marks Is not high enough',
      };
      dispatch(payloadCreditRepairExpert(null, false, error));
      resolve(null);
    }
  });
};

const updateSelfSimPayload = buildPayloadDispatcher(
  'selfSim',
  SELF_SIM_PAYLOAD,
);

export const loadSelfSim = creditInfo => dispatch => {
  return new Promise(resolve => {
    dispatch(updateSelfSimPayload(null, true));
    const creditScore = creditInfo.creditScore;
    if (creditScore >= 300 && creditScore < 550) {
      dispatch(
        a_fetchInHouseSimulation({
          newPersonalLoan: 520,
        }),
      )
        .then(results => {
          dispatch(updateSelfSimPayload(results));
          resolve(results);
        })
        .catch(error => {
          dispatch(updateSelfSimPayload(null, false, error));
          resolve(null);
        });
    } else {
      const error = { message: 'credit score is too high' };
      dispatch(updateSelfSimPayload(null, false, error));
      resolve(null);
    }
  });
};

const updateAutoRefinancePayload = (
  autoRefinance,
  autoRefinanceLoading,
  autoRefinanceError,
) => ({
  type: AUTO_REFINANCE_PAYLOAD,
  autoRefinance,
  autoRefinanceLoading,
  autoRefinanceError,
});

const startLoadAutoRefinance = () =>
  updateAutoRefinancePayload(null, true, null);
const succeedAutoRefinanceLoad = payload =>
  updateAutoRefinancePayload(payload, false, null);
const failAutoRefinanceLoad = error =>
  updateAutoRefinancePayload(null, false, error);

const updateautoLoanFormConfigPayload = autoLoanFormConfig => ({
  type: AUTO_LOAN_FORM_COMPLETED,
  autoLoanFormConfig,
});

const startAutoLoanFormConfig = () => updateautoLoanFormConfigPayload(null);
const succeedAutoLoanFormConfig = payload =>
  updateautoLoanFormConfigPayload(payload);

export const loadAutoRefinance = (creditInfo, userInfo) => dispatch => {
  return new Promise(resolve => {
    dispatch(startLoadAutoRefinance());
    dispatch(startAutoLoanFormConfig());
    const debtAnalysisAvailable = creditInfo?.debtAnalysis?.autoLoanDebt;

    if (userInfo && debtAnalysisAvailable) {
      const { refinanceAccountConfig, autoLoanFormConfig } =
        getRefinanceAutoLoanConfig(
          debtAnalysisAvailable?.accounts,
          creditInfo,
          userInfo,
          null,
        );

      if (refinanceAccountConfig) {
        dispatch(succeedAutoLoanFormConfig(autoLoanFormConfig));
        dispatch(a_fetchAutoLoanOffers(refinanceAccountConfig))
          .then(results => {
            dispatch(succeedAutoRefinanceLoad({ ...results }));
            resolve(results);
          })
          .catch(error => {
            dispatch(failAutoRefinanceLoad(error));
            resolve(null);
          });
      } else {
        const error = { message: 'user is not eligible' };
        dispatch(updateAutoRefinancePayload(null, false, error));
        resolve(null);
      }
    } else {
      const error = { message: 'user is not eligible' };
      dispatch(updateAutoRefinancePayload(null, false, error));
      resolve(null);
    }
  });
};

export const loadInsuranceActions =
  ({ creditInfo, userInfo, autoInsuranceConfig, placement }) =>
  dispatch => {
    dispatch(autoInsuranceOffersPayload(null, true, null));
    return new Promise((resolve, reject) => {
      dispatch(
        loadInsuranceOffers({
          homeowner: hasMortgage(creditInfo),
          multipleVehicles: false,
          currentlyInsured: true,
          insuranceType: 'AUTO',
          userId: 'me',
          goodDriver: true,
          carrier: autoInsuranceConfig?.issuerList[0],
          zip: userInfo?.primaryAddress?.zip,
          placement: placement,
        }),
      )
        .then(res => {
          dispatch(autoInsuranceOffersPayload(res.offers, false, null));
          resolve();
        })
        .catch(err => {
          dispatch(autoInsuranceOffersPayload(null, false, err));
          reject(err);
        });
    });
  };

export const loadHomeInsuranceActions =
  ({ creditInfo, userInfo, homeInsuranceConfig, placement }) =>
  dispatch => {
    dispatch(homeInsuranceOffersPayload(null, true, null));
    return new Promise((resolve, reject) => {
      dispatch(
        loadInsuranceOffers({
          homeowner: hasMortgage(creditInfo),
          currentlyInsured: true,
          insuranceType: 'HOME',
          userId: 'me',
          carrier: homeInsuranceConfig?.insurers[0],
          zip: userInfo?.primaryAddress?.zip,
          placement: placement,
          propertyType: homeInsuranceConfig?.propertyTypes[0],
          maritalStatus: homeInsuranceConfig?.maritalStatus[0],
        }),
      )
        .then(res => {
          dispatch(homeInsuranceOffersPayload(res, false, null));
          resolve();
        })
        .catch(err => {
          logger.reportAPIError(err);
          dispatch(homeInsuranceOffersPayload(null, false, err));
          reject(err);
        });
    });
  };
