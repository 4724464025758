export const CREDIT_CALENDAR_FREE_USERS_CONFIG = {
  logoClass: 'csPlusLogo',
  title: 'Stay ahead of your credit',
  subText:
    'Access your 30-day credit calendar with Credit Sesame+ and take control of your credit factors.',
  feature1ImgClass: 'ringArrowIncreaseImg',
  feature1Text: 'Manage your <br /> credit score',
  feature2ImgClass: 'creditCardChurnImg',
  feature2Text: ' Track credit <br /> payments',
  feature3ImgClass: 'calendarImg',
  feature3Text: 'Prepare for your <br /> next credit report',
  cta2Text: 'View my credit goals',
};
export const SECOND_TAB_NAME = 'Goals';
