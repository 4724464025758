export const getPageFormFactor = () => {
  var width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  return __getPageFormFactor(width);
};

export const __getPageFormFactor = screenWidth => {
  if (screenWidth < 640) {
    return 'Mobile';
  } else {
    return 'Web';
  }
};
