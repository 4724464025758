export const CREDIT_CARD_VERTICAL_CARD = 'Credit Card Vertical Card';
export const PERSONAL_LOAN_VERTICAL_CARD = 'Personal Loan Vertical Card';
export const AUTO_LOAN_VERTICAL_CARD = 'Auto Loan Vertical Card';
export const HOME_LOAN_VERTICAL_CARD = 'Home Loan Vertical Card';
export const AUTO_INSURANCE_VERTICAL_CARD = 'Auto Insurance Vertical Card';
export const HOME_INSURANCE_VERTICAL_CARD = 'Home Insurance Vertical Card';
export const LIFE_INSURANCE_VERTICAL_CARD = 'Life Insurance Vertical Card';
export const BUSINESS_LOANS_VERTICAL_CARD = 'Business Loan Vertical Card';
export const TRAVEL_LOANS_VERTICAL_CARD = 'Travel Loan Vertical Card';
export const WEDDING_LOANS_VERTICAL_CARD = 'Wedding Loan Vertical Card';
export const MEDICAL_LOANS_VERTICAL_CARD = 'Medical Loan Vertical Card';
export const SWIMMING_LOANS_VERTICAL_CARD = 'Swimming Loan Vertical Card';

// should move this to types/mrph.vertical.constants
export const CREDIT_CARD_VERTICAL = 'Credit Card';
export const PERSONAL_LOAN_VERTICAL = 'Personal Loan';
export const AUTO_LOAN_VERTICAL = 'Auto Loan';
export const HOME_LOAN_VERTICAL = 'Home Loan';
export const AUTO_INSURANCE_VERTICAL = 'Auto Insurance';
export const HOME_INSURANCE_VERTICAL = 'Home Insurance';
export const LIFE_INSURANCE_VERTICAL = 'Life Insurance';
export const INSURANCE_VERTICAL = 'Insurance';
export const BUSINESS_LOANS_VERTICAL = 'Business Loan';
export const CREDIT_REPAIR_EXPERT_VERTICAL = 'Credit Repair';

export const VERTICAL_OFFER_TYPE_MAP = {
  CREDIT_CARD: CREDIT_CARD_VERTICAL,
  PERSONAL_LOAN: PERSONAL_LOAN_VERTICAL,
  AUTO_LOAN: AUTO_LOAN_VERTICAL,
  HOME_LOAN: HOME_LOAN_VERTICAL,
  AUTO_INSURANCE: AUTO_INSURANCE_VERTICAL,
  HOME_INSURANCE: HOME_INSURANCE_VERTICAL,
  LIFE_INSURANCE: LIFE_INSURANCE_VERTICAL,
  BUSINESS: BUSINESS_LOANS_VERTICAL,
};

export const CREDIT_CARD = 'CREDIT_CARD';
export const PERSONAL_LOAN = 'PERSONAL_LOAN';
export const AUTO_LOAN = 'AUTO_LOAN';
export const LIFE_INSURANCE = 'LIFE_INSURANCE';
export const AUTO_INSURANCE = 'AUTO_INSURANCE';
export const BUSINESS = 'BUSINESS';
export const HOME_INSURANCE = 'HOME_INSURANCE';
export const HOME_LOAN = 'HOME_LOAN';

export const CREDIT_CARD_VERTICAL_VRI = 'credit_card';
export const PERSONAL_LOAN_VERTICAL_VRI = 'personal_loan';
export const AUTO_LOAN_VERTICAL_VRI = 'auto_loan';
export const HOME_LOAN_VERTICAL_VRI = 'mortgage';
export const AUTO_INSURANCE_VERTICAL_VRI = 'auto_insurance';
export const HOME_INSURANCE_VERTICAL_VRI = 'home_insurance';
export const LIFE_INSURANCE_VERTICAL_VRI = 'life_insurance';
export const BUSINESS_LOANS_VERTICAL_VRI = 'business_loan';
export const CREDIT_PROFILE = 'credit_profile';
