import {
  VERIFY_PHONE_ERROR,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_ERROR,
  RESEND_CODE_SUCCESS,
  RESEND_CODE_ERROR,
  SET_CODE_VALUE,
  SET_NEED_ACCESS_EMAIL_STEP,
  SUBMIT_EMAIL_FORM_ERROR,
  SUBMIT_EMAIL_FORM_NEED_PHONE,
  UPDATE_EMAIL_ERROR,
  CLEAR_NEED_ACCESS_EMAIL_STATE,
  SUBMIT_EMAIL_FORM_SAVE_EMAIL,
} from '../../PasswordReset.constants';
import { LOGOUT } from 'types/app.constants';

const initialState = {
  step: 'UPDATE_EMAIL_FORM',

  emailFormError: null,
  originalEmail: null,
  verifyPhoneError: null,
  verifyOtpMessage: null,
  codeValue: '',
  resendAttempts: 0,
  codeAttempts: 0,
  token: null,
  updateEmailError: null, 
};

const needAccessEmailReducer = (
  state = Object.assign({}, initialState),
  action,
) => {
  switch (action.type) {
    case SET_NEED_ACCESS_EMAIL_STEP:
      return Object.assign({}, state, {
        step: action.step,
      });
    case SUBMIT_EMAIL_FORM_ERROR:
      return Object.assign({}, state, {
        emailFormError: action.emailFormError,
      });
    case SUBMIT_EMAIL_FORM_NEED_PHONE:
      return Object.assign({}, state, {
        maskedPhoneNumber: action.maskedPhoneNumber,
      });
    case SUBMIT_EMAIL_FORM_SAVE_EMAIL:
      return Object.assign({}, state, {
        originalEmail: action.originalEmail,
      });
    case VERIFY_PHONE_ERROR:
      return Object.assign({}, state, {
        verifyPhoneError: action.verifyPhoneError,
      });
    case SET_CODE_VALUE:
      return Object.assign({}, state, {
        codeValue: action.codeValue,
      });
    case RESEND_CODE_SUCCESS:
      return Object.assign({}, state, {
        verifyOtpMessage: action.verifyOtpMessage,
        codeValue: '',
        resendAttempts: action.resendAttempts,
        codeAttempts: 0,
      });
    case RESEND_CODE_ERROR:
      return Object.assign({}, state, {
        verifyOtpMessage: action.verifyOtpMessage,
      });
    case VERIFY_OTP_SUCCESS:
      return Object.assign({}, state, {
        token: action.token,
        codeValue: '',
        codeAttempts: 0,
        resendAttempts: 0,
        verifyOtpMessage: null,
      });
    case VERIFY_OTP_ERROR:
      return Object.assign({}, state, {
        verifyOtpMessage: action.verifyOtpMessage,
        codeValue: '',
        codeAttempts: action.codeAttempts,
      });
    case UPDATE_EMAIL_ERROR:
      return Object.assign({}, state, {
        updateEmailError: action.updateEmailError,
      });
    case CLEAR_NEED_ACCESS_EMAIL_STATE:
    case LOGOUT:
      return Object.assign({}, initialState);
    default:
      return state;
  }
};

export default needAccessEmailReducer;
