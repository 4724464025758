import { useFlag } from 'hook/flag.hook';

export const useTUCreditLockValidation = () => {
  const tuCreditLockExp = useFlag('tu_credit_lock', {
    defaultConfig: {
      enabled: false,
    },
    forceDefaultConfigForDisabledOptimizely: true,
  });
  return tuCreditLockExp;
};
