import React from 'react';
import './Badge.scss';

function Badge(props) {
  const datatestid = props.datatestid ? props.datatestid : null;
  const valueLength =
    props.children && props.children.toString().length
      ? props.children.toString().length
      : 0;

  //if number exceeds 2 digits then convert to 99+
  const isOverflow = valueLength >= 3;

  const displayValue =
    isOverflow && !props.type === 'text'
      ? '99+'
      : props.children
      ? props.children
      : null;
  const inlineStyle = props.style ? props.style : null;

  //set badge type, default to dot
  let classes = props.type ? `${props.type}` : 'dot';
  classes = props.className ? `${classes} ${props.className}` : classes;

  if (isOverflow && !props.type === 'text') {
    classes += ' wide';
  }

  return (
    <div
      data-testid={datatestid}
      className={`Badge bgRed100 fontWhite ${classes}`}
      style={inlineStyle}
    >
      {displayValue}
    </div>
  );
}

export default Badge;
