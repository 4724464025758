import { LOGIN_SUCCESS, LOGOUT } from 'types/app.constants';
import { CMALERTS_PAYLOAD, PAYLOAD } from 'types/payload.constants';
import { mergePayloadChanges } from 'helpers/reducer.helper';

const initialState = {
  cmAlerts: null,
  cmAlertsLoading: false,
  cmAlertsError: null,
};

export default function cmAlertsReducer(state = initialState, action) {
  switch (action.type) {
    case CMALERTS_PAYLOAD:
    case LOGIN_SUCCESS:
    case PAYLOAD:
      return mergePayloadChanges(state, action);
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
}
