import { LOGOUT } from 'types/app.constants';
import {
  SELECT_CREDIT_AGE_CONTAINER_DATA,
  RESET_CREDIT_AGE_CONTAINER_DATA,
} from 'types/creditAgeContainer.constants';

const initialState = {
  creditAgeContainer: null,
  containerLoading: false,
  containerError: null,
};

export default function creditAgeContainerReducer(
  state = initialState,
  action,
) {
  switch (action?.type) {
    case SELECT_CREDIT_AGE_CONTAINER_DATA: {
      return {
        ...state,
        creditAgeContainer: action?.creditAgeContainer,
        containerLoading: action?.containerLoading,
        containerError: action?.containerError,
      };
    }
    case LOGOUT:
    case RESET_CREDIT_AGE_CONTAINER_DATA:
      return initialState;
    default:
      return state;
  }
}
