import React from 'react';
import './Row.scss';
import classnames from 'classnames';

function Row({
  children,
  className,
  fullWidth,
  type,
  datatestid,
  style,
  fullWidthMobile,
  ...props
}) {
  const testid = datatestid ? `${datatestid}-row` : null;

  const { padR0, padL0 } = props;

  let classNamesForRow = classnames(
    { Row: true },
    { fullWidth: fullWidth },
    { fullWidthMobile },
    { padR0: padR0 },
    { padL0: padL0 },
    type,
    className,
  );

  return (
    <div className={classNamesForRow} data-testid={testid} style={style}>
      {children}
    </div>
  );
}

export { Row };
