import React from 'react';
import Badge from 'components/Badge/Badge';
import { ReactComponent as HamburgerButton } from 'style/_assets/icons/menu.svg';
import { MobileMenu } from 'components/navigation/MobileMenu/MobileMenu';
import Logo from 'components/navigation/Menu/Logo';
import StayLimited from 'components/navigation/Menu/StayLimited';
import { ReactComponent as IconLockGray } from '../../../style/_assets/icons/lock-gray.svg';
import {
  getDiffDaysBetweenDates,
  getDateWithoutTime,
} from '../../../helpers/dateHelper';
import { FlexContainer } from '../../../components/Containers/FlexContainer/FlexContainer';
import { Button } from 'components/form/Button/Button';
import { useCashSignupState } from 'pages/SignupPage/Signup1/components/CashSignup1/hooks/CashSignup.hook';
import OpenInAppBtn from './OpenInAppBtn';
import classnames from 'classnames';
import GlobalHeaderWithOpenInAppBtn from './OpenInAppBtn/GlobalHeaderWithOpenInAppBtn';
import {
  isMobileByOS,
  isMobileByWidth,
} from 'externals/_tracking/dom/navigator';
import { ReactComponent as HamburgerButtonPlus } from 'style/icons/menu_white.svg';
import { isFree, isPlus } from 'helpers/userHelper';
import { Image } from 'components/Image/Image';
import { PremiumPill } from 'partial/PremiumPill/PremiumPill';
import { Link } from 'components/navigation/Link/Link';
import { CLICK } from 'externals/_tracking/types/eventTypes.constants';
import NotificationIconSection from 'components/NotificationIconSection/NotificationIconSection';
import Label from 'components/form/Label/Label';
import { useConfig } from 'hook/config.hook';
import { SUBSCRIPTION_VERTICAL } from 'types/mrph.vertical.constants';

const RenderCorrectNavRedirect = ({
  showLogo,
  eventHandler,
  loggedIn,
  logoLink,
  currentPath,
  isBoosterWaitlistPage,
  isPremiumUser,
  isSignup,
  forceHideMenuBar,
  menuType,
  headerMenuOpts,
  isPlusUser,
}) => {
  if (!forceHideMenuBar) {
    return (
      <Logo
        showLogo={showLogo}
        eventHandler={eventHandler}
        loggedIn={loggedIn}
        logoLink={logoLink}
        currentPath={currentPath}
        isBoosterWaitlistPage={isBoosterWaitlistPage}
        isPremiumUser={isPremiumUser}
        isSignup={isSignup}
        isPlusUser={isPlusUser}
      />
    );
  } else {
    if (menuType === 'DASHBOARD_BACK') {
      return (
        <StayLimited
          titleText={'Dashboard'}
          clickType="View Premium Dashboard"
          isPlusUser={isPlusUser}
        />
      );
    } else if (menuType === 'STAY_LIMITED') {
      return (
        <StayLimited
          titleText={'Stay Limited'}
          clickType="Stay Limited"
          isPlusUser={isPlusUser}
        />
      );
    } else if (menuType === 'STAY_STANDARD') {
      return (
        <StayLimited
          titleText={'Stay Standard'}
          clickType="Stay Standard"
          isPlusUser={isPlusUser}
        />
      );
    } else if (menuType === 'OVERVIEW_BACK') {
      return (
        <StayLimited
          titleText={'Overview'}
          clickType="Overview"
          isPlusUser={isPlusUser}
        />
      );
    } else if (menuType === 'BACK') {
      return (
        <StayLimited
          titleText={'Overview'}
          clickType="Overview"
          isPlusUser={isPlusUser}
        />
      );
    } else if (menuType === 'CUSTOM_URL_BACK') {
      return (
        <StayLimited
          titleText={headerMenuOpts?.titleText}
          customOptions={headerMenuOpts}
          isPlusUser={isPlusUser}
        />
      );
    } else if (menuType === 'HIDE_LOGO') {
      return '';
    } else {
      return (
        <Logo
          showLogo={true}
          eventHandler={eventHandler}
          loggedIn={loggedIn}
          logoLink={logoLink}
          currentPath={currentPath}
          isBoosterWaitlistPage={false}
          isPremiumUser={isPremiumUser}
          isSignup={isSignup}
        />
      );
    }
  }
};
const Navbar = ({
  collapseMenu,
  currentPath,
  eventHandler,
  numberOfGoals,
  extraProps,
  forceHideMenuBar,
  forceMobileHideMenuBar,
  isBoosterWaitlistPage,
  isHideHeader,
  isOcfPage,
  isOcfRole,
  isPremiumUser,
  isRecurlyHybridUser,
  isSignup,
  loggedIn,
  logoLink,
  menuType,
  headerMenuOpts,
  navbarShowing,
  notifications,
  onMenuToggle,
  removeHeader,
  showLogo,
  showMenuBar,
  userInfo,
  showPendingActions,
  showGoalsCounter,
  numberOfActions,
  secureLoggedIn,
  totalUnreadCount,
  creditManagerVisited,
  history,
}) => {
  const { config } = useConfig();
  const showUpgradePill =
    isFree(userInfo) &&
    navbarShowing &&
    !isMobileByWidth() &&
    config?.navBar?.showUpgradePill;
  const isFreeTrial = userInfo?.premiumPlanInfo?.freeTrialPeriod;
  const trialEndDate = getDateWithoutTime(
    userInfo?.premiumPlanInfo?.trialEndDate,
  );

  const onClickUpgradePill = () => {
    eventHandler(CLICK, {
      'Click Type': 'Global Header Try Premium ',
      Vertical: SUBSCRIPTION_VERTICAL,
    });
    history.push(config?.subscriptionLandingUrl);
  };
  const daysRemaining = getDiffDaysBetweenDates(
    trialEndDate,
    getDateWithoutTime(),
  );

  const premiumPacakgeTitle = userInfo?.premiumPlanInfo?.uiDisplayName;
  const isPlusUser = isPlus(userInfo) ? isPlus(userInfo) : false;

  const { onClickLogin, showLoginLink } = useCashSignupState(
    extraProps?.isSignupLoading,
  );
  if (isRecurlyHybridUser || isHideHeader) {
    return null;
  }

  const showContinueInAppButton = !isOcfPage && isMobileByOS();
  const showGlobalHeader =
    showContinueInAppButton &&
    secureLoggedIn &&
    !showLogo &&
    !forceMobileHideMenuBar;
  const showAppDownloadButton =
    showContinueInAppButton && !showGlobalHeader && !isSignup;

  const mainNavCssClasses = classnames('main-nav-container', {
    'flex-container wrap show-global-header': showGlobalHeader,
    'poof-hard': forceHideMenuBar && menuType === 'HIDE_COMPLETELY',
    'plus-main-nav-container': isPlusUser,
  });

  const globalHeaderClass = classnames('d-hide', {
    'm-hide': forceMobileHideMenuBar,
    't-width100': showGlobalHeader,
  });

  const premiumDesktopClass = classnames('d-hide', {
    'm-hide': forceMobileHideMenuBar,
    'mobile-right padR16 m-padR22': !forceMobileHideMenuBar,
  });

  const onClickBell = () =>
    eventHandler(CLICK, {
      'Click Type': 'Global Header Notification Bell',
    });
  if (removeHeader) {
    return '';
  } else {
    return (
      <div className={mainNavCssClasses}>
        {/* mobile nav header (shows continue in app button and cs logo (logged experiance)) */}
        <div className={globalHeaderClass}>
          {showGlobalHeader && (
            <GlobalHeaderWithOpenInAppBtn
              eventHandler={eventHandler}
              loggedIn={loggedIn}
              logoLink={logoLink}
              currentPath={currentPath}
              isBoosterWaitlistPage={isBoosterWaitlistPage}
              isPremiumUser={isPremiumUser}
              totalUnreadCount={totalUnreadCount}
              isSignup={isSignup}
              isPlusUser={isPlusUser}
              onMenuToggle={onMenuToggle}
              notifications={notifications}
              premiumPacakgeTitle={premiumPacakgeTitle}
            />
          )}
        </div>
        <div className="left">
          {/* mobile nav bar options (actions , offers , etc) */}
          {navbarShowing && (
            <>
              <div
                className="desktopHamberger pos-rel"
                onClick={collapseMenu}
                tabIndex={'0'}
                onKeyPress={e => {
                  e.preventDefault();
                  collapseMenu(e);
                }}
              >
                {notifications && (
                  <Badge
                    type="dot"
                    className="pos-abs"
                    style={{ right: '-4px', top: '-2px' }}
                  />
                )}
                {isPlusUser ? <HamburgerButtonPlus /> : <HamburgerButton />}
              </div>
              <MobileMenu
                onMenuToggle={onMenuToggle}
                numberOfGoals={numberOfGoals}
                eventHandler={eventHandler}
                datatestid="headerLoggedIn-mobile"
                notifications={notifications}
                currentPath={currentPath}
                userInfo={userInfo}
                isOcfRole={isOcfRole}
                numberOfActions={numberOfActions}
                showPendingActions={showPendingActions}
                showGoalsCounter={showGoalsCounter}
                isPlusUser={isPlusUser}
                isPremiumUser={isPremiumUser}
                creditManagerVisited={creditManagerVisited}
              />
            </>
          )}

          {/*  show cs logo in header (non secure logged experiance , login , ocf pages etc )*/}
          <RenderCorrectNavRedirect
            showMenuBar={showMenuBar}
            showLogo={showLogo}
            eventHandler={eventHandler}
            loggedIn={loggedIn}
            logoLink={logoLink}
            currentPath={currentPath}
            isSignup={isSignup}
            isBoosterWaitlistPage={isBoosterWaitlistPage}
            isPremiumUser={isPremiumUser}
            menuType={menuType}
            forceHideMenuBar={forceHideMenuBar}
            headerMenuOpts={headerMenuOpts}
            isPlusUser={isPlusUser}
          />
        </div>
        <FlexContainer alignItemsCenter className="right justify-end">
          {navbarShowing && (
            <Link
              onClickCallback={onClickBell}
              to="/alerts"
              data-testid={`header-alerts-link`}
              activeClassName="selected"
              className="pos-rel margR16 mobileHide"
            >
              <FlexContainer className="justify-center format100 items-align-center">
                <NotificationIconSection
                  totalUnreadCount={totalUnreadCount}
                  isPlusUser={isPlusUser}
                />
              </FlexContainer>
            </Link>
          )}
          {/* for premium in desktop */}
          <div className="right padR16">
            {showUpgradePill && (
              <Label onClick={onClickUpgradePill}>
                <PremiumPill
                  backgroundClass="navbarPill"
                  text="Upgrade"
                  className="pointer"
                />
              </Label>
            )}
            {showLoginLink && (
              <Button
                name="login"
                mrphType="standard"
                className="fullWidth padLR40"
                onClick={onClickLogin}
              >
                {' '}
                Log in{' '}
              </Button>
            )}
            {isPremiumUser && showMenuBar && (
              <>
                {isFreeTrial ? (
                  <FlexContainer className="text-right freeTrialWrap">
                    <div className="fontBlue100 toUpper text-12 fontLatoBold ">
                      <IconLockGray className="margTopN3" /> Free Trial
                    </div>
                    <div className="fontNeutral300 italic text-12 mobileHide">
                      {daysRemaining} days remaining
                    </div>
                  </FlexContainer>
                ) : (
                  <PremiumPill
                    backgroundClass="navbarPill"
                    text={premiumPacakgeTitle}
                  />
                )}
              </>
            )}
          </div>
          {/* for premium in desktop */}
          <div className={premiumDesktopClass}>
            {showAppDownloadButton && <OpenInAppBtn />}
            {showLoginLink && (
              <div
                className="noWrapText fontBlue3 bold lineHeight-1-5"
                onClick={onClickLogin}
              >
                Log in
              </div>
            )}
          </div>
        </FlexContainer>
      </div>
    );
  }
};

export default Navbar;
