import { getHighLightFeatureEndpoint } from 'api-urls';
import {
  buildFetchOptionsWithHeaders,
  standardizedFetchHandler,
} from './service_helpers';

export async function fetchHighlightFeatures() {
  return standardizedFetchHandler(
    getHighLightFeatureEndpoint(),
    buildFetchOptionsWithHeaders(),
  );
}
