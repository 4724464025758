import {
  EVENT,
  SET_FREEZE_EVENTS,
  STORE_EVENT,
} from 'types/eventHandler.constants';
import { brazeEvent } from 'externals/_tracking/braze/Braze.track';
import {
  mixpanelEvent,
  publicMixpanelTrack,
  visitorReportEvent,
} from 'externals/_tracking';
import { isLoggingToWindow } from 'helpers/localstorage';
import { getEnvironment, TEST } from 'helpers/env';
import {
  AUTOMATIC_DIRECT_DEPOSIT_LANDING_PENDING,
  AUTOMATIC_DIRECT_DEPOSIT_LANDING_SUCCESS,
} from 'types/mrph.pageName.constants';
import {
  AUTOMATIC_DIRECT_DEPOSIT_LANDING,
  CASH_FUND_ACCOUNT,
  DIRECT_DEPOSIT,
  POST_PREMIUM_LANDING,
} from 'types/mrph.pages.constants';
import * as pageNames from 'types/mrph.pageName.constants';
import { getCurrentUrl, getGetParam } from 'helpers/domHelper';
import { ATOMIC_SDK_OUTCOME_PENDING } from 'types/sesameCash.constants';

const dynamicPageNameRegistry = {
  [CASH_FUND_ACCOUNT]: state => {
    const showEnhanced = getCurrentUrl().indexOf('enhanced') >= 0;
    if (showEnhanced) {
      return pageNames[CASH_FUND_ACCOUNT + '_ENHANCED'];
    } else {
      return pageNames[CASH_FUND_ACCOUNT];
    }
  },
  [DIRECT_DEPOSIT]: state => {
    const showEnhanced = getGetParam('showEnhanced') === 'true';
    if (showEnhanced) {
      return pageNames[DIRECT_DEPOSIT + '_ENHANCED'];
    } else {
      return pageNames[DIRECT_DEPOSIT];
    }
  },
  [AUTOMATIC_DIRECT_DEPOSIT_LANDING]: state => {
    const showEnhanced = getGetParam('status') === ATOMIC_SDK_OUTCOME_PENDING;
    if (showEnhanced) {
      return AUTOMATIC_DIRECT_DEPOSIT_LANDING_PENDING;
    } else {
      return AUTOMATIC_DIRECT_DEPOSIT_LANDING_SUCCESS;
    }
  },
};

export const setFreezeEvents = (isFreezeEvents, frozenEvents) => {
  return { type: SET_FREEZE_EVENTS, isFreezeEvents, frozenEvents };
};
export const clearFrozenEvents = () => (dispatch, getState) => {
  const triggerSomeEvents = () => {
    setTimeout(() => {
      const currentState = getState();
      const currentFrozenEvents = currentState?.tracking?.frozenEvents;
      const isThereMoreIterations = currentFrozenEvents.length > 5;
      const numEvents =
        currentFrozenEvents.length >= 5 ? 5 : currentFrozenEvents.length;
      const eventsToTrigger = currentFrozenEvents.slice(0, numEvents);

      eventsToTrigger.forEach(e => {
        dispatch(standardEventHandler(e.event, e.attrs));
      });

      if (isThereMoreIterations) {
        const remainingEvents = currentFrozenEvents.slice(
          5,
          currentFrozenEvents.length,
        );
        dispatch(
          setFreezeEvents(
            getState()?.tracking?.isFreezeEvents,
            remainingEvents,
          ),
        );
        triggerSomeEvents();
      } else {
        dispatch(setFreezeEvents(getState()?.tracking?.isFreezeEvents, []));
      }
    }, 50);
  };
  triggerSomeEvents();
};
export const standardEventHandler =
  (event, attrs = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { tracking, sesameCash, userInfo, page } = state;
    if (tracking.isFreezeEvents) {
      dispatch({
        type: STORE_EVENT,
        event: {
          event,
          attrs,
        },
      });
      return;
    }
    const pageNameOverride = {};
    if (typeof dynamicPageNameRegistry[tracking.pageName] === 'function') {
      pageNameOverride.pageNameMeta =
        dynamicPageNameRegistry[tracking.pageName](state);
    }
    let eventContext = Object.assign(
      {},
      { sesameCash },
      userInfo,
      tracking,
      pageNameOverride,
    );
    /*
      TODO: Change the logic using the page state instead of compare manually with windows, last change POD5-1229
      and after that delete isPostPurchaseSignup from logic
    */
    const isSignup = window.location.pathname.toLowerCase().includes('signup');
    const isPostPurchaseSignup = page?.pageName === POST_PREMIUM_LANDING;
    let action = null;
    if (isSignup && !isPostPurchaseSignup) {
      const mixpanelVisitorEventResult = visitorReportEvent(
        event,
        attrs,
        eventContext,
      );
      let mixpanelUnifiedEventResult;
      if (attrs?.forceSendUnified) {
        mixpanelUnifiedEventResult = mixpanelEvent(event, attrs, eventContext);
      }
      action = {
        type: EVENT,
        event,
        attrs,
        mixpanelVisitorEventResult,
        mixpanelUnifiedEventResult,
      };
    } else if (state.app.loggedIn || state.app.ocfLoggedIn) {
      const brazeEventResult = brazeEvent(event, attrs, eventContext);
      const mixpanelUnifiedEventResult = mixpanelEvent(
        event,
        attrs,
        eventContext,
      );

      action = {
        type: EVENT,
        event,
        attrs,
        brazeEventResult,
        mixpanelUnifiedEventResult,
      };
    } else {
      const mixpanelUnifiedEventResult = publicMixpanelTrack(
        event,
        attrs,
        eventContext,
      );
      let mixpanelVisitorEventResult;
      if (attrs?.forceSendVisitor) {
        mixpanelVisitorEventResult = visitorReportEvent(
          event,
          attrs,
          eventContext,
        );
      }
      action = {
        type: EVENT,
        event,
        attrs,
        mixpanelUnifiedEventResult,
        mixpanelVisitorEventResult,
      };
    }

    if (isLoggingToWindow()) {
      if (!window.trackingLog) window.trackingLog = [];
      window.trackingLog.push(action);
    }
    if (getEnvironment() !== TEST) {
      console.log('Event', action);
    }
    return action;
  };
