import React from 'react';
import { NavLink } from 'react-router-dom';
import { CLICK } from 'externals/_tracking/types/eventTypes.constants';
import { MAIN_NAV } from 'externals/_tracking/types/clickEventTypes.constants';

function MrphNavLink({ children, eventHandler, navItem, vertical, ...props }) {
  function trackNavClick() {
    let eventData = {
      'Click Type': MAIN_NAV,
      'Nav Item': navItem,
    };
    if (vertical) {
      eventData.Vertical = vertical;
    }
    eventHandler(CLICK, eventData);
  }

  return (
    <div onClick={() => trackNavClick()}>
      <NavLink {...props}>{children}</NavLink>
    </div>
  );
}

export { MrphNavLink as NavLink };
