export const buildDispatchPooler = c => {
  const actions = [];
  const { getState, poolingAction } = c;

  const fakeDispatcher = action => {
    actions.push(action);

    if (typeof action === 'function') {
      return action(fakeDispatcher, getState);
    } else {
      const cleanAction = Object.assign({}, action, {
        type: poolingAction?.type,
      });
      Object.assign(poolingAction, cleanAction);
    }
  };
  const dispatchPooler = f => {
    if (typeof f === 'function') {
      return f(fakeDispatcher, getState);
    } else {
      if (!f?.type) throw new Error('Objects must be an action with type');
      fakeDispatcher(f);
    }
  };

  return {
    dispatch: dispatchPooler,
    actions,
    poolingAction,
  };
};
