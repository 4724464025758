import React from 'react';
import { useConfig } from 'hook/config.hook';

const FullProgramTerms = ({ href }) => {
  const { config } = useConfig();
  return (
    <>
      <a
        className="bold"
        href={
          href ??
          config?.termsandConditionsModalUrls?.fullProgramTermsDisclaimer
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        &nbsp;full program terms&nbsp;
      </a>
    </>
  );
};

export default FullProgramTerms;
