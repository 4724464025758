import { wrapService } from 'actions/service_wrapper.actions';
import { TIPOFTHEDAY_PAYLOAD } from 'types/payload.constants';
import { isPremium } from 'helpers/userHelper';
import { PREMIUM } from 'types/storage.constants';
import {
  addStorageByAttributeName,
  getStorageByAttributeName,
  updateStorageByAttributeName,
} from 'externals/_services/storage.service';
import { formatDateMMDDYY } from 'helpers/dateHelper';

const a_getStorage = wrapService(getStorageByAttributeName, {
  name: 'getStorageByAttributeName',
});

const a_addStorage = wrapService(addStorageByAttributeName, {
  name: 'addStorageByAttributeName',
});

const a_updateStorage = wrapService(updateStorageByAttributeName, {
  name: 'updateStorageByAttributeName',
});

const tipOfTheDayPayload = (loading, tipInfo) => {
  return {
    type: TIPOFTHEDAY_PAYLOAD,
    loading,
    tipInfo,
  };
};

function request() {
  return tipOfTheDayPayload(true, []);
}

function __success(data) {
  return tipOfTheDayPayload(false, data);
}

function __failure(error) {
  return tipOfTheDayPayload(false, []);
}

export const updateTipOfTheDay = tip => async (dispatch, getState) => {
  dispatch(request());
  try {
    await dispatch(
      a_updateStorage({
        attributeName: PREMIUM,
        attributeValue: JSON.stringify(tip),
      }),
    );
    const retrievedStorage = await dispatch(a_getStorage(PREMIUM));
    if (retrievedStorage.length > 0) {
      dispatch(__success(JSON.parse(retrievedStorage?.[0]?.attributeValue)));
    } else {
      dispatch(__failure());
    }
  } catch (err) {
    dispatch(__failure(err));
    throw err;
  }
};

export const loadTipOfTheDay = userInfo => async (dispatch, getState) => {
  if (isPremium(userInfo)) {
    dispatch(request());
    try {
      const retrievedStorage = await dispatch(a_getStorage(PREMIUM));
      if (retrievedStorage.length > 0) {
        dispatch(__success(JSON.parse(retrievedStorage?.[0]?.attributeValue)));
      } else {
        await dispatch(
          a_addStorage({
            attributeName: PREMIUM,
            attributeValue: JSON.stringify({
              lastSeenTipIndex: 0,
              lastSeenTipDate: formatDateMMDDYY(new Date()),
              tipUsefulArray: [],
            }),
          }),
        );
        const retrievedStorage = await dispatch(a_getStorage(PREMIUM));
        if (retrievedStorage.length > 0) {
          dispatch(
            __success(JSON.parse(retrievedStorage?.[0]?.attributeValue)),
          );
        }
      }
    } catch (err) {
      dispatch(__failure(err));
    }
  }
};
