import {
  FINISH_GOAL_ACTION_REQUEST,
  FINISH_GOALS_LOAD,
  GOALS_ERROR,
  START_GOAL_ACTION_REQUEST,
  START_GOALS_LOAD,
} from '../types/goals.constants';
import { LOGOUT } from '../types/app.constants';
import { PAYLOAD } from '../types/payload.constants';

const initialState = {
  goalsLoading: false,
  goalsLoaded: false,
  goalActionLoading: false,
  goalActionLoaded: false,
  goals: null,
  goalAction: null,
  goalsError: null,
};

export default function goalsReducer(state = initialState, action) {
  switch (action.type) {
    case START_GOALS_LOAD:
      return Object.assign({}, state, {
        goalsLoading: true,
        goalsLoaded: false,
        goals: null,
        goalsError: null,
      });
    case FINISH_GOALS_LOAD:
    case PAYLOAD:
      if (action.goals && !state.goals) {
        return Object.assign({}, state, {
          goalsLoading: false,
          goalsLoaded: true,
          goals: action.goals,
        });
      }
      return state;
    case GOALS_ERROR:
      return Object.assign({}, state, {
        goalsLoading: false,
        goalsLoaded: false,
        goalsError: action.error,
      });
    case START_GOAL_ACTION_REQUEST:
      return Object.assign({}, state, {
        goalActionLoading: true,
        goalActionLoaded: false,
        goalAction: null,
        goalsError: null,
      });
    case FINISH_GOAL_ACTION_REQUEST:
      return Object.assign({}, state, {
        goalActionLoading: false,
        goalActionLoaded: true,
        goalAction: action.goalAction,
      });
    case LOGOUT:
      return Object.assign({}, initialState);
    default:
      return state;
  }
}
