import {
  buildFetchOptionsWithHeaders,
  convertObjectToQueryString,
  standardizedFetchHandler
} from "./service_helpers";

export const fetchApplyNowTracking = async (
  url,
  queryOptions,
  queryStringInitialized,
) => {
  const opt = buildFetchOptionsWithHeaders();

  const queryString = convertObjectToQueryString(
    queryOptions,
    queryStringInitialized,
  );

  return standardizedFetchHandler(`${url}${queryString}`, opt);
};
