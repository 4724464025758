/* global CSMxpTracker */

import { getReferredBy } from 'helpers/localstorage';
import { getMixpanelEventName, getMixpanelMeta } from './mixpanel.helper';
import { mixpanelEvents } from 'externals/_tracking/mixpanel/Mixpanel.events.config';
import { getPublicMetadata } from 'helpers/tracking.helpers';
import { mixpanelTrack } from 'externals/_tracking/mixpanel/mixpanel.api';
import { buildBaselineMixpanelAttributes } from 'externals/_tracking/mixpanel/mixpanelLocalStorageReader';
import { getCookieValue } from 'helpers/cookie.helper';
import { VIEW_PAGE } from '../types/eventTypes.constants';

export const aliasVisitorUser = userId => {
  window.mixpanel.alias(userId);
};

export const identifyVisitorUser = userId => {
  try {
    window.mixpanel.identify(userId);
  } catch (err) {
    return err;
  }
};

export const identifyUnifiedUser = userId => {
  try {
    window.mixpanel.unified.identify(userId);
  } catch (err) {
    return err;
  }
};

export const trackMixpanelEvent = (eventName, eventAttrs) => {
  const trackingAttributes = {
    ...eventAttrs,
    ...buildBaselineMixpanelAttributes(),
  };

  mixpanelTrack(eventName, trackingAttributes);
};

export const visitorReportPageView = (page, eventAttributes, extraProps) => {
  if (typeof CSMxpTracker !== 'undefined') {
    const finalExtraAttrs = buildBaselineMixpanelAttributes(
      Object.assign(
        {
          'Page Name': page,
          'Referred By': getReferredBy(),
          trk: getCookieValue('trk'),
          partnerSubId: getCookieValue('subSrc'),
          txId: getCookieValue('tune_tid'),
        },
        eventAttributes,
      ),
    );
    CSMxpTracker.setPageName(page).trackEvent('View Page', finalExtraAttrs);
    if (extraProps?.isUnifiedBucket) {
      mixpanelTrack('View Page', finalExtraAttrs);
    }
  } else {
    return {
      success: false,
      err: { code: 'MIXPANEL_06', message: 'Mixpanel not found on window' },
    };
  }
};

export const visitorSetPageName = pageName => {
  if (typeof CSMxpTracker !== 'undefined') {
    CSMxpTracker.setPageName(pageName);
  } else {
    return {
      success: false,
      err: { code: 'MIXPANEL_06', message: 'Mixpanel not found on window' },
    };
  }
};

export const visitorReportError = function (errorType, errorCode, logger) {
  let additionalParams = { errorType: errorType };
  if (errorCode) {
    additionalParams['code'] = errorCode;
  }
  logger.reportAPIError(additionalParams);
};

export const visitorReportEvent = function (
  event,
  additionalParams,
  eventContext,
) {
  if (typeof CSMxpTracker !== 'undefined') {
    let finalEvent = getMixpanelEventName(event)
      ? getMixpanelEventName(event)
      : event;
    let finalAttributes = buildBaselineMixpanelAttributes(
      Object.assign(
        {},
        { 'Logged In': 'No', 'Referred By': getReferredBy() },
        additionalParams,
      ),
    );

    if (additionalParams?.forceSendVisitor && !additionalParams['Page Name']) {
      finalAttributes['Page Name'] = eventContext?.pageNameMeta;
    }

    if (event === VIEW_PAGE) {
      CSMxpTracker.setPageName(finalAttributes['Page Name']).trackEvent(
        finalEvent,
        finalAttributes,
      );
    } else {
      CSMxpTracker.trackEvent(finalEvent, finalAttributes);
    }
    return {
      success: true,
      name: finalEvent,
      attributes: finalAttributes,
    };
  } else {
    return {
      success: false,
      err: { code: 'MIXPANEL_05', message: 'Mixpanel not found on window' },
    };
  }
};

function sendMixpanelEvent(event, eventData, eventContext) {
  let eventName = getMixpanelEventName(event);
  let eventMetaData = getMixpanelMeta(event, eventData, eventContext);

  //send the mixpanel event
  mixpanelTrack(eventName, eventMetaData);

  return {
    success: true,
    name: eventName,
    attributes: eventMetaData,
  };
}

export function mixpanelEvent(event, eventData, eventContext) {
  try {
    if (mixpanelEvents[event]) {
      return sendMixpanelEvent(event, eventData, eventContext);
    } else {
      return {
        success: false,
        err: {
          code: 'MIXPANEL_02',
          message: 'Invalid secure event to trigger',
        },
      };
    }
  } catch (e) {
    return {
      success: false,
      err: {
        code: 'MIXPANEL_04',
        message: 'Error Happened while attempting to send:' + e,
      },
    };
  }
}

export function publicMixpanelTrack(event, eventData, eventContext) {
  try {
    if (mixpanelEvents[event]) {
      const { pageNameMeta } = eventContext;

      const publicAttrs = getPublicMetadata(eventData, {
        'Page Name': pageNameMeta,
      });
      const eventName = getMixpanelEventName(event);

      return mixpanelTrack(eventName, publicAttrs);
    } else {
      return {
        success: false,
        err: {
          code: 'MIXPANEL_01',
          message: 'Invalid public event to trigger',
        },
      };
    }
  } catch (e) {
    return {
      success: false,
      err: {
        code: 'MIXPANEL_03',
        message: 'Error Happened while attempting to send:' + e,
      },
    };
  }
}
