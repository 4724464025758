import { combineReducers } from 'redux';

import profileOverview from 'pages/Profile/pages/ProfileOverview/reducers/profileOverview.reducer';
import affiliateSignup from 'pages/SignupPage/AffiliateSignup/AffiliateSignup.reducer';
import dmCampaign from 'pages/SignupPage/DMCampaign/DMCampaign.reducer';
import oneClickSignup from 'pages/SignupPage/OneClickSignup/OneClickSignup.reducer';
import prefilledSignup from 'pages/SignupPage/PrefilledSignup/PrefilledSignup.reducer';
import signup from 'pages/SignupPage/reducers/signup.reducer';
import alertSettings from 'reducers/alertSettings.reducer';
import csidAlerts from 'reducers/csidAlerts.reducer';
import disputes from 'reducers/disputes.reducer';
import highlightFeatures from 'reducers/highlightFeatures.reducer';
import accountLink from 'reducers/accountLink.reducer';
import bankTransfer from 'reducers/bankTransfer.reducer';
import sesameCash from 'reducers/sesameCash.reducer';
import changeEmail from '../pages/Public/ChangeEmail/ChangeEmail.reducer';
import forgotEmail from '../pages/Public/PasswordReset/pages/ForgotMyEmail/ForgotMyEmail.reducer';
import forgotPassword from '../pages/Public/PasswordReset/pages/ForgotMyPassword/ForgotMyPassword.reducer';
import needAccessEmail from '../pages/Public/PasswordReset/pages/NeedAccessEmail/NeedAccessEmail.reducer';
import needAccessPhone from '../pages/Public/PasswordReset/pages/NeedAccessPhone/NeedAccessPhone.reducer';
import twofactor from '../partial/TwoFactorModal/TwoFactor.reducer';
import actions from '../reducers/actions.reducer';
import app from '../reducers/app.reducer';
import cardCategories from '../reducers/cardCategories.reducer';
import cashEnrollmentState from '../reducers/cashEnrollmentState.reducer';
import checkout from '../reducers/checkout.reducer';
import cmAlerts from '../reducers/cmAlerts.reducer';
import config from '../reducers/config.reducer';
import creditInfo from '../reducers/creditInfo.reducer';
import creditReport from '../reducers/creditReport.reducer';
import datacache from '../reducers/datacache.reducer';
import featuredAd from '../reducers/featuredAd.reducer';
import goals from '../reducers/goals.reducer';
import idProtection from '../reducers/idProtection.reducer';
import notification from '../reducers/notification.reducer';
import notificationsAlerts from '../reducers/notificationsAlerts.reducer';
import ocfInfo from '../reducers/ocfInfo.reducer';
import offerDetails from '../reducers/offerDetails.reducer';
import offerState from '../reducers/offerState.reducer';
import oneClickApplication from '../reducers/oneClickApplication.reducer';
import page from '../reducers/page.reducer';
import premium from '../reducers/premium.reducer';
import product from '../reducers/product.reducer';
import rentReporting from '../reducers/rentReporting.reducer';
import subscriptionInfo from '../reducers/subscription.reducer';
import profileContainer from '../reducers/profileContainer.reducer';
import paymentHistoryContainer from '../reducers/paymentHistoryContainer.reducer';
import potentialCreditProfileContainer from '../reducers/potentialCreditProfileContainer.reducer';
import creditManagerContainer from '../reducers/creditManagerContainer.reducer';
import creditReportTabContainer from '../reducers/creditReportTabContainer.reducer';
import creditUsageContainer from '../reducers/creditUsageContainer.reducer';
import accountMixContainer from '../reducers/accountMixContainer.reducer';
import creditAgeContainer from '../reducers/creditAgeContainer.reducer';
import creditInquiriesContainer from '../reducers/creditInquiriesContainer.reducer';
import todayContainer from '../reducers/todayContainer.reducer';
import thinFile from '../reducers/thinFile.reducer';
import tipOfTheDay from '../reducers/tipOfTheDay.reducer';
import tracking from '../reducers/tracking.reducer';
import userGoals from '../reducers/userGoals.reducer';
import userInfo from '../reducers/userInfo.reducer';
import score1b3bFactorDial from '../reducers/score1b3bFactorDial.reducer';
import hybridFlows from '../reducers/hybridFlows.reducer';
import offersMarketplace from '../reducers/offersMarketplace.reducer';

const rootReducer = combineReducers({
  actions,
  alertSettings,
  app,
  changeEmail,
  forgotPassword,
  forgotEmail,
  needAccessEmail,
  needAccessPhone,
  checkout,
  cmAlerts,
  config,
  cashEnrollmentState,
  csidAlerts,
  creditInfo,
  datacache,
  featuredAd,
  highlightFeatures,
  offerState,
  idProtection,
  notification,
  ocfInfo,
  page,
  accountLink,
  bankTransfer,
  product,
  profileOverview,
  rentReporting,
  sesameCash,
  signup,
  oneClickSignup,
  dmCampaign,
  prefilledSignup,
  affiliateSignup,
  tracking,
  twofactor,
  userInfo,
  userGoals,
  tipOfTheDay,
  thinFile,
  premium,
  subscriptionInfo,
  cardCategories,
  goals,
  notificationsAlerts,
  creditReport,
  disputes,
  oneClickApplication,
  profileContainer,
  todayContainer,
  offerDetails,
  paymentHistoryContainer,
  creditUsageContainer,
  accountMixContainer,
  creditInquiriesContainer,
  creditAgeContainer,
  potentialCreditProfileContainer,
  creditManagerContainer,
  creditReportTabContainer,
  score1b3bFactorDial,
  hybridFlows,
  offersMarketplace,
});

export default rootReducer;
