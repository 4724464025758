import moment from 'moment';

export const poll = (checkerFunction, timeout = 30000, interval = 30) => {
  return new Promise(function (resolve, reject) {
    const start = moment();
    const checkAndResolve__maybe = () => {
      const now = moment();

      try {
        const result = checkerFunction();
        if (result) {
          const { activeVariation } = result;
          return resolve(activeVariation ? activeVariation : true);
        } else if (now.diff(start) > timeout) {
          return reject();
        } else setTimeout(checkAndResolve__maybe, interval);
      } catch (e) {
        reject(e);
      }
    };

    checkAndResolve__maybe();
  });
};
