/*
    These are all the event types that I've noted for braze and mixpanel.
    These are the valid types for trackEvent
*/

export const LOGIN = `LOGIN`;
export const REFRESH_CREDIT_PROFILE = `REFRESH_CREDIT_PROFILE`;
export const REGISTER = `REGISTER`;
export const SIGNUP_FINISH = `SIGNUP_FINISH`;
export const VIEW_PAGE = `VIEW_PAGE`;
export const VIEW_OFFER = `VIEW_OFFER`;
export const CLICK = `CLICK`;
export const CLICK_APPLY = `CLICK_APPLY`;
export const CLICK_PREQUAL = `CLICK_PREQUAL`;
export const CLICK_REVIEWS = `CLICK_REVIEWS`;
export const COMPLETED = `COMPLETED`;
export const HOSTED_APP_SUBMISSION = `HOSTED_APP_SUBMISSION`;
export const INTERNAL_EMAIL_REDIRECT = 'INTERNAL_EMAIL_REDIRECT';
export const SEEN_OFFER = 'SEEN_OFFER';
export const VIEW_ACTIONS = 'VIEW_ACTIONS';
export const SEEN_ACTIONS = 'SEEN_ACTIONS';
export const VIEW_ALERT = 'VIEW_ALERT';
export const VIEW_MODAL = 'VIEW_MODAL';
export const VIEW_MODULE = 'VIEW_MODULE';
export const SEEN_MODULE = 'SEEN_MODULE';
export const SEEN_NOTIFICATION = 'SEEN_NOTIFICATION';
export const VIEW_POPUP = 'VIEW_POPUP';
export const DISMISS_MODAL = 'DISMISS_MODAL';
export const LOGOUT = 'LOGOUT';
export const ERROR_API = 'ERROR_API';
export const DATA_ERROR = 'DATA_ERROR';
export const ERROR = 'ERROR';
export const SESAME_CASH_ENROLL = 'SESAME_CASH_ENROLL';
export const SESAME_CASH_ENROLL_COMPLETE = 'SESAME_CASH_ENROLL_COMPLETE';
export const CREDIT_BUILDER_ENROLL_COMPLETE = 'CREDIT_BUILDER_ENROLL_COMPLETE';
export const SUBSCRIPTION_PURCHASE_RESULT = 'SUBSCRIPTION_PURCHASE_RESULT';
export const LOGIN_FAILED = `LOGIN_FAILED`;
export const TU_PROFILE_REFRESH_FAILED = `TU_PROFILE_REFRESH_FAILED`;
export const SIGNUP_PRIMARY_ADDRESS_FAILED = `SIGNUP_PRIMARY_ADDRESS_FAILED`;
export const SIGNUP_ACCOUNT_CREATED = `SIGNUP_ACCOUNT_CREATED`;
