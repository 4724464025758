import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT } from 'types/app.constants';
import { affiliateSignupConstants as signupConstants } from './AffiliateSignup.constants';

const initialState = {
  loading: false,
  decryptionLoading: true,
  affiliateData: null,
  step: 'AFF_SIGNUP_DOB_SSN_FORM',
  addressValidated: false,
  tempPassword: null,
  isErrorPath: false,
};

const affiliateSignupReducer = (
  state = Object.assign({}, initialState),
  action,
) => {
  switch (action.type) {
    case signupConstants.LOADING_START:
      return Object.assign({}, state, {
        loading: true,
      });
    case LOGIN_FAILURE:
    case signupConstants.LOADING_FINISH:
      return Object.assign({}, state, {
        loading: false,
      });
    case signupConstants.DECRYPTION_LOADING_START:
      return Object.assign({}, state, {
        decryptionLoading: true,
      });
    case signupConstants.DECRYPTION_LOADING_FINISH:
      return Object.assign({}, state, {
        decryptionLoading: false,
      });
    case signupConstants.ENABLE_ERROR_PATH:
      return Object.assign({}, state, {
        isErrorPath: true,
      });
    case signupConstants.CLEAR_TU_ERROR:
      return Object.assign({}, state, {
        errorTU: null,
        errorSectionTU: null,
      });
    case signupConstants.UPDATE_ADDRESS_FAIL:
      return Object.assign({}, state, {
        addressValidated: action.addressValidated,
      });
    case signupConstants.DECRYPT_DATA_SUCCESS:
      return Object.assign({}, state, {
        affiliateData: action.affiliateData,
      });
    case signupConstants.CREATE_USER_SUCCESS:
    case signupConstants.UPDATE_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        tempPassword: action.tempPassword,
      });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        needLogin: false,
      });
    case signupConstants.SET_SIGNUP_STEP:
      return Object.assign({}, state, {
        step: action.step,
      });
    case signupConstants.REPORT_TU_ERROR:
      return Object.assign({}, state, {
        errorTU: action.errorTU,
        errorSectionTU: action.errorSectionTU,
      });
    case signupConstants.CLEAR_AFFILIATE_SIGNUP_STATE:
    case LOGOUT:
      return Object.assign({}, initialState);
    default:
      return state;
  }
};

export default affiliateSignupReducer;
