import { mergePayloadChanges } from 'helpers/reducer.helper';
import { PAYLOAD } from 'types/payload.constants';
import {
  USER_GOALS_SET,
  USER_GOALS_LOADING,
  USER_GOALS_LOADING_FAILED,
} from 'types/userGoals.constants';

const intialState = {
  userGoalsLoading: false,
  userGoalsError: null,
  userGoals: null,
};

const userGoalsReducer = function (state = intialState, action) {
  switch (action.type) {
    case PAYLOAD:
      return mergePayloadChanges(state, action);
    case USER_GOALS_LOADING:
      return Object.assign({}, intialState, {
        loading: true,
      });
    case USER_GOALS_SET:
      return Object.assign({}, intialState, {
        loading: false,
        questionnaire: action.data,
      });
    case USER_GOALS_LOADING_FAILED:
      return Object.assign({}, intialState, {
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

export default userGoalsReducer;
