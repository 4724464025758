import {
  SET_CHANGE_EMAIL_STEP,
  SUBMIT_FORM_SUCCESS,
  SUBMIT_FORM_ERROR,
  SET_CODE_VALUE,
  VERIFY_PHONE_SUCCESS,
  VERIFY_PHONE_ERROR,
  CLEAR_VERIFY_PHONE_ERROR,
  RESEND_CODE_SUCCESS,
  RESEND_CODE_ERROR,
  VERIFY_IDENTITY_SUCCESS,
  VERIFY_IDENTITY_ERROR,
  CLEAR_CHANGE_EMAIL_STATE,
} from 'pages/Public/ChangeEmail/ChangeEmailView.constants';

const initialState = {
  step: 'CHANGE_EMAIL_FORM',

  newEmail: '',
  lastDigitsPhoneNumber: '',
  token: null,
  formApiError: null,
  formErrorMessage: null,
  formAttempts: 0,

  contactPhoneNumber: '',
  phoneErrorMessage: null,
  codeValue: '',
  codeAttempts: 0,
  verificationMessage: {},
  jwt2FAToken: null,
};

const changeEmailReducer = (
  state = Object.assign({}, initialState),
  action,
) => {
  switch (action.type) {
    case SET_CHANGE_EMAIL_STEP:
      return Object.assign({}, state, {
        step: action.step,
      });
    case SUBMIT_FORM_SUCCESS:
      return Object.assign({}, state, {
        lastDigitsPhoneNumber: action.lastDigitsPhoneNumber,
        token: action.token,
        newEmail: action.newEmail,
      });
    case SUBMIT_FORM_ERROR:
      return Object.assign({}, state, {
        formApiError: action.formApiError,
        formErrorMessage: action.formErrorMessage,
        formAttempts: state.formAttempts + 1,
      });
    case VERIFY_PHONE_SUCCESS:
      return Object.assign({}, state, {
        contactPhoneNumber: action.contactPhoneNumber,
      });
    case VERIFY_PHONE_ERROR:
      return Object.assign({}, state, {
        phoneErrorMessage: action.phoneErrorMessage,
      });
    case CLEAR_VERIFY_PHONE_ERROR:
      return Object.assign({}, state, {
        phoneErrorMessage: null,
      });
    case SET_CODE_VALUE:
      return Object.assign({}, state, {
        codeValue: action.codeValue,
      });
    case RESEND_CODE_SUCCESS:
      return Object.assign({}, state, {
        verificationMessage: action.verificationMessage,
      });
    case RESEND_CODE_ERROR:
      return Object.assign({}, state, {
        verificationMessage: action.verificationMessage,
      });
    case VERIFY_IDENTITY_SUCCESS:
      return Object.assign({}, state, {
        jwt2FAToken: action.jwt2FAToken,
      });
    case VERIFY_IDENTITY_ERROR:
      return Object.assign({}, state, {
        verificationMessage: action.verificationMessage,
        codeAttempts: state.codeAttempts + 1,
      });
    case CLEAR_CHANGE_EMAIL_STATE:
      return Object.assign({}, initialState);
    default:
      return state;
  }
};

export default changeEmailReducer;
