import { OptimizelyContext } from 'hoc/optimizely.hoc';
import { useState, useEffect, useContext } from 'react';

export const useVariationForExperiment = (
  experimentName,
  condition,
  timeout,
) => {
  const optimizelyContext = useContext(OptimizelyContext);
  const validCondition = condition || condition === undefined;
  const [localVariation, setLocalVariation] = useState(null);

  const contextVariation = optimizelyContext.getVariationKey(experimentName);

  useEffect(() => {
    setTimeout(_ => {
      setLocalVariation('TIMEDOUT');
    }, 150);
  }, [
    localVariation,
    validCondition,
    timeout,
    optimizelyContext?.consolidatePolling,
  ]);

  return {
    loading: !contextVariation && !(localVariation === 'TIMEDOUT'),
    experimentName,
    variation: contextVariation,
  };
};
