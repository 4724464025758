export const REQUIRE_2FA = 'REQUIRE_2FA';
export const DISABLE_2FA = 'DISABLE_2FA';
export const START_DISABLE = 'START_DISABLE';
export const COMPLETE_DISABLE = 'COMPLETE_DISABLE';
export const FAILURE_DISABLE = 'FAILURE_DISABLE';
export const CLEAR_2FA_MODAL = 'CLEAR_2FA_MODAL';
export const COMPLETE_2FA_SUCCESSFULLY = 'COMPLETE_2FA_SUCCESSFULLY';
export const STOP_2FA_PROCESS = 'STOP_2FA_PROCESS';

export const DEVICE_LOAD = 'DEVICE_LOAD';
export const DEVICE_LOAD_SUCCESS = 'DEVICE_LOAD_SUCCESS';
export const DEVICE_LOAD_ERROR = 'DEVICE_LOAD_ERROR';

export const SET_TWO_FACTOR_STEP = 'SET_TWO_FACTOR_STEP';

export const REQUEST_2FA_SECURITY_CODE = 'REQUEST_2FA_SECURITY_CODE';

export const SET_TWO_FACTOR_CODE_VALUE = 'SET_TWO_FACTOR_CODE_VALUE';
export const SELECT_RESEND_OPTION = 'SELECT_RESEND_OPTION';

export const VERIFY_SSN = 'VERIFY_SSN';
export const CLEAN_SSN_ERROR = 'CLEAN_SSN_ERROR';
export const VERIFY_OTP_ERROR = 'VERIFY_OTP_ERROR';
export const SET_TWO_FACTOR_SHOW_NOTIFICATION =
  'SET_TWO_FACTOR_SHOW_NOTIFICATION';
export const RESET_TWO_FACTOR_SHOW_NOTIFICATION =
  'RESET_TWO_FACTOR_SHOW_NOTIFICATION';
