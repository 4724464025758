import { getUserQuestionaire } from 'externals/_services/questionaire.service';
import { buildPayloadDispatcher } from 'types/payload.constants';

import {
  USER_GOALS_LOADING,
  USER_GOALS_LOADING_FAILED,
} from 'types/userGoals.constants';

import { wrapService } from 'actions/service_wrapper.actions';

const a_getUserQuestionaire = wrapService(getUserQuestionaire, {
  name: 'getUserQuestionaire',
});

const __loading = _ => {
  return {
    type: USER_GOALS_LOADING,
  };
};

const __failed = payload => {
  return {
    type: USER_GOALS_LOADING_FAILED,
    ...payload,
  };
};

const payload = buildPayloadDispatcher('userGoals');

export const __success = p => {
  return payload(p, false, null);
};

export const loadUserQuestionaire = _ => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch(__loading());
    dispatch(a_getUserQuestionaire())
      .then(data => {
        dispatch(payload(data, false, null));
        resolve({ ...data });
      })
      .catch(err => {
        dispatch(
          __failed({
            error: err.message,
          }),
        );
        reject(err);
      });
  });
};
