import { brazeEventNames } from 'externals/_tracking/braze/Braze.events.config';
import * as eventTypes from 'externals/_tracking/types/eventTypes.constants';
import {
  isValidBrazeClickEvent,
  expandBrazeClickType,
  getBrazeEventName,
  getBrazeMeta,
} from './Braze.helper';
import { initBraze, brazeEventTrack, startBrazeSession } from './Braze.api';
import { buildStandardException } from 'helpers/error.helper';

function sendBrazeEvent(event, eventData, eventContext) {
  let eventName = ``;
  let eventMetadataKey = event;

  if (event === eventTypes.CLICK) {
    const clickType = eventData['Click Type'];
    //check if this click type is the kind we want to track in braze
    if (isValidBrazeClickEvent(clickType, eventData)) {
      const clickEvent = expandBrazeClickType(clickType, eventData);
      if (clickEvent) eventMetadataKey = clickEvent;
      eventName = getBrazeEventName(clickEvent, eventData, eventContext);
    } else {
      return {
        success: false,
        err: buildStandardException({
          code: 'BRAZE_02',
          message: 'Invalid click event to trigger',
        }),
      };
    } // if click type is not a braze type, return and do not send event.
  } else {
    eventName = getBrazeEventName(event, eventData, eventContext);
  }

  const eventMetaData = getBrazeMeta(eventMetadataKey, eventData, eventContext);

  //send the braze event
  const result = brazeEventTrack(eventName, eventMetaData);

  return {
    ...result,
    name: eventName,
    attributes: eventMetaData,
  };
}

function brazeEvent(event, additionalData, eventContext) {
  const finalAdditionalData = additionalData ? additionalData : {};
  if (brazeEventNames[event] && !finalAdditionalData.__no_braze) {
    return sendBrazeEvent(event, finalAdditionalData, eventContext);
  } else {
    return {
      success: false,
      err: buildStandardException({
        code: 'BRAZE_01',
        message: 'Invalid event to trigger',
      }),
    };
  }
}

export { initBraze, brazeEventTrack, startBrazeSession, brazeEvent };
