import { LOGOUT } from 'types/app.constants';
import {
  SELECT_TODAY_CONTAINER_DATA,
  RESET_TODAY_CONTAINER_DATA,
} from 'types/todayContainer.constants';

const initialState = {
  todayContainer: null,
  containerLoading: false,
  containerError: null,
};

export default function todayContainerReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_TODAY_CONTAINER_DATA: {
      return {
        ...state,
        todayContainer: action?.todayContainer,
        containerLoading: action?.containerLoading,
        containerError: action?.containerError,
      };
    }
    case LOGOUT:
    case RESET_TODAY_CONTAINER_DATA:
      return initialState;
    default:
      return state;
  }
}
