import {
  SETTINGS_ALERTS_REQUEST,
  SETTINGS_ALERTS_SUCCESS,
  SETTINGS_ALERTS_FAILURE,
} from 'types/alertSettings.constants';
import { LOGOUT } from 'types/app.constants';
import { PAYLOAD } from 'types/payload.constants';
import { mergePayloadChanges } from 'helpers/reducer.helper';

const initialState = {
  alertSettings: null,
  loading: null,
  error: null,
};

export default function alertSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case SETTINGS_ALERTS_REQUEST:
      return Object.assign({}, initialState, { loading: true });
    case PAYLOAD:
      return mergePayloadChanges(state, action);
    case SETTINGS_ALERTS_SUCCESS:
      return Object.assign({}, initialState, {
        alertSettings: action.settings,
        loading: false,
        error: false,
      });
    case SETTINGS_ALERTS_FAILURE:
      return Object.assign({}, initialState, {
        error: action.error,
        loading: false,
      });
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
