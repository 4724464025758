export const getKeys = key => ({
  key,
  loading: key + '_LOADING',
  error: key + '_ERROR',
});

export const HOME_PAGE_CARD_MODULES = 'HOME_PAGE_CARD_MODULES';

export const START_LOADING = 'cach@@/START_LOADING';
export const LOAD_SUCCESS = 'cach@@/LOAD_SUCCESS';
export const LOAD_FAILURE = 'cach@@/LOAD_FAILURE';
export const CLEAR_CACHE = 'cach@@/CLEAR_CACHE';