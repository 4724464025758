/* Approved event names for braze events */

export const brazeEventNames = {
  LOGIN: `Log In`,
  REFRESH_CREDIT_PROFILE: `Refresh Credit Profile`,
  REGISTER: `Register`,
  VIEW_PAGE: `View Page`,
  VIEW_MODAL: 'View Pop Up',
  CLICK: `Click`,
  CLICK_APPLY: `Click Apply`,
  CLICK_TRANSFER_MONEY: 'Transfer Money',
  CHECKOUT_BUY_NOW_PREMIUM: 'Buy Now Premium',
  VIEW_POPUP: 'View Pop Up',
  DISMISS_POPUP: 'Dismiss Pop Up',
};

export const brazeClickEvents = {
  'Transfer Money': 'Transfer Money',
  'Buy Now Premium': 'Buy Now Premium',
};

/* Event properties for braze */
export const brazeEventProps = {
  LOGIN: [],
  REFRESH_CREDIT_PROFILE: [
    'Band',
    'Number of Points Change in Score',
    'CC Debt',
    'Old CC Debt',
    'Credit Utilization Ratio',
    'Old Credit Utilization Ratio',
    'Total Debt',
    'Old Total Debt',
    'Credit Score Band',
    'Old Credit Score Band',
    'Credit Usage Grade',
    'Old Credit Usage Grade',
    'Payment History Grade',
    'Old Payment History Grade',
    'Change in # of Inquiries',
    'Change in # of Collections',
    'Change in # of Negative Marks',
  ],
  VIEW_PAGE_DETAILS: ['Approval Odds', 'Partner', 'Card Name', 'Vertical'],
  CLICK_APPLY: [
    'Product Key',
    'Page Name',
    'Page Position',
    'Vertical',
    'Approval Odds',
    'Approval Odds',
    'Predicted Credit Limit',
  ],
  VIEW_MODAL: [
    'Product Key',
    'Page Name',
    'Page Position',
    'Vertical',
    'Approval Odds',
    'Approval Odds',
    'Predicted Credit Limit',
  ],
  CLICK_TRANSFER_MONEY: ['Pop Up Name'],
  CHECKOUT_BUY_NOW_PREMIUM: ['Page Name'],
  VIEW_POPUP: ['Pop Up Name'],
  DISMISS_POPUP: ['Pop Up Name', 'Page Name', 'Click Title'],
};
