import React from 'react';
import { RouteContainer } from 'components/Containers/RouteContainer/RouteContainer';
import PageContainer from 'components/Containers/PageContainer/index';
import { TitleContainer } from 'components/Containers/TitleContainer/TitleContainer';
import Label from 'components/form/Label/Label';
import { Row } from 'components/Containers/Row/Row';
import { Footer } from 'components/Footer/Footer';
import { FlexContainer } from 'components/Containers/FlexContainer/FlexContainer';
import classnames from 'classnames';

import BodyContainer from 'components/Containers/BodyContainer/index';
import '../SignupError.scss';
import HelmetContainer from 'components/HelmetContainer/HelmetContainer';
import { useConfig } from 'hook/config.hook';

export const ErrorTemplate = props => {
  const { config } = useConfig();
  let finalClass = classnames('ErrorTemplate', props.className);
  return (
    <RouteContainer type="public" className="bgWhite errorPage">
      <HelmetContainer title={'Signup Error'} />
      <BodyContainer type="signup">
        <PageContainer type="signup" className={finalClass}>
          <FlexContainer className="flex-column align-items-center">
            <FlexContainer
              className="Error flex-row align-content-center"
              style={{ width: '100%', height: '100%' }}
            >
              <Row fullWidth>
                <img
                  className="errormask-image margT16"
                  alt="illustration"
                  src={props.imgSrc}
                />
                <TitleContainer
                  className={props.centerAlign ? 'text-center' : 'text-left'}
                >
                  <Label type="H1" typeHeader={'h1'}>
                    {props.title}
                  </Label>
                </TitleContainer>
                <div
                  className={props.centerAlign ? 'text-center' : 'text-left'}
                >
                  {props.children}
                </div>
              </Row>
            </FlexContainer>
          </FlexContainer>
        </PageContainer>
        <div className="footer-container">
          <Footer
            datatestid="Footer"
            hideLinks={!config?.signUpError?.showFooterLinks}
          />
        </div>
      </BodyContainer>
    </RouteContainer>
  );
};
