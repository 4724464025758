/* global webengage */
import { createLogger } from 'helpers/logger';
import {
  getCreditLimitShiftSinceRefresh,
  getCUShiftSinceRefresh,
  getCUShiftSinceSignup,
  getCreditScoreBandI,
  getCreditScoreBandIII,
  getCSShiftSinceSignup,
  getCSShiftSinceRefresh,
  resolveBooleanToYesOrNo,
  __monthSinceTradelineWithNameMatch,
  getShiftInInquiries,
  getLastCharacter,
  getLast3Character,
  isUserEnrolledInCash,
} from 'helpers/tracking.helpers';
import { getUsage } from 'helpers/calculations';
import {
  getDaysSinceWithNull,
  getDaysSinceWithNullAs,
} from 'helpers/dateHelper';
import { getRefCode } from 'helpers/localstorage';
import { getWhatsChanged } from 'helpers/credit_info';
import { isPremium } from 'helpers/userHelper';

const logger = createLogger({
  name: 'webengage.service',
});

const checkIfWebEngageIsInitialized = () => {
  return window.webengage && window.webengage.survey && window.webengage.user;
};

export const trackWebEngageSurvey = nameOfSurvey => {
  if (checkIfWebEngageIsInitialized) {
    return window.webengage.track(nameOfSurvey);
  }
};

export const setSurveyAttributes = state => {
  const { userInfo, creditInfo } = state;
  if (!userInfo || !creditInfo) return {};

  const isFunded = userInfo.banking ? userInfo.banking.accountFunded : false;
  const creditTrends = creditInfo.trends;
  const monthsSinceAuto = __monthSinceTradelineWithNameMatch(
    creditInfo.debtAnalysis.autoLoanDebt.accounts,
  );
  const monthsSinceHome = __monthSinceTradelineWithNameMatch(
    creditInfo.debtAnalysis.homeLoanDebt.accounts,
  );
  const monthsSinceCreditCard = __monthSinceTradelineWithNameMatch(
    creditInfo.debtAnalysis.creditCardDebt.accounts,
  );
  const monthsSinceOther = __monthSinceTradelineWithNameMatch(
    creditInfo.debtAnalysis.otherLoanDebt.accounts,
  );
  const monthsSinceAny = Math.min(
    monthsSinceAuto,
    monthsSinceHome,
    monthsSinceCreditCard,
    monthsSinceOther,
  );
  const whatsChanged = getWhatsChanged(creditInfo);

  if (checkIfWebEngageIsInitialized()) {
    try {
      const webengageData = {
        'Account Last Digit': getLastCharacter(String(userInfo.accountId)),
        'Account Last Three Digits': getLast3Character(
          String(userInfo.accountId),
        ),
        'Cash Enroll Status': isUserEnrolledInCash(userInfo),
        City: userInfo.primaryAddress.city,
        'Closed Collections': creditInfo.totalClosedCollectionAccounts,
        Collections: creditInfo.creditScoreAnalysis.paymentHistory.collections,
        'Credit Limit': creditInfo.creditScoreAnalysis.creditUsage.limit,
        'Credit Limit Shift Since Refresh':
          getCreditLimitShiftSinceRefresh(creditTrends),
        'Credit Score': creditInfo.creditScore,
        'Credit Usage Percent': getUsage(
          creditInfo.creditScoreAnalysis.creditUsage.debt,
          creditInfo.creditScoreAnalysis.creditUsage.limit,
        ),
        'Credit Usage Shift Since Refresh':
          getCUShiftSinceRefresh(creditTrends),
        'Credit Usage Shift Since Sign Up': getCUShiftSinceSignup(creditTrends),
        'CS Band I': getCreditScoreBandI(creditInfo.creditScore),
        'CS Band III': getCreditScoreBandIII(creditInfo.creditScore),
        'CS Shift Since Refresh': getCSShiftSinceRefresh(creditTrends),
        'CS Shift Since Sign Up': getCSShiftSinceSignup(creditTrends),
        'Days Since Email Engagement Unsubscribe': getDaysSinceWithNullAs(
          userInfo.productFeatureEmailUnsubscribeDate,
        ),
        'Days Since Email Partner Unsubscribe': getDaysSinceWithNullAs(
          userInfo.offersEmailUnsubscribeDate,
        ),
        'Debt Amount - Auto Loans':
          creditInfo.debtAnalysis.autoLoanDebt.totalDebt,
        'Debt Amount - Credit Cards':
          creditInfo.debtAnalysis.creditCardDebt.totalDebt,
        'Debt Amount - Home Loans':
          creditInfo.debtAnalysis.homeLoanDebt.totalDebt,
        'DTI Ratio': creditInfo.debtAnalysis.debt2IncomeRatio,
        'Funded Sesame Cash account': isFunded,
        'Income From User': resolveBooleanToYesOrNo(
          userInfo.annualIncome !== null,
        ),
        'Is Premium': isPremium(userInfo),
        'Inquiries Count':
          creditInfo.creditScoreAnalysis.creditInquiries.last12Months,
        'Last 30 day active user':
          getDaysSinceWithNull(userInfo.previousLoginDate) < 30,
        'Last 90 day active user':
          getDaysSinceWithNull(userInfo.previousLoginDate) < 90,
        'Logged In Count': userInfo.loggedInCount,
        'Marketing Source': userInfo.marketingSource,
        'Marketing Site Subid': userInfo.subId,
        'Marketing Site Subid2': userInfo.subId2,
        'Monthly Payments - All': creditInfo.debtAnalysis.totalMonthlyPayment,
        'Monthly Payments - Auto Loan':
          creditInfo.debtAnalysis.autoLoanDebt.totalMonthlyPayment,
        'Monthly Payments - Credit Cards':
          creditInfo.debtAnalysis.creditCardDebt.totalMonthlyPayment,
        'Months Since Any Tradeline': monthsSinceAny,

        'Months Since New Auto Tradeline': monthsSinceAuto,
        'Months Since New CC Tradeline': __monthSinceTradelineWithNameMatch(
          creditInfo.debtAnalysis.creditCardDebt.accounts,
          '',
        ),
        'Months Since New Mortgage Tradeline Month': monthsSinceHome,
        'Months Since Other Loan Tradeline': monthsSinceOther,
        'Number of Days Since Registration': getDaysSinceWithNull(
          userInfo.createdDate,
        ),
        'Open Collections':
          creditInfo.creditScoreAnalysis.paymentHistory.collections,
        'Open Late Payments':
          creditInfo.creditScoreAnalysis.paymentHistory.latePaymentAccounts,
        'Open Most Recently Late Account':
          creditInfo.creditScoreAnalysis.paymentHistory.latePaymentAccounts,
        'Payment History': creditInfo.creditScoreAnalysis.paymentHistory.grade,
        'Ref Code': getRefCode(),
        'Reported Income': userInfo.annualIncome,
        'Shift in Collections Since Refresh':
          whatsChanged.negativeMarks.difference,
        'Shift in Inquiries Since Refresh': getShiftInInquiries(creditTrends),
        'Shift in Late Payments Since Refresh':
          whatsChanged.negativeMarks.difference,
        State: userInfo.primaryAddress.state,
        userToken: userInfo.userId,
        'User Segment': userInfo.userSegmentationNumber,
        'Zip Code': userInfo.primaryAddress.zip,
      };

      // Pending question to webengage
      //Object.keys(webengageData).forEach(x => webengage.screen(x, webengageData[x]));

      // if you are trying to troubleshoot the data that is going into webengage
      // consult _weq in the browser's console
      webengage.survey.options('customData', webengageData);
      webengage.survey.options('ruleData', webengageData);
    } catch (e) {
      logger.errorException(
        'An error happened while initializing Web Engage',
        e,
      );
      return {
        error: true,
      };
    }
  } else {
    logger.error('Webengage was not fully initialized');
  }
};
