import {
  OCS_ACCT_CREATION_NO_PREFILL,
  OCS_ACCT_CREATION_PREFILLED,
  OCS_ADDRESS_STEP_FALLBACK_FORM,
  OCS_CONFIRM_INFO_FORM,
  OCS_DUPE_SSN_LOADING,
  OCS_EDIT_ADDRESS,
  OCS_EDIT_ALL_FORM,
  OCS_EMAIL_STEP_FALLBACK_FORM,
  OCS_INIT_SMS_VERIFICATION,
  OCS_INIT_SMS_VERIFICATION_FAILURE,
  OCS_OTP_SENT,
  OCS_PASSWORD_STEP_FALLBACK_FORM,
  OCS_SMS_SENT,
  OCS_VERIFY_SMS_FAILURE,
  OCS_VERIFY_SMS_SUCCESS,
} from 'types/mrph.pages.constants';

export const oneClickSignupConstants = {
  PREFILL_START_SUCCESS: 'ONE_CLICK_SIGNUP_PREFILL_START_SUCCESS',
  LOADING_START: 'ONE_CLICK_SIGNUP_LOADING_START',
  LOADING_FINISH: 'ONE_CLICK_SIGNUP_LOADING_FINISH',
  SIGNUP_1_FORM_SUBMIT_REQUEST: 'ONE_CLICK_SIGNUP_SIGNUP_1_FORM_SUBMIT_REQUEST',
  SIGNUP_1_NO_PREFILL_SUBMIT_SUCCESS:
    'ONE_CLICK_SIGNUP_SIGNUP_1_NO_PREFILL_SUBMIT_SUCCESS',
  SET_REDIRECT_URL: 'SET_ONE_CLICK_SIGNUP_SET_REDIRECT_URL',
  SET_SIGNUP_1_STEP: 'SET_ONE_CLICK_SIGNUP_1_STEP',
  CREATE_USER_SUCCESS: 'ONE_CLICK_SIGNUP_CREATE_USER_SUCCESS',
  UPDATE_ADDRESS_FAIL: 'ONE_CLICK_SIGNUP_UPDATE_ADDRESS_FAIL',
  UPDATE_PASSWORD_SUCCESS: 'ONE_CLICK_SIGNUP_UPDATE_PASSWORD_SUCCESS',
  REPORT_TU_ERROR: 'ONE_CLICK_SIGNUP_REPORT_TU_ERROR',
  CLEAR_TU_ERROR: 'ONE_CLICK_SIGNUP_CLEAR_TU_ERROR',
  INIT_SIGNUP_2: 'ONE_CLICK_SIGNUP_INIT_SIGNUP_2',
  INIT_EDIT_ALL_FORM: 'ONE_CLICK_SIGNUP_INIT_EDIT_ALL_FORM',
  INIT_DUPE_SSN_LOADING: 'ONE_CLICK_SIGNUP_INIT_DUPE_SSN_LOADING',
  SET_SIGNUP_2_STEP: 'SET_ONE_CLICK_SIGNUP_2_STEP',
  INIT_SSN_VALIDATION: 'INIT_SSN_VALIDATION',
  FORM_INIT_COMPLETED: 'ONE_CLICK_SIGNUP_FORM_INIT_COMPLETED',
  CLEAR_SIGNUP_STATE: 'ONE_CLICK_SIGNUP_CLEAR_SIGNUP_STATE',
  SET_SIGNUP_ELIGIBLE_FOR_PREFILL: 'SET_ONE_CLICK_SIGNUP_ELIGIBLE_FOR_PREFILL',
  SET_SIGNUP_PROVE_ELEGIBLE_AUDIENCE: 'SET_SIGNUP_PROVE_ELEGIBLE_AUDIENCE',
  SET_SIGNUP_PREFILL_PHONE_EMAIL: 'SET_ONE_CLICK_SIGNUP_PREFILL_PHONE_EMAIL',
  SET_ONE_CLICK_SIGNUP_2: 'SET_ONE_CLICK_SIGNUP_2',
  SET_FINGER_PRINT_VERIFICATION: 'SET_FINGER_PRINT_VERIFICATION',
  SET_VERIFY_OTP_CONFIG: 'SET_VERIFY_OTP_CONFIG',
};

//signup flow for tracking
export const PREFILL_FLOW = 'prefill';
export const NO_PREFILL_FLOW = 'TU';
export const DUPE_SSN_FLOW = 'dupssn';
export const INVISIBLE_FLOW = 'invisible';

export const optEventsOneClickSignup = {
  [OCS_ACCT_CREATION_PREFILLED]: 'viewed_one_click_signup_1_prefilled',
  [OCS_ACCT_CREATION_NO_PREFILL]: 'viewed_one_click_signup_1_no_prefill',
  [OCS_SMS_SENT]: 'viewed_one_click_signup_1_sms_sent',
  [OCS_OTP_SENT]: 'viewed_one_click_signup_1_otp_sent',
  [OCS_VERIFY_SMS_SUCCESS]: 'viewed_one_click_signup_1_sms_success',
  [OCS_VERIFY_SMS_FAILURE]: 'viewed_one_click_signup_1_sms_failure',
  [OCS_INIT_SMS_VERIFICATION]:
    'viewed_one_click_signup_1_init_sms_verification',
  [OCS_INIT_SMS_VERIFICATION_FAILURE]:
    'viewed_one_click_signup_1_init_sms_verification_failure',
  [OCS_CONFIRM_INFO_FORM]: 'viewed_one_click_signup_2_confirm_info_form',
  [OCS_EDIT_ALL_FORM]: 'viewed_one_click_signup_2_edit_all_form',
  [OCS_EMAIL_STEP_FALLBACK_FORM]: 'one_click_signup_2_email_step_fallback_form',
  [OCS_ADDRESS_STEP_FALLBACK_FORM]:
    'one_click_signup_2_address_step_fallback_form',
  [OCS_PASSWORD_STEP_FALLBACK_FORM]:
    'one_click_signup_2_password_step_fallback_form',
  [OCS_DUPE_SSN_LOADING]: 'viewed_one_click_signup_2_dupe_ssn_loading',
  [OCS_EDIT_ADDRESS]: 'viewed_one_click_signup_2_edit_address',
};

export const optEventsSignupSubmit = {
  [OCS_ACCT_CREATION_PREFILLED]: 'one_click_signup_1_prefilled_submit',
  [OCS_ACCT_CREATION_NO_PREFILL]: 'one_click_signup_1_no_prefill_submit',
  [OCS_OTP_SENT]: 'one_click_signup_1_otp_submit',
  [OCS_CONFIRM_INFO_FORM]: 'one_click_signup_2_confirm_info_form_submit',
  [OCS_EDIT_ALL_FORM]: 'one_click_signup_2_edit_all_form_submit',
  [OCS_EDIT_ADDRESS]: 'one_click_signup_2_edit_address_submit',
  [OCS_EMAIL_STEP_FALLBACK_FORM]:
    'one_click_signup_2_email_step_fallback_form_submit',
  [OCS_ADDRESS_STEP_FALLBACK_FORM]:
    'one_click_signup_2_address_step_fallback_form_submit',
  [OCS_PASSWORD_STEP_FALLBACK_FORM]:
    'one_click_signup_2_password_step_fallback_form_submit',
};

export const ocsFlowTracking = {
  [OCS_CONFIRM_INFO_FORM]: 'prefill_flow',
  [OCS_EMAIL_STEP_FALLBACK_FORM]: 'TU_flow',
  [OCS_ADDRESS_STEP_FALLBACK_FORM]: 'TU_flow',
  [OCS_PASSWORD_STEP_FALLBACK_FORM]: 'TU_flow',
  [OCS_EDIT_ALL_FORM]: 'TU_flow',
  [DUPE_SSN_FLOW]: 'dupssn_flow',
  [INVISIBLE_FLOW]: 'invisible_flow',
};

export const smsRedirectUrl = {
  staging:
    'http://device.staging.payfone.com/fortified/2015/06/01/continueAuth',
  prod: 'http://device.payfone.com/fortified/2015/06/01/continueAuth',
};

export const SIGNUP_CHECK_COOKIE_CLIENT_IP = 'SIGNUP_CHECK_COOKIE_CLIENT_IP';
export const SIGNUP_CALL_SESSIONS = 'SIGNUP_CALL_SESSIONS';
export const SIGNUP_CALL_USERS_PREFILL_START =
  'SIGNUP_CALL_USERS_PREFILL_START';
export const SIGNUP_OPEN_REDIRECT_URL = 'SIGNUP_OPEN_REDIRECT_URL';
export const SIGNUP_CALL_USERS_PREFILL_VERIFICATION_FINGERPRINT =
  'SIGNUP_CALL_USERS_PREFILL_VERIFICATION_FINGERPRINT';

export const timePattern = 'HH:mm:ss.SSS MM/DD/YYYY Z';

export const CONSTANT_INITIAL_STAGE = { pos: 50, max: 100, maxPercentage: 100 };
