import { LOGIN } from 'externals/_tracking/types/eventTypes.constants';
import { waitForElm } from 'helpers/domHelper';

export const captureDashboardPerfMetrics =
  (loginDataCollector, loginAttr) => (dispatch, getState) => {
    const { eventHandler } = loginDataCollector.config;
    const { isOcfLogin } = loginDataCollector.arguments;
    let timeForPartialRender = null;

    waitForElm('#score-impact-module-dashboard').then(_ => {
      timeForPartialRender = performance.now() - loginDataCollector.startTime;
    });

    waitForElm('#overviewPageContent').then(_ => {
      const frontEndRenderTime =
        performance.now() - loginDataCollector.startTime;

      if (!isOcfLogin) {
        eventHandler(LOGIN, {
          ...loginAttr,
          'Partial Time': timeForPartialRender,
          'Total Time': loginDataCollector.totalTime,
          'E2E Time': frontEndRenderTime,
        });
      }
    });
  };
