export const paths = {
  AUTOLOAN_ZERO_OFFER_DESCRIPTION: [
    'disclaimers',
    'autoLoans',
    'zeroAutoLoansDescription',
  ],

  PREQUALIFIED_TOOLTIP: ['disclaimers', 'prequalifiedTooltip'],

  APP_PREPOP_TOOLTIP: ['disclaimers', 'applicationPrepopulatedTooltip'],
  LEX_LAW_PREFILL_TOOLTIP: ['disclaimers', 'lexLawPrefillTooltip'],

  NEW_SCORE_OVERTIME_HEADER: [
    'actions',
    'new_card_score_increase_over_time',
    'header',
  ],
  NEW_SCORE_OVERTIME_PARAGRAPH: [
    'actions',
    'new_card_score_increase_over_time',
    'paragraph',
  ],
  NEW_SCORE_OVERTIME_MONTH1: [
    'actions',
    'new_card_score_increase_over_time',
    'month1',
    'title',
  ],
  NEW_SCORE_OVERTIME_MONTH6: [
    'actions',
    'new_card_score_increase_over_time',
    'month6',
    'title',
  ],
  NEW_SCORE_INCREASE_HEADER: ['actions', 'new_card_score_increase', 'header'],
  EXP_NEW_SCORE_INCREASE_HEADER: [
    'actions',
    'new_card_score_increase',
    'exp_header',
  ],
  NEW_SCORE_INCREASE_PREPARAGRAPH: [
    'actions',
    'new_card_score_increase',
    'pre_paragraph',
  ],
  NEW_SCORE_INCREASE_LIMIT_TOOLTIP: [
    'actions',
    'new_card_score_increase',
    'limit_tooltip',
  ],
  NEW_SCORE_INCREASE_POSTPARAGRAPH: [
    'actions',
    'new_card_score_increase',
    'post_paragraph',
  ],

  CREDIT_REPAIR_EXPERT_HEADER: ['actions', 'credit_repair_expert', 'header'],
  CREDIT_REPAIR_EXPERT_HEADER_EXPERIMENT: [
    'actions',
    'credit_repair_expert',
    'redesign_exp_header',
  ],
  CREDIT_REPAIR_EXPERT_PREPARAGRAPH: [
    'actions',
    'credit_repair_expert',
    'pre_paragraph',
  ],
  CREDIT_REPAIR_EXPERT_PREPARAGRAPH_EXPERIMENT: [
    'actions',
    'credit_repair_expert',
    'redesign_exp_pre_paragraph',
  ],
  CREDIT_REPAIR_EXPERT_POSTPARAGRAPH: [
    'actions',
    'credit_repair_expert',
    'post_paragraph',
  ],
  CREDIT_REPAIR_EXPERT_POSTPARAGRAPH_EXPERIMENT: [
    'actions',
    'credit_repair_expert',
    'redesign_exp_post_paragraph',
  ],
  CREDIT_REPAIR_EXPERT_MONTH1: [
    'actions',
    'credit_repair_expert',
    'month1',
    'title',
  ],
  CREDIT_REPAIR_EXPERT_MONTH6: [
    'actions',
    'credit_repair_expert',
    'month6',
    'title',
  ],

  CREDIT_FOUNDATION_HEADER: ['actions', 'credit_foundation', 'header'],
  CREDIT_FOUNDATION_PREPARAGRAPH: [
    'actions',
    'credit_foundation',
    'pre_paragraph',
  ],
  CREDIT_FOUNDATION_POSTPARAGRAPH: [
    'actions',
    'credit_foundation',
    'post_paragraph',
  ],
  CREDIT_FOUNDATION_WHYIMPORTANT: [
    'actions',
    'credit_foundation',
    'why_important_text',
  ],
  CREDIT_FOUNDATION_WHYIMPORTANT_TT: [
    'actions',
    'credit_foundation',
    'why_important_tooltip',
  ],
  CREDIT_FOUNDATION_CREDIT_OFFERS_HEADER: [
    'actions',
    'credit_foundation',
    'credit_offers_header',
  ],
  CREDIT_LIMIT_HEADER: ['actions', 'credit_limit', 'header'],
  CREDIT_LIMIT_PARAGRAPH: ['actions', 'credit_limit', 'paragraph'],
  CREDIT_LIMIT_MONTH1: ['actions', 'credit_limit', 'month1', 'title'],
  CREDIT_LIMIT_MONTH8: ['actions', 'credit_limit', 'month8', 'title'],

  SELF_LENDER_HEADER: ['actions', 'self_lender', 'header'],
  SELF_LENDER_PARAGRAPH: ['actions', 'self_lender', 'paragraph'],
  SELF_LENDER_MONTH1: ['actions', 'self_lender', 'month1', 'title'],
  SELF_LENDER_MONTH6: ['actions', 'self_lender', 'month6', 'title'],
  SELF_LENDER_DISCLAIMER: ['actions', 'self_lender', 'disclaimerText'],

  NEGATIVE_MARKS_HEADER: ['actions', 'negative_marks', 'header'],
  NEGATIVE_MARKS_PARAGRAPH: ['actions', 'negative_marks', 'paragraph'],

  REDUCE_CREDIT_USAGE_WITH_LIMIT_INCREASE_HEADER: [
    'actions',
    'reduce_credit_usage_with_limit_increase',
    'header',
  ],

  AUTO_REFINANCE_HEADER: ['actions', 'auto_refinance', 'header'],
  REDUCE_CREDIT_USAGE_WITH_LIMIT_INCREASE_PARAGRAPH: [
    'actions',
    'reduce_credit_usage_with_limit_increase',
    'paragraph',
  ],
  REDUCE_CREDIT_USAGE_WITH_LIMIT_INCREASE_LIMIT: [
    'actions',
    'reduce_credit_usage_with_limit_increase',
    'potential_limit',
  ],
  CC_REWARDS_HEADER: ['actions', 'cc_rewards', 'header'],
  CC_REWARDS_PREPARAGRAPH: ['actions', 'cc_rewards', 'pre_paragraph'],
  CC_REWARDS_LIMIT_TOOLTIP: ['actions', 'cc_rewards', 'limit_tooltip'],
  CC_REWARDS_POSTPARAGRAPH: ['actions', 'cc_rewards', 'post_paragraph'],

  MARKETPLACE_PL_INCOME: ['marketplace', 'pl', 'income'],

  DISCLAIMER_AO: ['disclaimers', 'aoTooltip'],
  DISCLAIMER_PREPOP: ['disclaimers', 'applicationPrepopulatedTooltip'],
  DISCLAIMER_CREDIT_ONE_PREPOP: [
    'disclaimers',
    'applicationPrepopulatedCreditOneTooltip',
  ],
  DISCLAIMER_PREDICTED_LIMIT: ['disclaimers', 'predictedCreditLimitTooltip'],
  DISCLAIMER_CREDIT_SAVINGS: ['disclaimers', 'creditSavingsTooltip'],
  DISCLAIMER_OFFER_REVIEW: ['disclaimers', 'offerReviewsDisclaimer'],
  DISCLAIMER_PL_PREQUAL_INCOME: ['disclaimers', 'plPrequalAnnualIncomeTooltip'],
  DISCLAIMER_PL_PREQUAL_RESULT: ['disclaimers', 'plPrequalResultTooltip'],
  DISCLAIMER_CREDIT_BUILDER: ['disclaimers', 'CreditBuilderTooltip'],

  OCF_CARD_LIMITS_POPOVER: ['ocf', 'card_limit', 'info_popover'],

  OCF_CC_REWARDS_CATEGORIES_POPOVER: [
    'ocf',
    'cc_rewards_categories',
    'info_popover',
  ],

  DEBT_RELIEF_HEADER: ['actions', 'debt_relief', 'header'],
  DEBT_RELIEF_TOOLTIP: ['actions', 'debt_relief', 'tooltip'],

  CREDIT_LIMIT: {
    BASE: ['actions', 'credit_limit'],
  },
  SELF_LENDER: {
    BASE: ['actions', 'self_lender'],
  },
  CREDIT_FOUNDATION: {
    BASE: ['actions', 'credit_foundation'],
  },
  NEW_CARD_SCORE_INCREASE: {
    BASE: ['actions', 'new_card_score_increase'],
  },
  NEW_CARD_SCORE_INCREASE_OVER_TIME: {
    BASE: ['actions', 'new_card_score_increase_over_time'],
  },
  REDUCE_CREDIT_USAGE_WITH_LIMIT_INCREASE: {
    BASE: ['actions', 'reduce_credit_usage_with_limit_increase'],
  },
  CC_REWARDS: {
    BASE: ['actions', 'cc_rewards'],
  },
  NEGATIVE_MARKS: {
    BASE: ['actions', 'negative_marks'],
  },
  CREDIT_REPAIR_EXPERT: {
    BASE: ['actions', 'credit_repair_expert'],
  },
  DEBT_RELIEF: {
    BASE: ['actions', 'debt_relief'],
  },
  AUTO_REFINANCE: {
    BASE: ['actions', 'auto_refinance'],
  },
  AUTO_INSURANCE_FOR_CREDIT_SCORE_INCREASE: {
    BASE: ['actions', 'auto_insurance_score_increase'],
    HEADER: ['actions', 'auto_insurance_score_increase', 'header'],
  },
  AUTO_INSURANCE_FOR_NEW_AUTO_LOAN: {
    BASE: ['actions', 'auto_insurance_new_auto'],
    HEADER: ['actions', 'auto_insurance_new_auto', 'header'],
  },
  HOME_INSURANCE: {
    BASE: ['actions', 'home_insurance'],
    HEADER: ['actions', 'home_insurance', 'header'],
  },
  TWO_FACTOR_CTA_TEXT: ['twofactor', 'enroll_cta_text'],
  TWO_FACTOR_RESEND_TEXT: ['twofactor', 'resend_text'],
  RENT_PAYMENT_LINK_BANK_ACCOUNT: [
    'rentpayment',
    'link_bank_accounts',
    'tooltip',
  ],
};
