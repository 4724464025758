import {
  CLEAR_FROZEN_QUEUE,
  SET_FREEZE_EVENTS,
  STORE_EVENT,
} from 'types/eventHandler.constants';
import {
  SET_PAGE_POSITION,
  INIT_TRACKING,
  SET_PAGE_META,
  SET_SESSION_TOKEN,
  SET_REF_CODE,
} from 'types/tracking.constants';
import { GET_SUCCESS as USER_INFO_GET_SUCCESS } from 'types/userInfo.constants';
import {
  CMALERTS_PAYLOAD,
  CSIDALERTS_PAYLOAD,
  PAYLOAD,
} from 'types/payload.constants';
import { LOGOUT, LOGIN_SUCCESS, OCF_LOGIN_SUCCESS } from 'types/app.constants';
import { identifyUnifiedUser } from 'externals/_tracking/mixpanel/mixpanel.track';
import { initBraze } from 'externals/_tracking/braze/Braze.track';
import { setSurveyAttributes } from 'externals/_tracking/webengage';
import {
  setCStrack,
  getCStrack,
  setUsrTkn,
  getRefCode,
} from 'helpers/localstorage';
import { isObj } from 'helpers/objectHelpers';
import {
  buildUserMeta,
  buildCreditProfileMeta,
} from 'helpers/tracking.helpers';
import { CREDIT_INFO_PAYLOAD } from 'types/payload.constants';
import { setCSContentByDataPoints } from 'externals/_tracking/optimizely/optimizely.track';
import { setCookieWithExpiration } from 'helpers/cookie.helper';

const sessionTokenFromLocal = getCStrack() || 'Testing No Token';
const refCodeFromLocal = getRefCode() || 'Testing no ref code';

const initialState = {
  sessionToken: sessionTokenFromLocal,
  refCode: refCodeFromLocal,
  pageName: null,
  oldPageNameMeta: null,
  oldPageName: null,
  pageNameMeta: null,
  pagePosition: null,
  creditProfileMeta: {},
  userMeta: null,
  userInfo: null,
  creditInfo: null,
  fullyLoadedCallback: false,
  frozenEvents: [],
};

/***
 * This method is a callback for once the store is loaded with user and credit
 * info. Then it can properly initialize the webengage data
 *
 * @param userInfo
 * @param creditInfo
 */
const callbackOnLoad = state => {
  setCSContentByDataPoints(state);
  setSurveyAttributes(state);
};

const handleCreditInfoMerge = (action, state) => {
  if (
    state.fullyLoadedCallback === false &&
    state.userInfo &&
    action.creditInfo
  ) {
    callbackOnLoad({ ...state, creditInfo: action.creditInfo });
  }

  const creditProfileMeta = buildCreditProfileMeta(
    action.creditInfo,
    state.sessionToken,
    state.refCode,
  );
  return creditProfileMeta;
};

const handleCmAlertMerge = (action, state) => {
  if (state.fullyLoadedCallback === false && action.cmAlerts) {
    callbackOnLoad({ ...state, cmAlerts: action.cmAlerts });
  }

  return {
    cmAlerts: action.cmAlerts,
    cmAlertsLoading: action.cmAlertsLoading,
    cmAlertsError: action.cmAlertsError,
    fullyLoadedCallback: !!(
      state.userInfo &&
      state.creditInfo &&
      (state.csidAlerts || state.csidAlertsError) &&
      (action.cmAlerts || action.cmAlertsError)
    ),
  };
};

const handleCsidAlert = (action, state) => {
  if (state.fullyLoadedCallback === false && action.csidAlerts) {
    callbackOnLoad({ ...state, csidAlerts: action.csidAlerts });
  }

  return {
    csidAlerts: action.csidAlerts,
    csidAlertsLoading: action.csidAlertsLoading,
    csidAlertsError: action.csidAlertsError,
    fullyLoadedCallback: !!(
      state.userInfo &&
      state.creditInfo &&
      (action.csidAlerts || action.csidAlertsError) &&
      (state.cmAlerts || state.cmAlertsError)
    ),
  };
};

const handleUserInfoMerge = (action, state) => {
  const userInfo = action.userInfo;
  const userId = userInfo.userId;
  if (
    state.fullyLoadedCallback === false &&
    action.userInfo &&
    state.creditInfo
  ) {
    callbackOnLoad({
      ...state,
      userInfo: action.userInfo,
    });
  }

  identifyUnifiedUser(userId);
  initBraze(userId);

  setUsrTkn(userId);

  const userTrackingMeta = buildUserMeta(userInfo, action.creditInfo);
  return userTrackingMeta;
};

const mergeOnCreditInfo = (state, prop) => {
  if (typeof state.creditProfileMeta === 'object') {
    return Object.assign({}, state.creditProfileMeta, prop);
  } else {
    return state.creditProfileMeta;
  }
};

const handleLoginMerge = (action, state) => {
  let sessionToken = null;
  let userMeta = state.userMeta;

  const { sessionIdentifier, userInfo, creditInfo } = action;

  if (sessionIdentifier) {
    sessionToken = sessionIdentifier;
    setCStrack(sessionToken);
    // used by rentreporting.creditsesame.com
    setCookieWithExpiration(
      'sessionIdentifier',
      sessionToken,
      1,
      null,
      null,
      true,
    );
  } else {
    sessionToken = getCStrack();
  }

  if (userMeta === null && isObj(userInfo)) {
    userMeta = buildUserMeta(userInfo, action.creditInfo);
  }

  const newState = {
    sessionToken,
    userInfo,
    userMeta,
    creditInfo,
    creditProfileMeta: mergeOnCreditInfo(state, {
      'Session Identifier': sessionToken,
    }),
  };

  callbackOnLoad(newState);

  return newState;
};

const handleOCFLoginMerge = (state, action) => {
  const sessionToken = action.sessionIdentifier;
  setCStrack(sessionToken);
  return {};
};

const trackingReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_FREEZE_EVENTS:
      return {
        ...state,
        isFreezeEvents: action.isFreezeEvents,
        frozenEvents: action.frozenEvents ?? state.frozenEvents,
      };
    case STORE_EVENT:
      return {
        ...state,
        frozenEvents: [...state.frozenEvents, action.event],
      };
    case CLEAR_FROZEN_QUEUE:
      return {
        ...state,
        frozenEvents: [],
      };
    case SET_REF_CODE:
      return Object.assign({}, state, {
        refCode: action.refCode,
        creditProfileMeta: mergeOnCreditInfo(state, {
          'Ref Code': action.refCode,
        }),
      });
    case SET_PAGE_POSITION:
      return Object.assign({}, state, {
        pagePosition: action.pagePosition,
      });
    case INIT_TRACKING:
      return Object.assign({}, initialState, {
        refCode: action.refCode,
      });
    case SET_PAGE_META:
      return Object.assign({}, state, {
        pageNameMeta: action.pageNameMeta,
        pageName: action.pageName,
        oldPageNameMeta: action.oldPageNameMeta,
        oldPageName: action.oldPageName,
      });
    case SET_SESSION_TOKEN:
      setCStrack(action.sessionToken);
      return Object.assign({}, state, {
        sessionToken: action.sessionToken,
        creditProfileMeta: mergeOnCreditInfo(state, {
          'Session Identifier': action.sessionToken,
        }),
      });
    case PAYLOAD:
      if (action.creditInfo || action.userInfo) {
        const fullyLoadedCallback = !!(
          state.userInfo &&
          action.creditInfo &&
          (state.csidAlerts || state.csidAlertsError) &&
          (state.cmAlerts || state.cmAlertsError)
        );

        let creditProfileMeta, userMeta;

        if (action.creditInfo) {
          creditProfileMeta = handleCreditInfoMerge(action, state);
        }

        if (action.userInfo) {
          userMeta = handleUserInfoMerge(action, state);
        }
        return Object.assign({}, state, {
          creditProfileMeta,
          userMeta,
          userInfo: action.userInfo,
          creditInfo: action.creditInfo,
          fullyLoadedCallback,
        });
      } else {
        return state;
      }
    case CREDIT_INFO_PAYLOAD:
      if (action.creditInfo) {
        return Object.assign({}, state, {
          creditProfileMeta: handleCreditInfoMerge(action, state),
          creditInfo: action.creditInfo,
          fullyLoadedCallback: !!(
            state.userInfo &&
            action.creditInfo &&
            (state.csidAlerts || state.csidAlertsError) &&
            (state.cmAlerts || state.cmAlertsError)
          ),
        });
      } else {
        return state;
      }
    case CMALERTS_PAYLOAD:
      if (action.cmAlerts || action.cmAlertsError) {
        return Object.assign({}, state, handleCmAlertMerge(action, state));
      } else {
        return state;
      }
    case CSIDALERTS_PAYLOAD:
      if (action.csidAlerts || action.csidAlertsError) {
        return Object.assign({}, state, handleCsidAlert(action, state));
      } else {
        return state;
      }
    case USER_INFO_GET_SUCCESS:
      if (action.userInfo) {
        return Object.assign({}, state, {
          userMeta: handleUserInfoMerge(action, state),
          userInfo: action.userInfo,
          fullyLoadedCallback: !!(
            action.userInfo &&
            state.creditInfo &&
            (state.csidAlerts || state.csidAlertsError) &&
            (state.cmAlerts || state.cmAlertsError)
          ),
        });
      } else {
        return state;
      }
    case LOGIN_SUCCESS:
      return Object.assign({}, state, handleLoginMerge(action, state));
    case OCF_LOGIN_SUCCESS:
      return Object.assign({}, state, handleOCFLoginMerge(state, action));
    case LOGOUT:
      setCStrack(null);
      return Object.assign({}, initialState);
    default:
      return state;
  }
};

export default trackingReducer;
