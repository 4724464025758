import {
  OPT_VISITOR_ID,
  OPT_SESSSION_IDENTIFIER,
  OPT_USERTOKEN,
} from 'externals/_tracking/optimizely/optimizely.constants.js';
import { getEnvironment, DEFAULT, TEST, isPreprod2 } from 'helpers/env';
import moment from 'moment';
import { getOptimizelyPrefix } from 'helpers/env';

const isOptimizelyProperlyInitialized = () => {
  return window.optimizely && typeof window.optimizely.get === 'function';
};

const setCustomOptAttribute = (attribute, value) => {
  if (isOptimizelyProperlyInitialized()) {
    const stored = getCustomOptAttribute(attribute);
    if (stored == null || stored !== value) {
      window.optimizely.push({
        type: 'user',
        attributes: { [attribute]: value },
      });
    }
  }
};

export const optimizelyPush = pushObj => {
  window['optimizely'] = window['optimizely'] || [];
  window['optimizely'].push(pushObj);
};

export const activateOptSnippet = () => {
  if (isOptimizelyProperlyInitialized()) {
    window['optimizely'].push({
      type: 'activate',
    });
  }
};

const getCustomOptAttribute = attribute => {
  let custom;
  if (!isOptimizelyProperlyInitialized()) {
    try {
      custom = window['optimizely'].get('visitor').custom;
    } catch (e) {
      custom = null;
      console.warn(
        'Error retrieving optimizely file  (possible tracking content blocked). ' +
          e,
      );
    }
  } else {
    return null;
  }
  if (custom === undefined || custom === null) {
    return null;
  }
  if (custom[attribute] === undefined || custom[attribute] === null) {
    return null;
  }
  return custom[attribute].value;
};

export const initOptVisitorId = () => {
  const vId = document.cookie.replace(
    /(?:(?:^|.*;\s*)vid\s*=\s*([^;]*).*$)|^.*$/,
    '$1',
  );
  setCustomOptAttribute(OPT_VISITOR_ID, vId);
};

export const initOptSessionAttributes = (userId, sessionIdentifier) => {
  setCustomOptAttribute(OPT_SESSSION_IDENTIFIER, sessionIdentifier);
  setCustomOptAttribute(OPT_USERTOKEN, userId);
};

export const manuallyActivatePage = apiName => {
  optimizelyPush({ type: 'page', pageName: apiName });
  console.log('Manually activated page: ', apiName);
};

export const manuallyDeactivatePage = apiName => {
  optimizelyPush({
    type: 'page',
    pageName: apiName,
    isActive: false,
  });
};

export const pushOptAttribute = attributesObj => {
  optimizelyPush({ type: 'user', attributes: attributesObj });
};

export const getExpEnv = () => {
  const env = getEnvironment();

  if (env === DEFAULT) return TEST;
  else return env;
};

const getActiveExperiments = () => {
  if (isOptimizelyProperlyInitialized()) {
    return window.optimizely.get('state').getExperimentStates({
      isActive: true,
    });
  } else {
    return null;
  }
};

export const isActive = (experiment, variation) => {
  if (window.mrphOpt && window.mrphOpt.length > 0) {
    let result = null;
    for (const expObj of window.mrphOpt) {
      //if variation passed, return if experiment and variation are active
      //otherwise check only if experiment active and return variation
      if (variation && expObj.exp === experiment && expObj.var === variation) {
        result = true;
      } else if (!variation && expObj.exp === experiment) {
        result = { activeVariation: expObj.var };
      }
    }
    return result;
  } else return false;
};

export const getVariation = experiment => {
  if (window.mrphOpt && window.mrphOpt.length > 0) {
    for (const expObj of window.mrphOpt) {
      if (expObj.exp === experiment) {
        return expObj.var;
      }
    }
  } else return false;
};

/**
 *
 * @param {const string} experiment
 * @param {string} variation (optional)
 * @returns {Promise<unknown>}
 *
 * Will return a promise that either:
 * resolves with  ${variation_name} or reject if experiment is not active
 *
 * If you pass in optional @param variation promise will return true/false for specific variation
 *  Note: In case of non - active experiments the polling happens for 500 ms
 *
 */
export const pollForExp = (experiment, variation, timeout) => {
  return new Promise(function (resolve, reject) {
    if (global.isUnitTestSuite) {
      reject();
      return;
    }
    const start = moment();
    const finalTimeout = timeout ? timeout : 500;
    (function checkActiveTimeout() {
      const now = moment();

      const result = isActive(experiment, variation);
      if (result) {
        const { activeVariation } = result;
        return resolve(activeVariation ? activeVariation : true);
      } else if (now.diff(start) > finalTimeout) {
        return reject();
      } else setTimeout(checkActiveTimeout, 30);
    })();
  });
};

export const pollForLoginExp = (experiment, variation) => {
  return new Promise(function (resolve, reject) {
    const start = moment();
    (function checkActiveTimeout() {
      const now = moment();

      const result = isActive(experiment, variation);
      if (result) {
        const { activeVariation } = result;
        return resolve(activeVariation ? activeVariation : true);
      } else if (now.diff(start) > 500) {
        return resolve({});
      } else setTimeout(checkActiveTimeout, 30);
    })();
  });
};

const getOptimizelyExperimentName = experimentIdentifier => {
  return getOptimizelyPrefix() + ': ' + experimentIdentifier;
};

export const getActiveExperimentVariation = experimentIdentifier => {
  const activeExperiments = getActiveExperiments();
  const expName = getOptimizelyExperimentName(experimentIdentifier);
  if (activeExperiments) {
    for (let [, expObj] of Object.entries(activeExperiments)) {
      if (!expObj.isInExperimentHoldback) {
        const { experimentName, variation } = expObj;
        if (experimentName === expName && variation) {
          return variation.name;
        }
      }
    }
  }

  return false;
};

export const checkForActiveExperiment = experimentIdentifier => {
  if (isOptimizelyProperlyInitialized) {
    const experimentName = getOptimizelyExperimentName(experimentIdentifier);

    return window.optimizely
      .get('state')
      .getExperimentStates(function (expState) {
        return (
          expState.isActive &&
          !expState.isInExperimentHoldback &&
          expState.experimentName === experimentName
        );
      });
  } else {
    return null;
  }
};

export const isExpPrepod2Disabled = isExpVariation => {
  const isOptimizelyGloballyDisabled = isPreprod2();
  return isExpVariation && isOptimizelyGloballyDisabled === false;
};
