import $t from 'locales/en/translation';

export const IN_PROGRESS = 'IN_PROGRESS';
export const ENROLLED = 'ENROLLED';
export const UNENROLLED = 'UNENROLLED';

// Rent reporting sessions purposes
export const PROPERTIES = 'PROPERTIES';
export const DOCUMENTS = 'DOCUMENTS';
export const PAYMENTS = 'PAYMENTS';
export const ACCOUNTS = 'ACCOUNTS';
export const AUTHENTICATION = 'AUTHENTICATION';

export const RESET_MEMBER = 'RESET_MEMBER';
export const TRADELINE_CLOSED = 'TRADELINE_CLOSED';
export const ACCOUNT_CLOSED_TRADELINE_REMOVED =
  'ACCOUNT_CLOSED_TRADELINE_REMOVED';
export const PREMIUM_ACCOUNT_CANCELLED = 'PREMIUM_ACCOUNT_CANCELLED';
export const TRADELINE_TRANSFERRED = 'TRADELINE_TRANSFERRED';

export const CAN_RESTART_RENT_REPORTING = [
  RESET_MEMBER,
  TRADELINE_CLOSED,
  ACCOUNT_CLOSED_TRADELINE_REMOVED,
  PREMIUM_ACCOUNT_CANCELLED,
  TRADELINE_TRANSFERRED,
];

// Signup flow statuses
export const LANDLORD_DETAILS_NEEDED = 'LANDLORD_DETAILS_NEEDED';
export const RENTAL_DETAILS_NEEDED = 'RENTAL_DETAILS_NEEDED';
export const BANK_ACCOUNT_NOT_LINKED = 'BANK_ACCOUNT_NOT_LINKED';
export const BANK_ACCOUNT_LINKED = 'BANK_ACCOUNT_LINKED';
export const IDENTIFY_PAYMENTS = 'IDENTIFY_PAYMENTS';

// In progress payment identified statuses
export const READY_FOR_VERIFICATION = 'READY_FOR_VERIFICATION';
export const PUSHED_TO_SECONDARY_VERIFICATION =
  'PUSHED_TO_SECONDARY_VERIFICATION';
export const WAITING_FOR_INFORMATION = 'WAITING_FOR_INFORMATION';
export const VOR_SENT = 'VOR_SENT';
export const ESCALATED_VOR = 'ESCALATED_VOR';
export const UNABLE_TO_VERIFY = 'UNABLE_TO_VERIFY';
export const UNKNOWN_SUBSTATUS = 'UNKNOWN_SUBSTATUS';

// Enrolled Payment identified statuses
export const READY_FOR_SUBMISSION = 'READY_FOR_SUBMISSION';
export const WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT';
export const CALL_TO_VERIFY = 'CALL_TO_VERIFY';

export const CAN_CALL_GET_PAYMENT_IN_PROGRESS = [
  READY_FOR_VERIFICATION,
  PUSHED_TO_SECONDARY_VERIFICATION,
  ESCALATED_VOR,
  VOR_SENT,
  WAITING_FOR_INFORMATION,
  CALL_TO_VERIFY,
  UNKNOWN_SUBSTATUS,
];

// Unenrolled Identified statuses
export const VOR_REJECTED = 'VOR_REJECTED';

// Rent Reporting Actions
export const RENT_REPORTING_PAYMENTS_REQUEST =
  'RENT_REPORTING_PAYMENTS_REQUEST';
export const RENT_REPORTING_PAYMENTS_ERROR = 'RENT_REPORTING_PAYMENTS_ERROR';
export const RENT_REPORTING_PAYMENTS_SUCCESS =
  'RENT_REPORTING_PAYMENTS_SUCCESS';

// Rent Reporting Auth Actions
export const RENT_REPORTING_AUTH_REQUEST = 'RENT_REPORTING_AUTH_REQUEST';
export const RENT_REPORTING_AUTH_ERROR = 'RENT_REPORTING_AUTH_ERROR';
export const RENT_REPORTING_AUTH_SUCCESS = 'RENT_REPORTING_AUTH_SUCCESS';

// List of statuses and substatses combinations
export const IN_PROGRESS__LANDLORD_DETAILS_NEEDED =
  'IN_PROGRESS__LANDLORD_DETAILS_NEEDED';
export const IN_PROGRESS__RENTAL_DETAILS_NEEDED =
  'IN_PROGRESS__RENTAL_DETAILS_NEEDED';
export const IN_PROGRESS__BANK_ACCOUNT_NOT_LINKED =
  'IN_PROGRESS__BANK_ACCOUNT_NOT_LINKED';
export const IN_PROGRESS__BANK_ACCOUNT_LINKED =
  'IN_PROGRESS__BANK_ACCOUNT_LINKED';
export const IN_PROGRESS__IDENTIFY_PAYMENTS__DISCONNECTED_BANK =
  'IN_PROGRESS__IDENTIFY_PAYMENTS__DISCONNECTED_BANK';
export const IN_PROGRESS__IDENTIFY_PAYMENTS__NOT_DISCONNECTED_BANK =
  'IN_PROGRESS__IDENTIFY_PAYMENTS__NOT_DISCONNECTED_BANK';
export const IN_PROGRESS__READY_FOR_VERIFICATION__DISCONNECTED_BANK =
  'IN_PROGRESS__READY_FOR_VERIFICATION__DISCONNECTED_BANK';
export const IN_PROGRESS__READY_FOR_VERIFICATION__NOT_DISCONNECTED_BANK =
  'IN_PROGRESS__READY_FOR_VERIFICATION__NOT_DISCONNECTED_BANK';
export const IN_PROGRESS__PUSHED_TO_SECONDARY_VERIFICATION__DISCONNECTED_BANK =
  'IN_PROGRESS__PUSHED_TO_SECONDARY_VERIFICATION__DISCONNECTED_BANK';
export const IN_PROGRESS__PUSHED_TO_SECONDARY_VERIFICATION__NOT_DISCONNECTED_BANK =
  'IN_PROGRESS__PUSHED_TO_SECONDARY_VERIFICATION__NOT_DISCONNECTED_BANK';
export const IN_PROGRESS__WAITING_FOR_INFORMATION__DISCONNECTED_BANK =
  'IN_PROGRESS__WAITING_FOR_INFORMATION__DISCONNECTED_BANK';
export const IN_PROGRESS__WAITING_FOR_INFORMATION__NOT_DISCONNECTED_BANK =
  'IN_PROGRESS__WAITING_FOR_INFORMATION__NOT_DISCONNECTED_BANK';
export const IN_PROGRESS__WAITING_FOR_PAYMENT =
  'IN_PROGRESS__WAITING_FOR_PAYMENT';
export const IN_PROGRESS__VOR_SENT = 'IN_PROGRESS__VOR_SENT';
export const IN_PROGRESS__CALL_TO_VERIFY = 'IN_PROGRESS__CALL_TO_VERIFY';
export const IN_PROGRESS__ESCALATED_VOR = 'IN_PROGRESS__ESCALATED_VOR';
export const IN_PROGRESS__UNABLE_TO_VERIFY = 'IN_PROGRESS__UNABLE_TO_VERIFY';

export const ENROLLED__READY_FOR_SUBMISSION = 'ENROLLED__READY_FOR_SUBMISSION';
export const ENROLLED__WAITING_FOR_PAYMENT__DISCONNECTED_BANK =
  'ENROLLED__WAITING_FOR_PAYMENT__DISCONNECTED_BANK';
export const ENROLLED__WAITING_FOR_PAYMENT__NOT_MISSING_PAYMENTS =
  'ENROLLED__WAITING_FOR_PAYMENT__NOT_MISSING_PAYMENTS';
export const ENROLLED__WAITING_FOR_PAYMENT__MISSING_PAYMENTS =
  'ENROLLED__WAITING_FOR_PAYMENT__MISSING_PAYMENTS';
export const ENROLLED__READY_FOR_VERIFICATION =
  'ENROLLED__READY_FOR_VERIFICATION';
export const ENROLLED__READY_FOR_VERIFICATION__DISCONNECTED_BANK =
  'ENROLLED__READY_FOR_VERIFICATION__DISCONNECTED_BANK';
export const ENROLLED__PUSHED_TO_SECONDARY_VERIFICATION =
  'ENROLLED__PUSHED_TO_SECONDARY_VERIFICATION';
export const ENROLLED__WAITING_FOR_INFORMATION =
  'ENROLLED__WAITING_FOR_INFORMATION';
export const ENROLLED__CALL_TO_VERIFY__MISSING_PAYMENTS =
  'ENROLLED__CALL_TO_VERIFY__MISSING_PAYMENTS';
export const ENROLLED__CALL_TO_VERIFY__DISCONNECTED_BANK =
  'ENROLLED__CALL_TO_VERIFY__DISCONNECTED_BANK';
export const ENROLLED__VOR_SENT = 'ENROLLED__VOR_SENT';
export const ENROLLED__ESCALATED_VOR = 'ENROLLED__ESCALATED_VOR';
export const ENROLLED__UNABLE_TO_VERIFY = 'ENROLLED__UNABLE_TO_VERIFY';

export const UNENROLLED__VOR_REJECTED = 'UNENROLLED__VOR_REJECTED';

export const STATUS_NOT_FOUND = 'STATUS_NOT_FOUND';

export const CONGRATS = 'CONGRATS';

export const retrieveRentReportingStatusText = rentReportingStatus => {
  return $t.rentReporting['statuses'][rentReportingStatus];
};
