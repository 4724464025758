(function () {
  var recurly = document.createElement('script');
  recurly.type = 'text/javascript';
  recurly.charSet = 'utf-8';
  recurly.src = 'https://js.recurly.com/v4/recurly.js';
  document.head.appendChild(recurly);

  var recurlyStyle = document.createElement('link');
  recurlyStyle.rel = 'stylesheet';
  recurlyStyle.href = 'https://js.recurly.com/v4/recurly.css';
  document.head.appendChild(recurlyStyle);
})();
