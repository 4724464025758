import { mergePayloadChanges } from 'helpers/reducer.helper';
import { CONFIG_PAYLOAD, PAYLOAD } from 'types/payload.constants';
import { LOGIN_SUCCESS } from 'types/app.constants';

const initialState = {
  configLoading: false,
  configError: null,
  config: null,
};

export default function configReducer(state = initialState, action) {
  switch (action.type) {
    case CONFIG_PAYLOAD:
    case LOGIN_SUCCESS:
    case PAYLOAD:
      return mergePayloadChanges(state, action);
    default:
      return state;
  }
}
