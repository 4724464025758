import { buildOfferTrackingEncodedLink } from 'api-urls';
import { OVERVIEW_URL } from 'types/mrph.routes.constants';

export const cleanPopupPathsWithExceptions = (path, exceptions = []) => {
  const urlsToRemove = [
    { id: 'offerDetails', regex: /\/offerDetails.*/ },
    { id: 'prequalFlow', regex: /\/prequalFlow.*/ },
    { id: 'credit-card-pre-approval', regex: /\/credit-card-pre-approval.*/ },
    { id: 'terms', regex: /\/terms.*/ },
    { id: 'privacy', regex: /\/privacy.*/ },
    { id: 'cfsb-privacy', regex: /\/cfsb-privacy.*/ },
    { id: 'prescreen', regex: /\/prescreen.*/ },
    { id: '2', regex: /\/2fa/ },
    { id: 'prequalTerms', regex: /\/prequalTerms.*/ },
    { id: 'commsTerms', regex: /\/commsTerms.*/ },
    { id: 'premiumdiscountterms', regex: /\/premiumdiscountterms.*/ },
    { id: 'premiumplustrial', regex: /\/premiumplustrial.*/ },
    { id: 'premiumPaymentPending', regex: /\/premiumPaymentPending.*/ },
    {
      id: 'preQualProgamOptInReminderTerms',
      regex: /\/preQualProgamOptInReminderTerms.*/,
    },
    {
      id: 'premiumReactivatePendingCancellation',
      regex: /\/premiumReactivatePendingCancellation.*/,
    },
    { id: 'premiumReactivateCancel', regex: /\/premiumReactivateCancel.*/ },
    { id: 'premiumOpts', regex: /\/premiumOpts.*/ },
    { id: 'offers/details', regex: /\/offers\/details.*/ },
    { id: 'legalDo', regex: /\/legalDocs/ },
    { id: 'cashAccount', regex: /\/cashAccount.*/ },
    { id: 'sesameCashEnrollment', regex: /\/sesameCashEnrollment.*/ },
    { id: 'banner', regex: /\/banner.*/ },
    { id: 'learn', regex: /\/learn.*/ },
    { id: 'enrol', regex: /\/enroll$/ },
  ];
  return cleanPopupPathsCustom(
    path,
    urlsToRemove
      .filter(url => {
        return !exceptions.includes(url.id);
      })
      .map(url => {
        return url.id;
      }),
  );
};
export const cleanPopupPaths = path => {
  let clean = path.replace(/\/offerDetails.*/, ``);
  clean = clean.replace(/\/prequalFlow.*/, ``);
  clean = clean.replace(/\/credit-card-pre-approval.*/, ``);
  clean = clean.replace(/\/terms.*/, ``);
  clean = clean.replace(/\/privacy.*/, ``);
  clean = clean.replace(/\/cfsb-privacy.*/, ``);
  clean = clean.replace(/\/prescreen.*/, ``);
  clean = clean.replace(/\/2fa/, ``);
  clean = clean.replace(/\/prequalTerms.*/, ``);
  clean = clean.replace(/\/commsTerms.*/, ``);
  clean = clean.replace(/\/premiumdiscountterms.*/, ``);
  clean = clean.replace(/\/premiumplustrial.*/, ``);
  clean = clean.replace(/\/premiumPaymentPending.*/, ``);
  clean = clean.replace(/\/preQualProgamOptInReminderTerms.*/, ``);
  clean = clean.replace(/\/premiumReactivatePendingCancellation.*/, ``);
  clean = clean.replace(/\/premiumReactivateCancel.*/, ``);
  clean = clean.replace(/\/premiumOpts.*/, ``);
  clean = clean.replace(/\/offers\/details.*/, ``);
  clean = clean.replace(/\/purchaseConfirmation.*/, ``);
  clean = clean.replace(/\/creditToolsUnlocks.*/, ``);
  clean = clean.replace(/\/plusUpgradeAndConvert.*/, ``);
  clean = clean.replace(/\/cancelPlan.*/, ``);
  clean = cleanLegalDocsPopupPath(clean);
  clean = cleanSesameCashPaths(clean);
  return clean;
};

export const cleanLegalDocsPopupPath = clean => {
  clean = clean.replace(/\/legalDocs/, ``);
  return clean;
};

const cleanSesameCashPaths = clean => {
  clean = clean.replace(/\/cashAccount.*/, ``);
  clean = clean.replace(/\/sesameCashEnrollment.*/, ``);
  clean = clean.replace(/\/banner.*/, ``);
  clean = clean.replace(/\/learn.*/, ``);
  clean = clean.replace(/\/enroll$/, ``);
  clean = clean.replace(/\/cashAccount.*/, '');
  return clean;
};

export const cleanPopupPathsCustom = (path, urlsToRemove = []) => {
  let clean = path;
  urlsToRemove.forEach(url => {
    clean = clean.replace(url, ``);
  });
  return clean;
};

export const buildEncodedLinkForTrackingApi = (newLink, offerMetadata) => {
  const obj = {
    productType: offerMetadata.offerType,
    partnerUrl: newLink,
    provider: offerMetadata.providerName,
  };

  const encodedLink = Buffer.from(JSON.stringify(obj))
    .toString('base64')
    .replace('+', '-')
    .replace('/', '_');

  return buildOfferTrackingEncodedLink(encodedLink);
};

export const isDashboard = path => {
  return path?.indexOf(OVERVIEW_URL) !== -1;
};

export const isCash = path => {
  return path?.indexOf('/cash/') !== -1;
};
